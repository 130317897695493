import * as actions from "redux/action-types";
import {
    getPaymentHistory as getPaymentHistoryAPI,
} from "services/PaymentService";
import { Strings } from "resources";
import { CancelToken } from "axios";

/**
 * setPaymentLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setPaymentLoadingState(isLoading) {
    return {
        type: actions.LOADING_PAYMENT_HISTORY_DATA,
        isLoading,
    };
}

/**
 * addPaymentHistory
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Course into State
 */
export function addPaymentHistory(items, hasMore, page) {
    return {
        type: actions.ADD_PAYMENT_HISTORY_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setPaymentCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setPaymentCancelToken(token) {
    return {
        type: actions.SET_PAYMENT_HISTORY_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMorePaymentHistory
 * @description check If more files are there or not
 */
export function hasNoMorePaymentHistory() {
    return {
        type: actions.SET_PAYMENT_HISTORY_HAS_NO_MORE,
    };
}

/**
 * getPaymentHistory
 * @param {boolean} reset
 * @param {string} examId
 * @param {string} tokenId
 * @description Get Courses with Pagination
 */
export function getPaymentHistory(reset, tokenId, userId) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.paymentData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setPaymentCancelToken(newRequestSource));
            await dispatch(addPaymentHistory([], true, 0));
        }

        await dispatch(setPaymentLoadingState(true));

        let { paymentData } = await getState();
        let { page, limit, items, cancelRequest } = paymentData;
        const { data, error } = await getPaymentHistoryAPI(tokenId, userId);
        if (!error && data.status && Array.isArray(data.data) && data.data.length) {
            dispatch(addPaymentHistory(mergeData(items, data.data), true, page + 1));
        } else {
            dispatch(hasNoMorePaymentHistory());
        }

        dispatch(setPaymentLoadingState(false));
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.payment_id) {
            let index = oldData.findIndex(data => (data.payment_id && data.payment_id === newDataItem.payment_id));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
}
