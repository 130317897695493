import axios from "axios";
import appConfig from "config/config";
import apiConfig from "config/api";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { logOutUser } from "services/AuthService";
import { log } from "utils";

const defaultHeaders = {
    "Content-Type": "application/json",
  };

const appClient = axios.create({
  baseURL: appConfig.chatAPIUrl,
  timeout: 40000,
  headers: defaultHeaders,
});

appClient.interceptors.request.use(async function (config) {
    log("Starting Request", config);
    return config;
  });
  appClient.interceptors.response.use(
    function (response) {
      log("response", response);
      if (
        response.data &&
        response.data.exceptionMessage &&
        response.data.exceptionMessage === "Unauthorized"
      ) {
        log("token expired, logout user");
        logOutUser();
      }
      return response;
    },
    function (error) {
      log("Request Failed:", error.config);
      /* istanbul ignore else */
      if (error.response) {
        if (error.response.status === "401") {
          log("token expired, logout user");
          logOutUser();
        }
        if (error.response.status === 403) {
          log("token expired, logout user");
          logOutUser();
          window.location.reload();
        }
        log("Status:", error.response.status);
        log("Data:", error.response.data);
        log("Headers:", error.response.headers);
      } else {
        log("Error Message:", error.message);
      }
      /* istanbul ignore next */
      return Promise.reject(error.response || error.message);
    }
  );

export async function fetchPaginatedQuestions(userId, page, limit) {
    const chatAPIUrl = apiConfig.chat.getMessages + "/user/" + userId + "/page/" + page + "/size/" + limit;
    try {
        const response = await appClient.get(chatAPIUrl);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * submitQuestion
 * @param {string} userId
 * @param {string} messageText
 * @description Submit Main Quill It Question
 */
export async function submitQuestion(userId, messageText, messageType) {
    const payload = {
        'message': messageText,
        'messageType': messageType
    };
    const chatAPIUrl = apiConfig.chat.submitMessage + "/" + userId;
    try {
        const response = await appClient.post(chatAPIUrl, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * submitTextComment
 * @param {*} userId
 * @param {*} messageId
 * @param {*} messageText
 * @param {*} messageType
 * @description Submit Text Comment
 */
export async function submitTextComment(userId, messageId, messageText, messageType) {
  const chatAPIUrl = apiConfig.chat.commentMessage + "/" + messageId + "/user/" + userId;
  try {
      const response = await appClient.post(chatAPIUrl, messageText);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

/**
 * submitImageComment
 * @param {*} userId
 * @param {*} messageId
 * @param {*} messageText
 * @param {*} messageType
 * @param {*} selectedFile
 * @description Submit Image Comment
 */
export async function submitImageComment(userId, messageId, messageText, messageType, selectedFile) {
  try {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append("message", messageText);
    formData.append("messageType", messageType);
    const chatAPIUrl = apiConfig.chat.commentImage + "/" + messageId + "/user/" + userId;
    const response = await appClient.post(chatAPIUrl, formData);
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (!axios.isCancel(error)) {
      return hasError(error);
    }
  }
}

/**
 * submitImageQuestion
 * @param {*} userId
 * @param {*} messageText
 * @param {*} messageType
 * @param {*} selectedFile
 * @description Submit Image Question
 */
export async function submitImageQuestion(userId, messageText, messageType, selectedFile) {
  try {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append("message", messageText);
    formData.append("messageType", messageType);
    const chatAPIUrl = apiConfig.chat.submitMessage + "/Image/" + userId;
    const response = await appClient.post(chatAPIUrl, formData);
    return hasSuccess(response.data);
  } catch (error) {
    /* istanbul ignore next */
    if (!axios.isCancel(error)) {
      return hasError(error);
    }
  }
}

/**
 * replyQuestion
 * @param {string} userId
 * @param {string} messageText
 * @param {string} messageType
 * @param {string} replyingToMessageId
 * @description Reply to Question
 */
export async function replyQuestion(userId, messageText, messageType, replyingToMessageId) {
    const payload = {
        'message': messageText,
        'messageType': messageType,
        'replyingToMessageId': replyingToMessageId
    };
    const chatAPIUrl = apiConfig.chat.replyMessage + "/" + userId;
    try {
        const response = await appClient.post(chatAPIUrl, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * LikeQuestion
 * @param {*} userId
 * @param {*} messageText
 * @param {*} messageType
 * @param {*} messageId
 * @description Like Question
 */
export async function LikeQuestion(userId, messageText, messageType, messageId) {
  const payload = {
      'message': messageText,
      'messageType': messageType
  };
  const chatAPIUrl = apiConfig.chat.likeMessage + "/" + messageId + "/" + userId;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

/**
 * UnlikeQuestion
 * @param {*} userId
 * @param {*} messageText
 * @param {*} messageType
 * @param {*} messageId
 * @description Unlike Question
 */
export async function UnlikeQuestion(userId, messageText, messageType, messageId) {
  const payload = {
      'message': messageText,
      'messageType': messageType
  };
  const chatAPIUrl = apiConfig.chat.unLikeMessage + "/" + messageId + "/" + userId;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

export async function updateUserDetails(userId) {
  const chatAPIUrl = apiConfig.chat.updateUser + "/" + userId + "/Update";
  try {
      const response = await appClient.put(chatAPIUrl);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

export async function reportQuestion(chatMessageId, reportedByUserId) {
  const payload = {
      'messageId': chatMessageId,
      'reportedByUid': reportedByUserId
  };
  const chatAPIUrl = apiConfig.chat.reportChat;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

export async function reportUser(reportedByUserId, reportUserId) {
  const payload = {
      'reportedByUid': reportedByUserId,
      'reportUserId': reportUserId
  };
  const chatAPIUrl = apiConfig.chat.reportChatUser;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

export async function reportComment(messageId, commentId, reportedByUid) {
  const payload = {
      'messageId': messageId,
      'commentId': commentId,
      'reportedByUid': reportedByUid
  };
  const chatAPIUrl = apiConfig.chat.reportComment;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}

export async function reportCommentUser(reportedByUserId, commentId, reportUserId) {
  const payload = {
      'reportedByUid': reportedByUserId,
      'commentId': commentId,
      'reportUserId': reportUserId
  };
  const chatAPIUrl = apiConfig.chat.reportCommentUser;
  try {
      const response = await appClient.put(chatAPIUrl, payload);
      return hasSuccess(response.data);
  } catch (error) {
      /* istanbul ignore next */
      if (!axios.isCancel(error)) {
          return hasError(error);
      }
  }
}