import * as actions from "redux/action-types";
import { CancelToken } from "axios";

const initialState = {
    topics: [],
    category: [],
    reading: [],
    selectedCourse: null,
    selectedCategory: null,
    selectedTopic: null,
    overallAccuracy: 0,
    categoryAccuracy: 0,
    isLoading: false,
    cancelRequest: CancelToken.source()
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CATEGORY_ACCURACY:
      return {
        ...state,
        categoryAccuracy: action.categoryAccuracy,
      };
    case actions.ADD_CATEGORY_DATA:
      return {
        ...state,
        category: action.category,
      };
    case actions.ADD_TOPIC_DATA:
     return {
        ...state,
        topics: action.topics,
     };
    case actions.ADD_READING_DATA:
      return {
        ...state,
        reading: action.reading,
      };
    case actions.LOADING_CATEGORY_DATA:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.LOADING_TOPIC_DATA:
     return {
        ...state,
        isLoading: action.isLoading,
     };
    case actions.LOADING_READING_DATA:
      return {
        ...state,
        isLoading: action.isLoading,
     };

    default:
      return state;
  }
}
