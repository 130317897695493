/**
 * hasIdUpdated
 * @param {*} oldObject
 * @param {*} newId
 * @description Check If ID is updated or not
 */
export const hasIdUpdated = (oldObject, newId) => {
  if (oldObject === newId) return false;
  else if (oldObject && oldObject.id && oldObject.id === newId) return false;

  return true;
};

/**
 * ltrim
 * @param {*} str
 * @description Remove Any Space From Start Left Side
 */
export const ltrim = (str) => {
  let rgxtrim = new RegExp("^\\s+");
  return str.replace(rgxtrim, "");
};

/**
 * removeSpace
 * @param {*} str
 * @description Remove Space from the String
 */
export const removeSpace = (str) => {
  str = str.replace(/\r?\n|\r/g, "");
  str = str.replace(/\s\s+$/g, " ");
  return str;
};

/**
 * randomNumber
 * @description Get Random Number
 */
export const randomNumber = () => {
  const min = 1;
  const max = 100;
  return min + Math.random() * (max - min) + "";
};

/**
 * sortData
 * @param {*} data
 * @param {*} key
 * @description Sort Array in Ascending Order
 */
export const sortData = (data, key) => {
  return data.sort(function (a, b) {
    return b[key] - a[key];
  });
};

/**
 * insertOnIndex
 * @param {*} arr
 * @param {*} index
 * @param {*} newItem
 * @description Insert Element in Arrays
 */
export const insertOnIndex = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

/**
 * openInNewTab
 * @param {string} url
 * @description Open URL in New Window in a most secure fashion
 */
export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}