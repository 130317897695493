import * as actions from "redux/action-types";
import {
  isUserLoggedIn,
  getAllUsersWithCount as getAllUsersWithCountFromAPI,
  getUserCounters as getUserCountersFromAPI,
  getUserAccountSettings as getUserAccountSettingsFromAPI,
  setUserLanguageInApp as setUserLanguageInAppFromAPI,
  getStates as getStatesFromAPI,
  getLandingStatistics as getLandingStatisticsFromAPI,
  getLandingEvents as getLandingEventsFromAPI,
  getUserAccuracyChart as getUserAccuracyChartFromAPI,
  getUserDashboardCalculation as getUserDashboardCalculationFromAPI,
  searchSite as searchSiteFromAPI,
  validateUserGroupData as validateUserGroupDataFromAPI
} from "services/AuthService";
import { Strings } from "resources";
import { parseEvents, parseChartAccuracy } from "utils/chat";
import { getUserId, getUserToken } from "services/AuthService";

/*
 * Add consumer account
 * account Object
 * fields Array
 * currentImage String
 *
 * return object
 */
export function addAccount(account) {
  return {
    type: actions.ADD_ACCOUNT,
    account,
  };
}

export function addAllUsers(data) {
  return {
    type: actions.ADD_ALL_USERS,
    data,
  };
}

export function setUserCounters(todo, completed, todoTasks, todoSubTasks) {
  return {
    type: actions.SET_USER_COUNTERS,
    todo,
    completed,
    todoTasks,
    todoSubTasks
  };
}

/*
 * Get account if not already in state
 *
 * returns null
 */
export function getAccountInfo() {
  return async (dispatch, getState) => {
    const loggedInUser = isUserLoggedIn();
    const { status, user } = loggedInUser;

    if (!status) return;

    dispatch(addAccount(user));
  };
}

export function getUserCounters() {
  return async (dispatch, getState) => {
    const { data, error } = await getUserCountersFromAPI();

    if (!error && data.length) {
      dispatch(
        setUserCounters(
          data[0].todoCount ? data[0].todoCount : 0,
          data[0].recentCompletedCount ? data[0].recentCompletedCount : 0,
          data[0].todoTasks ? data[0].todoTasks : 0,
          data[0].todoSubTasks ? data[0].todoSubTasks : 0
        )
      );
    } else if (!error) {
      dispatch(
        setUserCounters(0,0,0,0)
      );
    }
  };
}

export function updateUsersCounters(userId, todoCount, recentCompletedCount, todoTasks, todoSubTasks) {
  return async (dispatch, getState) => {
    let { accountData } = getState();
    let { allUsers } = accountData;

    let index = allUsers.findIndex((item) => item.id === userId);
    if (index === -1) return;

    allUsers[index].todoCount = todoCount;
    allUsers[index].recentCompletedCount = recentCompletedCount;
    allUsers[index].todoTasks = todoTasks;
    allUsers[index].todoSubTasks = todoSubTasks;

    dispatch(addAllUsers(allUsers));
  };
}

export function getAllUsersWithCount() {
  return async (dispatch, getState) => {
    const { data, error } = await getAllUsersWithCountFromAPI();

    if (!error) dispatch(addAllUsers(parseUsers(data)));
  };
}

const parseUsers = (data) => {
  let newData = [];

  data.forEach((dat) => {
    let name = data.firstName === null || data.firstName === undefined ? "" 
    : (dat.lastName === null ? `${dat.firstName}` : `${dat.firstName} ${dat.lastName}`);
    name = name.trim();

    if (name === "") {
      let str = dat.email ? dat.email : "";
      let nameParts = str.split("@");
      name = nameParts.length === 2 ? nameParts[0] : "No Name";
    }

    const field = {
      id: dat.userId,
      email: dat.email,
      display: name,
      todoCount: dat.todoCount,
      recentCompletedCount: dat.recentCompletedCount,
      todoTasks: dat.todoTasks,
      todoSubTasks: dat.todoSubTasks,
    };
    newData.push(field);
  });

  return newData;
};

/**
 * fetch unread notifications for user
 * @param {int} page 
 */
export function getNewNotifications(page) {
  return async (dispatch, getState) => {

  };
}

/**
 * getAccountSettings
 * @param {string} userId
 * @param {string} userToken
 * @description Get Account Settings API
 */
export function getAccountSettings(userId, userToken) {
  return async (dispatch, getState) => {
    const loggedInUser = isUserLoggedIn();
    const { user } = loggedInUser;
    const payload = {
      "user_id" : userId,
      "token" : user?.token
    };
    const { data } = await getUserAccountSettingsFromAPI(payload);
    if (data?.status) dispatch(addAccountSettings(data.data));
  };
}

/**
 * addAccountSettings
 * @param {object} data
 * @description Add Account Settings in Reducer
 */
export function addAccountSettings(data) {
  return {
    type: actions.ADD_ACCOUNT_SETTINGS,
    accountSettings: data,
  };
}

/**
 * getUserStates
 * @description Get User States
 */
export function getUserStates() {
  return async (dispatch, getState) => {
    const { data } = await getStatesFromAPI();
    if (data.status) dispatch(addStates(data.data));
  };
}

/**
 * addStates
 * @param {object} data
 * @description Add States
 */
export function addStates(data) {
  return {
    type: actions.ADD_STATE_DATA,
    states: data,
  };
}

/**
 * setUserLanguage
 * @param {string} language
 * @description Set User Language
 */
export function setUserLanguage(language) {
  return async (dispatch, getState) => {
    const userLanguage = {
      lang: language
    }
    const { data, error } = await setUserLanguageInAppFromAPI(userLanguage);
    if (!error) {
      Strings.setLanguage(language);
      dispatch(addUserLanguage(data, language));
    }
  };
}

/**
 * addUserLanguage
 * @param {object} data
 * @param {string} language
 * @description Add User Language
 */
export function addUserLanguage(data, language) {
  return {
    type: actions.ADD_USER_LANGUAGE,
    language: language,
  };
}

/**
 * setSiteStatistics
 * @param {*} statistics
 * @description Set Site Statistics
 */
export function setSiteStatistics(statistics) {
  return {
    type: actions.ADD_SITE_STATISTICS,
    statistics: statistics,
  };
}

/**
 * getSiteStatistics
 * @description Get Site Statistics
 */
export function getSiteStatistics() {
  return async (dispatch, getState) => {
    const { data, error } = await getLandingStatisticsFromAPI();
    if (!error) {
      dispatch(setSiteStatistics(data.data));
    }
  };
}

/**
 * setSiteEvents
 * @param {*} events
 * @description Set Site Events
 */
export function setSiteEvents(events) {
  return {
    type: actions.ADD_SITE_EVENTS,
    events: events,
  };
}

/**
 * getSiteEvents
 * @description Get Site Events
 */
export function getSiteEvents() {
  return async (dispatch, getState) => {
    const eventDate = new Date();
    const eventMonth = eventDate.getMonth() + 1;
    const eventYear = eventDate.getFullYear();
    const { data, error } = await getLandingEventsFromAPI(eventMonth, eventYear);
    if (!error) {
      if (data?.data?.length > 0) {
        dispatch(setSiteEvents(parseEvents(data.data)));
      } else {
        dispatch(setSiteEvents([]));
      }
    }
  };
}

/**
 * setUserAccuracyCharts
 * @param {*} accuracy
 * @description Set User Accuracy Charts
 */
export function setUserAccuracyCharts(accuracy) {
  return {
    type: actions.SET_CHART_ACCURACY_DETAILS,
    accuracy: accuracy,
  };
}

/**
 * getUserAccuracyCharts
 * @description Get User Accuracy Charts
 */
export function getUserAccuracyCharts() {
  return async (dispatch, getState) => {
    const userId = getUserId();
    const userToken = getUserToken();
    const { data, error } = await getUserAccuracyChartFromAPI(userId, userToken);
    if (!error) {
      if (data?.data?.length > 0) {
        dispatch(setUserAccuracyCharts(parseChartAccuracy(data.data)));
      } else {
        const accuracyState = {
          weekNo: [],
          accuracyPercentage: []
        };
        dispatch(setUserAccuracyCharts(accuracyState));
      }
    }
  };
}

/**
 * setUserDashboardPoints
 * @param {*} points
 * @description Set User Dashboard Points
 */
export function setUserDashboardPoints(points) {
  return {
    type: actions.SET_USER_DASHBOARD_POINTS,
    points: points,
  };
}

/**
 * getUserDashboardPoints
 * @description Get User Dashboard Points
 */
export function getUserDashboardPoints() {
  return async (dispatch, getState) => {
    const userId = getUserId();
    const userToken = getUserToken();
    const { data, error } = await getUserDashboardCalculationFromAPI(userId, userToken);
    if (!error) {
      if (data?.data) {
        dispatch(setUserDashboardPoints(data.data));
      } else {
        dispatch(setUserDashboardPoints(null));
      }
    }
  };
}

export function searchSite(query, language) {
  return async (dispatch, getState) => {
    const userId = getUserId();
    const userToken = getUserToken();
    const { data, error } = await searchSiteFromAPI(userId, userToken, query, language);
    if (!error) {
      if (data?.data) {
        dispatch(setSearchData(query, data.data));
      } else {
        dispatch(setUserDashboardPoints(null));
      }
    }
  };
}

export function setSearchData(searchQuery, searchData) {
  return {
    type: actions.SET_SEARCH_DATA,
    searchQuery: searchQuery,
    searchData: searchData,
  };
}

export function clearSearchData() {
  return {
    type: actions.CLEAR_SEARCH_DATA
  };
}

export function validateUserGroup() {
  return async (dispatch, getState) => {
    const userId = getUserId();
    const userToken = getUserToken();
    const { data } = await validateUserGroupDataFromAPI(userId, userToken);
    if (data?.status) {
      dispatch(setUserGroupData(data?.data?.group, data?.data?.exam_id, data?.data?.valid, data?.data?.exam_attempted));
    }
  };
}

export function setUserGroupData(group, examId, isValid, examAttempted) {
  return {
    type: actions.SET_FREE_TRIAL_VALIDATION,
    groupId: group,
    examId: examId,
    isValid: isValid,
    examAttempted: examAttempted
  };
}