import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserToken, getUserId, setUserCourseInApp, getUserDetails } from "services/AuthService";
import { Strings } from 'resources';
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SmallButton from "components/UI/SmallButton";
import Select from 'react-select';
import { log } from "utils";
import "./Package.scss"
import packageimg from "../../../assets/images/exam-category/imgpsh.png"
import info from "../../../assets/images/icons/i.png"
import appConfig from 'config/config';
export default function Courses(props) {
    const alert = useAlert();
    const [subNavItem, setSubNavItem] = useState("all");
    const [fullpack, setfullpack] = useState("cgl_plan")
    const accountData = useSelector((state) => state.accountData);
    const courseData = useSelector((state) => state.courseData);
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = getUserId();
    const userDetails = getUserDetails();
    const userToken = getUserToken();
    const userLanguage = Strings.getLanguage();
    const [selectedOption, setSelectedOption] = useState({ value: 'all', label: Strings.all });

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    /* Get Courses Details */
    useEffect(() => {
        dispatch(CourseActions.getCoursesData(true, userToken));
    }, [dispatch, userToken]);

    /* Get My Active Course */
    useEffect(() => {
        dispatch(CourseActions.getActiveCourse(userToken, userId));
    }, [dispatch, userToken, userId]);

    /* Get User Account Settings */
    useEffect(() => {
        dispatch(AccountActions.getAccountSettings(userId));
    }, [dispatch, userId]);

    /**
     * goToSubscribePackage
     * @param {object} item
     * @param {number} index
     * @description Go To Subscription Package Selection Screen
     */
    const goToSubscribePackage = (item, index) => {
        const { error } = setUserCourseInApp(item.et_id);
        if (!error) {
            history.push({
                pathname: '/select-subscription-package/',
                state: { examId: item.et_id }
            });
        } else {
            alert.error(Strings.unableToGoSubscriptionPackageScreen);
        }
    }

    /**
     * goToDashboard
     * @param {object} item
     * @param {number} index
     * @description Go To Dashboard Screen
     */
    const goToDashboard = (item, index) => {
        const { error } = setUserCourseInApp(item.et_id);
        if (!error) {
            history.push(`/home/`);
        } else {
            alert.error(Strings.unableToSetCourse);
        }
    }


 

    const Examswise = () => {

        // for redirect learn protal url 
        const username_encoded = Buffer.from(`${userDetails.first_name}  ${userDetails.last_name}`).toString('base64');
        const email_encoded = Buffer.from(`${userDetails.email}`).toString('base64');
        const password_encoded = localStorage.getItem('upass');
        const is_instructor = Buffer.from(`${userDetails.user_type}`).toString('base64');

        const learnUrl = `${appConfig.learnUrl}/register.php?key=${username_encoded}&key1=${password_encoded}&key2=${email_encoded}&key3=${is_instructor}`;

        return (

            <>
                <div className="loadMore">
                    <ul>
                        <li className={`item examwise ${fullpack === 'cgl_plan' ? 'active' : ''}`} onClick={() => setfullpack('cgl_plan')}>{Strings.cglplan}</li>
                        <li className={`item examwise ${fullpack === 'chsl' ? 'active' : ''}`} onClick={() => setfullpack('chsl')}>{Strings.chslplan}</li>
                        <li  className={`item examwise ${fullpack === 'mts' ? 'active' : ''}`} onClick={() => setfullpack('mts')}>{Strings.mtsplan}</li>
                        <li  className={`item examwise ${fullpack === 'cpo' ? 'active' : 'cpo'}`} onClick={() => setfullpack('')}>{Strings.cpo}</li>
                        <li  className={`item examwise ${fullpack === 'cdconstable' ? 'active' : 'cdconstable'}`} onClick={() => setfullpack('')}>{Strings.cdconstable}</li>
                        <li  className={`item examwise ${fullpack === 'stenographer' ? 'active' : 'stenographer'}`} onClick={() => setfullpack('')}>{Strings.stenographer}</li>
                    </ul>
                </div>


            </>
        );
    }

    /**
     * currentSubscriptions
     * @description Show Current Subscriptions
     */
    const Subjectwise = () => {
        return (<>
       <div className="loadMore">
                <ul>
                    <li  className={`item examwise ${fullpack === 'cgl_plan' ? 'active' : ''}`} onClick={() => setfullpack('cgl_plan')}>{Strings.allsubject}</li>
                    <li className="examwise">{Strings.logicalreasoning}</li>
                    <li className="examwise">{Strings.quantitativeaptitude}</li>
                    <li className="examwise">{Strings.verbal}</li>
                    <li className="examwise">{Strings.generalknowledge}</li>
                </ul>
            </div>
           
        </>);
    }

    /**
 * currentSubscriptionItem
 * @param {object} item
 * @param {number} index
 * @description Current Subscription Item
 */
    const fullpacks = () => {
        return (<>
            <div className="fullPackheading">
                <h4 className="mb-0">{Strings.fullpackheading}</h4>
                <div className="full_pack">
                     <h6>full pack</h6>
                    <div className="flex flex-row flex-wrap ">
                            <div className="item"><p>{Strings.plan}</p><span>{Strings.rs}</span></div>
                            <div className="item"><p>{Strings.validity}</p><p>{Strings.days}</p></div>
                            <div className="item"><p>{Strings.mock}</p><p>{Strings.mocktest}</p></div>
                            <div className="item"><p>{Strings.pastquestionpapers}</p>{Strings.papercount}</div>
                            <div className="item flex flex-row justify-content-between">
                            <div className="item"> <p>{Strings.info}</p> <img src={info} className="img-fluid" alt="info"/></div>
                            <div className="item"><button type="button" className="btn">buy</button></div>
                            </div>
                           

                    </div>

                </div>
            </div>

        </>);
    }
    const fullpacks1 = () => {
        return (
        <>
            <div className="fullPackheading">
                <div className="full_pack">
                     <h6>full pack</h6>
                    <div className="flex flex-row flex-wrap">
                            <div className="item"><p>{Strings.plan}</p><span>{Strings.rs}</span></div>
                            <div className="item"><p>{Strings.validity}</p><p>{Strings.days}</p></div>
                            <div className="item"><p>{Strings.mock}</p><p>{Strings.mocktest}</p></div>
                            <div className="item"><p>{Strings.pastquestionpapers}</p>{Strings.papercount}</div>
                            <div className="item flex flex-row justify-content-between">
                            <div className="item"> <p>{Strings.info}</p> <img src={info} className="img-fluid" alt="info"/></div>
                            <div className="item"><button type="button" className="btn">buy</button></div>
                            </div>

                    </div>

                </div>
            </div>

        </>);
    }


  
    const  chslplan =() =>{
        let plan = ["cgl","chsl","mts"]
        return(
            <>
            <h1>{plan}</h1>
            </>
        )
    }
    const mtsplan = () =>{
        return(
            <>
            <h1>MTS PLAN</h1>
            </>
        )
    }
   
    return (
        <Fragment>
            <div className="container-fluid">
                
                <img src={packageimg} className="imgpsh" alt="packages" />
                <div className="row mt-4">
                    <div className="col-12 col-md-6 col-lg-3 col-xxl-3">
                        <div className="packageScreenContainer dispersionLogo">
                            <div className="myPage myCourses">
                                <div className="pageTitle">

                                </div>

                                <div className="subscriptionNavigationWrapper">
                                    <div className="courseTitle">
                                        <span>{Strings.packagetitle}</span>
                                    </div>
                                    <ul className="subscriptionNavigation">

                                        <li className={`item ${subNavItem === 'all' ? 'active' : ''}`} onClick={() => setSubNavItem('all')}><span>{Strings.examwise}</span></li>
                                        <li className={`item ${subNavItem === 'current-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('current-subscriptions')}><span>{Strings.subjectwise}</span></li>
                                    </ul>
                                </div>

                                {/* <div className="mobileCategoryListings">
              <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="categoryTitle">{Strings.selected_subscription} : {selectedOption?.label}</h4>
                      <Select 
                        defaultValue={selectedOption}
                        value={selectedOption}
                        isSearchable
                        options={mainPackageItems()}
                        placeholder="Select Subscription"
                        onChange={(item, index) => goToPackageItem(item, index)}
                      />
                  </div>
              </div>
            </div> */}
                                <div className="subscriptionListings">
                                    {subNavItem === 'all' && Examswise()}
                                    {subNavItem === 'current-subscriptions' && Subjectwise()}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-9 col-xxl-9">
                        {fullpack === 'cgl_plan' && fullpacks()}
                        {fullpack === 'cgl_plan' && fullpacks1()}
                        {fullpack === 'cgl_plan' && fullpacks1()}
                        {fullpack === 'cgl_plan' && fullpacks1()}
                        {fullpack === 'chsl' && chslplan()}
                        {fullpack === 'mts' && mtsplan()}

                    </div>
                </div>
            </div>
        </Fragment>
    );
}







