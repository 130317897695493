export const SET_SEARCH_KEYWORD = "SET_SEARCH_KEYWORD";
export const SET_SEARCH_DATA = "SET_SEARCH_DATA";
export const CLEAR_SEARCH_DATA = "CLEAR_SEARCH_DATA";

/* SET ACE TEST */
export const LOADING_ACE_TEST_QUESTION = "LOADING_ACE_TEST_QUESTION";
export const LOADING_ACE_TEST = "LOADING_ACE_TEST";
export const SET_ACE_TEST_CANCEL_TOKEN = "SET_ACE_TEST_CANCEL_TOKEN";
export const SET_ACE_TEST = "SET_ACE_TEST";
export const CLEAR_ACE_TEST = "CLEAR_ACE_TEST";
export const SET_ACE_TIME = "SET_ACE_TIME";
export const SET_ACE_QUESTION = "SET_ACE_QUESTION";
export const CLEAR_ACE_QUESTION = "CLEAR_ACE_QUESTION";
export const TOGGLE_ACE_TEST_QUESTION_OPTION = "TOGGLE_ACE_TEST_QUESTION_OPTION";
export const SET_ACE_QUESTIONS_STATUS = "SET_ACE_QUESTIONS_STATUS";
export const SET_ACE_SCORE = "SET_ACE_SCORE";
export const SET_ACE_LEADERBOARD = "SET_ACE_LEADERBOARD";
export const SET_ACE_CERTIFICATE = "SET_ACE_CERTIFICATE";
export const CLEAR_ACE_CERTIFICATE = "CLEAR_ACE_CERTIFICATE";
export const SET_VALID_ACE_TEST = "SET_VALID_ACE_TEST";

/* SET SUBJECT TEST */
export const LOADING_SUBJECT_TEST = "LOADING_SUBJECT_TEST";
export const SET_SUBJECT_TEST_CANCEL_TOKEN = "SET_SUBJECT_TEST_CANCEL_TOKEN";
export const SET_SUBJECT_TEST_HAS_NO_MORE = "SET_SUBJECT_TEST_HAS_NO_MORE";
export const ADD_SUBJECT_TEST = "ADD_SUBJECT_TEST";
export const CLEAR_SUBJECT_TESTS = "CLEAR_SUBJECT_TESTS";
export const SET_SUBJECT_TIME = "SET_SUBJECT_TIME";
export const SET_SUBJECT_TEST_LIVE = "SET_SUBJECT_TEST_LIVE";
export const SET_ACTIVE_SUBJECT_TEST = "SET_ACTIVE_SUBJECT_TEST";
export const SET_ACTIVE_SUBJECT_TEST_QUESTIONS = "SET_ACTIVE_SUBJECT_TEST_QUESTIONS";
export const TOGGLE_ACTIVE_SUBJECT_TEST_QUESTION_OPTION = "TOGGLE_ACTIVE_SUBJECT_TEST_QUESTION_OPTION";
export const CLEAR_ACTIVE_SUBJECT_TEST = "CLEAR_ACTIVE_SUBJECT_TEST";

/* CHAT WEBSOCKET STATUS */
export const SET_CHAT_WEBSOCKET_STATUS = "SET_CHAT_WEBSOCKET_STATUS";
export const ADD_CHAT_DATA = "ADD_CHAT_DATA";
export const LOADING_CHAT_DATA = "LOADING_CHAT_DATA";
export const SET_CHAT_CANCEL_TOKEN = "SET_CHAT_CANCEL_TOKEN";
export const SET_CHAT_HAS_NO_MORE = "SET_CHAT_HAS_NO_MORE";
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE";
export const ADD_CHAT_TEXT_COMMENT = "ADD_CHAT_TEXT_COMMENT";
export const ADD_CHAT_IMAGE_COMMENT = "ADD_CHAT_IMAGE_COMMENT";
export const CLEAR_CHAT_COMMENT = "CLEAR_CHAT_COMMENT";
export const UPDATE_CHAT_COMMENT = "UPDATE_CHAT_COMMENT";
export const UPDATE_CHAT_LIKE_COUNT = "UPDATE_CHAT_LIKE_COUNT";
export const HIDE_CHAT_MESSAGE = "HIDE_CHAT_MESSAGE";
export const HIDE_CHAT_COMMENT = "HIDE_CHAT_COMMENT";
export const SET_BANNED_USERS = "SET_BANNED_USERS";

/* CATEGORY AND TOPICS LISTING */
export const ADD_CATEGORY_DATA = "ADD_CATEGORY_DATA";
export const ADD_TOPIC_DATA = "ADD_TOPIC_DATA";
export const ADD_READING_DATA = "ADD_READING_DATA";
export const LOADING_CATEGORY_DATA = "LOADING_CATEGORY_DATA";
export const LOADING_TOPIC_DATA = "LOADING_TOPIC_DATA";
export const LOADING_READING_DATA = "LOADING_READING_DATA";
export const SET_CATEGORY_ACCURACY = "SET_CATEGORY_ACCURACY";
export const SET_OVERALL_ACCURACY = "SET_OVERALL_ACCURACY";

/* Library Files */
export const ADD_LIBRARY_FILE_DATA = "ADD_LIBRARY_FILE_DATA";
export const LOADING_LIBRARY_FILE_DATA = "LOADING_LIBRARY_FILE_DATA";
export const SET_LIBRARY_FILE_CANCEL_TOKEN = "SET_LIBRARY_FILE_CANCEL_TOKEN";
export const SET_LIBRARY_FILE_HAS_NO_MORE = "SET_LIBRARY_FILE_HAS_NO_MORE";

/* Notifications */
export const ADD_NOTIFICATION_DATA = "ADD_NOTIFICATION_DATA";
export const LOADING_NOTIFICATION_DATA = "LOADING_NOTIFICATION_DATA";
export const SET_NOTIFICATION_CANCEL_TOKEN = "SET_NOTIFICATION_CANCEL_TOKEN";
export const SET_NOTIFICATION_HAS_NO_MORE = "SET_NOTIFICATION_HAS_NO_MORE";
export const CLEAR_NOTIFICATION_COUNT = "CLEAR_NOTIFICATION_COUNT";

/* Courses */
export const ADD_COURSE_DATA = "ADD_COURSE_DATA";
export const LOADING_COURSE_DATA = "LOADING_COURSE_DATA";
export const SET_COURSE_CANCEL_TOKEN = "SET_COURSE_CANCEL_TOKEN";
export const SET_COURSE_HAS_NO_MORE = "SET_COURSE_HAS_NO_MORE";
export const SET_ACTIVE_COURSE_DATA = "SET_ACTIVE_COURSE_DATA";
export const CLEAR_ACTIVE_COURSE_DATA = "CLEAR_ACTIVE_COURSE_DATA";

/* Upcoming Events */
export const ADD_UPCOMING_EVENTS_DATA = "ADD_UPCOMING_EVENTS_DATA";
export const LOADING_UPCOMING_EVENTS_DATA = "LOADING_UPCOMING_EVENTS_DATA";
export const SET_UPCOMING_EVENTS_CANCEL_TOKEN = "SET_UPCOMING_EVENTS_CANCEL_TOKEN";
export const SET_UPCOMING_EVENTS_HAS_NO_MORE = "SET_UPCOMING_EVENTS_HAS_NO_MORE";

/* Payments */
export const ADD_PAYMENT_HISTORY_DATA = "ADD_PAYMENT_HISTORY_DATA";
export const LOADING_PAYMENT_HISTORY_DATA = "LOADING_PAYMENT_HISTORY_DATA";
export const SET_PAYMENT_HISTORY_CANCEL_TOKEN = "SET_PAYMENT_HISTORY_CANCEL_TOKEN";
export const SET_PAYMENT_HISTORY_HAS_NO_MORE = "SET_PAYMENT_HISTORY_HAS_NO_MORE";

/* Refer a Friend */
export const SET_REFERRAL_AMOUNT = "SET_REFERRAL_AMOUNT";
export const CLEAR_REFERRAL_AMOUNT = "CLEAR_REFERRAL_AMOUNT";
export const ADD_REFERRAL_DATA = "ADD_REFERRAL_DATA";
export const LOADING_REFERRAL_DATA = "LOADING_REFERRAL_DATA";
export const SET_REFERRAL_CANCEL_TOKEN = "SET_REFERRAL_CANCEL_TOKEN";
export const SET_REFERRAL_HAS_NO_MORE = "SET_REFERRAL_HAS_NO_MORE";

export const ADD_STATE_DATA = "ADD_STATE_DATA";

// User account
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ALL_USERS = "ADD_ALL_USERS";
export const SET_USER_COUNTERS = "SET_USER_COUNTERS";
export const ADD_USER_LANGUAGE = "ADD_USER_LANGUAGE";
export const SET_CURRENT_USER_ID = "SET_CURRENT_USER_ID";
export const ADD_SITE_STATISTICS = "ADD_SITE_STATISTICS";
export const ADD_SITE_EVENTS = "ADD_SITE_EVENTS";

// Navigation
export const TOGGLE_LEFT_SIDEBAR = "TOGGLE_LEFT_SIDEBAR";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_CREATE_NEW_PROJECT_MODAL_STATUS =
  "SET_CREATE_NEW_PROJECT_MODAL_STATUS";
export const SET_EDIT_PROJECT_MODAL_STATUS = "SET_EDIT_PROJECT_MODAL_STATUS";
export const SET_GO_TO_URL = "SET_GO_TO_URL";
export const SET_SEARCH_USERS_MODAL_STATUS = "SET_SEARCH_USERS_MODAL_STATUS";
export const SET_LOGIN_MODAL_STATUS = "SET_LOGIN_MODAL_STATUS";
export const SET_FORGOT_PASSWORD_MODAL_STATUS = "SET_FORGOT_PASSWORD_MODAL_STATUS";
export const SET_RESET_PASSWORD_MODAL_STATUS = "SET_RESET_PASSWORD_MODAL_STATUS";
export const SET_SIGNUP_MODAL_STATUS = "SET_SIGNUP_MODAL_STATUS";
export const SET_USER_ACTIVATE_MODAL_STATUS = "SET_USER_ACTIVATE_MODAL_STATUS";
export const CLOSE_AUTH_MODAL_STATUS = "CLOSE_AUTH_MODAL_STATUS";

export const SET_REFERRAL_CONFIRMATION_STATUS = "SET_REFERRAL_CONFIRMATION_STATUS";
export const CLOSE_REFERRAL_CONFIRMATION_STATUS = "CLOSE_REFERRAL_CONFIRMATION_STATUS";
export const SET_BILLING_VERIFICATION_STATUS = "SET_BILLING_VERIFICATION_STATUS";
export const CLOSE_BILLING_VERIFICATION_STATUS = "CLOSE_BILLING_VERIFICATION_STATUS";
export const SET_SUBJECT_QUIZ_VERIFY_STATUS = "SET_SUBJECT_QUIZ_VERIFY_STATUS";
export const CLOSE_SUBJECT_QUIZ_VERIFY_STATUS = "CLOSE_SUBJECT_QUIZ_VERIFY_STATUS";

export const SET_PRIVACY_POLICY_MODAL_STATUS = "SET_PRIVACY_POLICY_MODAL_STATUS";
export const SET_TERMS_CONDITIONS_MODAL_STATUS = "SET_TERMS_CONDITIONS_MODAL_STATUS";
export const SET_SUBJECT_QUIZ_RESULT_STATUS = "SET_SUBJECT_QUIZ_RESULT_STATUS";
export const SET_ACE_GAME_END_CONFIRMATION_STATUS = "SET_ACE_GAME_END_CONFIRMATION_STATUS";

export const SET_ACE_POINTS_REDEEM_STATUS = "SET_ACE_POINTS_REDEEM_STATUS";
export const SET_ACE_POINTS_REDEEM_CONFIRMATION_STATUS = "SET_ACE_POINTS_REDEEM_CONFIRMATION_STATUS";
export const SET_ACE_POINTS_CERTIFICATE_STATUS = "SET_ACE_POINTS_CERTIFICATE_STATUS";
export const SET_FEATURE_NOT_AVAILABLE_STATUS = "SET_FEATURE_NOT_AVAILABLE_STATUS";
export const SET_FREE_TRIAL_STATUS = "SET_FREE_TRIAL_STATUS";
export const SET_PLAY_ACE_GAME_STATUS = "SET_PLAY_ACE_GAME_STATUS";
export const SET_ACE_QUIZ_RESULT_STATUS = "SET_ACE_QUIZ_RESULT_STATUS";
export const SET_ACE_QUIZ_TIME_UP_STATUS = "SET_ACE_QUIZ_TIME_UP_STATUS";
export const SET_FREE_TRIAL_EXPIRED_STATUS = "SET_FREE_TRIAL_EXPIRED_STATUS";
export const SET_SUBSCRIPTION_PLAN_EXPIRED_STATUS = "SET_SUBSCRIPTION_PLAN_EXPIRED_STATUS";
export const SET_REFERRAL_CONFIRMATION_SUCCESS_STATUS = "SET_REFERRAL_CONFIRMATION_SUCCESS_STATUS";
export const SET_VIDEO_NOT_AVAILABLE = "SET_VIDEO_NOT_AVAILABLE";
export const SET_REPORT_USER = "SET_REPORT_USER";
export const SET_REPORT_MESSAGE = "SET_REPORT_MESSAGE";
export const SET_REPORT_COMMENT = "SET_REPORT_COMMENT";
export const SET_REPORT_COMMENT_USER = "SET_REPORT_COMMENT_USER";

export const SET_USER_ACCESS_FORGERY = "SET_USER_ACCESS_FORGERY";

export const SET_ACE_GAME_CURRENT_PLAY_INDEX = "SET_ACE_GAME_CURRENT_PLAY_INDEX";
export const SET_ACE_GAME_CURRENT_PLAY_KEY = "SET_ACE_GAME_CURRENT_PLAY_KEY";
export const SET_ACE_GAME_CURRENT_PLAY_STATUS = "SET_ACE_GAME_CURRENT_PLAY_STATUS";
export const SET_ACE_GAME_PLAY_STATUS = "SET_ACE_GAME_PLAY_STATUS";
export const SET_ACE_GAME_REPORT = "SET_ACE_GAME_REPORT";
export const SET_SUBJECT_GAME_REPORT = "SET_SUBJECT_GAME_REPORT";

/* Account Settings */
export const ADD_ACCOUNT_SETTINGS = "ADD_ACCOUNT_SETTINGS";
export const GET_ACCOUNT_SETTINGS = "GET_ACCOUNT_SETTINGS";

/* Dashboard Settings */
export const SET_CHART_ACCURACY_DETAILS = "SET_CHART_ACCURACY_DETAILS";
export const SET_USER_DASHBOARD_POINTS = "SET_USER_DASHBOARD_POINTS";
export const SET_FREE_TRIAL_VALIDATION = "SET_FREE_TRIAL_VALIDATION";
