const libraryExams = [
    { key: 'nracet', name: 'NRA CET', exams: [
        { key: 'nracet', name: 'NRACET'},
    ]
    },

    { key: 'banking', name: 'Banking', exams: [
        { key: 'ibps-po-prelims', name: 'IBPS PO PRELIMS'},
        { key: 'ibps-po-mains', name: 'IBPS PO MAINS'},
        { key: 'ibps-clerk-prelims', name: 'IBPS CLERK PRELIMS'},
        { key: 'ibps-clerk-mains', name: 'IBPS CLERK MAINS'},
        { key: 'ibps-so-prelims', name: 'IBPS SO PRELIMS'},
        { key: 'ibps-so-mains', name: 'IBPS SO MAINS'},
        { key: 'ibps-rrb-officer-scale-1-prelims', name: 'IBPS RRB OFFICER SCALE 1 PRELIMS'},
        { key: 'ibps-rrb-officer-scale-1-mains', name: 'IBPS RRB OFFICER SCALE 1 MAINS'},
        { key: 'ibps-rrb-clerk-prelims', name: 'IBPS RRB CLERK PRELIMS'},
        { key: 'ibps-rrb-clerk-mains', name: 'IBPS RRB CLERK MAINS'},
        { key: 'sbi-po-prelims', name: 'SBI PO PRELIMS'},
        { key: 'sbi-po-mains', name: 'SBI PO MAINS'},
        { key: 'sbi-clerk-prelims', name: 'SBI CLERK PRELIMS'},
        { key: 'sbi-clerk-mains', name: 'SBI CLERK MAINS'},
        { key: 'sbi-so-prelims', name: 'SBI SO PRELIMS'},
        { key: 'sbi-so-mains', name: 'SBI SO MAINS'},
        { key: 'rbi-assistant', name: 'RBI ASSISTANT'},
        { key: 'rbi-grade-b', name: 'RBI GRADE B'},
        { key: 'nabard-grade-a', name: 'NABARD GRADE A'},
        { key: 'nabard-grade-b', name: 'NABARD GRADE B'}] 
    },

    { key: 'ssc', name: 'SSC', exams: [
        { key: 'ssc-cgl', name: 'SSC CGL'},
        { key: 'ssc-chsl', name: 'SSC CHSL'},
        { key: 'ssc-mts', name: 'SSC MTS'},
        { key: 'ssc-stenographer', name: 'SSC STENOGRAPHER'},
        { key: 'ssc-cpo', name: 'SSC CPO'},
        { key: 'ssc-gd-constable', name: 'SSC GD Constable'}]
    },
    
    { key: 'rrb', name: 'RRB', exams: [
        { key: 'rrb-group-d', name: 'RRB GROUP D'},
        { key: 'rrb-ntpc', name: 'RRB NTPC'},
        { key: 'rrb-je', name: 'RRB JE'},
        { key: 'rrb-alp', name: 'RRB ALP'},
        { key: 'rrb-apprentice', name: 'RRB APPRENTICE'},
        { key: 'rrb-paramedical', name: 'RRB PARAMEDICAL'}]
    }
];
export default libraryExams;