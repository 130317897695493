import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import * as NavigationActions from "redux/actions/NavigationActions";
import FeatureTick from "assets/images/page/acetick.svg";
import "./FreeTrialACEGame.scss";

export default function FreeTrialACEGame(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const aceTestData = useSelector((state) => state.aceTestData);
  const history = useHistory();
  const dispatch = useDispatch();

  const playACEGame = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(false));
    history.push(`/ace-game-rules`);
  }

  return (<>
    <div
      className={`popupWrapper aceGame freeTrialACEGame ${
        !navigationData.showFreeTrialModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{Strings.this_is_a_free_trial_you_to_understand_the_game}</h3>
            <div className="modalContent">
                <div className="feature">
                    <div className="featureIcon">
                        <img className="featureTickIcon" src={FeatureTick} title="Feature" alt="Feature" />
                    </div>
                    <div className="featureDesc"><p>{Strings.set_20_random_questions}</p></div>
                </div>
                <div className="feature">
                    <div className="featureIcon">
                        <img className="featureTickIcon" src={FeatureTick} title="Feature" alt="Feature" />
                    </div>
                    <div className="featureDesc"><p>{Strings.exact_replica_of_the_real_ace_game}</p></div>
                </div>
                <div className="feature">
                    <div className="featureIcon">
                        <img className="featureTickIcon" src={FeatureTick} title="Feature" alt="Feature" />
                    </div>
                    <div className="featureDesc"><p>{Strings.detailed_performance_report}</p></div>
                </div>
                <div className="feature">
                    <div className="featureIcon">
                        <img className="featureTickIcon" src={FeatureTick} title="Feature" alt="Feature" />
                    </div>
                    <div className="featureDesc"><p>{Strings.answer_to_all_questions_available_for_review}</p></div>
                </div>
            </div>
            <div className="buttonContainer">
                <div className="btn-container">
                    <SmallButton clicked={() => playACEGame()} btnType="Orange" loading={aceTestData?.isLoading}>{Strings.ok_take_me_to_the_rules}</SmallButton>
                </div>
            </div>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showFreeTrialModal ? "closed" : "opened"}`}></div>
  </>
  );
}
