import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { Strings } from "resources";
import {
    userResetPassword as userResetPasswordAPI,
} from "services/AuthService";
import { useAlert } from "react-alert";
import "./ResetPassword.scss";
import Checked from "assets/images/checked.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as NavigationActions from "redux/actions/NavigationActions";

export default function ResetPassword(props) {
  const alert = useAlert();
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([
    {
      name: Strings.otpNo,
      key: "resetPasswordToken",
      value: "",
      type: "text",
      minLength: 5,
      rules: "required|numeric|min:5",
      touched: false,
      icon: false,
      placeholder: Strings.otpNo_placeholder
    },
    {
      name: Strings.password,
      key: "password",
      value: "",
      type: "text",
      minLength: 8,
      maxLength: 15,
      rules: 'required|no_space|min:8|max:15',
      touched: false,
      secure: true,
      icon: true,
      placeholder: Strings.password_placeholder
    },
    {
      name: Strings.confirmPassword,
      key: "confirm_password",
      value: "",
      type: "text",
      minLength: 8,
      maxLength: 15,
      rules: 'required|no_space|match_index:1|min:8|max:15',
      touched: false,
      secure: true,
      icon: true,
      placeholder: Strings.confirmPassword_placeholder
    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(false);

  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Handle Input Field State Change
   */
  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description check Validation Errors
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Field
   */
  const renderField = (field, index) => {
    return (
      <Input
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated(event.target.value, index)}
        onBlur={(event) => fieldUpdated(event.target.value, index)}
        key={index}
        touched={field.touched}
        value={field.value}
        errorMessage={
          field.errors && field.errors.length ? field.errors[0] : ""
        }
        secure={field.secure}
        icon={field.icon}
        iconPressed={() => iconShowPressed(field.secure, index)}
      />
    );
  };

  const iconShowPressed = (isSecure, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].secure = !isSecure;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
  }

  /**
   * submit
   * @param {*} event
   * @description User Reset Password Submit Action
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    userResetPassword(getPayload(fields));
  };

  /**
   * userResetPassword
   * @param {*} payload
   * @description User Reset Password Action
   */
  const userResetPassword = async (payload) => {
    setLoadingState(true);
    const { data } = await userResetPasswordAPI(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return userResetPasswordSuccess(data.data);
    }
  };

  /**
   * userResetPasswordSuccess
   * @param {*} userData
   * @description User Reset Password Success
   */
  const userResetPasswordSuccess = (userData) => {
    alert.success(Strings.successResetPassword);
    setResetPasswordStatus(true);
    return false;
  };

  /**
   * getPayload
   * @param {*} fields
   * @description Get PayLoad
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  const hideModal = async () => {
    dispatch(NavigationActions.setResetPasswordModalStatus(false));
  }

  return (<>
    <div
      className={`popupWrapper Auth dispersionLogo ${
        !navigationData.showResetPasswordModal ? "closed" : "opened"
      }`}
    >

      <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>

      <div className="popUpInner">
        <h3 className="modal-heading">{Strings.resetPassword}</h3>
        {!resetPasswordStatus &&
        <form onSubmit={(event) => submit(event)}>
            {fields.map((field, index) => renderField(field, index))}
            <Button disabled={hasErrors} loading={isLoading} btnType="Orange">
              {Strings.changePassword}
            </Button>
        </form>}
        {resetPasswordStatus &&
        <div className="nav-link-container noMargin">
            <div className="successSubmitWrapper">
              <img className="successSubmit" src={Checked} title={Strings.success} alt={Strings.success} />
            </div>
            <span className="resetPasswordLink">{Strings.successResetPassword}</span>
            <span className="resetPasswordLink"><span className="Link" onClick={() => props.onLoginShow()}>{Strings.login}</span></span>
        </div>}
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showResetPasswordModal ? "closed" : "opened"}`}></div>
    </>);
}
