import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserToken, getUserId, setUserCourseInApp, getUserDetails } from "services/AuthService";
import { Strings } from 'resources';
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SmallButton from "components/UI/SmallButton";
import Select from 'react-select';
import { log } from "utils";
import "./detailedanalysis.scss";
import leftarrow from "../../../assets/images/icons/left-arrow.png"
import appConfig from 'config/config';
export default function DetailedAnalysis(props) {
  const alert = useAlert();
  const [subNavItem, setSubNavItem] = useState("all");
  const accountData = useSelector((state) => state.accountData);
  const courseData = useSelector((state) => state.courseData);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userDetails = getUserDetails();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const [selectedOption, setSelectedOption] = useState({ value: 'all', label: Strings.all });

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Courses Details */
  useEffect(() => {
    dispatch(CourseActions.getCoursesData(true, userToken));
  }, [dispatch, userToken]);

  /* Get My Active Course */
  useEffect(() => {
    dispatch(CourseActions.getActiveCourse(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /**
   * goToSubscribePackage
   * @param {object} item
   * @param {number} index
   * @description Go To Subscription Package Selection Screen
   */
  const goToSubscribePackage = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push({
        pathname: '/select-subscription-package/',
        state: { examId: item.et_id }
      });
    } else {
      alert.error(Strings.unableToGoSubscriptionPackageScreen);
    }
  }

  /**
   * goToDashboard
   * @param {object} item
   * @param {number} index
   * @description Go To Dashboard Screen
   */
  const goToDashboard = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push(`/home/`);
    } else {
      alert.error(Strings.unableToSetCourse);
    }
  }

  /**
   * allSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description All Subscription Item
   */
  const allSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description;
    const activeCourseId = courseData?.active?.exam_id;
    const activeGroupId = courseData?.active?.group_id;
    const currentId = item.et_id;

    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 courseItemWrapper" key={`file-${index}`}>
      <div className="courseItem">
        <div className="courseHeading">
          <span className="courseTitle">{item.et_name}</span>
        </div>
        <div className="courseDescription" dangerouslySetInnerHTML={{ __html: courseDescription }}></div>
        <div className="courseLinks">
          <span className="exploreLink" onClick={() => goToDashboard(item, index)}>{Strings.allExplore}</span>
          {activeCourseId !== currentId && activeGroupId !== '3' && <span className="subscribeLink" onClick={() => goToSubscribePackage(item, index)}>{Strings.allSubscribe}</span>}
          {activeCourseId === currentId && activeGroupId !== '3' && <span className="subscribeLink" onClick={() => goToSubscribePackage(item, index)}>{Strings.allSubscribe}</span>}
        </div>
      </div>
    </div>);
  }

  /**
   * currentSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description Current Subscription Item
   */
  const currentSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description;
    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 courseItemWrapper" key={`file-${index}`}>
      <div className="courseItem">
        <div className="courseHeading">
          <span className="courseTitle">{item.et_name}</span>
          <span className="coursePackage">{userLanguage === 'hi' ? item.group_name_hindi : item.group_name}</span>
        </div>
        <div className="courseDescription" dangerouslySetInnerHTML={{ __html: courseDescription }}></div>
        <div className="courseLinks center">
          <span className="exploreLink" onClick={() => goToDashboard(item, index)}>{Strings.currentEnter}</span>
        </div>
      </div>
    </div>);
  }

  /**
   * pastSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description Past Subscription Item
   */
  const pastSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description;
    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  courseItemWrapper" key={`file-${index}`}>
      <div className="courseItem">
        <div className="courseHeading">
          <span className="courseTitle">{item.et_name}</span>
          <span className="coursePackage">{userLanguage === 'hi' ? item.group_name_hindi : item.group_name}</span>
        </div>
        <div className="courseDescription" dangerouslySetInnerHTML={{ __html: courseDescription }}></div>
      </div>
    </div>);
  }

  /**
   * allSubscriptions
   * @description Show All Subscriptions
   */
  const generalEnglish = () => {

    // for redirect learn protal url 
    const username_encoded = Buffer.from(`${userDetails.first_name}  ${userDetails.last_name}`).toString('base64');
    const email_encoded = Buffer.from(`${userDetails.email}`).toString('base64');
    const password_encoded = localStorage.getItem('upass');
    const is_instructor = Buffer.from(`${userDetails.user_type}`).toString('base64');

    const learnUrl = `${appConfig.learnUrl}/register.php?key=${username_encoded}&key1=${password_encoded}&key2=${email_encoded}&key3=${is_instructor}`;

    return (
      <div className="generalEnglish">
        <div class="ScoreWraper">
          <div class="scoredetails d-flex justify-content-between align-items-center ">
            <div className="score">
              <h3>Score</h3>
              <h4><span>0.25</span>/50</h4>
            </div>
            <div className="text-right px-1">
              <h5 className="correct">Correct | 10</h5>
              <h5 className="incorect">Incorrect | 30</h5>
              <h5 className="unaswered">Unanswered | 160</h5>
            </div>
          </div>
          <div class="scoredetails d-flex justify-content-center text-right text-center">

            <div>
              
              <div className="circle-container">
                <div className="circle">
                  <div className="item layer-1">
                    <div className="fill"></div>
                  </div>
                  <div className="item layer-2">
                    <div className="fill"></div>
                  </div>
                  <div className="inside-content">48%</div>
                </div>
              </div>
              <p>accuracy rate</p></div>

            {/* <div class="circle-bg-color-anim circular">
              <div class="circle-bg-color-anim inner"></div>
              <div class="circle-text-color-anim number">48%</div>

              <div class="circle-bg-color-anim circle">
                <div class="circle-bg-color-anim bar left">
                  <div class="progress"></div>
                </div>
                <div class="circle-bg-color-anim bar right">
                  <div class="progress"></div>
                </div>
              </div>
             
            </div> */}


          </div>
          <div class="scoredetails percentile d-flex justify-content-center">
            <div> <h2>29.85</h2>
              <p>percentile</p></div>

          </div>
        </div>
      </div>
    );
  }


  const subjectgeneralEnglish = () => {
    return (
      <>
        <div class="progressdiv">
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>45%</h5>
            <p>Time and Distance</p>
            <div class="progresstd">
              <div class="bartd">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>95%</h5>
            <p>Simple Interest</p>
            <div class="simple_interest">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Profit and Loss</p>
            <div class="Profit_and_Loss">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
        </div>
        <div class="progressdiv">
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>45%</h5>
            <p>Time and Distance</p>
            <div class="progresstd">
              <div class="bartd">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>95%</h5>
            <p>Simple Interest</p>
            <div class="simple_interest">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Profit and Loss</p>
            <div class="Profit_and_Loss">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
        </div>
        <div class="progressdiv">
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>45%</h5>
            <p>Time and Distance</p>
            <div class="progresstd">
              <div class="bartd">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>95%</h5>
            <p>Simple Interest</p>
            <div class="simple_interest">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Profit and Loss</p>
            <div class="Profit_and_Loss">
              <div class="bar">

              </div>
            </div>
          </div>
          <div class="progresschilddiv">
            <h5>35%</h5>
            <p>Number Series</p>
            <div class="progress">
              <div class="bar">

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  /**
   * currentSubscriptions
   * @description Show Current Subscriptions
   */
  const currentSubscriptions = () => {
    return (<div className="row">
      {accountData.accountSettings.current_subscription.length === 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
        <span className="noCourse">{Strings.noCurrentSubscription}</span>
        <span className="noCourse mb-5">{Strings.goCurrentSubscriptions}</span>
        <SmallButton clicked={() => setSubNavItem('all')} btnType="Orange">{Strings.currentViewCourses}</SmallButton>
      </div>}
      {accountData.accountSettings.current_subscription.length > 0 &&
        accountData.accountSettings.current_subscription.map((item, index) => currentSubscriptionItem(item, index))
      }
    </div>);
  }

  /**
   * pastSubscriptions
   * @description Show Past Subscriptions
   */
  const pastSubscriptions = () => {
    return (<div className="row">
      {accountData.accountSettings.past_subscription.length === 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
        <span className="noCourse">{Strings.noPastSubscriptions}</span>
        <span className="noCourse mb-5">{Strings.goPastCourses}</span>
        <SmallButton clicked={() => setSubNavItem('all')} btnType="Orange">{Strings.pastViewCourses}</SmallButton>
      </div>}
      {accountData.accountSettings.past_subscription.length > 0 &&
        accountData.accountSettings.past_subscription.map((item, index) => pastSubscriptionItem(item, index))
      }
    </div>);
  }

  const mainPackageItems = () => {
    const items = [{ value: 'all', label: Strings.all }, { value: 'current-subscriptions', label: Strings.ongoingSubscription }, { value: 'past-subscriptions', label: Strings.pastSubscription }];
    return items;
  }

  const goToPackageItem = (item, index) => {
    setSelectedOption(item);
    setSubNavItem(item.value);
  }

  return (
    <Fragment>
      <div className="container Detailed_Analysis ">
        <div className="d-flex justify-content-between details">
          <button type="button" className="btn">
            <img src={leftarrow} className="img-fluid" alt="left-arrow"/> Take more Tests</button>
          <button type="button" className="Detailed">Detailed Solutions</button>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
            <div className="headings">
              <h1>Sectional Analysis</h1>
            </div>
            <div className="pageScreenContainers">
              <div className="myPages myCourses">
                <div className="subscriptionNavigationWrapper">
                  <ul className="subscriptionNavigation">
                    <li className={`item ${subNavItem === 'all' ? 'active' : ''}`} onClick={() => setSubNavItem('all')}><span>General English</span></li>
                    <li className={`item ${subNavItem === 'current-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('current-subscriptions')}><span>Numerical Ability</span></li>
                    <li className={`item ${subNavItem === 'past-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscriptions')}><span>Reasoning Ability</span></li>
                    <li className={`item ${subNavItem === 'past-subscription' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscription')}><span>Quantitative Aptitude</span></li>
                  </ul>
                </div>
                {/* <div className="mobileCategoryListings">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h4 className="categoryTitle">{Strings.selected_subscription} : {selectedOption?.label}</h4>
                      <Select
                        defaultValue={selectedOption}
                        value={selectedOption}
                        isSearchable
                        options={mainPackageItems()}
                        placeholder="Select Subscription"
                        onChange={(item, index) => goToPackageItem(item, index)}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="subscriptionListings">
                  {subNavItem === 'all' && generalEnglish()}
                  {subNavItem === 'current-subscriptions' && currentSubscriptions()}
                  {subNavItem === 'past-subscriptions' && pastSubscriptions()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
            <div className="headings">
              <h1>Overall Performance</h1>
              <div className="overallPerformance align-items-center">
                <div className="d-flex justify-content-around">
                  <div>
                    <h6>Score</h6>
                    <p>4.75 <span>/out of 200</span></p>
                  </div>
                  <div><h6>Accuracy</h6>
                    <p>22.01% </p></div>
                </div>
                <div className="d-flex justify-content-around">
                  <div>
                    <h6>Rank</h6>
                    <p>3025 <span>/out of 4000</span></p>
                  </div>
                  <div><h6>Percentile</h6>
                    <p>9.08%</p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <div className="headings">
              <h1>Subject Wise Accuracy Rate</h1>
            </div>
            <div className="pageScreenContainers">
              <div className="myPages myCourses">
                <div className="subscriptionNavigationWrapper">
                  <ul className="subscriptionNavigation">
                    <li className={`item ${subNavItem === 'all' ? 'active' : ''}`} onClick={() => setSubNavItem('all')}><span>General English</span></li>
                    <li className={`item ${subNavItem === 'current-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('current-subscriptions')}><span>Numerical Ability</span></li>
                    <li className={`item ${subNavItem === 'past-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscriptions')}><span>Reasoning Ability</span></li>
                    <li className={`item ${subNavItem === 'past-subscription' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscription')}><span>Quantitative Aptitude</span></li>
                  </ul>
                </div>

                <div className="subscriptionListings">
                  {subNavItem === 'all' && subjectgeneralEnglish()}
                  {subNavItem === 'current-subscriptions' && currentSubscriptions()}
                  {subNavItem === 'past-subscriptions' && pastSubscriptions()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Compare_with_Topper">
          <h1>Compare with Topper</h1>
          <div className="row">

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-between align-items-center toper_wraper mt-2">
              <h4> TOPPER</h4>
              <div className="toper">
                <div className="inner_circle">
                  <span>97.5/<br /> 100</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-between align-items-center average_wraper mt-2">
              <h4> AVERAGE</h4>
              <div className="average">
                <div className="inner_circle">
                  <span>97.5/<br /> 100</span>
                </div>
              </div>

            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-between align-items-center you_wraper mt-2">
              <h4>you</h4>
              <div className="toper">
                <div className="inner_circle">
                  <span>97.5/<br /> 100</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="pageScreenContainer">
        <div className="myPage myCourses">
            <div className="pageTitle">
              <h1>{Strings.coursesHeading}</h1>
            </div>
            <div className="subscriptionNavigationWrapper">
              <ul className="subscriptionNavigation">
                <li className={`item ${subNavItem === 'all' ? 'active' : ''}`} onClick={() => setSubNavItem('all')}><span>{Strings.all}</span></li>
                <li className={`item ${subNavItem === 'current-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('current-subscriptions')}><span>{Strings.ongoingSubscription}</span></li>
                <li className={`item ${subNavItem === 'past-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscriptions')}><span>{Strings.pastSubscription}</span></li>
              </ul>
            </div>
            <div className="mobileCategoryListings">
              <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="categoryTitle">{Strings.selected_subscription} : {selectedOption?.label}</h4>
                      <Select 
                        defaultValue={selectedOption}
                        value={selectedOption}
                        isSearchable
                        options={mainPackageItems()}
                        placeholder="Select Subscription"
                        onChange={(item, index) => goToPackageItem(item, index)}
                      />
                  </div>
              </div>
            </div>
            <div className="subscriptionListings">
              {subNavItem === 'all' && generalEnglish()}
              {subNavItem === 'current-subscriptions' && currentSubscriptions()}
              {subNavItem === 'past-subscriptions' && pastSubscriptions()}
            </div>
        </div>
      </div> */}
    </Fragment>
  );
}
