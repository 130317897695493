import React from "react";
import { useSelector } from "react-redux";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ACEPointsRedeemConfirmation.scss";

export default function ACEPointsRedeemConfirmation(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const aceTestData = useSelector((state) => state.aceTestData);

  /**
   * showValidRedeemView
   * @description If User is valid for redeem then show this
   */
  const showValidRedeemView = () => {
    return (<>
              <h3 className="modal-info">{Strings.areYouSure}</h3>
              <p>{Strings.areYouSureDescription}</p>
              <p><span className="blueTitle" onClick={() => props.onCancelRedeem()}>{Strings.clickHere}</span> {Strings.toCancelRedemption}.</p>
              <p><span className="blueTitle" onClick={() => props.onProceedRedeem()}>{Strings.proceed}</span> {Strings.toRedeem}.</p>
            </>);
  }

  return (<>
    <div
      className={`popupWrapper ACEPointsRedeemConfirmation dispersionLogo ${
        !navigationData.showACEPointsRedeemConfirmationModal ? "closed" : "opened"
      }`}
    >
      <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
      <div className="popUpInner">
        {+aceTestData?.leaderBoard?.userLevel?.ace_points < 300 &&
          <h3 className="modal-info">{Strings.notEnoughACEPoints}</h3>
        }
        {+aceTestData?.leaderBoard?.userLevel?.ace_points >= 300 &&
          showValidRedeemView()
        }
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showACEPointsRedeemConfirmationModal ? "closed" : "opened"}`}></div>
  </>
  );
}
