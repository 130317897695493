import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Strings } from "resources";
import * as NavigationActions from "redux/actions/NavigationActions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SubjectQuizConfirmation.scss";

export default function SubjectQuizConfirmation(props) {
  const history = useHistory();
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();

  /**
   * hideModal
   * @description Hide Verification Modal
   */
  const hideModal = () => {
    dispatch(NavigationActions.closeSubjectQuizVerifyModal());
  };

  /**
   * goToProfileScreen
   * @description Go To Profile Screen
   */
  const goToQuizScreen = () => {
    hideModal();
    history.push('/my-profile/');
    // history.push(`/category/${+catId}/topic/${+topicId}/quiz/${quizId}`);
  }

  return (<>
    <div
      className={`popupWrapper dispersionLogo ${
        !navigationData.showSubjectQuizVerifyPopUp ? "closed" : "opened"
      }`}
    >
        <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{Strings.playSubjectTest}</h3>
            <p>{Strings.playSubjectTestDesc}</p>
            <div className="buttonContainer">
                <div className="btn-container">
                    <span className="Button Orange" onClick={() => goToQuizScreen()}>{Strings.subjectTestPlayNow}</span>
                </div>
            </div>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showSubjectQuizVerifyPopUp ? "closed" : "opened"}`}></div>
  </>
  );
}
