import * as actions from "redux/action-types";
import {
    getAllCourses as getAllCoursesAPI,
    getMyActiveCourse as getMyActiveCourseAPI
} from "services/CourseService";
import { Strings } from "resources";
import { CancelToken } from "axios";

/**
 * setCourseLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setCourseLoadingState(isLoading) {
    return {
        type: actions.LOADING_COURSE_DATA,
        isLoading,
    };
}

/**
 * addCourse
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Course into State
 */
export function addCourse(items, hasMore, page) {
    return {
        type: actions.ADD_COURSE_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setCourseCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setCourseCancelToken(token) {
    return {
        type: actions.SET_COURSE_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreCourse
 * @description check If more files are there or not
 */
export function hasNoMoreCourse() {
    return {
        type: actions.SET_COURSE_HAS_NO_MORE,
    };
}

/**
 * getCoursesData
 * @param {boolean} reset
 * @param {string} examId
 * @param {string} tokenId
 * @description Get Courses with Pagination
 */
export function getCoursesData(reset, tokenId) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.courseData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setCourseCancelToken(newRequestSource));
            await dispatch(addCourse([], true, 0));
        }

        await dispatch(setCourseLoadingState(true));

        let { courseData } = await getState();
        let { page, limit, items, cancelRequest } = courseData;
        const { data, error } = await getAllCoursesAPI(tokenId);
        if (!error && data.status && Array.isArray(data.data) && data.data.length) {
            dispatch(addCourse(mergeData(items, data.data), true, page + 1));
        } else {
            dispatch(hasNoMoreCourse());
        }

        dispatch(setCourseLoadingState(false));
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.et_id) {
            let index = oldData.findIndex(data => (data.et_id && data.et_id === newDataItem.et_id));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
}

/**
 * addActiveCourse
 * @param {object} courseItem
 * @description Add Active Course
 */
export function addActiveCourse(courseItem) {
    return {
        type: actions.SET_ACTIVE_COURSE_DATA,
        courseItem: courseItem
    };
}

/**
 * clearActiveCourse
 * @description Clear Active Course
 */
export function clearActiveCourse() {
    return {
        type: actions.CLEAR_ACTIVE_COURSE_DATA
    };
}

/**
 * getActiveCourse
 * @param {*} tokenId
 * @param {*} userId
 * @description Get My Active Course
 */
export function getActiveCourse(tokenId, userId) {
    return async (dispatch, getState) => {
        const { data, error } = await getMyActiveCourseAPI(tokenId, userId);
        if (!error && data.status && Array.isArray(data.data) && data.data.length) {
            dispatch(addActiveCourse(data.data[0]));
        } else {
            dispatch(clearActiveCourse());
        }
    };
}