import React, { useRef, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Strings } from 'resources';
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import Header from 'components/Header';
import { getNotification as getNotificationAPI } from "services/AuthService";
import Footer from 'components/Footer';
import moment from 'moment';
import { isUserLoggedIn } from "services/AuthService";
import { openInNewTab } from "utils/helpers";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import { log } from "utils";
import KnowledgeNotification from "components/KnowledgeNotification";
import DashboardImage from 'assets/images/v2/landing/dashboard.png';
import QuillItImage from 'assets/images/v2/landing/quillit.png';
import CertificateImage from 'assets/images/v2/landing/certificate.png';
import ScoreboardImage from 'assets/images/v2/landing/scoreboard.png';
import HeatMapImage from 'assets/images/v2/landing/heatmap.png';
import LibraryImage from 'assets/images/v2/landing/b6.svg';
import AccuracyWormImage from 'assets/images/v2/landing/accuracy_worm.png';
import AceGameImage from 'assets/images/v2/landing/acegame.png';

//exam category page
import "./Subscription.scss"

import greenCheckBoxImage from 'assets/images/v2/landing/greenCheckBox.svg';
import greenMaskImage from 'assets/images/v2/landing/greenMask.svg';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import RegisteredUsers from 'assets/images/landing/registeredUsers.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(Draggable);
gsap.config({
    nullTargetWarn: false
});

export default function Subscription(props) {
    const { user } = isUserLoggedIn();
    const accountData = useSelector((state) => state.accountData);
    const navigationData = useSelector((state) => state.navigationData);
    const { status } = isUserLoggedIn();
    const dispatch = useDispatch();
    const history = useHistory();
    const [redirectTo, setRedirectTo] = useState({});
    const [isRedirect, setIsRedirect] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const sliderRef = useRef(null);


    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);




    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);



    const notificationItemView = (item, index) => {
        return (<KnowledgeNotification key={`notif-item-${index}`} itemData={item} index={index} />);
    }



    return (

        <>
            <div className="container subscription">
                <div className="Subsc_heading">
                    <h1 >Your Subscription Details</h1>
                </div>
                <div>
                       
                            <h2 className="text-uppercase mb-0">cgl tier 1</h2>
                            <p>Currently you have subscribed for this package</p>
                    
                        <div class="prents1">
                            <div class="child1">
                                <h5>Total</h5>
                                 <span>₹ 150</span>
                            </div>
                            <div class="child1">
                            <h5>Payment Mode</h5>
                                 <span>GooglePay</span>
                            </div>
                            <div class="child1">
                            <h5>Validity</h5>
                                 <span>31st December, 2021</span>
                            </div>
                            <div class="child1">
                            <a href="#">Go to your subscribed plan</a>
                               
                            </div>
                        </div>
                        <div className="hrlines"></div>
                        <div className="hrlines mt-4"></div>
                        <h2 className="text-capitalize mb-0">Number Series</h2>
                            <p>Currently you have subscribed for this package</p>
                    
                        <div class="prents1">
                            <div class="child1">
                                <h5>Total</h5>
                                 <span>₹ 150</span>
                            </div>
                            <div class="child1">
                            <h5>Payment Mode</h5>
                                 <span>GooglePay</span>
                            </div>
                            <div class="child1">
                            <h5>Validity</h5>
                                 <span>31st December, 2021</span>
                            </div>
                            <div class="child1">
                            <a href="#">Go to your subscribed plan</a>
                               
                            </div>
                        </div>
                        <div className="hrlines"></div>
     
                </div>
            </div>

        </>
    );

}
