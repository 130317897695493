import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getAllCourses
 * @param {*} tokenId string
 * @param {*} pageSize number
 * @param {*} pageNo number
 * @description Get All Courses Listing
 */
export async function getAllCourses(tokenId) {
    const payload = {
        'token': tokenId,
        'exam_id': ''
    };
    try {
        const response = await appClient.post(apiConfig.course.getAllCourses, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getCourse
 * @param {string} tokenId
 * @param {string} courseId
 * @description Get Individual Course Details
 */
export async function getCourse(tokenId, courseId) {
    const payload = {
        'token': tokenId,
        'exam_id': courseId
    };
    try {
        const response = await appClient.post(apiConfig.course.getAllCourses, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getMyCourse
 * @param {string} tokenId
 * @param {string} userId
 * @description Get My User Id
 */
export async function getMyCourse(tokenId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId
    };
    try {
        const response = await appClient.post(apiConfig.course.getAllCourses, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getMyActiveCourse
 * @param {string} tokenId
 * @param {string} userId
 * @description Get My Active Course
 */
export async function getMyActiveCourse(tokenId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId
    };
    try {
        const response = await appClient.post(apiConfig.course.getActiveCourse, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getCourseGroups
 * @param {string} tokenId
 * @param {string} examId
 * @description Get Course Groups
 */
export async function getCourseGroups(tokenId, examId) {
    const payload = {
        'token': tokenId,
        'exam_id': examId
    };
    try {
        const response = await appClient.post(apiConfig.course.getCourseGroups, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}
