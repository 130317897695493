import * as actions from "redux/action-types";
import {
    getLibraryFiles as getLibraryFilesAPI,
} from "services/LibraryService";
import { Strings } from "resources";
import { CancelToken } from "axios";

/**
 * setLibraryFilesLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setLibraryFilesLoadingState(isLoading) {
    return {
        type: actions.LOADING_LIBRARY_FILE_DATA,
        isLoading,
    };
}

/**
 * addLibraryFiles
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Library Files into State
 */
export function addLibraryFiles(items, hasMore, page) {
    return {
        type: actions.ADD_LIBRARY_FILE_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setLibraryFilesCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setLibraryFilesCancelToken(token) {
    return {
        type: actions.SET_LIBRARY_FILE_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreLibraryFiles
 * @description check If more files are there or not
 */
export function hasNoMoreLibraryFiles() {
    return {
        type: actions.SET_LIBRARY_FILE_HAS_NO_MORE,
    };
}

/**
 * getLibraryFilesData
 * @param {boolean} reset
 * @param {string} examId
 * @param {string} tokenId
 * @description Get Library Files with Pagination
 */
export function getLibraryFilesData(reset, examId, examCategory, examCategoryType, tokenId) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.libraryData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setLibraryFilesCancelToken(newRequestSource));
            await dispatch(addLibraryFiles([], true, 0));
        }

        await dispatch(setLibraryFilesLoadingState(true));

        let { libraryData } = await getState();
        let { page, limit, items, cancelRequest } = libraryData;
        const { data, error } = await getLibraryFilesAPI(tokenId, examId, examCategory, examCategoryType, limit, page);
        if (!error && data.status && Array.isArray(data.data) && data.data.length) {
            dispatch(addLibraryFiles(mergeData(items, data.data), true, page + 1));
            if (data.data.length < limit) {
                dispatch(hasNoMoreLibraryFiles());
            }
        } else {
            dispatch(hasNoMoreLibraryFiles());
        }

        dispatch(setLibraryFilesLoadingState(false));
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.lid) {
            let index = oldData.findIndex(data => (data.lid && data.lid === newDataItem.lid));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
}
