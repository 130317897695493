import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as LibraryActions from "redux/actions/LibraryActions";
import { Strings } from "resources";
import { getUserToken } from "services/AuthService";
import { log } from "utils";
import "./UpcomingEvents.scss";

export default function UpcomingEvents(props) {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  const userToken = getUserToken();

  /* Handle Site Language */
  useEffect(() => {
      log('Site Language Changed');
       return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Library Files */
  useEffect(() => {
     dispatch(LibraryActions.getLibraryFilesData(true, 1, userToken));
  }, [dispatch, userToken]);

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.upcomingEvents}</h1>
            </div>
            <div className="myPageWrapper myEvents">

                <div className="calendarMonthWrapper">

                    <div className="calenderMonthItem">
                        <div className="monthView">

                        </div>
                        <div className="monthListing">
                            <div className="listingItem">
                                <span className="heading dates">{Strings.dates}</span>
                                <span className="heading events">{Strings.events}</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem active">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">20 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">22 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                        </div>
                    </div>

                    <div className="calenderMonthItem">
                        <div className="monthView">

                        </div>
                        <div className="monthListing">
                            <div className="listingItem">
                                <span className="heading dates">{Strings.dates}</span>
                                <span className="heading events">{Strings.events}</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                        </div>
                    </div>

                    <div className="calenderMonthItem">
                        <div className="monthView">

                        </div>
                        <div className="monthListing">
                            <div className="listingItem">
                                <span className="heading dates">{Strings.dates}</span>
                                <span className="heading events">{Strings.events}</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">20 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">22 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">20 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">22 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                        </div>
                    </div>

                    <div className="calenderMonthItem">
                        <div className="monthView">

                        </div>
                        <div className="monthListing">
                            <div className="listingItem">
                                <span className="heading dates">{Strings.dates}</span>
                                <span className="heading events">{Strings.events}</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">18 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">20 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                            <div className="listingItem">
                                <span className="eventDate">22 Jan</span>
                                <span className="eventTitle">Test Event Title</span>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
      </div>
    </Fragment>
  );
}
