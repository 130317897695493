import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import * as NavigationActions from "redux/actions/NavigationActions";
import "./PlayACEGame.scss";

export default function PlayACEGame(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToGameRules = () => {
    dispatch(NavigationActions.togglePlayAceGameModal(false));
    history.push({
        pathname: '/ace-game-rules/'
    });
  }

  return (<>
    <div
      className={`popupWrapper aceGame PlayACEGame ${
        !navigationData.showPlayAceGameModal ? "closed" : "opened"
      }`}
    >
      <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon className="closeModalIcon" icon={faTimes} size="lg" /></span>
      <div className="popUpInner">
        <p>{Strings.playACEGameText}</p>
        <SmallButton clicked={() => redirectToGameRules()} btnType="Orange">{Strings.playNowAceGame}</SmallButton>
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showPlayAceGameModal ? "closed" : "opened"}`}></div>
  </>
  );
}
