import React from "react";
import { useSelector } from "react-redux";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import "./ACEGameEndConfirmation.scss";

export default function ACEGameEndConfirmation(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const aceTestData = useSelector((state) => state.aceTestData);

  return (<>
    <div
      className={`popupWrapper aceGame ACEGameConfirmation ${
        !navigationData.showACEGameEndModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{Strings.endTheGameTitle}</h3>
            <p>{Strings.endTheGameConfirm}</p>
            <div className="buttonContainer">
                <div className="btn-container">
                    <SmallButton clicked={() => props.onSubmitGame()} btnType="Orange" loading={aceTestData?.isLoading}>{Strings.endTheGameSubmit}</SmallButton>
                    <SmallButton clicked={() => props.onGoBack()} btnType="Transparent">{Strings.endTheGameGoBack}</SmallButton>
                </div>
            </div>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showACEGameEndModal ? "closed" : "opened"}`}></div>
  </>
  );
}
