import React, { Fragment, useEffect } from "react";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { log } from "utils";
import "./PageNotFound.scss";
import { Strings } from "resources";

export default function PageNotFound(props) {
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  const goToHome = () => {
    history.push(`/`);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer PageNotFound dispersionLogo">
        <div className="pageScreen">

          <img className="nfLogo" src={BharatCETLogo} alt="BharatCET" title="BharatCET" />
          <h1 className="nfTitle">404</h1>
          <h2 className="nfSubTitle">{Strings.page_not_found}</h2>
          <p className="nfDesc">{Strings.sorry_we_can_not_find_page_you_are_looking_for}</p>
          <span className="nfLink" onClick={() => goToHome()}>{Strings.return_to_home_page}</span>

        </div>
      </div>
    </Fragment>
  );
}
