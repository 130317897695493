import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as PaymentActions from "redux/actions/PaymentActions";
import { getUserId, getUserToken } from "services/AuthService";
import { Strings } from 'resources';
import { log } from "utils";
import "./History.scss";
import moment from 'moment';

export default function PaymentHistory(props) {
  const paymentData = useSelector((state) => state.paymentData);
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Library Files */
  useEffect(() => {
     dispatch(PaymentActions.getPaymentHistory(true, userToken, userId));
  }, [dispatch, userToken, userId]);

  /**
   * getPaymentMode
   * @param {string} paymentMode
   * @description Get Payment Mode
   */
  const getPaymentMode = (paymentMode) => {
      let payModeTitle = '';
      switch (paymentMode) {
          case 'NB':
            payModeTitle = Strings.paymentHistoryNetBanking;
            break;

        case 'CC':
            payModeTitle = "Credit Card";
            break;

        case 'UPI':
            payModeTitle = "UPI";
            break;

        case 'PPI':
            payModeTitle = "Wallet/PPI";
            break;

          default:
            payModeTitle = '-';
            break;
      }
      return payModeTitle;
  }

  /**
   * getPaymentStatus
   * @param {string} paymentStatus
   * @description Get Payment Status
   */
  const getPaymentStatus = (paymentStatus) => {
    let payStatusTitle = '';
    switch (paymentStatus) {
        case 'TXN_SUCCESS':
          payStatusTitle = Strings.paymentHistorySuccess;
          break;

        case 'TXN_FAILURE':
          payStatusTitle = Strings.paymentHistoryFailure;
          break;

        default:
          payStatusTitle = Strings.paymentHistoryFailure;
          break;
    }
    return payStatusTitle;
}

/**
 * getInvoiceDateTime
 * @param {string} date
 * @description Get Invoice Date Time in desired format
 */
const getInvoiceDateTime = (date) => {
    return moment(date).format('DD MMM YYYY hh:mm:ss a');
}

/**
 * paymentHistoryItem
 * @param {object} item
 * @param {number} index
 * @description Payment History Individual Item
 */
const paymentHistoryItem = (item, index) => {
    return (<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 paymentItemWrapper" key={`payment-${index}`}>
        <div className="paymentHistoryItem">
            <div className="paymentDesc">
                <div className="item">
                    <span className="amountHeading">{item.p_total ? 'Rs. '+item.p_total : '-'}</span>
                    <span className="description">{getInvoiceDateTime(item.p_created_on)}</span>
                </div>
                <div className="item">
                    <span className="heading">{Strings.paymentHistoryPaymentMode}</span>
                    <span className="description">{item.p_txn_paymentmode ? getPaymentMode(item.p_txn_paymentmode) : '-'}</span>
                </div>
                <div className="item">
                    <span className="heading">{Strings.paymentHistoryPaymentStatus}</span>
                    <span className="description">{item.p_txn_status ? getPaymentStatus(item.p_txn_status) : Strings.paymentHistoryFailure}</span>
                </div>
                <div className="item">
                    <span className="heading">{Strings.paymentHistoryPlans}</span>
                    <span className="description">{item.items[0].et_name}</span>
                    <span className="description">{userLanguage === 'hi' ? item.items[0].group_name_hindi : item.items[0].group_name}</span>
                </div>
            </div>
        </div>
    </div>);
}

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.paymentHistoryHeading}</h1>
            </div>
            <div className="myPageWrapper myPaymentHistory">
                {paymentData.items.length === 0 && !paymentData.isLoading &&
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                            <h3>{Strings.paymentHistoryNotFound}</h3>
                        </div>
                    </div>
                }
                {paymentData.items.length === 0 && paymentData.isLoading &&
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                            <h3>{Strings.paymentHistoryLoading}</h3>
                        </div>
                    </div>
                }
                {paymentData.items.length > 0 &&
                    <div className="row">
                        {paymentData.items.map((item, index) => paymentHistoryItem(item, index))}
                    </div>
                }
            </div>
        </div>
      </div>
    </Fragment>
  );
}
