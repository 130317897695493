import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Strings } from 'resources';
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import { useSelector, useDispatch } from "react-redux";
import { openInNewTab } from "utils/helpers";
import { log } from "utils";
import "./Header.scss";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import languageIcon from "assets/images/header/translate.svg";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import queryString from 'query-string';

export default function Header(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const navigationData = useSelector((state) => state.navigationData);
  const userLanguage = Strings.getLanguage();
  const location = useLocation();
  const isSignInPage = location?.pathname?.includes("sign-in");
  const isSignUpPage = location?.pathname?.includes("sign-up");

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]); 

  const setUserLanguage = async (language) => {
    dispatch(AccountActions.setUserLanguage(language));
    Strings.setLanguage(language);
    setLanguageOpen(false);
  }

  const toggleResetPasswordModal = async () => {
    log('Show Reset Password');
    dispatch(NavigationActions.setResetPasswordModalStatus(true));
  }

  const toggleSignUpModal = async () => {
    log('Show SignUp');
    dispatch(NavigationActions.setSignUpModalStatus(true));
  }

  const toggleUserActivateModal = async () => {
    log('Show User Activate');
    dispatch(NavigationActions.setActivateUserModalStatus(true));
  }

  const toggleLoginModal = async () => {
    log('Show Logins');
    dispatch(NavigationActions.setLoginModalStatus(true));
  }

  const toggleForgotPasswordModal = async () => {
    log('Show Forgot Password');
    dispatch(NavigationActions.setForgotPasswordModalStatus(true));
  }

  /**
   * goToHome
   * @description Go To Home Page Upon Logo Click
   */
  const goToHome = () => {
    window.location.href = Strings.websiteURL;
  }

  const goToPortal = () => {
    history.push('/');
  }

  const goToDownloadApp = () => {
    const googleAppUrl = 'https://play.google.com/store/apps/details?id=com.bharatcet';
    openInNewTab(googleAppUrl);
  }

  const handleLanguageMenu = () => {
    setLanguageOpen(!isLanguageOpen);
  }

return (<>
  <header className="WithoutAuthHeader">
        <div className="main-header">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center flex-header">
                    <div className="left-part">
                        <span onClick={() => goToHome()}>
                            <img className="site-logo" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </span>
                    </div>
                    <div className="right-part">
                        <div className="headerMenuWrapper">
                            <div className="menu-header-menu-container">
                                <ul id="menu-header-menu" className="menu headerMenu">
                                    <li className="downloadApp"><span onClick={() => goToDownloadApp()}>{Strings.downloadApp}</span></li>
                                    <li className="menu-item-has-children languageSelection">
                                      <span onClick={() => setLanguageOpen(!isLanguageOpen)}>{Strings.chooseLanguage}<i className={isLanguageOpen ? `arrow up` : `arrow down`}></i></span>
                                      <ul className={isLanguageOpen ? `open` : `close`}>
                                        <li>
                                          <span className={`${userLanguage === 'en' ? 'orange': ''}`} onClick={() => setUserLanguage('en')}>English</span>
                                        </li>
                                        <li>
                                          <span className={`${userLanguage === 'hi' ? 'orange': ''}`} onClick={() => setUserLanguage('hi')}>हिंदी</span>
                                        </li>
                                      </ul>
                                    </li>
                                    {!isSignInPage && !isSignUpPage &&
                                      <li className="signInBtn"><span onClick={() => toggleLoginModal()}>{Strings.signIn}</span></li>
                                    }
                                    
                                    {isSignInPage &&
                                      <li className="signInBtn"><span onClick={() => goToPortal()}>{Strings.home}</span></li>
                                    }
                                    {isSignUpPage &&
                                      <li className="signInBtn"><span onClick={() => goToPortal()}>{Strings.home}</span></li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </header>

        {/*<div className="settingsIcon moveLeft">
          <img className="settingsImage" src={languageIcon} title={Strings.languageName} alt={Strings.languageName} onClick={() => handleLanguageMenu()} />
        </div>*/}

        {/* Language Section Starts
        <div className={`languageSection ${isLanguageOpen ? `open` : `close`}`}>
          <ul className="languageSectionMenu">
            <li><span className={`${userLanguage === 'en' ? 'orange': ''}`} onClick={() => setUserLanguage('en')}>English</span></li>
            <li><span className={`${userLanguage === 'hi' ? 'orange': ''}`} onClick={() => setUserLanguage('hi')}>हिंदी</span></li>
          </ul>
        </div>
        Language Section Ends */}

{navigationData.showLoginModal ? <Login onForgotPasswordShow={toggleForgotPasswordModal} onSignUpShow={toggleSignUpModal}  /> : null}
{navigationData.showForgotPasswordModal ? <ForgotPassword onLoginShow={toggleLoginModal} onResetPasswordShow={toggleResetPasswordModal} onSignUpShow={toggleSignUpModal} /> : null}
{navigationData.showResetPasswordModal ? <ResetPassword onLoginShow={toggleLoginModal} /> : null}
{navigationData.showSignUpModal ? <SignUp onLoginShow={toggleLoginModal} onUserActivateShow={toggleUserActivateModal} /> : null}
{navigationData.showUserActivateModal ? <ActivateUser onLoginShow={toggleLoginModal} /> : null}

</>
);

}
