import * as actions from "redux/action-types";

const initialState = {
    currentRoute: '',
    showUserActivateModal: false,
    showSignUpModal: false,
    showResetPasswordModal: false,
    showForgotPasswordModal: false,
    showLoginModal: false,
    goToUrl: undefined,
    hideLeftSidebar: true,
    showReferralConfirmation: false,
    showBillingVerification: false,
    showSubjectQuizVerifyPopUp: false,
    showPrivacyPolicyModal: false,
    showTermsConditionsModal: false,
    showSubjectQuizResultModal: false,
    showACEGameEndModal: false,
    showACEPointsRedeemConfirmationModal: false,
    showACEPointsRedeemModal: false,
    showACEPointsCertificateModal: false,
    showFeatureNotAvailableModal: false,
    showPlayAceGameModal: false,
    showFreeTrialModal: false,
    showACEQuizResultModal: false,
    showACEQuizTimeUpModal: false,
    showFreeTrialExpiredModal: false,
    showSubscriptionPlanExpiredStatusModel: false,
    showReferralConfirmationStatusModal: false,
    showVideoNotAvailable: false,
    showReportUser: false,
    showReportMessage: false,
    showReportComment: false,
    showReportCommentUser: false,
    showUserAccessForgery: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: action.name,
    };

    case actions.CLOSE_AUTH_MODAL_STATUS:
      return {
        ...state,
        showForgotPasswordModal: false,
        showUserActivateModal: false,
        showSignUpModal: false,
        showResetPasswordModal: false,
        showLoginModal: false,
    };

    case actions.SET_FORGOT_PASSWORD_MODAL_STATUS:
      return {
        ...state,
        showForgotPasswordModal: action.status,
        showUserActivateModal: false,
        showSignUpModal: false,
        showResetPasswordModal: false,
        showLoginModal: false,
    };

    case actions.SET_RESET_PASSWORD_MODAL_STATUS:
      return {
        ...state,
        showResetPasswordModal: action.status,
        showUserActivateModal: false,
        showSignUpModal: false,
        showForgotPasswordModal: false,
        showLoginModal: false,
    };

    case actions.SET_SIGNUP_MODAL_STATUS:
      return {
        ...state,
        showSignUpModal: action.status,
        showUserActivateModal: false,
        showResetPasswordModal: false,
        showForgotPasswordModal: false,
        showLoginModal: false,
    };

    case actions.SET_USER_ACTIVATE_MODAL_STATUS:
      return {
        ...state,
        showUserActivateModal: action.status,
        showSignUpModal: false,
        showResetPasswordModal: false,
        showForgotPasswordModal: false,
        showLoginModal: false,
    };

    case actions.SET_LOGIN_MODAL_STATUS:
      return {
        ...state,
        showLoginModal: action.status,
        showUserActivateModal: false,
        showSignUpModal: false,
        showResetPasswordModal: false,
        showForgotPasswordModal: false,
    };

    case actions.SET_REFERRAL_CONFIRMATION_STATUS:
      return {
        ...state,
        showReferralConfirmation: true,
    };

    case actions.CLOSE_REFERRAL_CONFIRMATION_STATUS:
      return {
        ...state,
        showReferralConfirmation: false,
    };

    case actions.SET_BILLING_VERIFICATION_STATUS:
      return {
        ...state,
        showBillingVerification: true,
    };

    case actions.CLOSE_BILLING_VERIFICATION_STATUS:
      return {
        ...state,
        showBillingVerification: false,
    };

    case actions.SET_SUBJECT_QUIZ_VERIFY_STATUS:
      return {
        ...state,
        showSubjectQuizVerifyPopUp: true,
    };

    case actions.CLOSE_SUBJECT_QUIZ_VERIFY_STATUS:
      return {
        ...state,
        showSubjectQuizVerifyPopUp: false,
    };

    case actions.SET_PRIVACY_POLICY_MODAL_STATUS:
      return {
        ...state,
        showPrivacyPolicyModal: (typeof action.status !== "undefined") ? action.status : !state.showPrivacyPolicyModal,
    };

    case actions.SET_TERMS_CONDITIONS_MODAL_STATUS:
      return {
        ...state,
        showTermsConditionsModal: (typeof action.status !== "undefined") ? action.status : !state.showTermsConditionsModal,
    };

    case actions.SET_SUBJECT_QUIZ_RESULT_STATUS:
      return {
        ...state,
        showSubjectQuizResultModal: (typeof action.status !== "undefined") ? action.status : !state.showSubjectQuizResultModal,
    };

    case actions.SET_ACE_GAME_END_CONFIRMATION_STATUS:
      return {
        ...state,
        showACEGameEndModal: (typeof action.status !== "undefined") ? action.status : !state.showACEGameEndModal,
    };

    case actions.SET_ACE_POINTS_REDEEM_CONFIRMATION_STATUS:
      return {
        ...state,
        showACEPointsRedeemConfirmationModal: (typeof action.status !== "undefined") ? action.status : !state.showACEPointsRedeemConfirmationModal,
    };

    case actions.SET_ACE_POINTS_REDEEM_STATUS:
      return {
        ...state,
        showACEPointsRedeemModal: (typeof action.status !== "undefined") ? action.status : !state.showACEPointsRedeemModal,
    };

    case actions.SET_ACE_POINTS_CERTIFICATE_STATUS:
      return {
        ...state,
        showACEPointsCertificateModal: (typeof action.status !== "undefined") ? action.status : !state.showACEPointsCertificateModal,
    };

    case actions.SET_FEATURE_NOT_AVAILABLE_STATUS:
      return {
        ...state,
        showFeatureNotAvailableModal: (typeof action.status !== "undefined") ? action.status : !state.showFeatureNotAvailableModal,
    };

    case actions.SET_FREE_TRIAL_STATUS:
      return {
        ...state,
        showFreeTrialModal: (typeof action.status !== "undefined") ? action.status : !state.showFreeTrialModal,
    };

    case actions.SET_FREE_TRIAL_EXPIRED_STATUS:
      return {
        ...state,
        showFreeTrialExpiredModal: (typeof action.status !== "undefined") ? action.status : !state.showFreeTrialExpiredModal,
    };

    case actions.SET_SUBSCRIPTION_PLAN_EXPIRED_STATUS:
      return {
        ...state,
        showSubscriptionPlanExpiredStatusModel: (typeof action.status !== "undefined") ? action.status : !state.showSubscriptionPlanExpiredStatusModel,
    };

    case actions.SET_PLAY_ACE_GAME_STATUS:
      return {
        ...state,
        showPlayAceGameModal: (typeof action.status !== "undefined") ? action.status : !state.showPlayAceGameModal,
    };

    case actions.SET_ACE_QUIZ_RESULT_STATUS:
      return {
        ...state,
        showACEQuizResultModal: (typeof action.status !== "undefined") ? action.status : !state.showACEQuizResultModal,
    };

    case actions.SET_ACE_QUIZ_TIME_UP_STATUS:
      return {
        ...state,
        showACEQuizTimeUpModal: (typeof action.status !== "undefined") ? action.status : !state.showACEQuizTimeUpModal,
    };

    case actions.SET_REFERRAL_CONFIRMATION_SUCCESS_STATUS:
      return {
        ...state,
        showReferralConfirmationStatusModal: (typeof action.status !== "undefined") ? action.status : !state.showReferralConfirmationStatusModal,
    };

    case actions.SET_VIDEO_NOT_AVAILABLE:
      return {
        ...state,
        showVideoNotAvailable: (typeof action.status !== "undefined") ? action.status : !state.showVideoNotAvailable,
    };

    case actions.SET_GO_TO_URL:
      return {
        ...state,
        goToUrl: action.url,
    };

    case actions.TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        hideLeftSidebar: (typeof action.status !== "undefined") ? action.status : !state.hideLeftSidebar,
    };

    case actions.SET_REPORT_USER:
      return {
        ...state,
        showReportUser: (typeof action.status !== "undefined") ? action.status : !state.showReportUser,
    };

    case actions.SET_REPORT_MESSAGE:
      return {
        ...state,
        showReportMessage: (typeof action.status !== "undefined") ? action.status : !state.showReportMessage,
    };

    case actions.SET_REPORT_COMMENT:
      return {
        ...state,
        showReportComment: (typeof action.status !== "undefined") ? action.status : !state.showReportComment,
    };

    case actions.SET_REPORT_COMMENT_USER:
      return {
        ...state,
        showReportCommentUser: (typeof action.status !== "undefined") ? action.status : !state.showReportCommentUser,
    };

    case actions.SET_USER_ACCESS_FORGERY:
      return {
        ...state,
        showUserAccessForgery: (typeof action.status !== "undefined") ? action.status : !state.showUserAccessForgery,
    };

    default:
      return state;
  }
}
