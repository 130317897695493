import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Strings } from "resources";
import timeUpAlarms from "assets/images/page/timeupAlarms.svg";
import "./ACEGameTimeUp.scss";

export default function ACEGameTimeUp(props) {
  const navigationData = useSelector((state) => state.navigationData);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onTimerEnds();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (<>
    <div
      className={`popupWrapper ACEGameTimeUp dispersionLogo ${
        !navigationData.showACEQuizTimeUpModal ? "closed" : "opened"
      }`}
    >
      <div className="popUpInner">
        <h3 className="modal-heading">{Strings.timesUp}</h3>
        <div className="featuredIconWrapper">
            <img className="featuredIcon" src={timeUpAlarms} title={Strings.success} alt={Strings.success} />
        </div>
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showACEQuizTimeUpModal ? "closed" : "opened"}`}></div>
  </>
  );
}
