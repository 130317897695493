import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logOutUserFromApp } from "services/AuthService";
import { Strings } from 'resources';
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as NotificationActions from "redux/actions/NotificationActions";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { log } from "utils";
import { getUserId, getUserToken } from "services/AuthService";
import { useAlert } from "react-alert";
import "./HeaderAuthenticated.scss";
import UserPlaceHolder from 'assets/images/page/userPlaceHolder.png';
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import navIcon from "assets/images/navigation/nav.svg";
/* import searchIcon from "assets/images/header/search.svg"; */
/* import alertIcon from "assets/images/header/help.svg"; */
import languageIcon from "assets/images/header/translate.svg";
import chatIcon from "assets/images/navigation/chat.svg";
import notificationIcon from "assets/images/header/notification.svg";

export default function HeaderAuthenticated(props) {
  const userId = getUserId();
  const userToken = getUserToken();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState(UserPlaceHolder);
  const [searchQuery, setSearchQuery] = useState('');

  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);
  const notificationData = useSelector((state) => state.notificationData);
  const userLanguage = Strings.getLanguage();
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]); 

  useEffect(() => {
    if (accountData && accountData.accountSettings) {
      const userPhoto = accountData.accountSettings.photo === '' || accountData.accountSettings.photo === null ? UserPlaceHolder : accountData.accountSettings.photo;
      setUserProfileImage(userPhoto);
    }
 }, [accountData]);

  const setUserLanguage = async (language) => {
    dispatch(AccountActions.setUserLanguage(language));
    Strings.setLanguage(language);
    setLanguageOpen(false);
  }

  const logOutCurrentUser = async () => {
    const logoutResponse = await logOutUserFromApp();
    if (logoutResponse) {
      goToHome();
    }
  }

  /**
   * goToHome
   * @description Go To Home Page Upon Logo Click
   */
  const goToHome = () => {
    window.location.href = Strings.websiteURL;
    setAccountOpen(false);
  }

  const goToJunction = () => {
    history.push("/my-courses");
    setAccountOpen(false);
  }

  const goToMyProfile = () => {
    history.push("/my-profile");
    setAccountOpen(false);
  }

  const goToMyChangePassword = () => {
    history.push("/change-password");
    setAccountOpen(false);
  }

  const goToPoliciesAndProcedures = () => {
    history.push("/policies-and-procedures");
    setAccountOpen(false);
  }

  const goToMySubscription = () => {
    history.push({
      pathname: '/select-subscription-package/',
      state: { examId: '1' }
    });
    setAccountOpen(false);
  }

  const goToMyPaymentHistory = () => {
    setAccountOpen(false);
    history.push("/payment-history/");
  }

  const goToMyCertificateHistory = () => {
    setAccountOpen(false);
    history.push("/certificate-history/");
  }

  const goToTermsConditions = () => {
    setAccountOpen(false);
    history.push("/terms-and-conditions/");
  }

  const goToPrivacyPolicy = () => {
    setAccountOpen(false);
    history.push("/copyright-and-privacy-policy/");
  }

  const goToContactUs = () => {
    setAccountOpen(false);
    setLanguageOpen(false);
    setNotificationOpen(false);
    history.push("/contact-us");
  }

  const goToQuillIt = () => {
    setNotificationOpen(false);
    setAccountOpen(false);
    setLanguageOpen(false);
    history.push("/quill-it");
  }

  const toggleLeftSideBar = () => {
    dispatch(NavigationActions.toggleLeftSidebar(!navigationData.hideLeftSidebar));
  }

  /* Handle Search Key and Options */
  const handleSearchKey = (event) => {
    if (event.key === 'Enter') {
      if (searchQuery !== '') {
        history.push(`/search/${fixedEncodeURIComponent(searchQuery)}`);
      }
    }
  }

  /* Encode Search URI Component */
  const fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return '%' + c.charCodeAt(0).toString(16)
    })
  }

  const handleAccountMenu = () => {
    setLanguageOpen(false);
    setSearchOpen(false);
    setNotificationOpen(false);
    setAccountOpen(!isAccountOpen);
  }

  const handleNotificationMenu = () => {
    setLanguageOpen(false);
    setAccountOpen(false);
    setSearchOpen(false);
    setNotificationOpen(!isNotificationOpen);
    if (notificationData?.notifCount > 0) {
      let notiId = '';
      dispatch(NotificationActions.updateNotification(userId, userToken, notiId ,'read'));
      dispatch(NotificationActions.clearNotifications());
    }
  }

  const handleLanguageMenu = () => {
    setNotificationOpen(false);
    setAccountOpen(false);
    setSearchOpen(false);
    setLanguageOpen(!isLanguageOpen);
  }

  const handleSearchMenu = () => {
    setNotificationOpen(false);
    setAccountOpen(false);
    setLanguageOpen(false);
    setSearchOpen(!isSearchOpen);
  }

  const hideMenus = () => {
    setNotificationOpen(false);
    setAccountOpen(false);
    setLanguageOpen(false);
    setSearchOpen(false);
  }

  const showAllNotification = (item, index) => {
    return (
      <li key={`notification-${index}`} className="border-line">
        <span>
          <span className="content" dangerouslySetInnerHTML={{__html : item.noti_description}}></span>
        </span>
      </li>  
    );
  }

  const readAllNotification = () => {
    let notiId = '';
    dispatch(NotificationActions.updateNotification(userId, userToken, notiId ,'read'))
    .then(() => {
      dispatch(NotificationActions.getNotificationData(true, userId, userToken, 'unread'));
    });
    alert.success(Strings.notification_read_successfully);

    return false;
  }

return (<>
<div className={`pageHeader ${props.showFullWidth ? 'WithoutAuth' : ''}`}>
  <div className="container-fluid">
    <div className="pageHeaderInner">

      {/* Mobile View Starts */}
      <div className="mobileView">
        {!props.isLanding && <div className={`hamburgerIcon ${props.isLanding ? 'landing' : ''}`}>
            {navigationData.hideLeftSidebar && <img className="hamburgerImage" src={navIcon} title={Strings.menuName} alt={Strings.menuName} onClick={() => toggleLeftSideBar()} />}
        </div>}
        <div className="headerLogoWrapper">
          <div className="headerLogo">
            <span className="portalLogoWrapper" onClick={() => goToJunction()}>
              <img className="portalLogo" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
            </span>
          </div>
        </div>
        {/*<div className="settingsIcon moveLeft">
          <img className="settingsImage" src={searchIcon} title={Strings.searchName} alt={Strings.searchName} onClick={() => handleSearchMenu()} />
        </div>*/}
        <div className="settingsIcon moveLeft notificationBubble">
          <img className="settingsImage" src={notificationIcon} title={Strings.notificationName} alt={Strings.notificationName} onClick={() => handleNotificationMenu()} />
          {notificationData?.notifCount > 0 && <span className="bubbleIcon">{notificationData?.notifCount}</span>}
        </div>
        <div className="settingsIcon">
          <img className="settingsImage" src={languageIcon} title={Strings.languageName} alt={Strings.languageName} onClick={() => handleLanguageMenu()} />
        </div>
        <div className="settingsIcon">
          <img className="settingsImage" src={chatIcon} title={Strings.quillItName} alt={Strings.quillItName} onClick={() => goToQuillIt()} />
        </div>
        <div className="settingsIcon">
          <img className="settingsImage userProfileImage" src={userProfileImage} title={Strings.myProfileName} alt={Strings.myProfileName} onClick={() => handleAccountMenu()} />
        </div>
        {/* Language Section Starts */}
        <div className={`languageSection ${isLanguageOpen ? `open` : `close`}`}>
          <ul className="languageSectionMenu">
            <li><span className={`${userLanguage === 'en' ? 'orange': ''}`} onClick={() => setUserLanguage('en')}>English</span></li>
            <li><span className={`${userLanguage === 'hi' ? 'orange': ''}`} onClick={() => setUserLanguage('hi')}>हिंदी</span></li>
          </ul>
        </div>
        {/* Language Section Ends */}

        {/* Search Section Starts */}
        <div className={`searchSection ${isSearchOpen ? `open` : `close`}`}>
          {isSearchOpen && <div className="searchInputBox">
            <input type="text" className="searchInput" value={searchQuery} placeholder={Strings.placeholderSearch} onChange={e => setSearchQuery(e.currentTarget.value)} onKeyDown={(event) => handleSearchKey(event)} />
          </div>}
        </div>
        {/* Search Section Ends */}

      </div>
      {/* Mobile View Ends */}

      {/* Desktop View Starts */}
      <div className="deskTopView">
        <div className="headerLogoWrapper">
          <div className="headerLogo">
            <span className="portalLogoWrapper" onClick={() => goToJunction()}>
              <img className="portalLogo" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
            </span>
          </div>
          <div className="headerTime">
              <span className="heading">{props.pageTitle}</span>
              <span className="time">{moment().format('dddd, DD MMMM YYYY')}</span>
          </div>
        </div>
        <div className="HeaderMenuItems">
          <ul className="nav">
            {/*<li>
              {!isSearchOpen && <span>
                <img className="menuIcon" src={searchIcon} title={Strings.searchName} alt={Strings.searchName} onClick={() => handleSearchMenu()} />
              </span>}
              {isSearchOpen && <div className="searchInputBox">
                    <input type="text" className="searchInput" value={searchQuery} placeholder={Strings.placeholderSearch} onChange={e => setSearchQuery(e.currentTarget.value)} onKeyDown={(event) => handleSearchKey(event)} />
                    <img className="menuIcon" src={searchIcon} title={Strings.searchName} alt={Strings.searchName} onClick={() => setSearchOpen(!isSearchOpen)} />
              </div>}
            </li>*/}
            <li>
              <span className="notificationBubble" onClick={() => handleNotificationMenu()}>
                <img className="menuIcon" src={notificationIcon} title={Strings.notificationName} alt={Strings.notificationName} />
                {notificationData?.notifCount > 0 && <span className="bubbleIcon">{notificationData?.notifCount}</span>}
              </span>
            </li>
            <li className="menu-item-has-children languageSelection">
              <span onClick={() => handleLanguageMenu()}>
                <img className="menuIcon" src={languageIcon} title={Strings.languageName} alt={Strings.languageName} />
              </span>
              <ul className={isLanguageOpen ? `open` : `close`}>
                <li><span className={`${userLanguage === 'en' ? 'orange': ''}`} onClick={() => setUserLanguage('en')}>English</span></li>
                <li><span className={`${userLanguage === 'hi' ? 'orange': ''}`} onClick={() => setUserLanguage('hi')}>हिंदी</span></li>
              </ul>
            </li>
            <li>
              <span onClick={() => goToQuillIt()}>
                <img className="menuIcon" src={chatIcon} title={Strings.quillItName} alt={Strings.quillItName} />
              </span>
            </li>
            <li className="menu-item-has-children userInfo">
                <span className="userInfoSectionWrapper" onClick={() => handleAccountMenu()}>
                  <div className="userInfoSection">
                    <div className="userImageWrapper">
                      <img className="userImage" src={userProfileImage} title={Strings.myProfileName} alt={Strings.myProfileName} />
                    </div>
                    <div className="userDetails">
                      {accountData.account && <span className="name"><span className="salute">{Strings.hi}</span>, {accountData.account.first_name}</span>}
                    </div>
                </div>
              </span>
            </li>
          </ul>
        </div>
      </div>
      {/* Desktop View Ends */}

      {/* Account Section Starts */}
      <div className={`accountSection ${isAccountOpen ? `open` : `close`}`}>
        <div className="userDetails">
          <div className="userImageWrapper">
            <img className="userImage" src={userProfileImage} title={Strings.myProfileName} alt={Strings.myProfileName} />
          </div>
          <div className="userInfo">
            {accountData.account && <span className="name">{accountData.account.first_name} {accountData.account.last_name}</span>}
            {accountData.account && <span className="email">{accountData.account.email}</span>}
            </div>
          </div>
          <div className="dividerWrapper">
            <div className="divider"/>
          </div>
          <ul className="accountSectionMenu">
            <li>
              <span onClick={() => goToMyProfile()}>{Strings.myProfile}</span>
            </li>
            <li>
              <span onClick={() => goToMyChangePassword()}>{Strings.changePasswordMenu}</span>
            </li>
            <li>
              <span onClick={() => goToMySubscription()}>{Strings.mySubscription}</span>
            </li>
            <li>
              <span onClick={() => goToMyPaymentHistory()}>{Strings.paymentHistory}</span>
            </li>
            <li>
              <span onClick={() => goToMyCertificateHistory()}>{Strings.myCertificates}</span>
            </li>
            <li>
              <span onClick={() => goToPoliciesAndProcedures()}>{Strings.policies} & {Strings.procedures}</span>
            </li>
            <li>
              <span onClick={() => goToTermsConditions()}>{Strings.myTermsConditions}</span>
            </li>
            <li>
              <span onClick={() => goToPrivacyPolicy()}>{Strings.myPrivacyPolicy}</span>
            </li>
            <li>
              <span onClick={() => goToContactUs()}>{Strings.myContactUs}</span>
            </li>
            <li>
              <div className="divider"/>
            </li>
            <li>
              <span className="orange" onClick={() => logOutCurrentUser()}>{Strings.mySignOut}</span>
            </li>
          </ul>
        </div>
      {/* Account Section Ends */}

      {/* Notification Section Starts */}
      <div className={`notificationSection ${isNotificationOpen ? `open` : `close`}`}>
          <h4>{Strings.notifPageTitle}</h4>
          <ul className="notificationSectionMenu">
              {notificationData?.items?.length === 0 &&
                <li>
                  <span>
                    <span className="date">{Strings.oops}</span> {Strings.no_notification_found}
                  </span>
                </li>
              }

              {notificationData?.items?.length > 0 &&
                notificationData?.items.map((item, index) => showAllNotification(item, index))
              }
            
          </ul>
          {notificationData?.items?.length > 0 &&
            <span className="readAll" onClick={() => readAllNotification()} >{Strings.mark_all_as_read}</span>
          }
      </div>
      {/* Notification Section Ends */}

    </div>
  </div>
  <div className={`popUpMenuItemOverlay ${!isAccountOpen ? "closed" : "opened"}`} onClick={() => hideMenus()}></div>
  <div className={`popUpMenuItemOverlay ${!isNotificationOpen ? "closed" : "opened"}`} onClick={() => hideMenus()}></div>
  <div className={`popUpMenuItemOverlay ${!isLanguageOpen ? "closed" : "opened"}`} onClick={() => hideMenus()}></div>
</div>
</>
);

}
