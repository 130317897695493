import React, { useEffect, Fragment, useState } from "react";
import { log } from "utils";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { getUserId, getUserToken } from "services/AuthService";
import SmallButton from "components/UI/SmallButton";
import { submitContactRequest } from "services/AuthService";
import { useAlert } from "react-alert";
import { Strings } from 'resources';
import "./ContactUs.scss";

export default function ContactUs(props) {
  const alert = useAlert();
  const [isLoading, setLoadingState] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [contactNo, setContactNo] = useState('');
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();

  /* Handle Site Language */
  useEffect(() => {
      log('Site Language Changed');
      return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /**
   * If Dispatch and UserId set then get the account settings details
   */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /**
   * Setup Fields Based upon Account Data
   */
  useEffect(() => {
    if (accountData && accountData.accountSettings) {
      const userFirstName = accountData.accountSettings.first_name;
      const userLastName = accountData.accountSettings.last_name;
      const userEmail = accountData.accountSettings.email;
      const userContactNo = accountData.accountSettings.contact_no;
      setFirstName(userFirstName);
      setLastName(userLastName);
      setEmail(userEmail);
      setContactNo(userContactNo);
    }
 }, [accountData]);

 /**
  * sendContactRequest
  * @description Send Contact Request to Admin
  */
  const sendContactRequest = async () => {
    if (subject === '' || subject === null) {
      alert.error(Strings.subjectRequired);
      return log(Strings.subjectRequired);
    }

    if (message === '' || message === null) {
      alert.error(Strings.messageRequired);
      return log(Strings.messageRequired);
    }

    setLoadingState(true);
    const payload = {
      user_id : userId,
      first_name : firstName,
      last_name : lastName,
      email : email,
      contact_no: contactNo,
      subject : subject,
      message : message,
      token: userToken
    }
    const { data, error } = await submitContactRequest(payload);
    setLoadingState(false);
    if (error) {
      alert.error(error);
      return log(error);
    } else {
      return onContactRequestSuccess(data.data);
    }
  };

  /**
   * onContactRequestSuccess
   * @param {*} data
   * @description On Contact Request Sent Notify Admin
   */
  const onContactRequestSuccess = (data) => {
    alert.success(Strings.contactRequestSuccess);
    setSubject('');
    setMessage('');
  };

  return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo">
        <div className="myPage contactUs">
          <div className="myContactRequestWrapper">
            <h2 className="sectionHeading">{Strings.contactHear}</h2>
            <div className="contactUsForm">
                <div className="formGroup inputForm">
                  <input type="text" value={subject} placeholder={Strings.typeSubject} onChange={(e) => { setSubject(e.target.value); }} />
                </div>
                <div className="formGroup inputForm">
                  <textarea placeholder={Strings.typeMessage} onChange={(e) => { setMessage(e.target.value); }} value={message} rows="6"></textarea>
                </div>
                <div className="formGroup buttonForm">
                  <SmallButton clicked={() => sendContactRequest()} loading={isLoading} btnType="Orange">{Strings.contactSubmit}</SmallButton>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
