import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as TopicActions from "redux/actions/TopicActions";
import { useParams } from "react-router-dom";
import { getUserId, getUserToken } from "services/AuthService";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Strings } from 'resources';
import Select from 'react-select';
import { log } from "utils";
import "./Reading.scss";

export default function Reading(props) {
  const accountData = useSelector((state) => state.accountData);
  const topicData = useSelector((state) => state.topicData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const { catId, topicId } = useParams();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState({});
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Category Topics */
  useEffect(() => {
    dispatch(TopicActions.getCategoryTopics(userToken, catId, userId));
  }, [dispatch, userToken, userId, catId]);

  /* Get Category Topics */
  useEffect(() => {
    dispatch(TopicActions.getTopicMainCategories(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get Reading Materials */
  useEffect(() => {
    dispatch(TopicActions.getTopicsReading(userToken, catId, topicId, userId));
  }, [dispatch, userToken, userId, catId, topicId]);

  useEffect(() => {
    let selectedTopic = topicData.topics.filter(function (item) {
      return +item.topic_id === +topicId;
    });
    if (selectedTopic.length > 0) {
      const topicName = userLanguage === 'hi' ? selectedTopic[0].topic_name_hindi : selectedTopic[0].topic_name ;
      setSelectedOption({ value: +selectedTopic[0].topic_id, label: topicName});
    }
  }, [dispatch, topicData.topics, topicId]);

  const mainTopicsOptions = () => {
    const topicItems = [];
    topicData.topics.map((item, index) => {
      const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;
      topicItems.push({ value: +item.topic_id, label: topicName });
    });
    return topicItems;
  }

  /**
   * goToCategory
   * @description Go to Parent Category Page
   */
  const goToCategory = () => {
    history.push(`/category/${+catId}/heat-map`);
  }

  /**
   * goToTopic
   * @param {object} item
   * @description Go to Other Siblings Page
   */
  const goToTopic = (item) => {
    history.push(`/category/${+catId}/topic/${+item.topic_id}/reading`);
  }

  const goToTopicItem = (item) => {
    history.push(`/category/${+catId}/topic/${+item.value}/reading`);
  }

  /**
   * topicItem
   * @param {object} item
   * @param {number} index
   * @description Show Topic Item in Left SideBar
   */
  const topicItem = (item, index) => {
    const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;
    return (<li className={`topicItem ${+item.topic_id === +topicId ? "active" : "" }`} key={`topic-${index}`} onClick={() => goToTopic(item, index)}>{topicName}</li>);
  }

  /**
   * readingItem
   * @param {object} item
   * @param {object} index
   * @description Show Reading Item in the Content bar
   */
  const readingItem = (item, index) => {
    const materialDescription = userLanguage === 'hi' ? item.study_description_hindi : item.study_description ;
    return (<div key={`reading-${index}`} dangerouslySetInnerHTML={{__html : materialDescription}}></div>);
  }

  /**
   * showCategoryHeader
   * @description Show Selected Category Description
   */
  const showCategoryHeader = () => {
    let filteredCategory = topicData.category.filter(categoryItem => {
      return +categoryItem.cid === +catId;
    });
    let categoryName = "-";
    if (filteredCategory.length > 0) {
      categoryName = userLanguage === 'hi' ? filteredCategory[0].category_name_hindi : filteredCategory[0].category_name;
    }
    return (<h3 className="mainCategoryName" onClick={() => goToCategory()}><FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" />{categoryName}</h3>);
  }

  /**
   * showTopicBreadCrumbs
   * @description Show Topic Details BreadCrumbs
   */
  const showTopicBreadCrumbs = () => {
    let filteredTopic = topicData.topics.filter(topicItem => {
      return +topicItem.topic_id === +topicId;
    });
    let topicName = "-";
    if (filteredTopic.length > 0) {
      topicName = userLanguage === 'hi' ? filteredTopic[0].topic_name_hindi : filteredTopic[0].topic_name;
    }
    return (<h3 className="pageTitle">{topicName} &#8594; {Strings.smReading}</h3>);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg noPadding">
        <div className="topicPage">
                <div className="topicLists">
                    {showCategoryHeader()}
                    {topicData.topics.length > 0 &&
                    <ul className="topicItems">
                    {topicData.topics.map((item, index) => topicItem(item, index))}
                    </ul>
                    }
                </div>
                <div className="mobileCategoryListings">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h4 className="categoryTitle">{Strings.selectedTopic} : {selectedOption?.label}</h4>
                          <Select 
                            defaultValue={selectedOption}
                            value={selectedOption}
                            isSearchable
                            options={mainTopicsOptions()}
                            placeholder={Strings.select_topic}
                            onChange={(item, index) => goToTopicItem(item, index)}
                          />
                    </div>
                  </div>
                </div>
                <div className="readingLists">
                    {showTopicBreadCrumbs()}
                    <div className="readingItemListing">
                           {topicData.reading.length > 0 &&
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {topicData.reading.map((item, index) => readingItem(item, index))}
                                </div>
                            </div>
                            }
                            {topicData.reading.length === 0 && topicData.isLoading &&
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                                <h3>{Strings.smLoading}</h3>
                              </div>
                            </div>
                          }
                          {topicData.reading.length === 0 && !topicData.isLoading &&
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                                <h3>{Strings.smNoReadingFound}</h3>
                              </div>
                            </div>
                          }

                    </div>
                </div>
        </div>
      </div>
    </Fragment>
  );
}
