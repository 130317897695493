import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { getUserId } from "services/AuthService";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import TermsAndConditionsData from "assets/data/TermsAndConditions";
import { log } from "utils";
import "./TermsAndConditions.scss";
import { Strings } from "resources";

export default function TermsAndConditions(props) {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountData);
  const userId = getUserId();
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Load User Settings */
  useEffect(() => {
     dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  const termsDescription = () => {
    return TermsAndConditionsData;
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg">
        <div className="pageScreen">
          <div className="pageHeading">
            <div className="Heading">
              <span className="title">{Strings.terms} &amp; {Strings.conditions}</span>
              <span className="breadcrumbs">{Strings.settings} &rarr; {Strings.terms} &amp; {Strings.conditions}</span>
            </div>
            <div className="Logo">
              <img className="logoImage" src={BharatCETLogo} title={Strings.bharatCET} alt={Strings.bharatCET} />
            </div>
          </div>
          <div className="pageContent" dangerouslySetInnerHTML={{__html : termsDescription()}}></div>
        </div>
      </div>
    </Fragment>
  );
}
