// ES6 module syntax
import LocalizedStrings from "react-localization";
import English from "./languages/english.js";
import Hindi from "./languages/hindi.js";
import { getUserLanguageInApp } from "services/AuthService";

let Strings = new LocalizedStrings({
  en: English,
  hi: Hindi,
});

const setUserLanguage = async () => {
  const data = await getUserLanguageInApp();
  data && data.lang ? Strings.setLanguage(data.lang) : Strings.setLanguage('en');
}

setUserLanguage();
/*
const dataLang = Strings.getAvailableLanguages();
const dataCurrentLang = Strings.getLanguage();
*/

export { Strings };
