import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as AccountActions from "redux/actions/AccountActions";
import * as AceTestActions from "redux/actions/AceTestActions";
import { getUserId, getUserToken } from "services/AuthService";
import { sendACEGameScoreCard as sendACEGameScoreCardFromAPI } from "services/AceTestService";
import { Strings } from 'resources';
import { log } from "utils";
import SmallButton from "components/UI/SmallButton";
import ACEGameResultSend from "modals/ACEGameResultSend";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import "./ScoreCard.scss";

export default function ACEGameScoreCard(props) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = getUserId();
    const userToken = getUserToken();
    const { quizId, attemptId } = useParams();
    const [isLoading, setLoadingState] = useState(false);
    const aceTestData = useSelector((state) => state.aceTestData);
    const navigationData = useSelector((state) => state.navigationData);
    const accountData = useSelector((state) => state.accountData);

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    /* Get ACE Game Statistics */
    useEffect(() => {
        dispatch(AceTestActions.getACEGameStats(quizId, attemptId));
        dispatch(AccountActions.getUserDashboardPoints());
        dispatch(AccountActions.validateUserGroup());
    }, [dispatch, quizId, attemptId]);


    /**
     * questionStatusView
     * @param {*} queItem
     * @param {*} queIndex
     * @description Question Status View
     */
    const questionStatusView = (queItem, queIndex) => {
        let className = 'black';
        if (queItem.questionStatus === 'skipped' || queItem.questionStatus === 'pending') {
          className = 'blue';
        } else if (queItem.questionStatus === 'answered' && queItem.answerStatus === '0') {
          className = 'yellow';
        } else if (queItem.questionStatus === 'answered' && queItem.answerStatus === '1') {
            className = 'green';
        }
        return (<span className={className} key={`question-status-${queIndex}`}>{queItem.displayOrder}</span>);
    }

    /**
     * goToLeaderBoard
     * @description Redirect to Leaderboard
     */
    const goToLeaderBoard = () => {
        history.push(`/leader-board`);
    }

    /**
     * playMoreACEGame
     * @description Go to Dashboard Screen
     */
    const playMoreACEGame = () => {
        history.push(`/dashboard`);
    }

    /**
     * sendScoreCard
     * @description Send Scorecard to respective emails
     */
    const sendScoreCard = async () => {
        const status = checkACEGameExpireModal();
        if (status === '4') {
            history.push(`/ace-game/${quizId}/${attemptId}/report`);
        } else {
          showFeatureNotAvailableModal();
        }

        /*
        setLoadingState(true);
        const { data, error } = await sendACEGameScoreCardFromAPI(userToken, userId, quizId, attemptId, '1');
        if (!error && data.status) {
          log(data);
          setLoadingState(false);
          dispatch(NavigationActions.toggleACEQuizResultModal(true));
        }
        setLoadingState(false);
        */
    }

    const checkACEGameExpireModal = () => {
        let status = '0';
        const valExamId = accountData?.status?.exam;
        const valGroupId = accountData?.status?.group;
        const isValid = accountData?.status?.valid;
        if (valExamId === '1' && valGroupId === '1' && isValid) {
          status = '1';
        } else if (valExamId === '1' && valGroupId === '2' && isValid) {
          status = '2';
        } else if (valExamId === '1' && valGroupId === '3' && isValid) {
          status = '4';
        } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
          status = '3';
        }
        return status;
      }
    
     
    const showFeatureNotAvailableModal = () => {
        dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
    }

    /**
     * onGoBackModal
     * @description Go back or close the modal
     */
    const onGoBackModal = () => {
        dispatch(NavigationActions.toggleACEQuizResultModal(false));
    }

    const hideFeatureNotAvailableModal = () => {
        dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
    }

    const showACEScoreSalutation = () => {
        let totalPercentage = 0;
        if(isNaN(+aceTestData?.activeTestScore?.correctScore) || isNaN(+aceTestData?.activeTestScore?.totalScore)){
            totalPercentage = 0;
        } else {
            totalPercentage = Math.round((+aceTestData?.activeTestScore?.correctScore/+aceTestData?.activeTestScore?.totalScore) * 100);
        }
        return totalPercentage;
    }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg noPadding">
        <div className="myPage">
            <div className="myPageWrapper scoreCardPage">
                <div className="secRow">
                    <div className="pointsSec">
                        <div className="totalPoints">
                            {!aceTestData.isLoading && showACEScoreSalutation() <= 60 && <h3>{Strings.practice_will_make_you_better} {accountData?.account?.first_name} !</h3>}
                            {!aceTestData.isLoading && showACEScoreSalutation() > 60 && <h3>{Strings.congratulations} {accountData?.account?.first_name} !</h3>}
                            <p>{Strings.youHaveScored}</p>
                            <div className="points">{aceTestData?.activeTestScore?.correctScore} <span>{Strings.acePoints}</span></div>
                        </div>
                        <div className="pointsDetail">
                            <div className="correctAns">
                                <div className="number">{aceTestData?.activeTestScore?.correctQuestions}</div>
                                <p>{Strings.correctAnswers}</p>
                            </div>
                            <div className="incorrectAns">
                                <div className="number">{aceTestData?.activeTestScore?.incorrectQuestions}</div>
                                <p>{Strings.inCorrectAnswers}</p>
                            </div>
                            <div className="unanswered">
                                <div className="number">{aceTestData?.activeTestScore?.unAnsweredQuestions}</div>
                                <p>{Strings.unAnswered}</p>
                            </div>
                        </div>
                        <div className="redeemRow">
                            <div className="text">
                                {Strings.super} <span>.... {Strings.youHaveAccumulated} {accountData?.points?.currentACEPoints} {Strings.acePointsGathered} !!</span>
                            </div>
                            <span className="redeemNow" onClick={() => goToLeaderBoard()}>{Strings.redeemNow}</span>
                        </div>
                    </div>
                    <div className="reportSec">
                        {aceTestData?.activeTestScore?.questions.length > 0 &&
                            <div className="questions">
                                {aceTestData?.activeTestScore?.questions.map((queItem, queIndex) => questionStatusView(queItem, queIndex))}
                            </div>
                        }
                        <div className="stage">
                            {aceTestData?.activeTestScore?.currentUserLevel} {aceTestData?.activeTestScore?.currentUserLevel ? 'Stage' : ''}
                        </div>
                        <div className="moreDetail">
                            <span className="reportLink">{Strings.forDetailedReport} {Strings.clickHere}</span>
                            <SmallButton clicked={() => sendScoreCard()} btnType="Orange" loading={isLoading}>{Strings.getDetailedReport}</SmallButton>
                        </div>
                        <div className="playBtn">
                            <span className="playMore" onClick={() => playMoreACEGame()}>{Strings.playMore}</span>
                        </div>
                    </div>
                </div>
          </div>
        </div>
      </div>
      {navigationData.showACEQuizResultModal ? <ACEGameResultSend onGoBack={() => onGoBackModal()} /> : null}
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
    </Fragment>
  );
}
