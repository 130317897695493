import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getPaymentDetailsFromInvoice
 * @param {string} tokenId
 * @param {string} userId
 * @param {string} invoiceId
 * @description Get Payment Details From Invoice Id
 */
export async function getPaymentDetailsFromInvoice(tokenId, userId, invoiceId) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'invoice_id': invoiceId
    };
    try {
        const response = await appClient.post(apiConfig.invoice.getInvoicePaymentDetails, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getPaymentHistory
 * @param {string} tokenId
 * @param {string} userId
 * @description Get My Payment History
 */
export async function getPaymentHistory(tokenId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId
    };
    try {
        const response = await appClient.post(apiConfig.invoice.getPaymentHistory, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * validateReferralCode
 * @param {*} referralCode
 * @param {*} examId
 * @param {*} packageId
 * @param {*} tokenId
 * @param {*} userId
 * @description Validate Referral Code
 */
export async function validateReferralCode(referralCode, examId, packageId, tokenId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'referral_code': referralCode,
        'package_id' : packageId,
        'exam_id' : examId
    };
    try {
        const response = await appClient.post(apiConfig.invoice.validateReferralCode, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}
