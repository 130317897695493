import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as NavigationActions from "redux/actions/NavigationActions";
import Checked from "assets/images/checked.svg";
import "./ReferralConfirmationSuccess.scss";

export default function ReferralConfirmationSuccess(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();

  /**
   * closePopUp
   * @description Close the PopUp
   */
  const closePopUp = () => {
    dispatch(NavigationActions.toggleReferralConfirmationSuccessModal(false));
  }

  return (<>
    <div
      className={`popupWrapper ReferralConfirmationSuccess ${
        !navigationData.showReferralConfirmationStatusModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => closePopUp()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <img className="successActionIcon" src={Checked} title={Strings.success} alt={Strings.success} />
            <p className="successMessageText">{Strings.redemption_applied_successfully}</p>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showReferralConfirmationStatusModal ? "closed" : "opened"}`}></div>
  </>
  );
}
