import React, { useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Strings } from 'resources';
import { log } from "utils";
import LibraryExamsData from "assets/data/LibraryExams";
import "./Library.scss";

export default function Library(props) {
  const accountData = useSelector((state) => state.accountData);
  const history = useHistory();
  const { category } = useParams();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  
  /**View All Exam Category */
  const viewExamCategory = (item, index) => {
    return (
      <li key={`cate_${index}`} className={`item ${category === item.key ? 'active' : ''}`}><span onClick={() =>changeCategory(item.key)}>{item.name}</span></li>
    );
  }

  /**View Category wise type */
  const viewCategoryTypes = (item, index) => {
    return (
      <div key={`cat_type_${index}`} className="card" onClick={() =>goToLibrary(item.key)}><span >{item.name}</span></div>
    );
  }
  
  const changeCategory = (cat) => {
    history.push('/library/'+ cat +'/');
  }

  const goToLibrary = (categoryType) => {
    history.push('/library/'+ category +'/'+ categoryType +'/');
  }

  const selectedCategoryTypes = LibraryExamsData.filter((e) => e.key === category);

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
          <div className="pageTitle">
            <h1>{Strings.library}</h1>
          </div>
          <div className="myPageWrapper myLibrary">
            <div className="ExamCategoryTabs">
              <ul className="NavTabs">
                {LibraryExamsData.length > 0 &&
                  <div className="row">
                      {LibraryExamsData.map((item, index) => viewExamCategory(item, index))}
                  </div>
                }
              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="cards">
                  {selectedCategoryTypes[0].exams.length > 0 &&
                    selectedCategoryTypes[0].exams.map((item, index) => viewCategoryTypes(item, index))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
