const apiEndPoints = {
    auth: {
        login: "/auth/userLogin",
        forgotPassword: "/auth/userForgotPassword",
        resetPassword: "/auth/updateUserPassword",
        googleAuthentication: "auth/googleAuthentication",
        facebookAuthentication: "auth/facebookAuthentication"
    },
    user: {
        create_user: "auth/registerUser",
        update_user_activation: "auth/updateUserActivation",
        generate_paytm_checksum: "payment/paymentProcessor",
        get_user_settings: "user/getUserDetails",
        save_user_settings: "user/updateUserDetails",
        uploadProfilePic: "user/uploadProfilePic",
        removeProfilePic: "user/removeUserImage",
        getStates: "user/getState",
        checkBillingDetails: "auth/checkBillingDetails",
        logoutUser: "auth/userLogout",
        sendWelcomeEmail: "user/sendWelcomeMail",
        getSiteStatistics: "user/sitestatistics",
        getEvents: "event/getEvent",
        changePassword: "auth/changePassword",
        siteSearch: "search/mysearch",
        checkACEGameValidity: "user/validateFreeTrialAccess",
        checkClaimOfferValidate: "acegame/isOfferAvailable",
        addClaimOfferPoint: "acegame/addClaimOfferPoints",
    },
    topic: {
        getMainCategories: "category/getCategory",
        getStudyMaterials: "studymaterials/getStudyMaterial",
        getTopicList: "studymaterials/getTopicList"
    },
    contact: {
        contactUs: "user/contactUs",
    },
    notifications: {
        getNotification: "notifications/getMyUnreadNotifications",
        updateNotification: "notifications/updateNotificationStatus",
    },
    file: {
        downloadFile: "file/getFile",
        downloadPDF: "file/downloadPDF"
    },
    library: {
        getLibraryFiles: "libraries/getLibraries"
    },
    course: {
        getAllCourses: "exam/getAllExam",
        getCurrentCourses: "libraries/getLibraries",
        getPastCourses: "libraries/getLibraries",
        getActiveCourse: "subscription/getActiveSubscription",
        getCourseGroups: "exam/getCourseGroups",
    },
    invoice: {
        getInvoicePaymentDetails: "payments/getInvoiceDetails",
        getPaymentHistory: "payments/getMyPaymentHistory",
        validateReferralCode: "user/validateReferralCode"
    },
    refer: {
        getInvitedFriends: "referral/getInvitedFriends",
        inviteFriend: "referral/inviteFriend",
        remindInvitedFriend: "referral/remindUser",
        applyRedemption: "redemption/submitRedemption",
        getRedemptionHistory: "redemption/getRedemptionList"
    },
    websocket: {
        user: "topic",
    },
    chat: {
        submitMessage: "ChatMessage",
        replyMessage: "ChatMessage",
        likeMessage: "Like",
        unLikeMessage: "Unlike",
        getMessages: "ChatMessage",
        imageMessage: "ChatMessage/Image",
        commentMessage: "ChatMessage/Comment/message",
        commentImage: "ChatMessage/CommentWithImage/message",
        updateUser: "User",
        reportChat: "ChatMessage/Report",
        reportComment: "Comment/Report",
        reportChatUser: "ChatMessage/Report",
        reportCommentUser: "Comment/Report"
    },
    subject: {
        getSubjectQuiz: "quiz/getQuizWiseContainers",
        getAllSubjectQuizzes: "quiz/getQuiz_Containers",
        getAllSubjectQuizQuestions: "quiz/getQuiz_QuestionOptions",
        submitSubjectQuizAnswers: "quiz/InsertQuiz_Answers",
        sendSubjectQuizReportCard: "quiz/quizAttemptedQuestionAnswer",
        getReportData: "quiz/SubjectGamescoreCardAPI"
    },
    ace: {
        startACEGame: "acegame/startAceGame",
        getACEGame: "acegame/getACEGame",
        getACEGameQuestion: "acegame/getACEGameQuestion",
        saveACEGameQuestion: "acegame/saveACEGameQuestion",
        endACEGame: "acegame/endACEGame",
        getACEGameStats: "acegame/getACEGameStats",
        getLeaderBoard: "acegame/leaderboard",
        submitACEPointsRedemption: "acepointhistory/submitAcePointRedemption",
        verifyACEPoints: "acegame/verifyACEPointsCertificate",
        getAccuracyChart: "acegame/getUserAccuracyChart",
        getDashboardCalculation: "acegame/getScoreCalculation",
        sendScoreCard: "acegame/scoreCard",
        validateFreeTrialAccess: "acegame/validateUserPackageStatus",
        gerReportData: "acegame/AceGamescoreCardAPI",
        aceGameHistory: "acegame/playedAceGameDetails",
        aceGamePlayStatus: "acegame/aceGamePlayStatus",
        getMyScholarshipCertificates: "exam/getMyScholarshipCertificates",
        getMyAcePointsHistory: "acepointhistory/getAcePointHistory"
    },
    notification: {
        webNotificationList: "pushnotifications/getWebNofitication",
    }
};

export default apiEndPoints;
