import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import appConfig from "config/config";
import { setItem, getItem, removeAll } from "utils/storage";
import apiConfig from "config/api";

const USER_KEY = "bharatcet_user";
const USER_COURSE_KEY = "bharatcet_user_course";

/*
 * Get user session
 *
 * returns Object {data, error, status}
 */
export async function getUserSession(token) {
  const headers = { token };

  try {
    const response = await appClient.get(apiConfig.user.get_user_session, {
      headers,
    });
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}


/**
 * get user details
 *
 * @returns user details
 */
export const getUserDetails = () => {
  const localStorageValue = localStorage.getItem("bharatcet_user");
  return localStorageValue ? JSON.parse(localStorageValue).user : "";
};

/*
 * Get user id
 *
 * returns Object
 */
export const getUserId = () => {
  const { status, user } = isUserLoggedIn();
  if (status && user && user.uid) return user.uid;
  else return logOutUser();
};

export const getUserToken = () => {
  const { status, user } = isUserLoggedIn();
  if (status && user && user?.token) return user?.token;
  else return logOutUser();
};

export const getUserCode = () => {
  const { status, user } = isUserLoggedIn();
  if (status && user && user.user_code) return user.user_code;
  else return logOutUser();
};

/*
 * Get username
 *
 * returns String
 */
export const getUserName = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id) {
    let name = `${user.firstName} ${user.lastName}`;
    name = name.trim();

    if (name === "") {
      let str = user.email ? user.email : "";
      let nameParts = str.split("@");
      name = nameParts.length === 2 ? nameParts[0] : "No Name";
    }

    return name;
  } else return logOutUser();
};

/*
 * Get user object
 *
 * returns Object
 */
export const getUserObject = () => {
  const { status, user } = isUserLoggedIn();

  if (status && user && user.id) {
    let name = `${user.firstName} ${user.lastName}`;
    name = name.trim();

    if (name === "") {
      let str = user.email ? user.email : "";
      let nameParts = str.split("@");
      name = nameParts.length === 2 ? nameParts[0] : "No Name";
    }

    return { id: user.id, display: name };
  } else return logOutUser();
};

/*
 * Is current logged in user an admin
 *
 * returns Boolean
 */
export const isAdmin = () => {
  let { status, role } = isUserLoggedIn();
  if (!status && role) return logOutUser();

  role = role.toLowerCase();

  if (role === "admin") return true;

  return false;
};

/*
 * Is current logged in user an Manager
 *
 * returns Boolean
 */
export const isManager = () => {
  let { status, role } = isUserLoggedIn();
  if (!status && role) return logOutUser();

  role = role.toLowerCase();

  if (role === "manager") return true;

  return false;
};

/*
 * Login user
 *
 * returns Object {error}
 */
export function loginUserInApp(user) {
  let { error } = setItem(USER_KEY, JSON.stringify(user));

  return { error };
}

/**
 * setUserLanguageInApp
 * @param {string} language
 * @description Set User Language Stored in App
 */
export function setUserLanguageInApp(language) {
  const USER_LANGUAGE_KEY = "bharatcet_user_language";
  let { error } = setItem(USER_LANGUAGE_KEY, JSON.stringify(language));
  return { error };
}

/**
 * getUserLanguageInApp
 * @description Get User Language Stored in App
 */
export function getUserLanguageInApp() {
  const USER_LANGUAGE_KEY = "bharatcet_user_language";
  try {
    const value = localStorage.getItem(USER_LANGUAGE_KEY);
    const userLanguage = value ? JSON.parse(value) : "en";
    return userLanguage;
  } catch (error) {
    return { status: false };
  }
}

/**
 * setUserCourseInApp
 * @param {string} courseId
 * @description Set User Course in App
 */
export function setUserCourseInApp(courseId) {
  let { error } = setItem(USER_COURSE_KEY, JSON.stringify(courseId));
  return { error };
}

/**
 * getUserCourseInApp
 * @description Get User Course Stored From App Storage
 */
export function getUserCourseInApp() {
  let { data, error } = getItem(USER_COURSE_KEY);
  if (error) {
    return { status: false };
  }
  const userCourse = data ? JSON.parse(data) : null;
  return userCourse;
}

/*
 * logout user
 *
 * returns boolean
 */
export async function logOutUser() {
  const { user } = isUserLoggedIn();
  removeAll();
  await logoutExistingUser(user?.uid, user?.token);
  // window.location.reload();
  return true;
}

export async function logoutExistingUser(userId, userToken) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
    };
    const response = await appClient.post(apiConfig.user.logoutUser, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function logOutUserFromApp() {
  const { user } = isUserLoggedIn();
  removeAll();
  await logoutExistingUser(user?.uid, user?.token);
  return true;
}

/*
 * Is user logged in
 *
 * returns Object {status, token, user, role, company}
 */
export function isUserLoggedIn() {
  let { data, error } = getItem(USER_KEY);

  if (error) {
    return { status: false };
  }

  return parseUserData(data);
}

/*
 * Parse user data
 * data Object
 *
 * returns Object {status, token, user, role, company}
 */
function parseUserData(data) {
  try {
    const { token, user, role, company } = JSON.parse(data);
    return { status: true, token, user, role, company };
  } catch (error) {
    return { status: false };
  }
}

/*
 * User Login
 * payload Object {email, password}
 *
 * returns Object {data, error, status}
 */
export async function userLogin(payload) {
  try {
    const response = await appClient.post(apiConfig.auth.login, payload);
    const pass = JSON.parse(response.config.data);
    localStorage.setItem(
      "upass",
      Buffer.from(payload.password).toString("base64")
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function userForgotPassword(payload) {
  try {
    const response = await appClient.post(
      apiConfig.auth.forgotPassword,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function userResetPassword(payload) {
  try {
    const response = await appClient.post(
      apiConfig.auth.resetPassword,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

let dataLayer = window.dataLayer || []; 

function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "AW-321171010"); 

// < Event snippet for Sign-up conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
 function  gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      // window.location = url;
      console.log(url);
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-321171010/798JCOmas_8CEMLckpkB",
    event_callback: callback,
  });
  return false;
}
export async function createUser(payload) {
  let data = gtag_report_conversion(`${appConfig.redirectUrl}`);
  console.log(payload,data );

  try {
    // console.log(payload);
    const response = await appClient.post(apiConfig.user.create_user, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function activateUser(payload) {
  try {
    const response = await appClient.post(
      apiConfig.user.update_user_activation,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function generatePayTMChecksum(payload) {
  try {
    const response = await appClient.post(
      apiConfig.user.generate_paytm_checksum,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update User Info
 *
 * returns Object {data, error, status}
 */
export async function updateUser(payload) {
  try {
    const response = await appClient.put(apiConfig.user.create_user, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Invite all given team members email
 *
 * returns Object {data, error, status}
 */
export async function inviteEmail(payload) {
  try {
    const response = await appClient.post(apiConfig.user.invite_mail, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update invite Email status
 *
 * returns Object {data, error, status}
 */
export async function updateInviteEmailStatus(userId, inviteStatus) {
  try {
    const response = await appClient.put(
      apiConfig.user.update_invite_status + userId + "/" + inviteStatus
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Verify company
 *
 * returns Object {data, error, status}
 */
export async function verifyCompany(companyName) {
  try {
    const response = await appClient.get(
      apiConfig.user.verify_company + companyName
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get all users with task count
 *
 * returns Object {data, error, status}
 */
export async function getAllUsersWithCount() {
  try {
    const response = await appClient.get(
      `${apiConfig.user.get_users_with_count}/all`
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get logged in user counters
 *
 * returns Object {data, error, status}
 */
export async function getUserCounters() {
  let url = `${apiConfig.user.get_users_with_count}/${getUserId()}`;
  try {
    const response = await appClient.get(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Switch Company logged in user counters
 *
 * returns Object {data, error, status}
 */
export async function switchCompany(companyId) {
  let url = `${apiConfig.user.switch_user_company}/${companyId}`;
  try {
    const response = await appClient.put(url);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * Save user Account Settings
 * payload object
 *
 * returns Object {data, error, status}
 */
export async function saveUserAccountSettings(payload) {
  try {
    const response = await appClient.post(
      apiConfig.user.save_user_settings,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Get list of Invited Users details
 *
 * returns Object {data, error, status}
 */
export async function getInvitedUsers(userId) {
  try {
    const response = await appClient.get(
      apiConfig.user.get_invited_members + userId
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/*
 * Update User Integration ID
 *
 * returns Object {data, error, status}
 */
export async function updateUserIntegrationId(id) {
  const payload = { integrationId: id };
  try {
    const response = await appClient.put(
      apiConfig.user.update_integration_id,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getUserAccountSettings(payload) {
  try {
    const response = await appClient.post(
      apiConfig.user.get_user_settings,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * uploadProfileImage
 * @param {file} selectedFile
 * @param {string} userId
 * @param {string} token
 * @description Upload User Profile Image
 */
export async function uploadProfileImage(selectedFile, userId, token) {
  try {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    formData.append("user_id", userId);
    formData.append("token", token);
    const response = await appClient.post(
      apiConfig.user.uploadProfilePic,
      formData
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * submitContactRequest
 * @param {object} payload
 * @description Submit Contact Request to Administrator
 */
export async function submitContactRequest(payload) {
  try {
    const response = await appClient.post(apiConfig.contact.contactUs, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * getStates
 * @param {object} payload
 * @description Get States
 */
export async function getStates() {
  try {
    const response = await appClient.get(apiConfig.user.getStates);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * checkBillingDetails
 * @param {string} userId
 * @param {string} userToken
 * @description Check User's Billing Details
 */
export async function checkBillingDetails(userId, userToken) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
    };
    const response = await appClient.post(
      apiConfig.user.checkBillingDetails,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * sendWelcomeEmail
 * @param {*} userEmail
 * @description Send Welcome Emails
 */
export async function sendWelcomeEmail(userEmail) {
  try {
    const payload = {
      email: userEmail,
    };
    const response = await appClient.post(
      apiConfig.user.sendWelcomeEmail,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * getLandingStatistics
 * @description Get Landing Page Statistics
 */
export async function getLandingStatistics() {
  try {
    const payload = {};
    const response = await appClient.post(
      apiConfig.user.getSiteStatistics,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * getLandingEvents
 * @param {*} month
 * @param {*} year
 * @description get Landing Page Events
 */
export async function getLandingEvents(month, year) {
  try {
    const payload = {
      type: "",
      month: month,
      year: year,
    };
    const response = await appClient.post(apiConfig.user.getEvents, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * getUserAccuracyChart
 * @param {*} UserId
 * @param {*} userToken
 * @description Get User Accuracy Chart
 */
export async function getUserAccuracyChart(UserId, userToken) {
  try {
    const payload = {
      'user_id' : UserId,
      'token': userToken
    };
    const response = await appClient.post(
      apiConfig.ace.getAccuracyChart,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * getUserDashboardCalculation
 * @param {*} userId
 * @param {*} userToken
 * @param {*} examId
 * @description Get User Dashboard Calculations
 */
export async function getUserDashboardCalculation(
  userId,
  userToken,
  examId = "1"
) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
      exam_id: examId,
    };
    const response = await appClient.post(
      apiConfig.ace.getDashboardCalculation,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * changeUserPassword
 * @param {*} userId
 * @param {*} userToken
 * @param {*} password
 * @param {*} confirmPassword
 * @description Change User Password
 */
export async function changeUserPassword(
  userId,
  userToken,
  password,
  confirmPassword
) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
      password: password,
      confirm_password: confirmPassword,
    };
    const response = await appClient.post(
      apiConfig.user.changePassword,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * searchSite
 * @param {*} userId
 * @param {*} userToken
 * @param {*} query
 * @param {*} language
 * @description Search Site Content
 */
export async function searchSite(userId, userToken, query, language) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
      query: query,
      lang: language,
    };
    const response = await appClient.post(apiConfig.user.siteSearch, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * validateFreeTrialAccess
 * @param {*} userId
 * @param {*} userToken
 * @param {*} examId
 * @description Validate Free Trial Access
 */
export async function validateFreeTrialAccess(userId, userToken, examId = "1") {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
      exam_id: examId,
    };
    const response = await appClient.post(
      apiConfig.user.checkACEGameValidity,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

/**
 * validateUserGroupData
 * @param {*} userId
 * @param {*} userToken
 * @param {*} examId
 * @description Validate User Group Data
 */
export async function validateUserGroupData(userId, userToken, examId = "1") {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
      exam_id: examId,
    };
    const response = await appClient.post(
      apiConfig.ace.validateFreeTrialAccess,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function removeProfilePic(userId, userToken) {
  try {
    const payload = {
      user_id: userId,
      token: userToken,
    };
    const response = await appClient.post(
      apiConfig.user.removeProfilePic,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function doGoogleAuthentication(
  email,
  firstName,
  lastName,
  googleId,
  userImage,
  userFullName
) {
  try {
    const payload = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      google_id: googleId,
      image: userImage,
      full_name: userFullName,
    };
    const response = await appClient.post(
      apiConfig.auth.googleAuthentication,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function doFacebookAuthentication(email, name, facebookId, userFullName) {
  try {
    const payload = {
      'email' : email,
      'name': name,
      'facebook_id': facebookId,
      'full_name': userFullName
    };
    const response = await appClient.post(apiConfig.auth.facebookAuthentication, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function checkClaimOfferAvailable(userId, userToken) {
  try {
    const payload = {
      'user_id': userId,
      'token': userToken
    }
    const response = await appClient.post(apiConfig.user.checkClaimOfferValidate, payload);
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function addClaimOfferPoints(payload) {
  try {
    const response = await appClient.post(
      apiConfig.user.addClaimOfferPoint,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}

export async function getNotification(payload) {
  try {
    const response = await appClient.post(
      apiConfig.notification.webNotificationList,
      payload
    );
    return hasSuccess(response.data);
  } catch (error) {
    return hasError(error);
  }
}
