import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Strings } from "resources";
import * as NavigationActions from "redux/actions/NavigationActions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrivacyPolicyData from '../../assets/data/PrivacyPolicyPopUp';
import "./PrivacyPolicyModal.scss";

export default function PrivacyPolicyModal(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();

  /**
   * hideModal
   * @description Hide Privacy Policy Modal
   */
  const hideModal = () => {
    dispatch(NavigationActions.togglePrivacyPolicyModal(false));
  };

  /**
   * goToBack
   * @description Go To Back
   */
  const goToBack = () => {
    hideModal();
  }

  /**
   * policyDescription
   * @description Get Privacy Policy HTML Content
   */
  const policyDescription = () => {
    return PrivacyPolicyData;
  }

  return (<>
    <div
      className={`popupWrapper policyModal dispersionLogo ${
        !navigationData.showPrivacyPolicyModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{Strings.copyRightAndPrivacyNorms}</h3>

            <div className="modalContent" dangerouslySetInnerHTML={{__html : policyDescription()}}></div>

            <div className="buttonContainer">
                <div className="btn-container">
                    <span className="Button Orange" onClick={() => goToBack()}>{Strings.iAgree}</span>
                </div>
            </div>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showPrivacyPolicyModal ? "closed" : "opened"}`}></div>
  </>
  );
}
