import * as actions from "redux/action-types";
// import { Strings } from "resources";
import { CancelToken } from "axios";
import { parseChat } from "utils/chat";

const initialState = {
  items: [],
  hasMore: true,
  page: 0,
  limit: 10,
  isLoading: false,
  cancelRequest: CancelToken.source(),
  autoFocus: null,
  websocketStatus: "INIT",
  bannedUsers: []
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BANNED_USERS:
      return {
        ...state,
        bannedUsers: action.bannedUsers,
      };

    case actions.SET_CHAT_WEBSOCKET_STATUS:
      return {
        ...state,
        websocketStatus: action.status,
      };

      case actions.ADD_CHAT_DATA:
        return {
          ...state,
          items: action.items,
          hasMore: action.hasMore,
          page: action.page,
        };

      case actions.LOADING_CHAT_DATA:
        return {
          ...state,
          isLoading: action.isLoading,
        };

      case actions.SET_CHAT_CANCEL_TOKEN:
        return {
          ...state,
          cancelRequest: action.token,
        };

      case actions.SET_CHAT_HAS_NO_MORE:
        return {
          ...state,
          hasMore: false,
        };

      case actions.ADD_CHAT_MESSAGE:
        let newItems = [...state.items];
        let myBannedUsers = [...state.bannedUsers];
        const newMessage = parseChat(action.message, myBannedUsers);
        newItems.push(newMessage);
        return {
          ...state,
          items: newItems,
        };

      case actions.UPDATE_CHAT_MESSAGE:
        let existingChats = [...state.items];
        let chatIndex = -1;
        if (existingChats.length > 0) {
          chatIndex = existingChats.findIndex((item) => item['id'] === action.messageId);
        }
        if (chatIndex < 0) {
          return {
            ...state
          };
        } else {
          const updatedExistingChats = [
            ...existingChats.slice(0,chatIndex),
            {
                ...existingChats[chatIndex],
                comments: action?.message?.comments
            },
            ...existingChats.slice(chatIndex+1)
          ];
          return {
            ...state,
            items: updatedExistingChats
          };
        }

        case actions.UPDATE_CHAT_LIKE_COUNT:
          let lChats = [...state.items];
          let lIndex = -1;
          if (lChats.length > 0) {
            lIndex = lChats.findIndex((item) => item['id'] === action.messageId);
          }
          if (lIndex < 0) {
            return {
              ...state
            };
          } else {
            const updatedExistingChats = [
              ...lChats.slice(0,lIndex),
              {
                  ...lChats[lIndex],
                  likedBy: action.likedBy,
                  totalLikedBy: action.totalLikedBy,
                  liked: action.liked
              },
              ...lChats.slice(lIndex+1)
            ];
            return {
              ...state,
              items: updatedExistingChats
            };
          }

      case actions.UPDATE_CHAT_COMMENT:
        let currentChats = [...state.items];
        let index = -1;
        if (currentChats.length > 0) {
            index = currentChats.findIndex((item) => item['id'] === action.messageId);
        }
        if (index < 0) {
          return {
            ...state
          };
        } else {
          const updatedChats = [
            ...currentChats.slice(0,index),
            {
                ...currentChats[index],
                inputComment: action.message,
                inputCommentType: action.messageType,
            },
            ...currentChats.slice(index+1)
          ];
          return {
            ...state,
            items: updatedChats
          };
        }

      case actions.HIDE_CHAT_MESSAGE:
        let newChats = [...state.items];
        let newIndex = -1;
        if (newChats.length > 0) {
          newIndex = newChats.findIndex((item) => item['id'] === action.messageId);
        }
        if (newIndex < 0) {
          return {
            ...state
          };
        } else {
          const updatedChats = [
            ...newChats.slice(0,newIndex),
            {
              ...newChats[newIndex],
              hidden: true
            },
            ...newChats.slice(newIndex+1)
          ];
          return {
            ...state,
            items: updatedChats
          };
        }
        
      case actions.HIDE_CHAT_COMMENT:
        let newCommentChats = [...state.items];
        let newChatIndex = -1;
        if (newCommentChats.length > 0) {
          newChatIndex = newCommentChats.findIndex((item) => item['id'] === action.messageId);
        }
        if (newChatIndex < 0) {
          return {
            ...state
          };
        } else {
          let newChatComments = [...newCommentChats[newChatIndex].comments];
          let newChatCommentIndex = -1;
          if (newChatComments.length > 0) {
            newChatCommentIndex = newChatComments.findIndex((item) => item['id'] === action.commentId);
          }
          if (newChatCommentIndex < 0) {
            return {
              ...state
            };
          } else {
            const updatedChatComments = [
              ...newChatComments.slice(0,newChatCommentIndex),
              {
                ...newChatComments[newChatCommentIndex],
                hidden: true
              },
              ...newChatComments.slice(newChatCommentIndex+1)
            ];

            const updatedChats = [
              ...newCommentChats.slice(0,newChatIndex),
              {
                ...newCommentChats[newChatIndex],
                comments: updatedChatComments
              },
              ...newCommentChats.slice(newChatIndex+1)
            ];

            return {
              ...state,
              items: updatedChats
            };
          }
        }

      default:
        return state;
  }
}
