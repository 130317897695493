import * as actions from "redux/action-types";
import { CancelToken } from "axios";

const initialState = {
    items: [],
    amount: 0,
    hasMore: true,
    page: 0,
    limit: 10,
    course: null,
    isLoading: false,
    cancelRequest: CancelToken.source()
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_REFERRAL_DATA:
      return {
        ...state,
        items: action.items,
        hasMore: action.hasMore,
        page: action.page,
      };
    case actions.LOADING_REFERRAL_DATA:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_REFERRAL_CANCEL_TOKEN:
      return {
        ...state,
        cancelRequest: action.token,
      };
    case actions.SET_REFERRAL_HAS_NO_MORE:
      return {
        ...state,
        hasMore: false,
      };
    case actions.SET_REFERRAL_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    case actions.CLEAR_REFERRAL_AMOUNT:
      return {
        ...state,
        amount: 0,
      };
    default:
      return state;
  }
}
