import * as actions from "redux/action-types";
import {
    getAllSubjectQuizzes as getAllSubjectQuizzesFromAPI,
    getAllSubjectQuizQuestions as getAllSubjectQuizQuestionsFromAPI,
    getSubjectQuizDetails as getSubjectQuizDetailsFromAPI
} from "services/SubjectTestService";
import { Strings } from "resources";
import { CancelToken } from "axios";
import { parseSubjectTests, parseSubjectTestQuestions, getDateInYmdHisFormat } from "utils/chat";
import { log } from "utils";
import { getUserId, getUserToken } from "services/AuthService";

/**
 * setSubjectQuizLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setSubjectQuizLoadingState(isLoading) {
    return {
        type: actions.LOADING_SUBJECT_TEST,
        isLoading,
    };
}

/**
 * addSubjectTestData
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Subject Test into State
 */
export function addSubjectTestData(tests) {
    return {
        type: actions.ADD_SUBJECT_TEST,
        tests: tests
    };
}

/**
 * setSubjectTestCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setSubjectTestCancelToken(token) {
    return {
        type: actions.SET_SUBJECT_TEST_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreSubjectTest
 * @description check If more chat messages are there or not
 */
export function hasNoMoreSubjectTest() {
    return {
        type: actions.SET_SUBJECT_TEST_HAS_NO_MORE,
    };
}

/**
 * getMySubjectTests
 * @param {boolean} reset
 * @description Get My Subject Tests
 */
export function getMySubjectTests(categoryId, topicId) {
    return async (dispatch, getState) => {

        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setSubjectTestCancelToken(newRequestSource));
        await dispatch(addSubjectTestData([]));
        await dispatch(setSubjectQuizLoadingState(true));
        const { data, error } = await getAllSubjectQuizzesFromAPI(userToken, userId, categoryId, topicId);
        if (!error && Array.isArray(data.data) && data.data.length) {
            dispatch(addSubjectTestData(parseSubjectTests(data.data)));
        } else {
            dispatch(hasNoMoreSubjectTest());
        }
        dispatch(setSubjectQuizLoadingState(false));
    };
}

export function setActiveSubjectTest(test) {
    return {
        type: actions.SET_ACTIVE_SUBJECT_TEST,
        activeTest: test
    };
}

export function setActiveSubjectTestQuestions(questions) {
    return {
        type: actions.SET_ACTIVE_SUBJECT_TEST_QUESTIONS,
        activeTestQuestions: questions,
        activeTestStartTime: getDateInYmdHisFormat()
    };
}

export function setActiveSubjectTestDetails(data) {
    return {
        type: actions.SET_ACTIVE_SUBJECT_TEST,
        activeTest: data
    };
}

export function clearActiveSubjectTest() {
    return {
        type: actions.CLEAR_ACTIVE_SUBJECT_TEST,
    };
}

export function toggleSubjectTestQuestionOption(questionId, optionId) {
    return {
        type: actions.TOGGLE_ACTIVE_SUBJECT_TEST_QUESTION_OPTION,
        questionId: questionId,
        optionId: optionId
    };
}

/**
 * getMySubjectTestQuestions
 * @param {number} quizId
 * @description Get My Subject Test Questions
 */
export function getMySubjectTestQuestions(quizId) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setSubjectTestCancelToken(newRequestSource));
        await dispatch(setSubjectQuizLoadingState(true));
        const { data, error } = await getAllSubjectQuizQuestionsFromAPI(userToken, userId, quizId);
        if (!error && Array.isArray(data.data) && data.data.length) {
            dispatch(setActiveSubjectTestQuestions(parseSubjectTestQuestions(data.data)));
        }
        await dispatch(setSubjectQuizLoadingState(false));
    };
}

/**
 * getMySubjectQuizDetails
 * @param {number} quizId
 * @description Get My Subject Quiz Details
 */
export function getMySubjectQuizDetails(quizId) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setSubjectTestCancelToken(newRequestSource));
        await dispatch(clearActiveSubjectTest());
        await dispatch(setSubjectQuizLoadingState(true));
        const { data, error } = await getSubjectQuizDetailsFromAPI(userToken, userId, quizId);
        if (!error && Array.isArray(data.data) && data.data.length) {
            const subjectTestData = parseSubjectTests(data.data);
            if (subjectTestData.length > 0) {
                dispatch(setActiveSubjectTestDetails(subjectTestData[0]));
                dispatch(getMySubjectTestQuestions(quizId));
            }
        }
        dispatch(hasNoMoreSubjectTest());
        dispatch(setSubjectQuizLoadingState(false));
    };
}

export function setSubjectGameReport(data) {
    return {
        type: actions.SET_SUBJECT_GAME_REPORT,
        report: data
    };
}