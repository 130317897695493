import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Strings } from "resources";
import * as NavigationActions from "redux/actions/NavigationActions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ACEGameCertificate.scss";

export default function ACEGameCertificate(props) {
  const history = useHistory();
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();

  /**
   * hideModal
   * @description Hide Verification Modal
   */
  const hideModal = () => {
    dispatch(NavigationActions.toggleACEPointsCertificateModal(false));
  };

  /**
   * goToCertificate
   * @description Go To ACE Game Certificate Screen
   */
  const goToCertificate = () => {
    hideModal();
    history.push('/ace-certificate/');
  }

  return (<>
    <div
      className={`popupWrapper ACEGameCertificate dispersionLogo ${
        !navigationData.showACEPointsCertificateModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-info">{Strings.congratulationsTitle}</h3>
            <p>{Strings.certificateDescription}</p>
            <p>{Strings.your} <span className="blueTitle" onClick={() => goToCertificate()}>{Strings.certificateWord}</span> {Strings.isReady}.</p>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showACEPointsCertificateModal ? "closed" : "opened"}`}></div>
  </>
  );
}
