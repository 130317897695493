import React, { useEffect, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserToken } from "services/AuthService";
import { getCourseGroups as getCourseGroupsAPI } from "services/CourseService";
import { Strings } from 'resources';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { log } from "utils";
import "./SubscriptionPackage.scss";

export default function SubscriptionPackage(props) {
  const [courseGroup, setCourseGroup] = useState([]);
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const history = useHistory();
  const userToken = getUserToken();
  const selectedCourseId = props?.location?.state?.examId ?? '1';

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get Courses Details */
  useEffect(() => {
    dispatch(CourseActions.getCoursesData(true, userToken));
  }, [dispatch, userToken]);

  /* Get Course Group Details */
  useEffect(() => {
    async function getCourseGroupDetails(){
      const { data } = await getCourseGroupsAPI(userToken, selectedCourseId);
      if (data.status) {
        setCourseGroup(data.data);
      }
    }
    getCourseGroupDetails();
  }, [selectedCourseId, userToken]);

  /**
   * goToPaymentPage
   * @param {string} groupId
   * @description Go To Payment Page
   */
  const goToPaymentPage = (groupId) => {
    history.push({
      pathname: '/payment/',
      state: { examId: selectedCourseId, groupId: groupId }
    });
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.choose_your_subscription_plan}</h1>
            </div>
            <div className="myPageWrapper myPackagesDesktopView rightPadding">
              <div className="row packageHeader">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                  <span className="PackageDesc">&nbsp;</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center">
                  <div className="PackageDetails">
                    <p className="prePackagePrice">&#8377;{Strings.preMonthlyCharge}<sup>+{Strings.gstTax}</sup></p>
                    <p className="packageMonth">{Strings.packageMonthly}</p>
                    <div className="packagePrice">
                        &#8377; {Strings.priceMonthly}*<span>+{Strings.gstTax}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center" >
                  <div className="PackageDetails">
                    <p className="prePackagePrice">&#8377;{Strings.preHalfYearlyplanCharges}<sup>+{Strings.gstTax}</sup></p>
                    <p className="packageMonth">{Strings.packageHalfYearly}</p>
                    <div className="packagePrice">
                      &#8377; {Strings.priceHalfYearly}*<span>+{Strings.gstTax}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row packageInfo noPadding">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                  <p className="PackageFeature">&nbsp;</p>
                  <span className="PackageDesc">&nbsp;</span>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 subscriptionTitleContainer leftShadow">
                  <span className="subscribeNowTitle" onClick={() => goToPaymentPage('2')} >{Strings.subscribeNow}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 subscriptionTitleContainer rightShadow">
                  <span className="subscribeNowTitle" onClick={() => goToPaymentPage('3')} >{Strings.subscribeNow}</span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.dashboard}</p>
                  <span className="PackageDesc">{Strings.packageDescription1}</span>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.aceGame}</p>
                  <span className="PackageDesc">{Strings.packageDescription2}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.quillItTitle}</p>
                  <span className="PackageDesc">{Strings.packageDescription3}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.keyIndicators}</p>
                  <span className="PackageDesc">{Strings.packageDescription4}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.accessToVideos}</p>
                  <span className="PackageDesc">{Strings.packageDescription5}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.languageSelection}</p>
                  <span className="PackageDesc">{Strings.packageDescription6}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                    <p className="PackageFeature">{Strings.scholarship}</p>
                    <span className="PackageDesc">{Strings.packageDescription7}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew leftShadow">
                  <p className="PackageFeature">{Strings.knowledge_maturity_stages}</p>
                  <span className="PackageDesc">{Strings.packageDescription8}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.detailedReport}</p>
                  <span className="PackageDesc">{Strings.packageDescription9}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.library}</p>
                  <span className="PackageDesc">{Strings.packageDescription10}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="row packageInfo oddCls">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left packageFeatureNew">
                  <p className="PackageFeature">{Strings.accuracyWorm}</p>
                  <span className="PackageDesc">{Strings.packageDescription11}</span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow leftShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 featureAllowNotAllow rightShadow">
                  <span className="PackageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

            </div>

            <div className="myPageWrapper myPackagesMobileView">
              <div className="row packageHeader">
                <div className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 text-center">
                  <div className="PackageButton">
                    <p className="prePackagePrice">&#8377;{Strings.preMonthlyCharge}<sup>+{Strings.gstTax}</sup></p>
                    <p className="packageMonth">{Strings.packageMonthly}</p>
                    <div className="packagePrice">
                        &#8377; {Strings.priceMonthly}*<span>+{Strings.gstTax}</span>
                    </div>
                  </div>
                  <div className="packageInfoMobile">
                    <div className="subscriptionTitleContainer">
                      <span className="subscribeNowTitle" onClick={() => goToPaymentPage('2')} >{Strings.subscribeNow}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 text-center" >
                  <div className="PackageButton">
                    <p className="prePackagePrice">&#8377;{Strings.preHalfYearlyplanCharges}<sup>+{Strings.gstTax}</sup></p>
                    <p className="packageMonth">{Strings.packageHalfYearly}</p>
                    <div className="packagePrice">
                      &#8377; {Strings.priceHalfYearly}*<span>+{Strings.gstTax}</span>
                    </div>
                  </div>
                  <div className="packageInfoMobile">
                    <div className="subscriptionTitleContainer">
                      <span className="subscribeNowTitle" onClick={() => goToPaymentPage('2')} >{Strings.subscribeNow}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.dashboard}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription1}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.aceGame}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription2}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.quillItTitle}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription3}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.keyIndicators}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription4}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.accessToVideos}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription5}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.languageSelection}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription6}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.scholarship}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription7}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.knowledge_maturity_stages}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription8}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.detailedReport}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription9}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.library}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription10}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

              <div className="packageInfoItem">
                <div className="packageFeatureWrapper">
                  <span className="packageFeature">{Strings.accuracyWorm}</span>
                </div>
                <div className="packageDescWrapper">
                  <span className="packageDesc">{Strings.packageDescription11}</span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.monthly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faTimes} size="lg" className="Times" /></span>
                </div>
                <div className="packageNameDesc">
                  <span className="packageName">{Strings.halfYearly}</span>
                  <span className="packageStatus"><FontAwesomeIcon icon={faCheck} size="lg" className="Check" /></span>
                </div>
              </div>

            </div>
        </div>
      </div>
    </Fragment>
  );
}
