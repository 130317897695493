import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const style = { name: "button", extension: "scss" };

const button = (props) => {
  const { btnType, clicked, disabled, loading } = props;
  require(`./${style.name}.${style.extension}`);

  const renderLoadingIcon = () => {
    if (loading) {
      return (
        <span className="loading-con">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      );
    }

    return null;
  };

  return (
    <div className="btn-container">
    <button
      disabled={disabled || loading}
      className={["Button", btnType].join(" ")}
      onClick={clicked}
    >
      {props.children} {renderLoadingIcon()}
    </button>
    </div>
  );
};

export default button;
