import React, { useEffect }  from "react";
import { useSelector } from "react-redux";
import { log } from "utils";
import "./SmallButton.scss";

const SmallButton = (props) => {
  const { btnType, clicked, disabled, loading, btnHeight } = props;
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]); 
  /* Render Loading Icon */
  const renderLoadingIcon = () => {
    if (loading) {
      return (
            <div className="ButtonRingLoader"><div></div><div></div><div></div><div></div></div>
      );
    }
    return null;
  };

  return (
    <button
      disabled={disabled || loading}
      className={["SmallButton", btnType, btnHeight].join(" ")}
      onClick={clicked}
    >
      {props.children} {renderLoadingIcon()}
    </button>
  );
};

export default SmallButton;
