import * as actions from "redux/action-types";

/*
 * Set current route
 *
 * name String
 *
 * return object
 */
export function setCurrentRoute(name) {
  return {
    type: actions.SET_CURRENT_ROUTE,
    name,
  };
}

/*
 * Set create new project modal status
 *
 * status Boolean
 *
 * return object
 */
export function setCreateNewProjectModalStatus(status) {
  return {
    type: actions.SET_CREATE_NEW_PROJECT_MODAL_STATUS,
    status,
  };
}


/*
 * Toggle left sidebar
 *
 * status Boolean
 *
 * return object
 */
export function toggleLeftSidebar(status) {
  return {
    type: actions.TOGGLE_LEFT_SIDEBAR,
    status
  };
}


/*
 * Set edit project modal status
 *
 * status Boolean
 *
 * return object
 */
export function setEditProjectModalStatus(status) {
  return {
    type: actions.SET_EDIT_PROJECT_MODAL_STATUS,
    status,
  };
}


/*
 * Set GO TO url
 * data Array
 *
 * returns Object
 */
export function setGoToUrl(url) {
  return {
    type: actions.SET_GO_TO_URL,
    url,
  };
}

export function setForgotPasswordModalStatus(status) {
  return {
    type: actions.SET_FORGOT_PASSWORD_MODAL_STATUS,
    status,
  };
}

export function setLoginModalStatus(status) {
  return {
    type: actions.SET_LOGIN_MODAL_STATUS,
    status,
  };
}

export function setResetPasswordModalStatus(status) {
  return {
    type: actions.SET_RESET_PASSWORD_MODAL_STATUS,
    status,
  };
}

export function setSignUpModalStatus(status) {
  return {
    type: actions.SET_SIGNUP_MODAL_STATUS,
    status,
  };
}

export function setActivateUserModalStatus(status) {
  return {
    type: actions.SET_USER_ACTIVATE_MODAL_STATUS,
    status,
  };
}

export function closeAuthModalStatus() {
  return {
    type: actions.CLOSE_AUTH_MODAL_STATUS,
  };
}

export function setReferralConfirmationModal() {
  return {
    type: actions.SET_REFERRAL_CONFIRMATION_STATUS,
  };
}

export function closeReferralConfirmationModal() {
  return {
    type: actions.CLOSE_REFERRAL_CONFIRMATION_STATUS,
  };
}

export function setBillingVerificationModal() {
  return {
    type: actions.SET_BILLING_VERIFICATION_STATUS,
  };
}

export function closeBillingVerificationModal() {
  return {
    type: actions.CLOSE_BILLING_VERIFICATION_STATUS,
  };
}

export function setSubjectQuizVerifyModal() {
  return {
    type: actions.SET_SUBJECT_QUIZ_VERIFY_STATUS,
  };
}

export function closeSubjectQuizVerifyModal() {
  return {
    type: actions.CLOSE_SUBJECT_QUIZ_VERIFY_STATUS,
  };
}

export function togglePrivacyPolicyModal(status) {
  return {
    type: actions.SET_PRIVACY_POLICY_MODAL_STATUS,
    status
  };
}

export function toggleTermsConditionsModal(status) {
  return {
    type: actions.SET_TERMS_CONDITIONS_MODAL_STATUS,
    status
  };
}

export function toggleSubjectQuizResultModal(status) {
  return {
    type: actions.SET_SUBJECT_QUIZ_RESULT_STATUS,
    status
  };
}

export function toggleACEGameEndModal(status) {
  return {
    type: actions.SET_ACE_GAME_END_CONFIRMATION_STATUS,
    status
  };
}

export function toggleACEGameRedeemConfirmationModal(status) {
  return {
    type: actions.SET_ACE_POINTS_REDEEM_CONFIRMATION_STATUS,
    status
  };
}

export function toggleACEGameRedeemModal(status) {
  return {
    type: actions.SET_ACE_POINTS_REDEEM_STATUS,
    status
  };
}

export function toggleACEPointsCertificateModal(status) {
  return {
    type: actions.SET_ACE_POINTS_CERTIFICATE_STATUS,
    status
  };
}

export function toggleFeatureNotAvailableModal(status) {
  return {
    type: actions.SET_FEATURE_NOT_AVAILABLE_STATUS,
    status
  };
}

export function toggleFreeTrialModal(status) {
  return {
    type: actions.SET_FREE_TRIAL_STATUS,
    status
  };
}

export function toggleFreeTrialExpiredModal(status) {
  return {
    type: actions.SET_FREE_TRIAL_EXPIRED_STATUS,
    status
  };
}

export function toggleSubscriptionPlanExpiredStatusModel(status) {
  return {
    type: actions.SET_SUBSCRIPTION_PLAN_EXPIRED_STATUS,
    status
  };
}

export function togglePlayAceGameModal(status) {
  return {
    type: actions.SET_PLAY_ACE_GAME_STATUS,
    status
  };
}

export function toggleACEQuizResultModal(status) {
  return {
    type: actions.SET_ACE_QUIZ_RESULT_STATUS,
    status
  };
}

export function toggleACEQuizTimeUpModal(status) {
  return {
    type: actions.SET_ACE_QUIZ_TIME_UP_STATUS,
    status
  };
}

export function toggleReferralConfirmationSuccessModal(status) {
  return {
    type: actions.SET_REFERRAL_CONFIRMATION_SUCCESS_STATUS,
    status
  };
}

export function toggleVideoNotAvailablePopUpModal(status) {
  return {
    type: actions.SET_VIDEO_NOT_AVAILABLE,
    status
  };
}

export function toggleReportUserModal(status) {
  return {
    type: actions.SET_REPORT_USER,
    status
  };
}

export function toggleReportMessageModal(status) {
  return {
    type: actions.SET_REPORT_MESSAGE,
    status
  };
}

export function toggleReportCommentModal(status) {
  return {
    type: actions.SET_REPORT_COMMENT,
    status
  };
}

export function toggleReportCommentUserModal(status) {
  return {
    type: actions.SET_REPORT_COMMENT_USER,
    status
  };
}

export function toggleUserAccessForgery(status) {
  return {
    type: actions.SET_USER_ACCESS_FORGERY,
    status
  };
}