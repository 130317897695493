import * as actions from "redux/action-types";
import { CancelToken } from "axios";

const initialState = {
    items: [],
    active: null,
    hasMore: true,
    page: 0,
    limit: 18,
    isLoading: false,
    cancelRequest: CancelToken.source()
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_COURSE_DATA:
      return {
        ...state,
        items: action.items,
        hasMore: action.hasMore,
        page: action.page,
      };
  case actions.SET_ACTIVE_COURSE_DATA:
      return {
        ...state,
        active: action.courseItem,
      };
  case actions.CLEAR_ACTIVE_COURSE_DATA:
      return {
        ...state,
        active: null,
      };
  case actions.LOADING_COURSE_DATA:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_COURSE_CANCEL_TOKEN:
      return {
        ...state,
        cancelRequest: action.token,
      };
    case actions.SET_COURSE_HAS_NO_MORE:
      return {
        ...state,
        hasMore: false,
      };

    default:
      return state;
  }
}
