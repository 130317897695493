import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { useParams } from "react-router-dom";
import { Strings } from "resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import PDFIcon from "assets/images/page/pdf.svg";
import apiConfig from "config/api";
import appConfig from "config/config";
import { log } from "utils";
import "./Search.scss";

export default function Search(props) {
  const history = useHistory();
  const { query } = useParams();
  const accountData = useSelector((state) => state.accountData);
  const siteLanguage = Strings.getLanguage();
  const dispatch = useDispatch();
  const userLanguage = Strings.getLanguage();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
    
  /* Get My Active Course */
  useEffect(() => {
    dispatch(AccountActions.searchSite(query, siteLanguage));
  }, [dispatch, query, siteLanguage]);

  const downloadLibraryFile = (fileUrl, fileName) => {
    const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadFile + "?file="+fileUrl;
    fetch(fileDownloadUrl)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
            //window.location.href = response.url;
    });
}

  const libraryFileItem = (item, index) => {
    const fileTitle = userLanguage === 'hi' ? item.ltitle_hindi : item.ltitle ;
    const fileUrl = userLanguage === 'hi' && item.lfile_hindi !== '' ? item.lfile_hindi : item.lfile ;
    return (<div className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 fileItemWrapper" key={`file-${index}`}>
        <div className="fileItem">
            <div className="fileThumb">
                <img src={PDFIcon} alt="File" title="File" />
            </div>
            <div className="fileDesc">
                <span className="title" onClick={() => downloadLibraryFile(fileUrl,'file.pdf')}>{fileTitle}</span>
                <span className="icon" onClick={() => downloadLibraryFile(fileUrl,'file.pdf')}><FontAwesomeIcon icon={faCloudDownloadAlt} size="lg" className="download" /></span>
            </div>
        </div>
    </div>);
}

const showReadingMaterial = (item) => {
  history.push(`/category/${+item.cid}/topic/${+item.topic_id}/reading`);
}

const studyMaterialItem = (item, index) => {
  const materialTitle = userLanguage === 'hi' ? item.title_hindi : item.title ;
  const materialDesc = userLanguage === 'hi' ? item.study_description_hindi : item.study_description ;
  return (<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 materialItemWrapper" key={`file-${index}`}>
      <div className="materialItem">
          <span className="mTitle" onClick={() => showReadingMaterial(item)}>{index+1}. {materialTitle}</span>
          <div className="mDesc" dangerouslySetInnerHTML={{__html : materialDesc}}></div>
      </div>
  </div>);
}

const eventItem = (item, index) => {
  const eventTitle = userLanguage === 'hi' ? item.event_title_hindi : item.event_title ;
  const eventDesc = userLanguage === 'hi' ? item.event_description_hindi : item.event_description ;
  return (<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 eventItemWrapper" key={`file-${index}`}>
      <div className="eventItem">
          <span className="mTitle">{index+1}. {eventTitle}</span>
          <span className="mType">{Strings.eventType} : {item.event_type}</span>
          <span className="mDate">{Strings.eventDate} : {item.event_date}</span>
          <div className="mDesc" dangerouslySetInnerHTML={{__html : eventDesc}}></div>
      </div>
  </div>);
}

  return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo">
        <div className="myPage">
            <div className="myPageWrapper searchPage">

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="pageTitle">
                  <h1>{Strings.searchResultsFor} "{decodeURIComponent(query)}"</h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="pageTitle">
                  <h3>{Strings.library} :</h3>
                </div>
              </div>
            </div>
            {accountData?.searchData?.library?.length > 0 &&
              <div className="row">
                {accountData?.searchData?.library?.map((item, index) => libraryFileItem(item, index))}
              </div>
            }
            {accountData?.searchData?.library?.length === 0 &&
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                  <p>{Strings.noLibraryFilesFound}</p>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="pageTitle">
                  <h3>{Strings.events} :</h3>
                </div>
              </div>
            </div>
            {accountData?.searchData?.events?.length > 0 &&
              <div className="row">
                {accountData?.searchData?.events?.map((item, index) => eventItem(item, index))}
              </div>
            }
            {accountData?.searchData?.events?.length === 0 &&
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                  <p>{Strings.no_events_found}</p>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="pageTitle">
                  <h3>{Strings.studyMaterial} :</h3>
                </div>
              </div>
            </div>
            {accountData?.searchData?.stydymaterial?.length > 0 &&
              <div className="row">
                {accountData?.searchData?.stydymaterial?.map((item, index) => studyMaterialItem(item, index))}
              </div>
            }
            {accountData?.searchData?.stydymaterial?.length === 0 &&
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                  <p>{Strings.smNoReadingFound}</p>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </Fragment>
  );
}
