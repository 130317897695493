import React, { useRef, useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as AceTestActions from "redux/actions/AceTestActions";
import * as TopicActions from "redux/actions/TopicActions";
import { getUserId, getUserToken } from "services/AuthService";
import { Strings } from 'resources';
import Chart from "react-apexcharts";
import aceGameIcon from "assets/images/dashboard/dashboardAceGame.svg";
import lockIcon from "assets/images/home/lock.svg";
import dashboardLinkIcon from "assets/images/home/dashboardnew.svg";
import chatLinkIcon from "assets/images/home/quillIt.svg";
import redeemLinkIcon from "assets/images/home/redeem.svg";
import referLinkIcon from "assets/images/home/refer.svg";
import FreeTrialACEGame from "modals/FreeTrialACEGame";
import FreeTrialExpiredACEGame from "modals/FreeTrialExpiredACEGame";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import SubscriptionPlanExpired from "modals/SubscriptionPlanExpired";
import { useParams } from "react-router-dom";
import "./Home.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from 'react-select';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { log } from "utils";
gsap.registerPlugin(Draggable);
gsap.config({
  nullTargetWarn: false
});

export default function Home(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const topicData = useSelector((state) => state.topicData);
  const accountData = useSelector((state) => state.accountData);
  const aceTestData = useSelector((state) => state.aceTestData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const history = useHistory();
  const [aceGameState, setAceGameState] = useState('1-20');
  const [categoryName, setCategoryName] = useState('');
  const [catId, setCatId] = useState('0');
  const [selectedCat, selectCat] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const sliderRef = useRef();

  /* ACE Game Carousel */
  const settings = {
    infinite: false,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect:true,
    afterChange: (index) => { let i = (index*20)+1; let j = ((index+1)*20); let k = i+'-'+j;  goToAceQuiz(k); },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  // component did mount
  useEffect(() => {
     dispatch(AccountActions.getAccountSettings(userId));
     dispatch(AccountActions.getUserAccuracyCharts(userId));
     dispatch(AccountActions.getUserDashboardPoints());
     dispatch(AceTestActions.getACEGameStatus());
  }, [dispatch, userId]);

  /* Get Main Categories */
  useEffect(() => {
    dispatch(TopicActions.getTopicMainCategories(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get Category Topics */
  useEffect(() => {
    dispatch(TopicActions.getCategoryTopics(userToken, catId, userId));
  }, [dispatch, userToken, userId, catId]);

  useEffect(() => {
    if (topicData.category.length > 0) {
      if (catId === '0') {
        setCatId(topicData?.category?.[0].cid ?? '0');
        selectCat(topicData?.category?.[0]);
      }
      if (categoryName === '') {
        const cat_name = accountData.language === 'hi' ? topicData?.category?.[0].category_name_hindi : topicData?.category?.[0].category_name;
        setCategoryName(cat_name ?? '');
        setSelectedOption({ value: +topicData?.category?.[0].cid, label: cat_name, category_name: topicData?.category?.[0].category_name, category_name_hindi: topicData?.category?.[0].category_name_hindi });
      }
    }
  }, [topicData, accountData.language]);
  
  /**
   * Show Play ACE Game PopUp
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.localStorage) {
        let nextPopup = localStorage.getItem('nextACEPlayGame');
        if (nextPopup > new Date()) {
          return;
        }
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem('nextACEPlayGame', expires );
      }

      /* Show PopUp According to User Activity */
      const valExamId = accountData?.status?.exam;
      const valGroupId = accountData?.status?.group;
      const isValid = accountData?.status?.valid;
      if (valExamId === '1' && valGroupId === '1' && isValid) {
        dispatch(NavigationActions.toggleFreeTrialModal(true));
      } else if (valExamId === '1' && valGroupId === '2' && isValid) {
        dispatch(NavigationActions.togglePlayAceGameModal(true));
      } else if (valExamId === '1' && valGroupId === '3' && isValid) {
        dispatch(NavigationActions.togglePlayAceGameModal(true));
      } else if (valExamId === '1' && valGroupId === '2' && !isValid) {
        dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
      } else if (valExamId === '1' && valGroupId === '3' && !isValid) {
        dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
      } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
        dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
      } else {
        dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch, accountData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAceGameState(aceTestData?.currentGameKey);
      sliderRef.current.slickGoTo(aceTestData?.currentGameIndex);      
    }, 1250);
    return () => clearTimeout(timer);
  }, [aceTestData?.currentGameIndex, aceTestData?.currentGameKey]);

  /**
   * checkACEGameExpireModal
   * @description Check ACE Game Expire Modal
   */
  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '2' && !isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId === '3' && !isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const showFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(true));
  }

  const hideFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(false));
  }

  const showFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
  }

  const hideFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(false));
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  const hideSubscriptionPlanExpiredModal = () => {
    dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(false));
  }

  /**
   * goToCategoryHeatMap
   * @param {object} item
   * @param {number} index
   * @description Go To Category Heat Map
   */
  const goToCategoryHeatMap = (item, index) => {
    history.push(`/category/${+item.cid}/heat-map`);
  }

  /**
   * goToAceGameRules
   * @description Play ACE Game
   */
  const goToAceGameRules = (quizId) => {
    const status = checkACEGameExpireModal();
    if (status === '0') {
      showFreeTrialExpiredACEGameModal();
    } else if (status === '1') {
      showFreeTrialACEGameModal();
    } else if (status === '2') {
      history.push({
        pathname: '/ace-game-rules/',
        state: { quizId: quizId }
      });
    } else if (status === '4') {
      dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
    } else if (status === '3') {
      showFeatureNotAvailableModal();
    }
  }

  /**
   * viewCategoryItem
   * @param {object} item
   * @param {number} index
   * @description View Category Item
   */
  const viewCategoryItem = (item, index) => {
    const chartOptions = {
      chart: {
        height: 150,
        type: 'radialBar',
        offsetX: 0,
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#F5A32F"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 270,
          hollow: {
            margin: 0,
            size: '64%',
            background: 'transparent',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            show: true,
            background: '#fff',
            strokeWidth: '64%',
            opacity: 1,
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              formatter: function(val) {
                return parseInt(val)+'%';
              },
              color: '#000000',
              fontSize: '20px',
              show: true,
              offsetY: 7
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ["#E1246A"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['%'],
    };
    const chartSeries = [item.accuracy_points ? Math.round(item.accuracy_points) : 0];
    const categoryTitle = userLanguage === 'hi' ? item?.category_name_hindi : item?.category_name;

    return (<div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 categoryItemWrapper" key={`category-${index}`}>
      <div className="categoryItemView" onClick={() => goToCategoryHeatMap(item, index)}>
        <div className="categoryChartView">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="radialBar"
            width="150"
          />
        </div>
        <div className="categoryTitleView">
          <span onClick={() => goToCategoryHeatMap(item, index)}>{categoryTitle}</span>
        </div>
      </div>
    </div>
    );
  }

  const goToDashboard = () => {
    history.push(`/dashboard/`);
  };

  const goToRefer = () => {
    history.push(`/refer-a-friend/`);
  };

  const goToChat = () => {
    history.push(`/quill-it`);
  };

  const goToRedeem = () => {
    history.push(`/leader-board`);
  };

  const goToAceQuiz = (range) => {
    setAceGameState(range);
  }

  const viewRangeItem = (item, index, key) => {
    return (<li className="rangeItem" onClick={() => goToAceQuiz(key)}>{key}</li>);
  }

  const viewAceGameStatusItem = (item, index) => {
    if (+item.baqc_id === +aceTestData?.currentGameId) {
      return (<li className={`current`} key={`ace-item-${index}`} onClick={() => goToAceGameRules(item.baqc_id)}>
                <div className="aceItem">
                  <span className="examNo">{item.baqc_display_order}</span>
                </div>
              </li>);
    }

    if (item.status === 'completed' || item.status === 'ongoing') {
      return (<li className={`completed`} key={`ace-item-${index}`}>
              <div className="aceItem">
                <img className="examReport" src={aceGameIcon} alt={item.baqc_display_order} title={item.baqc_display_order} />
              </div>
            </li>);
    } else if (item.status === '') {
      return (<li className={`pending`} key={`ace-item-${index}`}>
                <div className="aceItem">
                  <img className="lockedExam" src={lockIcon} alt={item.baqc_display_order} title={item.baqc_display_order} />
                </div>
              </li>);
    }
  }

  /**
   * categoryItem
   * @param {object} item
   * @param {number} index
   * @description Show Category Item
   */
   const categoryItem = (item, index) => {
      const categoryName = userLanguage === 'hi' ? item.category_name_hindi : item.category_name;
      return (<li className={`categoryItem ${+item.cid === +catId ? "active" : "" }`} key={`category-${index}`} onClick={() => goToCategory(item, index)}>{categoryName}</li>);
    }

    /**
     * showCategoryTopics
     * @param {object} item
     * @param {number} index
     * @description Show Category Item
     */
    const showCategoryTopics = (item, index) => {
      const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;

      const topicList = item.subject_tests ? (<li className={`topicItems`} key={`topic-${index}`} onClick={() => showReadingMaterial(item, item.category_id)}>{topicName}  <i className="arrowRightTopic"></i></li>) : null;
      if(item.subject_tests){
        return topicList;
      }
    }

    /**
     * showReadingMaterial
     * @param {object} item
     * @description Show Reading Material
     */
    const showReadingMaterial = (item, cid) => {
      history.push('/category/'+cid+'/topic/'+item.topic_id+'/quiz-lists');
    }
  
  /**
   * goToCategory
   * @param {object} item
   * @description Go to Category Page
   */
  const goToCategory = (item) => {
    const catName = userLanguage === 'hi' ? item?.category_name_hindi : item?.category_name;
    setCatId(item?.cid);
    setCategoryName(catName);
    selectCat(item);
  }

  const mainCategoryOptions = () => {
    const catItems = [];
    topicData.category.map((item, index) => {
      const categoryName = userLanguage === 'hi' ? item.category_name_hindi : item.category_name;
      catItems.push({ value: +item.cid, label: categoryName, category_name: item.category_name, category_name_hindi: item.category_name_hindi });
    });
    return catItems;
  }

  const goToCategoryItem = (item, index) => {
    const catName = userLanguage === 'hi' ? item?.category_name_hindi : item?.category_name;
    setCatId(item?.value)
    setSelectedOption(item);
    setCategoryName(catName);
    selectCat(item);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
          <div className="myPageWrapper myHome">

            <div className="SubjectWiseContainer">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                  <h2>{Strings.get_subjectwise_test}</h2>
                  <p>{Strings.get_subjectwise_test_desc}</p>
                  <p>{Strings.get_subjectwise_test_desc1}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 adIcon">
                    <div className="categoryLists">
                        {topicData.category.length > 0 &&
                          <ul className="categoryItems">
                            {topicData.category.map((item, index) => categoryItem(item, index))}
                          </ul>
                        }
                    </div>
                    <div className="mobileCategoryListings">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h4>{Strings.takeSubjectExamNotice}</h4>
                            <Select 
                            styles={{
                              dropdownIndicator: (provided, state) => ({
                                ...provided,
                                color: '#F8820E',
                              }),
                              indicatorSeparator: (provided, state) => ({
                                ...provided,
                                color: '#F8820E',
                              }),
                            }}
                            defaultValue={selectedOption}
                            value={selectedOption}
                            isSearchable
                            options={mainCategoryOptions()}
                            placeholder="Select Category"
                            onChange={(item, index) => goToCategoryItem(item, index)}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="catListWrapper">
                      <h4>{userLanguage === 'hi' ? selectedCat?.category_name_hindi : selectedCat?.category_name}</h4>
                      {categoryName !== "" && topicData.topics.length > 0 &&
                        <ul className="catList">
                          {topicData.topics.map((item, index) => showCategoryTopics(item, index))}
                        </ul>
                      }
                    </div>
                </div>
              </div>
            </div>

            <div className="homeDetails">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 aceQuizSection">
                  <div className="aceGameView">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="sTitle">{Strings.practice_tests_play_quick_ace_games}</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        
                        <div className="gameRangeWrapper">
                          <Slider {...settings} ref={sliderRef} >
                            {aceTestData.aceTestsStatus &&
                              Object.entries(aceTestData.aceTestsStatus).map(([key, val]) => (
                                <div key={`item-${key}`} className={`rangeItem`}>
                                  <span className={`rangeSingleItem ${aceGameState === key ? 'active' : ''}`}>
                                    {key === "1-20" ? Strings.free : key}
                                  </span>
                                </div>
                              ))}
                          </Slider>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <ul className="aceGameLists">
                          {aceTestData.aceTestsStatus && aceTestData.aceTestsStatus[aceGameState] && aceTestData.aceTestsStatus[aceGameState].length > 0 &&
                            aceTestData.aceTestsStatus[aceGameState].map((item, index) => viewAceGameStatusItem(item, index))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 analyticsSection">
                  <div className="categoryView">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="sTitle">{Strings.subject_wise_practice}</h2>
                      </div>
                    </div>
                    <div className="row">
                      {topicData.category.length > 0 &&
                        topicData.category.map((item, index) => viewCategoryItem(item, index))
                      }
                    </div>
                  </div>
                  <div className="quickAccessView">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="sTitle">{Strings.quick_access}</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="featureLink" onClick={() => goToDashboard()}>
                          <img className="fIcon" src={dashboardLinkIcon} alt={Strings.dashboard} title={Strings.dashboard} />
                          <span className="fLink">{Strings.dashboard}</span>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="featureLink" onClick={() => goToChat()}>
                          <img className="fIcon" src={chatLinkIcon} alt="Chat" title="Chat" />
                          <span className="fLink">{Strings.chat}</span>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="featureLink" onClick={() => goToRefer()}>
                          <img className="fIcon" src={referLinkIcon} alt={Strings.refer} title={Strings.refer} />
                          <span className="fLink">{Strings.refer}</span>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                        <div className="featureLink" onClick={() => goToRedeem()}>
                          <img className="fIcon" src={redeemLinkIcon} alt={Strings.redeem} title={Strings.redeem} />
                          <span className="fLink">{Strings.redeem}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {navigationData.showFreeTrialModal ? <FreeTrialACEGame onGoBack={() => hideFreeTrialACEGameModal()} /> : null}
      {navigationData.showFreeTrialExpiredModal ? <FreeTrialExpiredACEGame onGoBack={() => hideFreeTrialExpiredACEGameModal()} /> : null}
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
      {navigationData.showSubscriptionPlanExpiredStatusModel ? <SubscriptionPlanExpired onGoBack={() => hideSubscriptionPlanExpiredModal()} /> : null}
    </Fragment>
  );
}
