import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { getUserId } from "services/AuthService";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import PrivacyPolicyData from 'assets/data/PrivacyPolicy';
import { log } from "utils";
import "./CopyrightAndPrivacyPolicy.scss";
import { Strings } from "resources";

export default function CopyrightAndPrivacyPolicy(props) {
  const dispatch = useDispatch();
  const userId = getUserId();
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
      log('Site Language Changed');
      return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Load User Settings */
  useEffect(() => {
     dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  const policyDescription = () => {
    return PrivacyPolicyData;
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg">
        <div className="pageScreen">
          <div className="pageHeading">
            <div className="Heading">
              <span className="title">{Strings.copyright} &amp; {Strings.privacyPolicy}</span>
              <span className="breadcrumbs">{Strings.settings} &rarr; {Strings.copyright} &amp; {Strings.privacyPolicy}</span>
            </div>
            <div className="Logo">
              <img className="logoImage" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
            </div>
          </div>
          <div className="pageContent" dangerouslySetInnerHTML={{__html : policyDescription()}}></div>
        </div>
      </div>
    </Fragment>
  );
}
