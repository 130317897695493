import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getMainCategories
 * @param {string} tokenId
 * @param {string} userId
 * @description Get Main Categories
 */
export async function getMainCategories(tokenId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId
    };
    try {
        const response = await appClient.post(apiConfig.topic.getMainCategories, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getTopicsFromCategory
 * @param {string} tokenId
 * @param {string} catId
 * @param {string} userId
 * @description Get Topics From Category
 */
export async function getTopicsFromCategory(tokenId, catId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'cat_id': catId
    };
    try {
        const response = await appClient.post(apiConfig.topic.getTopicList, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getStudyMaterialFromTopic
 * @param {string} tokenId
 * @param {string} catId
 * @param {string} userId
 * @description Get StudyMaterial From Topic
 */
export async function getStudyMaterialFromTopic(tokenId, catId, topicId, userId) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'topic_id': topicId,
        'cat_id': catId
    };
    try {
        const response = await appClient.post(apiConfig.topic.getStudyMaterials, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}
