import React from "react";
import { useSelector } from "react-redux";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userImage from "assets/images/page/featureImage.svg";
import "./VideoNotAvailable.scss";

export default function VideoNotAvailable(props) {
  const navigationData = useSelector((state) => state.navigationData);

  return (<>
    <div
      className={`popupWrapper VideoNotAvailable dispersionLogo ${
        !navigationData.showVideoNotAvailable ? "closed" : "opened"
      }`}
    >
      <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
      <div className="popUpInner">
        <div className="featuredIconWrapper">
            <img className="featuredIcon" src={userImage} title={Strings.success} alt={Strings.success} />
        </div>
        <p>{Strings.we_are_making_nice_vide_tutorial}</p>
        <p>{Strings.it_will_be_made_available_shortly}</p>
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showVideoNotAvailable ? "closed" : "opened"}`}></div>
  </>
  );
}
