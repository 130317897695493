import React, { Fragment, useState, useEffect } from "react";
import { log } from "utils";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import * as AceTestActions from "redux/actions/AceTestActions";
import { getUserId, getUserToken } from "services/AuthService";
import { startACEGame as startACEGameFromAPI } from "services/AceTestService";
import { Strings } from 'resources';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import { getDateInYmdHisFormat } from "utils/chat";
import "./GameRules.scss";

export default function ACEGameRules(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userToken = getUserToken();
  const [policyAgree, setPolicyAgree] = useState(false);
  const [policyAgreeTime, setPolicyAgreeTime] = useState('');
  const [isLoading, setLoadingState] = useState(false);
  const siteLanguage = Strings.getLanguage();
  const accountData = useSelector((state) => state.accountData);
  const selectedQuizId = props?.location?.state?.quizId ?? '';

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /**
   * handlePolicyCheck
   * @param {object} event
   * @description Handle Policy Check View
   */
  const handlePolicyCheck = (event) => {
    const userAgreeTime = getDateInYmdHisFormat();
    setPolicyAgree(event.target.checked);
    setPolicyAgreeTime(userAgreeTime);
  }

  /**
   * playNow
   * @description Play the ACE Game Now
   */
  const playNow = async () => {
    setLoadingState(true);
    const userAgreeValue = policyAgree ? '1' : '0';
    const userLevel = accountData?.points?.currentUserLevel ? accountData?.points?.currentUserLevel?.bagsl_id : '0';
    const { data, error } = await startACEGameFromAPI(userToken, userId, siteLanguage, userAgreeValue, policyAgreeTime, userLevel, selectedQuizId);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(error);
    } else {
      return onACEGameSuccess(data.data);
    }
  }

  /**
   * onACEGameSuccess
   * @param {object} data
   * @description On ACE Game Success
   */
  const onACEGameSuccess = (data) => {
    dispatch(AceTestActions.addACEGameData(data));
    history.push(`/ace-game/${data.baqc_id}/attempt/${data.attempt_id}/play`);
  }

  const goToDashboard = () => {
    history.goBack();
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1><span className="goBackViewFile" onClick={() => goToDashboard()}><FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" /></span>{Strings.aceGameRules}</h1>
            </div>
            <div className="myPageWrapper gameRulesPage">
              <div className="custom-container">
                  <section className="rulesSection">
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">1.</div>
                              <p>{Strings.gameRule1}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">2.</div>
                              <p>{Strings.gameRule2}</p>
                          </div>
                      </div>
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">3.</div>
                              <p>{Strings.gameRule3}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">4.</div>
                              <p>{Strings.gameRule4}</p>
                          </div>
                      </div>
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">5.</div>
                              <p>{Strings.gameRule5}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">6.</div>
                              <p>{Strings.gameRule6}</p>
                          </div>
                      </div>
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">7.</div>
                              <p>{Strings.gameRule7}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">8.</div>
                              <p>{Strings.gameRule8}</p>
                          </div>
                      </div>
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">9.</div>
                              <p>{Strings.gameRule9}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">10.</div>
                              <p>{Strings.gameRule10}</p>
                          </div>
                      </div>
                      <div className="ruleRow">
                          <div className="leftText">
                              <div className="number">11.</div>
                              <p>{Strings.gameRule11}</p>
                          </div>
                          <div className="rightText">
                              <div className="number">12.</div>
                              <p>{Strings.gameRule12}</p>
                          </div>
                      </div>
                  </section>
                  <div className="playBtn">
                      <div className="policyApprove">
                          <label className="checkboxContainer">{Strings.aceGameAgreeTerms}
                            <input type="checkbox" onChange={e => handlePolicyCheck(e)} checked={policyAgree} />
                            <span className="checkmark"></span>
                          </label>
                      </div>
                      <SmallButton clicked={() => playNow()} btnType="Orange" disabled={!policyAgree} loading={isLoading}>{Strings.playNowAceGame}</SmallButton>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
