import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserToken, getUserId, setUserCourseInApp, getUserDetails } from "services/AuthService";
import { Strings } from 'resources';
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SmallButton from "components/UI/SmallButton";
import Select from 'react-select';
import { log } from "utils";
import "./PackCgl.scss"
import lock from "../../../assets/images/icons/lock.png"
import Calendar from "./Calendar"
import appConfig from 'config/config';
export default function Courses(props) {
  const [Year, setYear] = useState(Calendar)
  const alert = useAlert();
  const [allTier, setallTier] = useState("all");
  const accountData = useSelector((state) => state.accountData);
  const courseData = useSelector((state) => state.courseData);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userDetails = getUserDetails();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const [selectedOption, setSelectedOption] = useState({ value: 'all', label: Strings.all });

  /* Handle Site Language */
  useEffect(() => {
      log('Site Language Changed');
      return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get Courses Details */
  useEffect(() => {
    dispatch(CourseActions.getCoursesData(true, userToken));
  }, [dispatch, userToken]);

  /* Get My Active Course */
  useEffect(() => {
    dispatch(CourseActions.getActiveCourse(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /**
   * goToSubscribePackage
   * @param {object} item
   * @param {number} index
   * @description Go To Subscription Package Selection Screen
   */
  const goToSubscribePackage = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push({
        pathname: '/select-subscription-package/',
        state: { examId: item.et_id }
      });
    } else {
      alert.error(Strings.unableToGoSubscriptionPackageScreen);
    }
  }

  /**
   * goToDashboard
   * @param {object} item
   * @param {number} index
   * @description Go To Dashboard Screen
   */
  const goToDashboard = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push(`/home/`);
    } else {
      alert.error(Strings.unableToSetCourse);
    }
  }

  /**
   * allSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description All Subscription Item
   */
  const Alltier = (item, index) => {
    return (<div className="col-12 " key={`file-${index}`}>
        <div className="courseItem">
          <div className="courseHeading">  
          <div className="row calender_section ">
                            <div className="col-12 col-md-8 col-lg-6 col-xl-6">
                                <div className="calendar_all d-flex text-center flex-wrap justify-content-center">                            
                                    {Year.map((curElem)=>{
                                        const {year,langauge} = curElem;
                                        return(
                                            <>
                                              <div className="calendar mx-1 py-1 mt-2">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="77" height="77" viewBox="0 0 66 66">
                                 <path id="Path_827" data-name="Path 827" d="M56.621,5.078h-5.84V2.031a2.031,2.031,0,0,0-4.063,0V5.078H18.281V2.031a2.031,2.031,0,1,0-4.063,0V5.078H8.379A8.388,8.388,0,0,0,0,13.457V56.621A8.388,8.388,0,0,0,8.379,65H56.621A8.388,8.388,0,0,0,65,56.621V13.457A8.388,8.388,0,0,0,56.621,5.078ZM8.379,9.141h5.84v2.031a2.031,2.031,0,1,0,4.063,0V9.141H46.719v2.031a2.031,2.031,0,0,0,4.063,0V9.141h5.84a4.321,4.321,0,0,1,4.316,4.316v4.824H4.063V13.457A4.321,4.321,0,0,1,8.379,9.141Zm48.242,51.8H8.379a4.321,4.321,0,0,1-4.316-4.316V22.344H60.938V56.621A4.321,4.321,0,0,1,56.621,60.938Z" transform="translate(0.5 0.5)" fill="none" stroke="#f93" stroke-width="1"/>
                              </svg>
                                        <div className="inner_cals"> <h6>{year} </h6>  <p>{langauge}</p> </div>
                                        </div>
                                        </>
                                        )
                                    })}

                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-3 col-xl-3 ">
                                <h5 className="text-center">Mock Test</h5>
                                <div className="d-flex flex-wrap justify-content-center">

                                    <div className="locks">
                                          <h3>1</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>2</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>3</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>4</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>5</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>6</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>7</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>8</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>9</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div> <div className="locks">
                                          <h3>10</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>11</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>12</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>13</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>14</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>15</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>16</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>17</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>17</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>19</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>20</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>21</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>22</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>
                                    <div className="locks">
                                          <h3>23</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>24</h3>
                                        <img src={lock} className="img-fluid" alt="lock" />
                                    </div>
                                    <div className="locks">
                                          <h3>25</h3>
                                        <img src={lock} className="img-fluid" alt="lock" /> </div>

                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center align-items-center">
                                <button type="button" className="btn px-4">Unlock</button>
                                
                            </div>
                       </div>
          </div>
          <div className="courseDescription" ></div>
          <div className="courseLinks">
            
          </div>
        </div>
      </div>);
  }

  return (
    <Fragment>
      <div className="tierpageScreenContainer dispersionLogo">
        <div className="myPage myCourses">
            <div className="pageTitle">
              
            </div>
            <div className="subscriptionNavigationWrapper">
            <div className="row ">
                     <div className="col-12 col-md-3 col-xl-3 col-xxl-3 ">
                         <div className="Pack_cgl">  
                          <div><h2>cgl <br /> pack</h2>
                                <p>All exams under this category can be unlocked at a price of ₹200+taxes which includes past question papers for IBPS PO, IBPS CLERK, IBPS SO, and IBPS RRB - prelims +mains  in English and Hindi.</p>
                                 <div className="text-center">  <button type="button" class="btn">unlock</button></div>
                            </div>
                        </div>
                     </div>
                     <div className="col-12 col-md-9 col-xl-9 col-xxl-9">
                     <ul className="subscriptionNavigation">
                <li className={`item ${allTier === 'all' ? 'active' : ''}`} onClick={() => setallTier('all')}><span>Tier I</span></li>
                <li className={`item ${allTier === 'tier_2' ? 'active' : ''}`} onClick={() => setallTier('tier_2')}><span>Tier II</span></li>
                <li className={`item ${allTier === 'past-subscriptions' ? 'active' : ''}`} onClick={() => setallTier('past-subscriptions')}><span>Tier III</span></li>
              </ul>
                 <p className="subscriptionNavigation_para">All exams under this category can be unlocked at a price of ₹150+taxes which includes past question papers for PO- Prelims + Mains in English and Hindi.</p>
              <div className="subscriptionListings">
              {allTier === 'all' && Alltier()}
              {allTier === 'tier_2' && Alltier()}
       
            </div>
                     </div>
                     </div>
             
             
            </div>
            {/* <div className="mobileCategoryListings">
              <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="categoryTitle">{Strings.selected_subscription} : {selectedOption?.label}</h4>
                      <Select 
                        defaultValue={selectedOption}
                        value={selectedOption}
                        isSearchable
                        options={mainPackageItems()}
                        placeholder="Select Subscription"
                        onChange={(item, index) => goToPackageItem(item, index)}
                      />
                  </div>
              </div>
            </div> */}
           
        </div>
      </div>
    </Fragment>
  );
}




