import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as AceTestActions from "redux/actions/AceTestActions";
import { getUserId, getUserToken } from "services/AuthService";
import { endACEGame as endACEGameFromAPI, saveACEGameQuestion as saveACEGameQuestionFromAPI } from "services/AceTestService";
import { Strings } from 'resources';
import { log } from "utils";
import SmallButton from "components/UI/SmallButton";
import { faChevronLeft, faChevronRight, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ACEGameEndConfirmationModal from "modals/ACEGameEndConfirmation";
import ACEGameTimeUpModal from "modals/ACEGameTimeUp";
import AceIcon from "assets/images/dashboard/dashboardAceGame.svg";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import "./Game.scss";

export default function ACEGame(props) {
    const alert = useAlert();
    const dispatch = useDispatch();
    const accountData = useSelector((state) => state.accountData);
    const history = useHistory();
    const userId = getUserId();
    const userToken = getUserToken();
    const { quizId, attemptId } = useParams();
    const [isLoading, setLoadingState] = useState(false);
    const aceTestData = useSelector((state) => state.aceTestData);
    const navigationData = useSelector((state) => state.navigationData);
    const userLanguage = Strings.getLanguage();

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    /* Load User Settings */
    useEffect(() => {
        dispatch(AceTestActions.getACEGame(quizId));
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        };
    }, [dispatch, quizId]);

    /* Handle Mouse Right Click Context Menu */
    const handleContextMenu = (e) => {
        e.preventDefault();
    }

    /**
     * showExamTimer
     * @description Show Exam Timer
     */
  const showExamTimer = () => {
    return (<CountdownCircleTimer
      isPlaying
      onComplete={() => {
        handleTimeUpModal();
        return [false, 1500]
      }}
      size={80}
      strokeWidth={6}
      duration={1800}
      colors={[
        ['#FF9933', 1],
      ]}
    >{timerChildren}</CountdownCircleTimer>);
  }

  /**
   * prependZero
   * @param {*} number
   * @description Prepend Zero to the Timer Number Text
   */
  const prependZero = (number) => {
        if (number <= 9) {
            return "0" + number;
        } else {
            return number;
        }
    }

  /**
   * timerChildren
   * @param {*} remainingTime
   * @description Timer Seconds to Show
   */
  const timerChildren = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return (<span className="quizTime">{prependZero(minutes)}:{prependZero(seconds)}</span>);
  }

  const handleTimeUpModal = () => {
    dispatch(NavigationActions.toggleACEQuizTimeUpModal(true));
  }

  /**
   * handleTimerCompleted
   * @description Handle Completed Timer Event
   */
  const handleTimerCompleted = () => {
    dispatch(AceTestActions.onTimerComplete()).then(response => {
        dispatch(NavigationActions.toggleACEQuizTimeUpModal(false));
        dispatch(AccountActions.validateUserGroup());
        history.push(`/ace-game/${quizId}/${attemptId}/score-card`);
    });
  }

  /**
   * questionStatusView
   * @param {*} queItem
   * @param {*} queIndex
   * @description Question Status View
   */
  const questionStatusView = (queItem, queIndex) => {
    const questionId = aceTestData?.activeTestQuestion?.id;
    let className = ' ';
    if (queItem.questionStatus === 'skipped') {
      className = ' ';
    } else if (queItem.questionStatus === 'answered') {
      className = 'blue';
    }
    if(+questionId === +queItem.questionId) {
        className = 'yellow';
    }
    return (<span className={className} key={`question-status-${queIndex}`} onClick={() => viewQuestion(queItem, queIndex)}>{queItem.displayOrder}</span>);
  }

  /**
   * optionItemView
   * @param {object} optionItem
   * @param {number} optionIndex
   * @description Option Item View
   */
  const optionItemView = (optionItem, optionIndex) => {
    let className = 'options';
    if (optionItem.isChecked) {
        className = 'options green';
    }
    log(optionItem);
    const optionTitle = userLanguage === 'hi' ? optionItem?.optionHindi : optionItem?.option;
    return (<div className={className} key={`question-status-${optionIndex}`} onClick={(e) =>  handleOption(optionItem.id, optionItem.isChecked)}>
        <span className="letter">{getOptionName(optionIndex)}</span>
        <div className="text" dangerouslySetInnerHTML={{__html : optionTitle}}></div>
    </div>);
   }

   /**
    * handleOption
    * @param {*} optionId
    * @param {*} isChecked
    * @description Toggle Option
    */
   const handleOption = (optionId, isChecked) => {
        const questionId = aceTestData?.activeTestQuestion?.id;
        dispatch(AceTestActions.toggleACETestQuestionOption(questionId, optionId));
   }

   /**
    * getOptionName
    * @param {number} index
    * @description get Option Name
    */
   const getOptionName = (index) => {
       let optionName = Strings.a;
        switch (index) {
            case 0:
                optionName = Strings.a;
            break;

            case 1:
                optionName = Strings.b;
            break;

            case 2:
                optionName = Strings.c;
            break;

            case 3:
                optionName = Strings.d;
            break;

            case 4:
                optionName = Strings.e;
            break;

            default:
                optionName = Strings.a;
            break;
        }
        return optionName;
    }

    /**
     * showNavigationIcons
     * @description Show Quiz Navigation Icons
     */
    const showNavigationIcons = () => {
        const questionOrder = +aceTestData?.activeTestQuestion?.questionOrder;
        return (<>
            {questionOrder !== 1 &&<div className="leftArrow">
                <FontAwesomeIcon icon={faChevronLeft} size="lg" className="leftArrowIcon" onClick={() => navigateToQuestion('prev')} />
            </div>}
            <div className="rightArrow">
                <FontAwesomeIcon icon={faChevronRight} size="lg" className="rightArrowIcon" onClick={() => navigateToQuestion('next')} />
            </div>
        </>);
    }

    /**
     * navigateToQuestion
     * @param {*} action
     * @description Navigate Question Previous or Next
     */
    const navigateToQuestion = (action) => {
        let queOrder = aceTestData?.activeTestQuestion?.questionOrder;
        const attemptId = aceTestData?.activeTest?.attemptId;
        const queTimeId = aceTestData?.activeTestQuestion?.timeId;
        const questionId = aceTestData?.activeTestQuestion?.id;
        const totalQuestions = +aceTestData?.activeTest?.testQuestionsCount;
        let answerId = aceTestData?.activeTestQuestion?.answerId;
        let isOptionSelected = false;
        aceTestData?.activeTestQuestion?.options.forEach((optionItem, optionIndex) => {
            if (optionItem.isChecked) {
                answerId = optionItem.id;
                isOptionSelected = true;
            }
        });
        if (!isOptionSelected) {
            answerId = null;
        }

        if (action === 'prev') {
            if (queOrder === 1) {
                return false;
            }
            dispatch(AceTestActions.saveACEGameQuestion(quizId, attemptId, queOrder, queTimeId, questionId, answerId, 'prev'));
        } else if (action === 'next') {
            if (queOrder === totalQuestions) {
                onConfirmEndACEGame();
                return false;
            }
            dispatch(AceTestActions.saveACEGameQuestion(quizId, attemptId, queOrder, queTimeId, questionId, answerId, 'next'));
        }
    }

    /**
     * viewQuestion
     * @param {*} queItem
     * @param {*} queIndex
     * @description Go To Specific Question
     */
    const viewQuestion = (queItem, queIndex) => {
        let queOrder = aceTestData?.activeTestQuestion?.questionOrder;
        const attemptId = aceTestData?.activeTest?.attemptId;
        const queTimeId = aceTestData?.activeTestQuestion?.timeId;
        const questionId = aceTestData?.activeTestQuestion?.id;
        let answerId = aceTestData?.activeTestQuestion?.answerId;
        let isOptionSelected = false;
        aceTestData?.activeTestQuestion?.options.forEach((optionItem, optionIndex) => {
            if (optionItem.isChecked) {
                answerId = optionItem.id;
                isOptionSelected = true;
            }
        });
        if (!isOptionSelected) {
            answerId = null;
        }
        
        dispatch(AceTestActions.saveACEGameQuestion(quizId, attemptId, queOrder, queTimeId, questionId, answerId, 'goto', queItem.displayOrder));
    }

    /**
     * endACEGame
     * @description End ACE Game Functionality
     */
    const endACEGame = async () => {
        const courseId = '1';
        const queOrder = aceTestData?.activeTestQuestion?.questionOrder;
        const attemptId = aceTestData?.activeTest?.attemptId;
        const queTimeId = aceTestData?.activeTestQuestion?.timeId;
        const questionId = aceTestData?.activeTestQuestion?.id;
        let answerId = aceTestData?.activeTestQuestion?.answerId;
        setLoadingState(true);
        dispatch(AceTestActions.setACETestLoadingState(true));
        aceTestData?.activeTestQuestion?.options.forEach((optionItem, optionIndex) => {
            if (optionItem.isChecked) {
                answerId = optionItem.id;
            }
        });

        /* Call Save ACE Game Question API */
        const { data, error } = await saveACEGameQuestionFromAPI(userToken, userId, quizId, attemptId, queOrder, queTimeId, questionId, answerId);
        if (!error && data.status) {
            /* Call the End Game API */
            const { data } = await endACEGameFromAPI(userToken, userId, quizId, attemptId, courseId);
            if (!data.status) {
                dispatch(AceTestActions.setACETestLoadingState(false));
                setLoadingState(false);
                alert.error(Strings[data.message]);
                return log(data.message);
            } else {
                dispatch(AceTestActions.setACETestLoadingState(false));
                setLoadingState(false);
                return onEndACEGameSuccess(data.data);
            }
        } else {
            dispatch(AceTestActions.setACETestLoadingState(false));
            setLoadingState(false);
            alert.error(Strings[data.message]);
            return log(data.message);
        }
    }

    /**
     * onEndACEGameSuccess
     * @param {object} data
     * @description On End ACE Game Success
     */
    const onEndACEGameSuccess = (data) => {
        const quizId = aceTestData?.activeTest?.id;
        const attemptId = aceTestData?.activeTest?.attemptId;
        dispatch(NavigationActions.toggleACEGameEndModal(false));
        dispatch(AccountActions.validateUserGroup());
        history.push(`/ace-game/${quizId}/${attemptId}/score-card`);
    }

    /**
     * onGoBackModal
     * @description on Go Back in PopUp
     */
    const onGoBackModal = () => {
        dispatch(NavigationActions.toggleACEGameEndModal(false));
    }

    /**
     * onConfirmEndACEGame
     * @description on Confirm ACE Game End
     */
    const onConfirmEndACEGame = () => {
        setLoadingState(true);
        dispatch(NavigationActions.toggleACEGameEndModal(true));
        setLoadingState(false);
    }

    const onHomeRedirect = (data) => {
        history.push(`/my-courses`);
    }

    const aceGameView = () => {
        log(aceTestData?.activeTestQuestion);
        const newQuestionName = userLanguage === 'hi' ? aceTestData?.activeTestQuestion?.questionHindi : aceTestData?.activeTestQuestion?.question;
        // const newQuestionName = queTitle.replaceAll('&#34;', '"');
        const queDesc = userLanguage === 'hi' ? aceTestData?.activeTestQuestion?.descriptionHindi : aceTestData?.activeTestQuestion?.description;

         return (<div className="contentRow">
            <div className="leftCnt">
                <div className="leftCntHeader">
                    <div className="aceLogo">
                        <img className="aceLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                    </div>
                    <div className="counter">
                        {showExamTimer()}
                    </div>
                </div>
                <div className="pointsSec">
                    <p>{Strings.acePoints}</p>
                    <div className="acePoint">
                        <p>{aceTestData?.activeTestQuestion?.questionScore}</p>
                    </div>
                </div>
                {aceTestData?.activeTestQuestionStatus.length > 0 &&
                    <div className="questions">
                    {aceTestData?.activeTestQuestionStatus?.map((queItem, queIndex) => questionStatusView(queItem, queIndex))}
                    </div>
                }
                <div className="endGameButton">
                    <SmallButton clicked={() => onConfirmEndACEGame()} btnType="Orange" loading={isLoading}>{Strings.endTheGame}</SmallButton>
                </div>
            </div>
            <div className="rightCnt">
                <div className="quesSec">
                    <div className="questionDescription" dangerouslySetInnerHTML={{__html : queDesc}}></div>
                    <div className="questionName" dangerouslySetInnerHTML={{__html : newQuestionName}}></div>
                    {aceTestData?.activeTestQuestion?.options.length > 0 &&
                        aceTestData?.activeTestQuestion?.options.map((optionItem, optionIndex) => optionItemView(optionItem, optionIndex))
                    }
                    {aceTestData?.isLoadingQuestion && <div className="saveQuestion">
                        <span className="saveQuestionStatus"><FontAwesomeIcon icon={faCircleNotch} className="saveQuestionStatusIcon" spin />{Strings.your_response_registered}</span>
                    </div>}
                </div>
                {showNavigationIcons()}
            </div>
        </div>);
    }

    const invalidGamePlayView = () => {
        return (<div className="contentRow">
            <div className="leftCnt directCenter">
                <div className="endGameButton">
                    <img className="loaderLogo" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                    <p>{Strings.the_previous_ace_game_is_over}</p>
                    <SmallButton clicked={() => onHomeRedirect()} btnType="Orange" loading={isLoading}>{Strings.goToCourses}</SmallButton>
                </div>
            </div>
        </div>);
    }

    const loaderView = () => {
        return (<div className="contentRow">
            <div className="leftCnt directCenter">
                <div className="endGameButton">
                    <img className="loaderLogo" src={BharatCETLogo} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                    <p className="loadingGame">{Strings.loadingAceGame}</p>
                </div>
            </div>
        </div>);
    }

    return (
        <Fragment>
        <div className="pageScreenContainer gamePage logoBg">
            <div className="myPage">
                <div className="myPageWrapper gameScreen">
                    <div className="custom-container">
                        {aceTestData?.isTestValid && !aceTestData?.isLoading && aceGameView()}
                        {!aceTestData?.isTestValid && !aceTestData?.isLoading && invalidGamePlayView()}
                        {aceTestData?.isLoading && loaderView()}
                    </div>
                </div>
            </div>
        </div>
        {navigationData.showACEGameEndModal ? <ACEGameEndConfirmationModal onGoBack={() => onGoBackModal()} onSubmitGame={() => endACEGame()} /> : null}
        {navigationData.showACEQuizTimeUpModal ? <ACEGameTimeUpModal onTimerEnds={() => handleTimerCompleted()} /> : null}
        </Fragment>
    );
}
