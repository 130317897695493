import { Strings } from "resources";
/**
 * ValidationClass
 * @description Form Fields Validation Class
 */

class ValidationClass {
  errors = [];

  validate = (data) => {
    let newData = [];

    for (let item of data) {
      let { value, name, rules, type, ignore } = item;

      if (type && type === "text") value = value.trim();

      if (ignore) newData.push(item);
      else {
        item.errors = [];

        rules = rules.split("|");

        for (let rule of rules) {
          rule = rule.trim();
          let validationRules = rule.split(":");

          let error = this.validateData(
            value,
            name,
            validationRules[0],
            validationRules[1],
            data
          );

          if (error) {
            item.errors.push(error);
          }
        }

        newData.push(item);
      }
    }

    return newData;
  };

  messages = (attribute, rule, ruleData) => {
    switch (rule) {
      case "required":
        return `${attribute} ${Strings.isRequired}`;
      case "email":
        return `${attribute} ${Strings.isNotValid}`;
      case "us_phone_number":
        return `${attribute} ${Strings.should_be_valid_US_number}`;
      case "alpha":
        return `${attribute} ${Strings.should_only_consist_of_alpha_char}`;
      case "max":
        return `${attribute} ${Strings.may_not_greater_than} ${ruleData} ${Strings.characters}`;
      case "min":
        return `${attribute} ${Strings.must_be_at_least} ${ruleData} ${Strings.characters}.`;
      case "arrayMax":
        return `${attribute} ${Strings.total_may_not_greater_than} ${ruleData}.`;
      case "arrayMin":
        return `${attribute} ${Strings.total_must_be_least_of_total} ${ruleData}.`;
      case "numeric":
        return `${attribute} ${Strings.should_only_consist_numeric_char}`;
      case "no_space":
        return `${attribute} ${Strings.should_not_have_any_space_in_between}`;
      case "match_index":
        return `${attribute} ${Strings.does_not_match_with} ${ruleData}.`;
      default:
        return;
    }
  };

  validateData = (field, name, rule, ruleData, allFields) => {
    switch (rule) {
      case "required":
        return this.fieldIsRequired(field, name, rule);
      case "email":
        return this.fieldIsEmail(field, name, rule);
      case "us_phone_number":
        return this.USPhoneNumber(field, name, rule);
      case "alpha":
        return this.fieldIsAlpha(field, name, rule);
      case "max":
        return this.fieldMax(field, name, rule, ruleData);
      case "min":
        return this.fieldMin(field, name, rule, ruleData);
      case "arrayMax":
        return this.fieldMax(field, name, rule, ruleData);
      case "arrayMin":
        return this.fieldMin(field, name, rule, ruleData);
      case "numeric":
        return this.isNumeric(field, name, rule);
      case "no_space":
        return this.noSpace(field, name, rule);
      case "match_index":
        return this.matchIndex(field, name, rule, ruleData, allFields);
      default:
        break;
    }
  };

  fieldIsRequired = (field, name, rule) => {
    if (field == null || field === undefined) return this.messages(name, rule);
    else if (typeof field == "object" && Object.keys(field).length === 0) {
      return this.messages(name, rule);
    } else if (!field) {
      return this.messages(name, rule);
    }
  };

  fieldIsEmail = (field, name, rule) => {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(field)) {
      return this.messages(name, rule);
    }
  };

  USPhoneNumber = (field, name, rule) => {
    let regex = /^1?\d{10}$/;

    if (!regex.test(field)) {
      return this.messages(name, rule);
    }
  };

  fieldIsAlpha = (field, name, rule) => {
    let regex = /^$|^[a-zA-Z ]+$/;

    if (!regex.test(field)) {
      return this.messages(name, rule);
    }
  };

  fieldMax = (field, name, rule, ruleData) => {
    if (field.length > parseInt(ruleData)) {
      return this.messages(name, rule, ruleData);
    }
  };

  fieldMin = (field, name, rule, ruleData) => {
    if (field.length < parseInt(ruleData)) {
      return this.messages(name, rule, ruleData);
    }
  };

  isNumeric = (field, name, rule) => {
    let isNumeric = !isNaN(parseFloat(field)) && isFinite(field);

    if (!isNumeric) {
      return this.messages(name, rule);
    }
  };

  noSpace = (field, name, rule) => {
    let regex = /^$|^[^\s]+$/;

    if (!regex.test(field)) {
      return this.messages(name, rule);
    }
  };

  matchIndex = (field, name, rule, ruleData, allFields) => {
    let secondField = allFields[ruleData];

    if (secondField && field !== secondField.value)
      return this.messages(name, rule, secondField.name);
  };
}

export default new ValidationClass();
