import React, { Fragment, useState, useEffect } from "react";
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import firewordImage from 'assets/images/v2/landing/fireworks.png';
import giftCardImg from 'assets/images/v2/landing/giftcard.png';
import noOfferValidImg from 'assets/images/v2/landing/not_valid_for_you.png';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { useAlert } from "react-alert";
import md5 from 'md5';
import * as NavigationActions from "redux/actions/NavigationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    userLogin as userLoginAPI,
    addClaimOfferPoints as addClaimOfferPointsAPI,
    loginUserInApp,
    getUserId, 
    getUserToken,
    checkClaimOfferAvailable,
  } from "services/AuthService";

import "./ClaimOffer.scss";
import { Strings } from "resources";

export default function ClaimOffer(props) {
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const alert = useAlert();
  const userId = getUserId();
  const userToken = getUserToken();
  const {user_id, appKey} = useParams();
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAlreadyClaimed, setIsAlreadyClaimed] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isClaimedSuccess, setIsClaimedSuccess] = useState(false);
  const [isValidOffer, setIsValidOffer] = useState(true);
  const [isRequestValid, setRequestValid] = useState(true);

  const [fields, setFields] = useState([
    {
      name: Strings.email_address,
      key: "email",
      value: "",
      type: "text",
      maxLength: 50,
      rules: "required|email|max:50",
      touched: false,
      icon: false,
      placeholder: Strings.email_address_placeholder
    },
    {
      name: Strings.password,
      key: "password",
      value: "",
      type: 'text',
      maxLength: 15,
      rules: 'required|no_space|min:4|max:15',
      touched: false,
      secure: true,
      icon: true,
      placeholder: Strings.password_placeholder
    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

   /* Claim offer Validate */
   useEffect(() => {
    async function checkClaimOffer(){
        const { data } = await checkClaimOfferAvailable(user_id);
        if (data.status && data.message === 'offer_already_claimed_by_you') {
            setIsAlreadyClaimed(true);
        } else if (data.status && data.message === 'offer_not_valid_for_you') {
            setIsValidOffer(false);
        }
    }
    checkClaimOffer();
  }, [user_id]);

  useEffect(() => {
    async function setClainOfferValidRequest(){
      const key = user_id+'BharatCETSampark';
      let encodedKeyVal = md5(key);
      if(encodedKeyVal !== appKey) {
        setRequestValid(false);
      }
    }
    setClainOfferValidRequest();
  }, [user_id, appKey]);

  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Handle Field Update Action
   */
   const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description Check Errors
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Fields
   */
  const renderField = (field, index) => {
    return (
      <Input
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated(event.target.value, index)}
        onBlur={(event) => fieldUpdated(event.target.value, index)}
        key={index}
        touched={field.touched}
        value={field.value}
        errorMessage={
          field.errors && field.errors.length ? field.errors[0] : ""
        }
        secure={field.secure}
        icon={field.icon}
        iconPressed={() => iconShowPressed(field.secure, index)}
      />
    );
  };

  const iconShowPressed = (isSecure, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].secure = !isSecure;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
  }

  /**
   * submit
   * @param {*} event
   * @description User Login Action Submit
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    userLogin(getPayload(fields));
  };

  /**
   * userLogin
   * @param {*} payload
   * @description User Login Action
   */
  const userLogin = async (payload) => {
    setLoadingState(true);
    const { data } = await userLoginAPI(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return userLoginSuccess(data.data);
    }
  };

  /**
   * userLoginSuccess
   * @param {*} userData
   * @description User Login Success
   */
  const userLoginSuccess = (userData) => {
    const user = {
      token: userData.token ? userData.token : "",
      user: userData ? userData : {},
      role: userData ? userData.account_name : '',
    };
    loginUser(user);
  };

  /**
   * loginUser
   * @param {*} user
   * @description Login User Success Action
   */
  const loginUser = (user) => {
    const { error } = loginUserInApp(user);
    if (!error) {
      alert.success(Strings.login_success);
      setIsLoggedIn(true);      
    }
    return true;
  };

  /**
   * getPayload
   * @param {*} fields
   * @description Get PayLoad
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  /**
   * claimMyOffer
   * @description 50 ACE Point offer claim
   */
  const claimMyOffer = async () => {
    const key = user_id+'BharatCETSampark';
    let encodedKeyVal = md5(key);
    if(userId === user_id && encodedKeyVal === appKey) {
        const payload = {
            'user_id': userId,
            'token': userToken
          }
        const { data } = await addClaimOfferPointsAPI(payload);
        if(data.status) {
            setIsClaimed(true);
            setIsClaimedSuccess(true);
        }
    } else {
      setRequestValid(false);
    }
  }

  const goToHome = () => {
    history.push(`/`);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer claimOffer dispersionLogo">
            <div className="pageScreen">
               
                {isLoggedIn && !isAlreadyClaimed && !isClaimed && !isClaimedSuccess && isValidOffer && isRequestValid  && <div className="loginOnClaim">
                  <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <img className="nfLogo celebrationIcon" src={giftCardImg} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <span className="claim-info">{Strings.click_below_button_to_go_ace_point_bonus}</span>
                  <span className="claim-go" onClick={() => claimMyOffer()}>{Strings.claimOffer}</span>
                </div>}

                {isRequestValid && isLoggedIn && !isValidOffer && <div className="loginOnClaim">
                  <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <img className="nfLogo celebrationIcon" src={noOfferValidImg} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <span className="claim-info">{Strings.offer_is_not_valid_for_you_now}</span>
                  <span className="claim-go" onClick={() => goToHome()}>{Strings.goToHome}</span>
                </div>}

                {isRequestValid && isLoggedIn && isAlreadyClaimed && !isClaimed && <div className="loginOnClaim">
                  <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <img className="nfLogo celebrationIcon" src={firewordImage} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <span className="claim-info">{Strings.you_have_already_claimed_50_bonus_ace_points}</span>
                  <span className="claim-go" onClick={() => goToHome()}>{Strings.goToHome}</span>
                </div>} 

                {isRequestValid && isLoggedIn && isClaimed && isClaimedSuccess && <div className="loginOnClaim">
                  <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <img className="nfLogo celebrationIcon" src={firewordImage} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <span className="claim-info">{Strings.fifty_ace_points_claimed_successfully}</span>
                  <span className="claim-go" onClick={() => goToHome()}>{Strings.goToHome}</span>
                </div>}

                {isRequestValid && isLoggedIn === false && !isClaimed && (
                    <div className="loginOnClaim">
                        <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                        <h3 className="modal-heading"><span className="lTab Active">{Strings.login}</span></h3>
                        <form onSubmit={(event) => submit(event)}>
                        {fields.map((field, index) => renderField(field, index))}
                        <Button disabled={hasErrors} loading={isLoading} btnType="Orange">{Strings.login}</Button>
                        </form>
                    </div>  
                )}

                {!isRequestValid &&
                <div className="loginOnClaim">
                  <img className="nfLogo" src={BharatCETLogo} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <img className="nfLogo celebrationIcon" src={noOfferValidImg} alt={Strings.bharatCET} title={Strings.bharatCET} />
                  <span className="claim-info">{Strings.opps_not_valid_request}</span>
                  <span className="claim-go" onClick={() => goToHome()}>{Strings.goToHome}</span>
                </div>}

            </div>
        </div>
    </Fragment>
  );
}
