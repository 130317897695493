const Calendar = [
    {
        year: 2020,
        langauge: "english"
    },
    {
        year: 2019,
        langauge: "english"
    },
    {
        year: 2018,
        langauge: "english"
    },
    {
        year: 2017,
        langauge: "english"
    },
    {
        year: 2016,
        langauge: "english"
    }, {
        year: 2015,
        langauge: "english"
    },
    {
        year: 2014,
        langauge: "english"
    },
    {
        year: 2013,
        langauge: "english"
    },
    {
        year: 2012,
        langauge: "english"
    },
    {
        year: 2011,
        langauge: "english"
    },
    {
        year: 2020,
        langauge: "हिन्दी"
    },
    {
        year: 2019,
        langauge: "हिन्दी"
    }, {
        year: 2018,
        langauge: "हिन्दी"
    },
    {
        year: 2017,
        langauge: "हिन्दी"
    },
    {
        year: 2016,
        langauge: "हिन्दी"
    }
]

export default Calendar