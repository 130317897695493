import { combineReducers } from "redux";

import AccountReducers from "./AccountReducers";
import UserReducers from "./UserReducers";
import NavigationReducers from "./NavigationReducers";
import LibraryReducers from "./LibraryReducer";
import CourseReducers from "./CourseReducer";
import PaymentReducers from "./PaymentReducers";
import ReferReducers from "./ReferReducers";
import TopicReducers from "./TopicReducers";
import ChatReducers from "./ChatReducers";
import SubjectTestReducers from "./SubjectTestReducers";
import AceTestReducers from "./AceTestReducers";
import NotificationReducers from "./NotificationReducers";

const appReducer = combineReducers({
  accountData: AccountReducers,
  userData: UserReducers,
  navigationData: NavigationReducers,
  libraryData: LibraryReducers,
  courseData: CourseReducers,
  paymentData: PaymentReducers,
  referData: ReferReducers,
  topicData: TopicReducers,
  chatData: ChatReducers,
  subjectTestData: SubjectTestReducers,
  aceTestData: AceTestReducers,
  notificationData: NotificationReducers
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
