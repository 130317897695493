import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getInvitedFriends
 * @param {*} tokenId string
 * @param {*} examId number
 * @param {*} pageSize number
 * @param {*} pageNo number
 * @description Get List of Invited Friends List
 */
export async function getInvitedFriends(tokenId, userId, pageSize, pageNo) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'limit': pageSize,
        'page': pageNo,
    };
    try {
        const response = await appClient.post(apiConfig.refer.getInvitedFriends, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * inviteFriend
 * @param {string} tokenId
 * @param {string} userId
 * @param {string} type
 * @param {string} email
 * @param {string} mobileNo
 * @description Invite Friend API
 */
export async function inviteFriend(tokenId, userId, type, email, mobileNo) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'type': type,
        'email': email,
        'mobile_no': mobileNo,
    };
    try {
        const response = await appClient.post(apiConfig.refer.inviteFriend, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * remindInvitedFriend
 * @param {*} tokenId
 * @param {*} userId
 * @param {*} mobileNo
 * @description Remind Invited Friend
 */
export async function remindInvitedFriend(tokenId, userId, mobileNo) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'mobile': mobileNo,
    };
    try {
        const response = await appClient.post(apiConfig.refer.remindInvitedFriend, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getMyRedemptionHistory
 * @param {string} tokenId
 * @param {string} userId
 * @param {string} pageSize
 * @param {string} pageNo
 * @description Get My Redemption History
 */
export async function getMyRedemptionHistory(tokenId, userId, pageSize, pageNo) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'limit': pageSize,
        'page': pageNo,
    };
    try {
        const response = await appClient.post(apiConfig.refer.getRedemptionHistory, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * applyForRedemption
 * @param {string} tokenId
 * @param {string} userId
 * @param {string} amount
 * @param {string} accountNumber
 * @param {string} accountName
 * @param {string} ifscCode
 * @param {string} bankName
 * @param {string} accountType
 * @description Apply For Redemptions
 */
export async function applyForRedemption(tokenId, userId, amount, accountNumber, accountName, ifscCode, bankName, accountType) {
    const payload = {
        'token': tokenId,
        'user_id': userId,
        'amount': amount,
        'bank_account_no': accountNumber,
        'bank_account_name': accountName,
        'ifsc_code': ifscCode,
        'bank_name': bankName,
        'account_type': accountType,
    };
    try {
        const response = await appClient.post(apiConfig.refer.applyRedemption, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}
