import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getNotifications
 * @param {*} tokenId string
 * @param {*} userId number
 * @param {*} type string
 * @description Get Notification Listing
 */
export async function getNotifications(tokenId, userId, type) {
    const payload = {
        'user_id': userId,
        'token': tokenId,
        'type': type
    };
    try {
        const response = await appClient.post(apiConfig.notifications.getNotification, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * updateNotificationStatus
 * @param {*} userId number
 * @param {*} notiId number
 * @param {*} type string
 * @description Update notification status
 */
 export async function updateNotificationStatus(userId, userToken, notiId, type) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        'noti_id': notiId,
        'is_read': type
    };
    try {
        const response = await appClient.post(apiConfig.notifications.updateNotification, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

