export function hasSuccess(data) {
  if (
    data &&
    typeof data.isException !== "undefined" &&
    data.isException === true
  ) {
    return {
      data,
      error: data.exceptionMessage
        ? data.exceptionMessage
        : "Something went wrong!",
      status: 0,
    };
  }

  return {
    data: data.responseData ? data.responseData : data,
    error: false,
    status: 200,
  };
}

export function hasError(err) {
  if (err === "Network Error") {
    return {
      data: null,
      error: "Please check your internet connection.",
      status: 0,
    };
  }

  const error = err.data ? err.data : err;

  return {
    data: null,
    error: error.message ? error.message : "Something went wrong.",
    status: error.response && error.response.status ? error.response.status : 0,
  };
}
