import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { setPageTitle } from "utils";
import { Strings } from 'resources';
import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import queryString from 'query-string';
import backLinkIcon from "assets/images/bottom/back.svg";
import chatLinkIcon from "assets/images/bottom/chat.svg";
import homeLinkIcon from "assets/images/bottom/home.svg";
import "./AuthLayout.scss";

export default function AuthLayoutWithoutAuth({
  component: Component,
  pageTitle,
  ...rest
}) {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const urlParams = queryString.parse(location.search);

  const setUserLanguage = async (language) => {
    dispatch(AccountActions.setUserLanguage(language));
    Strings.setLanguage(language);
  }

  useEffect(() => {
    if (urlParams?.lang === 'hi') {
      setUserLanguage(urlParams?.lang);
    }
  }, []);

  /* Set Page Title */
  useEffect(() => {
    setPageTitle(pageTitle ? pageTitle : "");
  }, [pageTitle]);

  const goToHome = () => {
    history.push("/");
  }

  const goToChat = () => {
    history.push("/quill-it");
  }

  const goToBack = () => {
    history.goBack();
  }

  return(
        <Route
          {...rest}
          render={(props) =>
            (<>
              <Component {...props} />
              <div className="WithoutAuthFooterWrapper">
                <div className="footerNavigationBar">
                  <span className="nItem" onClick={() => goToChat()}>
                    <img className="nIcon" src={chatLinkIcon} alt="Refer" title="Refer" />
                  </span>
                  <span className="nItem between" onClick={() => goToHome()}>
                    <img className="nIcon" src={homeLinkIcon} alt="Refer" title="Refer" />
                  </span>
                  <span className="nItem" onClick={() => goToBack()}>
                    <img className="nIcon" src={backLinkIcon} alt="Refer" title="Refer" />
                  </span>
                </div>
              </div>
            </>)
          }
        />
  );
}