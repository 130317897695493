import * as actions from "redux/action-types";
import {
    getInvitedFriends as getInvitedFriendsFromAPI,
} from "services/ReferService";
import { Strings } from "resources";
import { CancelToken } from "axios";

/**
 * getInvitedFriendsLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function getInvitedFriendsLoadingState(isLoading) {
    return {
        type: actions.LOADING_REFERRAL_DATA,
        isLoading,
    };
}

/**
 * addInvitedFriendData
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Invited Friend Data
 */
export function addInvitedFriendData(items, hasMore, page) {
    return {
        type: actions.ADD_REFERRAL_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setInvitedFriendCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setInvitedFriendCancelToken(token) {
    return {
        type: actions.SET_REFERRAL_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreInvitedFriends
 * @description check If more files are there or not
 */
export function hasNoMoreInvitedFriends() {
    return {
        type: actions.SET_REFERRAL_HAS_NO_MORE,
    };
}

export function setReferralAmount(amount) {
    return {
        type: actions.SET_REFERRAL_AMOUNT,
        amount: amount
    };
}

export function clearReferralAmount() {
    return {
        type: actions.CLEAR_REFERRAL_AMOUNT
    };
}


/**
 * getInvitedFriendsData
 * @param {boolean} reset
 * @param {string} examId
 * @param {string} tokenId
 * @description Get Invited Friends Data with Pagination
 */
export function getInvitedFriendsData(reset, userId, tokenId) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.referData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setInvitedFriendCancelToken(newRequestSource));
            await dispatch(addInvitedFriendData([], true, 0));
        }

        await dispatch(getInvitedFriendsLoadingState(true));

        let { referData } = await getState();
        let { page, limit, items, cancelRequest } = referData;
        const { data, error } = await getInvitedFriendsFromAPI(tokenId, userId, limit, page);
        if (!error && data.status && Array.isArray(data.data.history) && data.data.history.length) {
            dispatch(setReferralAmount(data.data.total_amount));
            dispatch(addInvitedFriendData(mergeData(items, data.data.history), true, page + 1));
            if (data.data.history.length < limit) {
                dispatch(hasNoMoreInvitedFriends());
            }
        } else {
            dispatch(hasNoMoreInvitedFriends());
        }

        dispatch(getInvitedFriendsLoadingState(false));
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.iu_id) {
            let index = oldData.findIndex(data => (data.iu_id && data.iu_id === newDataItem.iu_id));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
}
