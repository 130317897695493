import React, { useEffect, Fragment, useState } from "react";
import { log } from "utils";
import { useSelector, useDispatch } from "react-redux";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as ReferActions from "redux/actions/ReferActions";
import { getUserId, getUserToken } from "services/AuthService";
import { inviteFriend as inviteFriendAPI, remindInvitedFriend as remindInvitedFriendFromAPI } from "services/ReferService";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { Strings } from 'resources';
import SmallButton from "components/UI/SmallButton";
import copy from 'copy-to-clipboard';
import moment from 'moment';
import "./ReferAFriend.scss";
import ReferralConfirmation from "modals/ReferralConfirmation";
import ReferralConfirmationSuccess from "modals/ReferralConfirmationSuccess";
import FeatureNotAvailable from "modals/FeatureNotAvailable";

export default function ReferAFriend(props) {
  const alert = useAlert();
  const accountData = useSelector((state) => state.accountData);
  const referData = useSelector((state) => state.referData);
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const [contact, setContact] = useState('');
  const [isLoading, setLoadingState] = useState(false);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get Invited Friends Data */
  useEffect(() => {
    dispatch(ReferActions.getInvitedFriendsData(true, userId, userToken));
  }, [dispatch, userId, userToken]);

  /**
   * fetchMoreData
   * @description Fetch More Invited Friends Data
   */
  const fetchMoreData = () => {
    dispatch(ReferActions.getInvitedFriendsData(false, userId, userToken));
  }

  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const showReferralConfirmationPopUp = async () => {
    const status = checkACEGameExpireModal();
    if (+status === 4) {
      if (referData.amount === 0.00 || referData.amount === '0' || referData.amount === '0.00') {
        alert.error('You have no amount available for redeem');
      } else {
        dispatch(NavigationActions.setReferralConfirmationModal());
      }
    } else {
      showFeatureNotAvailableModal();
    }
  }

  const closeReferralConfirmationPopUp = async () => {
    dispatch(NavigationActions.closeReferralConfirmationModal());
  }

  const copyToClipboard = () => {
    copy(accountData?.account?.user_code);
    alert.success(Strings.copyToClipboard);
  };

/**
 * referralItem
 * @param {object} item
 * @param {number} index
 * @description Library Files Individual Item
 */
const referralItem = (item, index) => {
   const createdOn = moment(item.iu_created_on).format('DD/MM/YYYY hh:mm:ss a');
   const userStatus = item.iu_user_status === 'subscribed' ? Strings.strSubscribed : Strings.strNotSubscribed;
   const codeStatus = item.iu_status === 'used' ? Strings.strUsed : Strings.strNotUsed;
   let actionStatus = Strings.remindStatus;
   let actionStatusClass = 'remind';
   if (item.iu_user_status === 'subscribed' && item.iu_status === 'used') {
    actionStatus = Strings.earnedStatus;
    actionStatusClass = 'earned';
   } else if (item.iu_user_status === 'subscribed' && item.iu_status === 'pending') {
    actionStatus = '-';
   }

    return (<tr key={`referral-${index}`}>
        <td>{+index+1}</td>
        <td>{item.iu_to_mobile ? item.iu_to_mobile : item.iu_to_mobile}</td>
        <td>{createdOn}</td>
        <td>{userStatus}</td>
        <td>{codeStatus}</td>
        {accountData?.account?.user_type !== 'professor' && <td>{item.iu_potential_earnings}</td>}
        <td><span className={`referAction ${actionStatusClass}`} onClick={() => remindInviteFriendRequest(item.iu_to_mobile, actionStatus)}>{actionStatus}</span></td>
      </tr>);
}

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validatePhoneNumber = (contact) => {
    const re = /^[0-9]{10}$/im;
    return re.test(contact);
}

const setInviteRequest = async () => {
  const status = checkACEGameExpireModal();
    if (+status === 4 || +status === 2) {
      if (contact === '' || contact === null) {
        alert.error(Strings.contactIsRequired);
        return log(Strings.contactIsRequired);
      }
    
      if (!validatePhoneNumber(contact)) {
        alert.error(Strings.enterValidMobileNumber);
        return log(Strings.enterValidMobileNumber);
      }
    
      setLoadingState(true);
      const contactType = validateEmail(contact) ? 'email' : 'mobile';
      const contactEmail = validateEmail(contact) ? contact : '';
      const contactNumber = validateEmail(contact) ? '' : contact;
      const { data } = await inviteFriendAPI(userToken, userId, contactType, contactEmail, contactNumber);
      setLoadingState(false);
      if (!data.status) {
        setContact('');
        alert.error(Strings[data.message]);
        return log(data.message);
      } else {
        return onInviteRequestSuccess(data.message);
      }
    } else {
      showFeatureNotAvailableModal();
    }
}

const onInviteRequestSuccess = (message) => {
  alert.success(Strings[message]);
  setContact('');
  dispatch(ReferActions.getInvitedFriendsData(true, userId, userToken));
}

const remindInviteFriendRequest = async (contactNumber, status) => {
  const expireStatus = checkACEGameExpireModal();
  if (+expireStatus === 4 || +expireStatus === 2) {
    if (status === 'earned') {
      return false;
    }
  
    setLoadingState(true);
    const { data } = await remindInvitedFriendFromAPI(userToken, userId, contactNumber);
    setLoadingState(false);
    if (!data.status) {
      setContact('');
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return onInviteRemindRequestSuccess(data.message);
    }
  } else {
    showFeatureNotAvailableModal();
  }
}

const onInviteRemindRequestSuccess = (message) => {
  alert.success(Strings[message]);
}

const showFeatureNotAvailableModal = () => {
  dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
}

const hideFeatureNotAvailableModal = () => {
  dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
}

const onChangeMobileNumber = e => {
  const pattern = /^[0-9\b]+$/;
  if (e.target.value === '' || pattern.test(e.target.value)) {
     setContact(e.target.value);
  }
}

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitleBox">
              <div className="titleBox">
                <h1>{Strings.referTitle}</h1>
              </div>
              <div className="codeBox">
                <span className="codeTitle">{Strings.your_referral_code}</span>
                <span className="codeText">{accountData?.account?.user_code}</span>
                <span className="codeCopy" onClick={() => copyToClipboard()}><FontAwesomeIcon icon={faClone} size="lg" className="codeCopyIcon" /></span>
              </div>
            </div>

            <div className="inviteBox">
              <div className="inviteUser">
                <div className="inviteUserInputTitle"><span>{Strings.invite}</span></div>
                <div className="inviteUserInputBox">
                  <input className="inputElement" type="text" value={contact} placeholder={Strings.enterFriendMobileNumber} onChange={(e) => { onChangeMobileNumber(e); }} />
                  <SmallButton className="inputButton" disabled={contact === ''} loading={isLoading} btnType="Orange" btnHeight="h-30" clicked={() => setInviteRequest()}>{Strings.invite}</SmallButton>
                </div>
              </div>
              <div className="availableRewards">
              {accountData?.account?.user_type !== 'professor' && <span className="rewardsTitle">{Strings.available_rewards}</span>}
              {accountData?.account?.user_type !== 'professor' && <>
                <span className="rewardsAmount">&#x20B9; {referData?.amount?.toFixed(2)}</span>
                <button className="rewardsEncashButton" onClick={() => showReferralConfirmationPopUp()}>{Strings.encash}</button>
                </>}
              </div>
            </div>

            <div className="myPageWrapper myReferralHistory">
              <div className="referralSectionTitle">
                <h2>{Strings.invitedFriends}</h2>
              </div>
              <div className="referralSectionHistory">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{Strings.mobileNo}</th>
                        <th>{Strings.invitedOn}</th>
                        <th>{Strings.subscriptionStatus}</th>
                        <th>{Strings.codeStatus}</th>
                        {accountData?.account?.user_type !== 'professor' && <th>{Strings.youEarned}</th>}
                        <th>{Strings.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {referData.items.length === 0 && !referData.isLoading &&
                      <tr>
                        <td colSpan="7" align="center">{Strings.not_invited_yet_start_inviting_and_earn_points}</td>
                      </tr>
                    }
                    {referData.items.length === 0 && referData.isLoading &&
                        <tr>
                          <td colSpan="7" align="center">{Strings.loading_invited_friend}</td>
                        </tr>
                    }
                    {referData.items.length > 0 &&
                      referData.items.map((item, index) => referralItem(item, index))
                    }
                    {referData.items.length > 0 && referData.hasMore && <tr>
                          <td colSpan="7" align="center">
                            <SmallButton clicked={() => fetchMoreData()} loading={referData.isLoading} btnType="Orange">{Strings.loadMore}</SmallButton>
                          </td>
                        </tr>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </div>
      {navigationData.showReferralConfirmation ? <ReferralConfirmation /> : null}
      {navigationData.showReferralConfirmationStatusModal ? <ReferralConfirmationSuccess /> : null}
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
    </Fragment>
  );
}
