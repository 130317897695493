import { log } from "utils";
import * as ChatActions from "redux/actions/ChatActions";
import store from "redux/store";

/**
 * getReducerTypeMain
 * @param {object} state
 * @description Get Main Reducer Type
 */
export const getReducerTypeMain = (state) => {
    let reducerTypeField = "chatData";
    return state[reducerTypeField];
};

/**
 * findChatWithKey
 * @param {*} id
 * @param {*} chatData
 * @param {*} chatType
 * @param {*} key
 * @description Find Chat with Key
 */
const findChatWithKey = (id, chatData, key) => {
    log('findChatWithKey', id, chatData, key);
    let index = -1;
    if (chatData.items.length > 0) {
        index = chatData.items.findIndex((item) => item[key] === id);
    }
    return { index };
};

/**
 * addChatMessageData
 * @param {*} chatId
 * @param {*} chatType
 * @param {*} chatDetails
 * @description Add Chat Message Data Inserted by Other User
 */
export const addChatMessageData = (
    chatId,
    chatType,
    chatDetails,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index >= 0) return;
    store.dispatch(
        ChatActions.addChatMessage(
        index,
        chatDetails,
        chatType,
        chatId
      )
    );
  };

  /**
   * addChatCommentData
   * @param {*} chatId
   * @param {*} commentId
   * @param {*} commentDetails
   * @description Add Chat Comment Data Inserted by Other User
   */
  export const addChatCommentData = (
    chatId,
    commentId,
    commentDetails,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index < 0) return;
    store.dispatch(
        ChatActions.addChatCommentData(
        index,
        chatId,
        commentId,
        commentDetails
      )
    );
  };

  /**
   * addQuestionLikeCount
   * @param {*} chatId
   * @param {*} likeCount
   * @description Add Question Like Count
   */
  export const addQuestionLikeCount = (
    chatId,
    likeCount,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index < 0) return;
    store.dispatch(
      ChatActions.updateMessageLike(
        index,
        chatId,
        likeCount,
        'liked',
      )
    );
  };

  /**
   * subTractQuestionLikeCount
   * @param {*} chatId
   * @param {*} likeCount
   * @description Subtract Question Like Count
   */
  export const subTractQuestionLikeCount = (
    chatId,
    likeCount,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index < 0) return;
    store.dispatch(
      ChatActions.updateMessageUnlike(
        index,
        chatId,
        likeCount,
        'unliked',
      )
    );
  };

  export const hideChatMessage = (
    chatId,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index < 0) return;
    store.dispatch(
      ChatActions.hideMessageFromChat(
        index,
        chatId,
      )
    );
  };

  export const hideChatComment = (
    chatId,
    commentId,
  ) => {
    let chatData = getReducerTypeMain(store.getState());
    const { index } = findChatWithKey(chatId, chatData, "id");
    if (index < 0) return;
    store.dispatch(
      ChatActions.hideCommentFromChat(
        index,
        chatId,
        commentId
      )
    );
  };
