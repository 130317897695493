import React, { useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as NavigationActions from "redux/actions/NavigationActions";
import "./LeftBar.scss";
import { log } from "utils";
import navIcon from "assets/images/navigation/nav.svg";
import BharatCETLogoMenu from 'assets/images/logo/logoMenuBeta.png';

import homeIcon from "assets/images/navigation/home.svg";
import dashiconIcon from "assets/images/navigation/dashicon.svg";
import calenderIcon from "assets/images/navigation/calender.svg";
import referIcon from "assets/images/navigation/refer.svg";
import rewardsIcon from "assets/images/navigation/rewards.svg";
import settingIcon from "assets/images/navigation/setting.svg";
import testIcon from "assets/images/navigation/test.svg";
import thumbUpIcon from "assets/images/navigation/thumb-up.svg";
import aceIcon from "assets/images/navigation/ace.svg";

import homeHoverIcon from "assets/images/navigation/hover/home.svg";
import dashiconHoverIcon from "assets/images/navigation/hover/dashicon.svg";
import calenderHoverIcon from "assets/images/navigation/hover/calender.svg";
import referHoverIcon from "assets/images/navigation/hover/refer.svg";
import rewardsHoverIcon from "assets/images/navigation/hover/rewards.svg";
import settingHoverIcon from "assets/images/navigation/hover/setting.svg";
import testHoverIcon from "assets/images/navigation/hover/test.svg";
import thumbUpHoverIcon from "assets/images/navigation/hover/thumb-up.svg";
import aceHoverIcon from "assets/images/navigation/hover/ace.svg";

import FeatureNotAvailable from "modals/FeatureNotAvailable";
import { Strings } from "resources";

export default function LeftBar(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  let isHomeURL = location?.pathname?.includes("home");
  let isDashboardURL = location?.pathname?.includes("dashboard");
  let isLibraryURL = location?.pathname?.includes("library");
  let isLeaderBoardURL = location?.pathname?.includes("leader-board");
  let isReferFriendURL = location?.pathname?.includes("refer-a-friend");
  let isAceGameHistoryURL = location?.pathname?.includes("ace-game-history");
  let isMyProfileURL = location?.pathname?.includes("my-profile");
  let isContactUsURL = location?.pathname?.includes("contact-us");
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]); 

  const hideLeftSidebar = () => {
    dispatch(NavigationActions.toggleLeftSidebar(true));
  };

  const toggleLeftSideBar = () => {
    dispatch(NavigationActions.toggleLeftSidebar(!navigationData.hideLeftSidebar));
  }

  const goToHome = () => {
    hideLeftSidebar();
    history.push(`/home/`);
  };

  const goToDashboard = () => {
    hideLeftSidebar();
    history.push(`/dashboard/`);
  };

  const goToLibrary = () => {
    hideLeftSidebar();
    history.push(`/library/banking/`);
  };

  const goToScoreCard = () => {
    hideLeftSidebar();
    history.push(`/leader-board/`);
  };

  const goToRefer = () => {
    hideLeftSidebar();
    history.push(`/refer-a-friend/`);
  };

  const goToUpcomingEvents = () => {
    hideLeftSidebar();
    history.push(`/events/`);
  };

  const goToSettings = () => {
    hideLeftSidebar();
    history.push(`/my-profile/`);
  };

  const goToContactUs = () => {
    hideLeftSidebar();
    history.push(`/contact-us`);
  };

  const goToPastACEGameHistory = () => {
    hideLeftSidebar();
    history.push("/ace-game-history/");
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  const handleMouseOverAndOut = (e, isScreenURL, overIcon, outIcon, isOutOrOver) => {
    if(isScreenURL){
      if(isOutOrOver === 'over') {
        return e.currentTarget.src = overIcon ;
      } else {
        return e.currentTarget.src = overIcon ;
      }
    } else {
      if(isOutOrOver === 'over') {
        return e.currentTarget.src = overIcon ;
      } else {
        return e.currentTarget.src = outIcon ;
      }
    }
  }

  return (<>
    <div
      className={`leftSideMenu fadeIn ${
        !navigationData.hideLeftSidebar ? "open" : "hide"
      }`}
    >
      <div className="hamburgerIcon">
        {navigationData.hideLeftSidebar && <img className="hamburgerImage" src={navIcon} title="BharatCET" alt="BharatCET" onClick={() => toggleLeftSideBar()} />}
        {!navigationData.hideLeftSidebar && <div className="closeIconWrapper">
          <FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" onClick={() => toggleLeftSideBar()} />
          <img className="bharatCETIcon" src={BharatCETLogoMenu} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
        </div>}
      </div>

      <ul className="sideMenusContainer">
        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isHomeURL ? homeHoverIcon : homeIcon} title={Strings.home} alt={Strings.home}
          onClick={() => goToHome()}
          onMouseOver={e => handleMouseOverAndOut(e, isHomeURL, homeHoverIcon, homeIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isHomeURL, homeHoverIcon, homeIcon, 'out')} />
          <span onClick={() => goToHome()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isHomeURL ? 'active' : ''}`}>{Strings.home}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isDashboardURL ? dashiconHoverIcon : dashiconIcon} title={Strings.dashboard} alt={Strings.dashboard}
          onClick={() => goToDashboard()}
          onMouseOver={e => handleMouseOverAndOut(e, isDashboardURL, dashiconHoverIcon, dashiconIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isDashboardURL, dashiconHoverIcon, dashiconIcon, 'out')} />
          <span onClick={() => goToDashboard()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isDashboardURL ? 'active' : ''}`}>{Strings.dashboard}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isLibraryURL ? testHoverIcon : testIcon} title={Strings.library} alt={Strings.library}
          onClick={() => goToLibrary()}
          onMouseOver={e => handleMouseOverAndOut(e, isLibraryURL, testHoverIcon, homeIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isLibraryURL, testHoverIcon, testIcon, 'out')}
          />
          <span onClick={() => goToLibrary()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isLibraryURL ? 'active' : ''}`}>{Strings.library}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isLeaderBoardURL ? rewardsHoverIcon : rewardsIcon} title="Rewards" alt="Rewards"
          onClick={() => goToScoreCard()}
          onMouseOver={e => handleMouseOverAndOut(e, isLeaderBoardURL, rewardsHoverIcon, rewardsIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isLeaderBoardURL, rewardsHoverIcon, rewardsIcon, 'out')}
          />
          <span onClick={() => goToScoreCard()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isLeaderBoardURL ? 'active' : ''}`}>{Strings.scoreboard}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isReferFriendURL ? referHoverIcon : referIcon} title={Strings.refer} alt={Strings.refer}
          onClick={() => goToRefer()}
          onMouseOver={e => handleMouseOverAndOut(e, isReferFriendURL, referHoverIcon, referIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isReferFriendURL, referHoverIcon, referIcon, 'out')}
          />
          <span onClick={() => goToRefer()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isReferFriendURL ? 'active' : ''}`}>{Strings.refer}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isAceGameHistoryURL ? aceHoverIcon : aceIcon} title={Strings.ace_history} alt={Strings.ace_history}
          onClick={() => goToPastACEGameHistory()}
          onMouseOver={e => handleMouseOverAndOut(e, isAceGameHistoryURL, aceHoverIcon, aceIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isAceGameHistoryURL, aceHoverIcon, aceIcon, 'out')}
           />
          <span onClick={() => goToPastACEGameHistory()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isAceGameHistoryURL ? 'active' : ''}`}>{Strings.ace_game_history}</span>
        </li>

        {/*<li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={calenderIcon} title="Home" alt="Home"
          onClick={() => goToUpcomingEvents()}
          onMouseOver={e => (e.currentTarget.src = calenderHoverIcon)}
          onMouseOut={e => (e.currentTarget.src = calenderIcon)} />
          <span onClick={() => goToUpcomingEvents()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'}`}>Upcoming Events</span>
        </li>*/}

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isMyProfileURL ? settingHoverIcon : settingIcon} title={Strings.settings} alt={Strings.settings} onClick={() => goToSettings()}
          onMouseOver={e => handleMouseOverAndOut(e, isMyProfileURL, settingHoverIcon, settingIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isMyProfileURL, settingHoverIcon, settingIcon, 'out')} />
          <span onClick={() => goToSettings()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isMyProfileURL ? 'active' : ''}`}>{Strings.settings}</span>
        </li>

        <li className={`${!navigationData.hideLeftSidebar ? "show" : "hide"}`}>
          <img className="menuIcon" src={isContactUsURL ? thumbUpHoverIcon : thumbUpIcon} title={Strings.contactUs} alt={Strings.contactUs} onClick={() => goToContactUs()}
          onMouseOver={e => handleMouseOverAndOut(e, isContactUsURL, thumbUpHoverIcon, thumbUpIcon, 'over')}
          onMouseOut={e => handleMouseOverAndOut(e, isContactUsURL, thumbUpHoverIcon, thumbUpIcon, 'out')}
           />
          <span onClick={() => goToContactUs()} className={`menuTitle ${navigationData.hideLeftSidebar ? 'hide': 'show'} ${isContactUsURL ? 'active' : ''}`}>{Strings.contactUs}</span>
        </li>

      </ul>
    </div>
    <div className={`popUpMenuOverlay ${navigationData.hideLeftSidebar ? "closed" : "opened"}`} onClick={() => hideLeftSidebar()}></div>

    {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
  </>);
}
