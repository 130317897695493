import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as ChatActions from "redux/actions/ChatActions";
import userImage from "assets/images/page/featureImage.svg";
import { log } from "utils";
import { reportCommentUser } from "services/ChatService";
import { getUserId } from "services/AuthService";
import { useAlert } from "react-alert";
import "./ReportCommentUser.scss";

export default function ReportCommentUser(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const alert = useAlert();
  const dispatch = useDispatch();
  const userId = getUserId();
  const fullName = props?.item?.senderUser?.firstName + " " + props?.item?.senderUser?.lastName;
  const commentId = props?.item?.id;
  const commentUserId = props?.item?.senderUser?.uid;
  const [reportStatus, setReportStatus] = useState(false);

  /**
   * redirectToUpgrade
   * @description Redirect to Upgrade Screen
   */
  const goBack = () => {
    dispatch(NavigationActions.toggleReportCommentUserModal(false));
  }

  const reportChatCommentUser = async (reportedByUserId, commentId) => {
    setReportStatus(false);
    const { data, error } = await reportCommentUser(reportedByUserId.toString(), commentId.toString(), commentUserId.toString());
    if (error) {
      alert.error(error);
      return log(error);
    } else {
        setReportStatus(true);
        dispatch(ChatActions.getBannedUsers(userId));
      return false;
    }
  }

  return (<>
    <div
      className={`popupWrapper ReportCommentUser dispersionLogo ${
        !navigationData.showReportCommentUser ? "closed" : "opened"
      }`}
    >
      <span onClick={() => goBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
      <div className="popUpInner">
        <div className="featuredIconWrapper">
            <img className="featuredIcon" src={userImage} title={Strings.success} alt={Strings.success} />
        </div>
        {!reportStatus && <p>{Strings.are_you_sure_report_this_user} {fullName} {Strings.as_objectionable_comment_poster_user}</p>}
        {reportStatus && <p>{Strings.thanksForReporting} {fullName} {Strings.asObjectionableCommentPosterUser}</p>}
        {!reportStatus && <SmallButton clicked={() => reportChatCommentUser(userId, commentId)} btnType="Orange">{Strings.commentReport}</SmallButton>}
        {reportStatus && <SmallButton clicked={() => goBack()} btnType="Orange">{Strings.go_to_quillit}</SmallButton>}
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showReportCommentUser ? "closed" : "opened"}`}></div>
  </>
  );
}
