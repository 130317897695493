import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as TopicActions from "redux/actions/TopicActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import { useParams } from "react-router-dom";
import { getUserId, getUserToken } from "services/AuthService";
import { Strings } from 'resources';
import Chart from "react-apexcharts";
import YoutubeIcon from "assets/images/social/youtube.svg";
import TestIcon from "assets/images/page/test.svg";
import BooksIcon from "assets/images/page/books.svg";
import VideoNotAvailable from "modals/VideoNotAvailable";
import Select from 'react-select';
import { log } from "utils";
import "./HeatMap.scss";

export default function HeatMap(props) {
  const topicData = useSelector((state) => state.topicData);
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const { catId } = useParams();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState({});

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Category Topics */
  useEffect(() => {
    dispatch(TopicActions.getCategoryTopics(userToken, catId, userId));
  }, [dispatch, userToken, userId, catId]);

  /* Get Category Topics */
  useEffect(() => {
    dispatch(TopicActions.getTopicMainCategories(userToken, userId));
  }, [dispatch, userToken, userId]);

  useEffect(() => {
    let selectedCategory = topicData.category.filter(function (item) {
      return +item.cid === +catId;
    });
    if (selectedCategory.length > 0) {
      const categoryName = userLanguage === 'hi' ? selectedCategory[0].category_name_hindi : selectedCategory[0].category_name;
      setSelectedOption({ value: +selectedCategory[0].cid, label: categoryName});
    }
  }, [dispatch, topicData.category, catId]);

  const mainCategoryOptions = () => {
    const catItems = [];
    topicData.category.map((item, index) => {
      const categoryName = userLanguage === 'hi' ? item.category_name_hindi : item.category_name;
      catItems.push({ value: +item.cid, label: categoryName });
    });
    return catItems;
  }

  /**
   * showCategoryAccuracy
   * @description Show Category Accuracy Chart Radial Bar
   */
  const showCategoryAccuracy = () => {
      const chartOptions = {
        chart: {
          height: 140,
          type: 'radialBar',
          offsetX: 0,
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#F5A32F"],
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 270,
            hollow: {
              margin: 0,
              size: '64%',
              background: 'transparent',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              show: true,
              background: '#fff',
              strokeWidth: '64%',
              opacity: 1,
              margin: 0,
              dropShadow: {
                enabled: true,
                top: -2,
                left: 0,
                blur: 4,
                opacity: 0.15
              }
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                formatter: function(val) {
                  return parseInt(val)+'%';
                },
                color: '#000080',
                fontSize: '18px',
                show: true,
                offsetY: 7,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ["#E1246A"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['%'],
      };
      const chartSeries = [Math.round(topicData.categoryAccuracy ? topicData.categoryAccuracy : 0)];
      return (<Chart
        options={chartOptions}
        series={chartSeries}
        type="radialBar"
        width="140"
        />);
  }

  /**
   * showYouTubeVideo
   * @param {object} item
   * @description Redirect to YouTube Video
   */
  const showYouTubeVideo = (item) => {
    const youtubeURL = item?.youtube_link ?? '';
    if (youtubeURL !== '') {
      const win = window.open(youtubeURL, '_blank');
      if (win != null) {
        win.focus();
      }
    } else {
      showVideoNotAvailableModal();
    }
  }

  const isShowYouTubeVideo = (item) => {
    const youtubeStatus = item?.youtube_link ?? '';
    if (youtubeStatus !== '') {
      return true;
    }
    return false;
  }

  const isShowStudyMaterial = (item) => {
    return item?.study_material;
  }

  const isShowSubjectTest = (item) => {
    return item?.subject_tests;
  }

  /**
   * showReadingMaterial
   * @param {object} item
   * @description Show Reading Materials
   */
  const showReadingMaterial = (item) => {
    history.push(`/category/${+item.category_id}/topic/${+item.topic_id}/reading`);
  }

  /**
   * showQuiz
   * @param {object} item
   * @description Show Quiz Software
   */
  const showQuiz = (item) => {
    history.push(`/category/${+item.category_id}/topic/${+item.topic_id}/quiz-lists`);
  }

  /**
   * goToCategory
   * @param {object} item
   * @description Go to Category Page
   */
  const goToCategory = (item) => {
    history.push(`/category/${+item.cid}/heat-map`);
  }

  const hideVideoNotAvailableModal = () => {
    dispatch(NavigationActions.toggleVideoNotAvailablePopUpModal(false));
  }

  const showVideoNotAvailableModal = () => {
    dispatch(NavigationActions.toggleVideoNotAvailablePopUpModal(true));
  }

  /**
   * topicItem
   * @param {object} item
   * @param {object} index
   * @description Show Topic Item
   */
  const topicItem = (item, index) => {
    const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;
    let topicAccuracy = "-";
    let topicAccuracyLevel = "NoData";
    if (item.topic_accuracy !== '') {
      topicAccuracy = 0+'%';
      topicAccuracyLevel = "Low";
      if (+item.topic_accuracy > 0) {
        topicAccuracy = Math.round(item.topic_accuracy)+'%';
        if (item.topic_accuracy > 40 && item.topic_accuracy <= 80) {
          topicAccuracyLevel = "Medium";
        } else if (item.topic_accuracy > 80) {
          topicAccuracyLevel = "High";
        } else if (item.topic_accuracy <= 40 && item.topic_accuracy > 0) {
          topicAccuracyLevel = "Low";
        }
      }
    }

    return (<div className="col-12 col-sm-6 col-md-6 col-xl-4 topicItemWrapper" key={`topic-${index}`}>
    <div className={`topicItem`}>
        <div className={`topicScoreCount ${topicAccuracyLevel}`}>
          <span>{topicAccuracy}</span>
        </div>
        <div className="topicDesc">
          <div className="topicTitleSec">
            <div className="topicTitle">
              <h4>{topicName}</h4>
            </div>
            <div className="youTubeLink">
              {isShowYouTubeVideo(item) && <span className="topicLinkItem" onClick={() => showYouTubeVideo(item)}><img src={YoutubeIcon} alt={Strings.youTube} title={Strings.youTube} /></span>}
            </div>
          </div>
          <div className="topicLinks">
              {isShowStudyMaterial(item) && <span className="topicLinkItem animate left" onClick={() => showReadingMaterial(item)}>{Strings.studyMaterial}</span>}
              {isShowSubjectTest(item) && <span className="topicLinkItem animate right" onClick={() => showQuiz(item)}>{Strings.practiceTests}</span>}
          </div>
        </div>
    </div>
    </div>);
  }

  /**
   * categoryItem
   * @param {object} item
   * @param {number} index
   * @description Show Category Item
   */
  const categoryItem = (item, index) => {
    const categoryName = userLanguage === 'hi' ? item.category_name_hindi : item.category_name;

    return (<li className={`categoryItem ${+item.cid === +catId ? "active" : "" }`} key={`category-${index}`} onClick={() => goToCategory(item, index)}>{categoryName}</li>);
  }

  const goToCategoryItem = (item, index) => {
    setSelectedOption(item);
    history.push(`/category/${+item.value}/heat-map`);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noPadding">
        <div className="categoryPage">
                <div className="topicLists">
                    <div className="pageTitle">
                        <div className="pageHeading">
                            <h3>{Strings.hmHeading}</h3>
                        </div>
                        <div className="categoryAccuracy">
                            <div className="accuracyTitle">
                                <h4>{Strings.hmAccuracyRate}</h4>
                            </div>
                            <div className="accuracyChart">
                                {showCategoryAccuracy()}
                            </div>
                        </div>
                    </div>
                    <div className="categoryLists">
                        {topicData.category.length > 0 &&
                            <ul className="categoryItems">
                            {topicData.category.map((item, index) => categoryItem(item, index))}
                            </ul>
                          }
                    </div>
                    <div className="mobileCategoryListings">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <h4 className="categoryTitle">{Strings.selectedCategory} : {selectedOption?.label}</h4>
                            <Select 
                            defaultValue={selectedOption}
                            value={selectedOption}
                            isSearchable
                            options={mainCategoryOptions()}
                            placeholder="Select Category"
                            onChange={(item, index) => goToCategoryItem(item, index)}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="topicItemListing">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <span className="legendItem">
                              <span className="legendTile blue">&nbsp;</span> {Strings.yet_not_answered}
                            </span>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <span className="legendItem">
                              <span className="legendTile red">&nbsp;</span> 0% to 40%
                            </span>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <span className="legendItem">
                              <span className="legendTile orange">&nbsp;</span> 41% to 80%
                            </span>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <span className="legendItem">
                              <span className="legendTile green">&nbsp;</span> Over 80%
                            </span>
                          </div>
                        </div>
                        {topicData.topics.length > 0 && !topicData.isLoading &&
                            <div className="row">
                                {topicData.topics.map((item, index) => topicItem(item, index))}
                            </div>
                        }
                        {topicData.isLoading &&
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                              <h3>{Strings.hmLoading}</h3>
                            </div>
                          </div>
                        }
                        {topicData.topics.length === 0 && !topicData.isLoading &&
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                              <h3>{Strings.hmNoTopicsFound}</h3>
                            </div>
                          </div>
                        }
                    </div>
                </div>
        </div>
      </div>
      {navigationData.showVideoNotAvailable ? <VideoNotAvailable onGoBack={() => hideVideoNotAvailableModal()} /> : null}
    </Fragment>
  );
}
