import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { Strings } from "resources";
import {
    userForgotPassword as userForgotPasswordAPI,
} from "services/AuthService";
import { useAlert } from "react-alert";
import * as NavigationActions from "redux/actions/NavigationActions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ForgotPassword.scss";

export default function ForgotPassword(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigationData = useSelector((state) => state.navigationData);
  const [fields, setFields] = useState([
    {
        name: Strings.email_address,
        key: "email",
        value: "",
        type: "text",
        maxLength: 50,
        rules: "required|email|max:50",
        touched: false,
        placeholder: Strings.email_address_placeholder
      },
      {
        name: Strings.mobileNo,
        key: "contact_no",
        value: "",
        type: "text",
        maxLength: 12,
        rules: "required|numeric|min:10|max:12",
        touched: false,
        placeholder: Strings.mobileNo_placeholder
      },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);

  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Update Field State
   */
  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description Check Errors
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Fields
   */
  const renderField = (field, index) => {
    return (
      <Input
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated(event.target.value, index)}
        onBlur={(event) => fieldUpdated(event.target.value, index)}
        key={index}
        touched={field.touched}
        value={field.value}
        errorMessage={
          field.errors && field.errors.length ? field.errors[0] : ""
        }
        secure={field.secure}
      />
    );
  };

  /**
   * submit
   * @param {*} event
   * @description Submit Input Fields
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    userForgotPassword(getPayload(fields));
  };

  /**
   * userForgotPassword
   * @param {*} payload
   * @description User Forgot Password Action
   */
  const userForgotPassword = async (payload) => {
    setLoadingState(true);
    const { data } = await userForgotPasswordAPI(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return userForgotPasswordSuccess(data.data);
    }
  };

  /**
   * userForgotPasswordSuccess
   * @param {*} userData
   * @description User Forgot Password Action Success
   */
  const userForgotPasswordSuccess = (userData) => {
    alert.success(Strings.successForgotPassword);
    dispatch(NavigationActions.setResetPasswordModalStatus(true));
  };

  /**
   * getPayload
   * @param {*} fields
   * @description Get PayLoad
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  const hideModal = async () => {
    dispatch(NavigationActions.setForgotPasswordModalStatus(false));
  }

  return (<>
    <div
      className={`popupWrapper Auth dispersionLogo ${
        !navigationData.showForgotPasswordModal ? "closed" : "opened"
      }`}
    >

  <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>

    <div className="popUpInner">
      <h3 className="modal-heading">{Strings.forgotPassword}</h3>
      <form onSubmit={(event) => submit(event)}>
        {fields.map((field, index) => renderField(field, index))}
        <Button disabled={hasErrors} loading={isLoading} btnType="Orange">{Strings.formSubmit}</Button>
      </form>
      <div className="nav-link-container">
          <span className="createAccountLink">{Strings.dontHaveAccount} <span className="Link" onClick={() => props.onSignUpShow()}>{Strings.createAccount}</span></span>
          <span className="resetPasswordLink">{Strings.haveCredentials} <span className="Link" onClick={() => props.onLoginShow()}>{Strings.login}</span></span>
      </div>
    </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showForgotPasswordModal ? "closed" : "opened"}`} onClick={() => hideModal()}></div>
  </>);
}
