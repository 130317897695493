import React, { useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Strings } from 'resources';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { log } from "utils";
import "./FileView.scss";
import PDFViewer from 'pdf-viewer-reactjs'

export default function FileView(props) {
  const accountData = useSelector((state) => state.accountData);
  const history = useHistory();
  const userLanguage = Strings.getLanguage();
  const libraryFileDownloadUrl = props?.location?.state?.fileUrl ?? '';
  const libraryFileTitle = props?.location?.state?.fileTitle ?? '';

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    log(userLanguage);
    return () => log('Site Language Unmounted...');
  }, [accountData.language, userLanguage]);

  /* Disable Mouse Right Click */
  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  /* Handle Mouse Right Click Context Menu */
  const handleContextMenu = (e) => {
    e.preventDefault();
  }

  const goToLibrary = () => {
    history.goBack();
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1><span className="goBackViewFile" onClick={() => goToLibrary()}><FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" /></span>{libraryFileTitle}</h1>
            </div>
            <div className="myPageWrapper fileView">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        {/*<Document
                            file={libraryFileUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {
                                Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )
                            }
                        </Document>*/}

                        <PDFViewer
                            scale={2}
                            document={{
                                url: libraryFileDownloadUrl,
                            }}
                            hideRotation={false}
                            hideZoom={false}
                            css={'reactPdfDocument'}
                        />
                     </div>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  );
}
