import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { Strings } from "resources";
import { activateUser as activateUserAPI } from "services/AuthService";
import { useAlert } from "react-alert";
import "./ActivateUser.scss";
import Checked from "assets/images/checked.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as NavigationActions from "redux/actions/NavigationActions";

export default function ActivateUser(props) {
  const alert = useAlert();
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const [fields, setFields] = useState([
    {
      name: Strings.otpNo,
      key: "userActivationToken",
      value: "",
      type: "text",
      minLength: 5,
      rules: "required|numeric|min:5",
      touched: false,
      placeholder: Strings.otpNo_placeholder
    }
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const [userActivationStatus, setUserActivationSuccess] = useState(false);

  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Field State Update
   */
  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description Check for errors if any
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Fields
   */
  const renderField = (field, index) => {
    return (
      <Input
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated(event.target.value, index)}
        onBlur={(event) => fieldUpdated(event.target.value, index)}
        key={index}
        touched={field.touched}
        value={field.value}
        errorMessage={
          field.errors && field.errors.length ? field.errors[0] : ""
        }
        secure={field.secure}
      />
    );
  };

  /**
   * submit
   * @param {*} event
   * @description Submit Input Fields
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    userUserActivation(getPayload(fields));
  };

  /**
   * userUserActivation
   * @param {*} payload
   * @description Activate User APIs
   */
  const userUserActivation = async (payload) => {
    setLoadingState(true);
    const { data } = await activateUserAPI(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return userActivationSuccess(data.data);
    }
  };

  /**
   * userActivationSuccess
   * @param {*} userData
   * @description Handle User Activation Success
   */
  const userActivationSuccess = (userData) => {
    alert.success(Strings.successUserActivated);
    setUserActivationSuccess(true);
    return false;
  };

  /**
   * getPayload
   * @param {*} fields
   * @description Get Payload of User Submitted Fields
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  const hideModal = async () => {
    dispatch(NavigationActions.setActivateUserModalStatus(false));
  }

  return (<>
    <div
      className={`popupWrapper Auth dispersionLogo ${
        !navigationData.showUserActivateModal ? "closed" : "opened"
      }`}
    >

    <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>

    <div className="popUpInner">
      <h3 className="modal-heading">{Strings.activateUser}</h3>
      {!userActivationStatus &&
      <form onSubmit={(event) => submit(event)}>
          {fields.map((field, index) => renderField(field, index))}
          <Button disabled={hasErrors} loading={isLoading} btnType="Orange">
            {Strings.activate}
          </Button>
      </form>}
      {userActivationStatus &&
      <div className="nav-link-container">
          <div className="successSubmitWrapper">
            <img className="successSubmit" src={Checked} title={Strings.success} alt={Strings.success} />
          </div>
          <span className="resetPasswordLink">{Strings.successUserActivated}</span>
          <span className="resetPasswordLink"><span className="Link" onClick={() => props.onLoginShow()}>{Strings.login}</span></span>
      </div>}
    </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showUserActivateModal ? "closed" : "opened"}`}></div>
  </>);
}
