import { isUserLoggedIn } from "services/AuthService";
import appConfig from "config/config";
import apiConfig from "config/api";
import { Client } from "@stomp/stompjs";
import { getUserId } from "services/AuthService";
import * as ChatActions from "redux/actions/ChatActions";
import store from "redux/store";
import { log } from "utils";
import {
  addChatMessageData,
  addQuestionLikeCount,
  subTractQuestionLikeCount,
  addChatCommentData,
  hideChatMessage,
  hideChatComment
} from "websocket/activities";

let client = null;
let accountHeartbeatTimer = null;
const HEARTBEAT_INTERVAL = 15000;

export const open = () => {
  let url = `${appConfig.webSocketUrl}`;
  log('-- Websocket URL --');
  log(url);

  client = new Client({
    brokerURL: url,
    connectHeaders: {
      login: "",
      passcode: "",
    },
    debug: function (str) {
      log(str);
    },
    reconnectDelay: 2000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    discardWebsocketOnCommFailure: true,
  });

  client.discardWebsocketOnCommFailure = true;

  client.onConnect = function (frame) {
    const { status } = isUserLoggedIn();

    if (!status) return;
    let channel = `/${apiConfig.websocket.user}/${getUserId()}`;

    log(client, channel, "channel to connect");
    log('-- Channel Url --');
    log(channel);

    if (client) {
      client.subscribe(channel, onMessage);
      setHeartBeat();

      // Dispatch Action to get chat messages
      store.dispatch(ChatActions.getBannedUsers(getUserId())).then(() => {
        store.dispatch(ChatActions.getChatMessages(true));
      });
      
      log('^^^ Dispatch the Chat Messages Action Bro ^^^');
    }
  };

  client.onDisconnect = function (frame) {
    store.dispatch(ChatActions.setWebsocketStatus("DISCONNECTED"));
  };

  client.onStompError = function (frame) {
    // Will be invoked in case of error encountered at Broker
    // Bad login/pass code typically will cause an error
    // Complaint brokers will set `message` header with a brief message. Body may contain details.
    // Compliant brokers will terminate the connection after any error
    log("Broker reported error: " + frame.headers["message"]);
    log("Additional details: " + frame.body);

    store.dispatch(ChatActions.setWebsocketStatus("DISCONNECTED"));
  };

  client.activate();
};

export const close = () => {
  clearTimeout(accountHeartbeatTimer);
  store.dispatch(ChatActions.setWebsocketStatus("DISCONNECTED"));
  if (client) client.deactivate();
};

const setHeartBeat = () => {
  store.dispatch(ChatActions.setWebsocketStatus("CONNECTED"));
  clearTimeout(accountHeartbeatTimer);
  accountHeartbeatTimer = setTimeout(() => {
    resetConnection();
  }, HEARTBEAT_INTERVAL);
};

const resetConnection = () => {
  clearTimeout(accountHeartbeatTimer);
  store.dispatch(ChatActions.setWebsocketStatus("DISCONNECTED"));
  if (client) client.deactivate();
  open();
};

const onMessage = (message) => {
  log("Main channel", message.body, JSON.parse(message.body));

  const body = message.body ? JSON.parse(message.body) : undefined;

  log("main channel event");
  log(body.event);

  if (body && body.event) handleEvent(body);
};

const handleEvent = (body) => {

  log("----- Chat ------");
  log(body);
  log(body.event);
  log("------X -----");
  

  switch (body.event) {
    case "HEART_BEAT":
      setHeartBeat();
      break;

    case "MESSAGE_ADDED":
      addChatMessageData(
        body.MESSAGE.id,
        body.MESSAGE.messageType,
        body.MESSAGE
      )
    break;

    case "COMMENT_ADDED":
      addChatCommentData(
        body.MESSAGE_ID,
        body?.COMMENT?.id,
        body?.COMMENT
      );
    break;

    case "MESSAGE_LIKED":
      addQuestionLikeCount(
        body.MESSAGE_ID,
        body.LIKECOUNT
      );
    break;

    case "MESSAGE_UNLIKED":
      subTractQuestionLikeCount(
        body.MESSAGE_ID,
        body.LIKECOUNT
      );
    break;

    case "MESSAGE_HIDDEN":
      hideChatMessage(
        body.MESSAGE_ID
      );
    break;

    case "COMMENT_HIDDEN":
      hideChatComment(
        body.MESSAGE,
        body.COMMENT
      );
    break;

    default:
      break;
  }
};
