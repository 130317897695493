import * as actions from "redux/action-types";
import {
    getNotifications as getNotificationsAPI,
    updateNotificationStatus as updateNotificationStatusAPI
} from "services/NotificationService";
import { Strings } from "resources";
import { CancelToken } from "axios";

/**
 * setNotificationLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setNotificationLoadingState(isLoading) {
    return {
        type: actions.LOADING_NOTIFICATION_DATA,
        isLoading,
    };
}

/**
 * addNotification
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Notification into State
 */
export function addNotification(items, hasMore, page) {
    return {
        type: actions.ADD_NOTIFICATION_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setNotificationCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setNotificationCancelToken(token) {
    return {
        type: actions.SET_NOTIFICATION_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreNotification
 * @description check If more files are there or not
 */
export function hasNoMoreNotification() {
    return {
        type: actions.SET_NOTIFICATION_HAS_NO_MORE,
    };
}

/**
 * getNotificationData
 * @param {boolean} reset
 * @param {number} userId
 * @param {string} tokenId
 * @description Get Notification with Pagination
 */
export function getNotificationData(reset, userId, tokenId, type) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.notificationData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setNotificationCancelToken(newRequestSource));
            await dispatch(addNotification([], true, 0));
        }

        await dispatch(setNotificationLoadingState(true));

        let { notificationData } = await getState();
        let { page, limit, items, cancelRequest } = notificationData;
        const { data, error } = await getNotificationsAPI(tokenId, userId, type);
        if (!error && data.status && Array.isArray(data.data) && data.data.length) {
            dispatch(addNotification(mergeData(items, data.data), true, page + 1));
            if (data.data.length < limit) {
                dispatch(hasNoMoreNotification());
            }
        } else {
            dispatch(hasNoMoreNotification());
        }

        dispatch(setNotificationLoadingState(false));
    };
}

/**
 * updateNotificationStatus
 * @param {number} userId 
 * @param {number} notiId 
 * @param {string} status 
 * @description Update Notification status
 */
export function updateNotification(userId, userToken, notiId, status){
    return async (dispatch, getState) => {
        const { data, error } = await updateNotificationStatusAPI(userId, userToken, notiId, status);
        if (!error && data.status ) {
            dispatch(hasNoMoreNotification());
        }
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.nu_id) {
            let index = oldData.findIndex(data => (data.nu_id && data.nu_id === newDataItem.nu_id));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
}

export function clearNotifications() {
    return {
        type: actions.CLEAR_NOTIFICATION_COUNT,
    };
}