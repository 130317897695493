import React, { useEffect } from "react";
import { setPageTitle } from "utils";
import {
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as CourseActions from "redux/actions/CourseAction";
import * as NotificationActions from "redux/actions/NotificationActions";
import LeftBar from "components/LeftBar";
import { getUserId, getUserToken, isUserLoggedIn } from "services/AuthService";
import "./UserLayout.scss";
import HeaderAuthenticated from "components/HeaderAuthenticated";
import backLinkIcon from "assets/images/bottom/back.svg";
import chatLinkIcon from "assets/images/bottom/chat.svg";
import homeLinkIcon from "assets/images/bottom/home.svg";
import UserAccessForgery from "modals/UserAccessForgery";
import { Strings } from 'resources';
import queryString from 'query-string';

export default function UserLayout({
  component: Component,
  pageTitle,
  reducerType,
  ...rest
}) {
  const userId = getUserId();
  const userToken = getUserToken();
  const dispatch = useDispatch();
  const history = useHistory();
  const navigationData = useSelector((state) => state.navigationData);
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  const setUserLanguage = async (language) => {
    dispatch(AccountActions.setUserLanguage(language));
    Strings.setLanguage(language);
  }

  useEffect(() => {
    if (urlParams?.lang === 'hi') {
      setUserLanguage(urlParams?.lang);
    }
  }, []);

  useEffect(() => {
    dispatch(AccountActions.getAccountInfo());
    dispatch(AccountActions.validateUserGroup());
      // On Load
    return () => {
      // On UnLoad
    };
  }, [dispatch, userId]);

  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /* Get My Active Course */
  useEffect(() => {
    dispatch(CourseActions.getActiveCourse(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get Notification */
  useEffect(() => {
    dispatch(NotificationActions.getNotificationData(true, userId, userToken, 'unread'));
  }, [dispatch, userToken, userId]);

  // component did mount
  useEffect(() => {
    if (pageTitle) setPageTitle(pageTitle);
  }, [pageTitle]);
  const { status } = isUserLoggedIn();

  const goToHome = () => {
    history.push("/home");
  }

  const goToChat = () => {
    history.push("/quill-it");
  }

  const goToBack = () => {
    history.goBack();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        status ? (
        <div className="userLayout">
          <div className="userLayoutContainer">
              <LeftBar />
              <div className="userPageContainer">
                <HeaderAuthenticated currentRoute={props.match.path} isLoggedIn={true} pageTitle={pageTitle} showFullWidth={false} />
                <div className="userPage">
                  <Component {...props} />
                </div>
                <div className="footerNavigationBar">
                  <span className="nItem" onClick={() => goToChat()}>
                    <img className="nIcon" src={chatLinkIcon} alt="Refer" title="Refer" />
                  </span>
                  <span className="nItem between" onClick={() => goToHome()}>
                    <img className="nIcon" src={homeLinkIcon} alt="Refer" title="Refer" />
                  </span>
                  <span className="nItem" onClick={() => goToBack()}>
                    <img className="nIcon" src={backLinkIcon} alt="Refer" title="Refer" />
                  </span>
                </div>
              </div>
            </div>
            {/* navigationData.showUserAccessForgery ? <UserAccessForgery /> : null */}
          </div>
        ) : (
          <Redirect to={`/sign-in/?redirect=${location.pathname}`} />
        )
      }
    />
  );
}
