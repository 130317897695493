import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as TopicActions from "redux/actions/TopicActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as SubjectTestActions from "redux/actions/SubjectTestActions";
import { useParams } from "react-router-dom";
import { getUserId, getUserToken } from "services/AuthService";
import { Strings } from 'resources';
import SubjectQuizConfirmation from "modals/SubjectQuizConfirmation";
import FreeTrialExpiredACEGame from "modals/FreeTrialExpiredACEGame";
import SubscriptionPlanExpired from "modals/SubscriptionPlanExpired";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import Select from 'react-select';
import { log } from "utils";
import "./QuizLists.scss";

export default function QuizLists(props) {
    const topicData = useSelector((state) => state.topicData);
    const navigationData = useSelector((state) => state.navigationData);
    const subjectTestData = useSelector((state) => state.subjectTestData);
    const accountData = useSelector((state) => state.accountData);
    const dispatch = useDispatch();
    const userId = getUserId();
    const userToken = getUserToken();
    const userLanguage = Strings.getLanguage();
    const { catId, topicId } = useParams();
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState({});

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);
    
    /* Get Category Topics */
    useEffect(() => {
      dispatch(TopicActions.getCategoryTopics(userToken, catId, userId));
    }, [dispatch, userToken, userId, catId]);

    /* Get Category Topics */
    useEffect(() => {
      dispatch(TopicActions.getTopicMainCategories(userToken, userId));
    }, [dispatch, userToken, userId]);

    /* Get My Subject Tests */
    useEffect(() => {
        dispatch(SubjectTestActions.clearActiveSubjectTest())
        dispatch(SubjectTestActions.getMySubjectTests(catId, topicId));
    }, [dispatch, userId, catId, topicId]);

    useEffect(() => {
        let selectedTopic = topicData.topics.filter(function (item) {
        return +item.topic_id === +topicId;
        });
        if (selectedTopic.length > 0) {
        const topicName = userLanguage === 'hi' ? selectedTopic[0].topic_name_hindi : selectedTopic[0].topic_name ;
        setSelectedOption({ value: +selectedTopic[0].topic_id, label: topicName});
        }
    }, [dispatch, topicData.topics, topicId]);

    const checkACEGameExpireModal = () => {
        let status = '0';
        const valExamId = accountData?.status?.exam;
        const valGroupId = accountData?.status?.group;
        const isValid = accountData?.status?.valid;
        if (valExamId === '1' && valGroupId === '1' && isValid) {
            status = '1';
        } else if (valExamId === '1' && valGroupId === '2' && isValid) {
            status = '2';
        } else if (valExamId === '1' && valGroupId === '3' && isValid) {
            status = '4';
        } else if (valExamId === '1' && ( valGroupId === '2' ||  valGroupId === '3') && !isValid) {
            status = '5';
        } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
            status = '3';
        }
        return status;
      }

    const mainTopicsOptions = () => {
        const topicItems = [];
        topicData.topics.map((item, index) => {
        const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;
        topicItems.push({ value: +item.topic_id, label: topicName });
        });
        return topicItems;
    }

    /**
     * goToCategory
     * @description Go to Parent Category Page
     */
    const goToCategory = () => {
        history.push(`/category/${+catId}/heat-map`);
    }

    /**
     * goToTopic
     * @param {object} item
     * @description Go to Other Siblings Page
     */
    const goToTopic = (item) => {
        history.push(`/category/${+catId}/topic/${+item.topic_id}/quiz-lists`);
    }

    const goToTopicItem = (item) => {
        history.push(`/category/${+catId}/topic/${+item.value}/quiz-lists`);
    }

    const showFeatureNotAvailableModal = () => {
        dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
    }

    const showFreeTrialExpiredACEGameModal = () => {
        dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
    }

    const hideFreeTrialExpiredACEGameModal = () => {
        dispatch(NavigationActions.toggleFreeTrialExpiredModal(false));
    }

    const hideSubscriptionPlanExpiredModal = () => {
        dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(false));
      }
    
    /**
     * goToTopicQuiz
     * @param {string} catId
     * @param {string} topicId
     * @param {string} quizId
     */
    const goToTopicQuiz = (catId, topicId, quizId, attemptId) => {
        // dispatch(NavigationActions.setSubjectQuizVerifyModal());
        const status = checkACEGameExpireModal();
      
        if (attemptId !== '' && +attemptId > 0) {
            if (status === '4') {
                history.push(`/category/${+catId}/topic/${+topicId}/quiz/${+quizId}/attempt/${+attemptId}/report`);
            } else {
                showFeatureNotAvailableModal();
            }
        } else {
            if (status === '1' || status === '2' || status === '4') {
                history.push(`/category/${+catId}/topic/${+topicId}/quiz/${+quizId}`);
            } else if(status === '0'){
                showFreeTrialExpiredACEGameModal();
            } else if(status === '5') {
                dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
            } else {
                showFeatureNotAvailableModal();
            }
            
        }        
    }

    /**
     * topicItem
     * @param {object} item
     * @param {number} index
     * @description Show Topic Item in Left SideBar
     */
    const topicItem = (item, index) => {
        const topicName = userLanguage === 'hi' ? item.topic_name_hindi : item.topic_name ;
        return (<li className={`topicItem ${+item.topic_id === +topicId ? "active" : "" }`} key={`topic-${index}`} onClick={() => goToTopic(item, index)}>{topicName}</li>);
    }

    /**
     * showCategoryHeader
     * @description Show Selected Category Description
     */
    const showCategoryHeader = () => {
        let filteredCategory = topicData.category.filter(categoryItem => {
        return +categoryItem.cid === +catId;
        });
        let categoryName = "-";
        if (filteredCategory.length > 0) {
        categoryName = userLanguage === 'hi' ? filteredCategory[0].category_name_hindi : filteredCategory[0].category_name;
        }
        return (<h3 className="mainCategoryName" onClick={() => goToCategory()}><FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" />{categoryName}</h3>);
    }

    /**
     * showTopicBreadCrumbs
     * @description Show Topic Details BreadCrumbs
     */
    const showTopicBreadCrumbs = () => {
        let filteredTopic = topicData.topics.filter(topicItem => {
        return +topicItem.topic_id === +topicId;
        });
        let topicName = "-";
        if (filteredTopic.length > 0) {
        topicName = userLanguage === 'hi' ? filteredTopic[0].topic_name_hindi : filteredTopic[0].topic_name;
        }
        return (<h3 className="pageTitle">{topicName} &#8594; {Strings.tests}</h3>);
    }

    /**
     * showSubjectTestItem
     * @param {object} item
     * @param {number} index
     * @description Show Subject Test Item
     */
    const showSubjectTestItem = (item, index) => {
        const messageDateTime = item.timeEnded ? moment(item.timeEnded).format('DD MMM') : '';
        const gameTitle = userLanguage === 'hi' ? item.nameHindi : item.name ;
        return (<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 quizItemWrapper" key={`quiz-${index}`}>
        <div className="quizItemBox">
                <div className="quizItemTitle">
                    <span className="qTitle" onClick={() => goToTopicQuiz(item.categoryId, item.topicId, item.id, item?.attemptId)}>{gameTitle}</span>
                    <span className="qDate">{messageDateTime}</span>
                </div>
                <span className="qPoints">{item.status === 'completed' ? `Scored ${item.correctQuestions}/${item.testQuestionsCount}` : `${Strings.tap_to_take_test}`}</span>
                <span className={`qStatus ${item.status === 'completed' ? 'Completed' : 'Pending'}`}>{item.status}</span>
            </div>
        </div>);
    }

    return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo noPadding">
        <div className="quizPage">
            <div className="topicLists">
                {showCategoryHeader()}
                {topicData.topics.length > 0 &&
                    <ul className="topicItems">
                    {topicData.topics.map((item, index) => topicItem(item, index))}
                </ul>
                }
            </div>
            <div className="mobileCategoryListings">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h4 className="categoryTitle">{Strings.selectedTopic} : {selectedOption?.label}</h4>
                          <Select 
                            defaultValue={selectedOption}
                            value={selectedOption}
                            isSearchable
                            options={mainTopicsOptions()}
                            placeholder="Select Topic"
                            onChange={(item, index) => goToTopicItem(item, index)}
                          />
                    </div>
                </div>
            </div>
            <div className="quizItemLists">
                {showTopicBreadCrumbs()}
                <div className="quizItemListing">
                    {subjectTestData.tests.length === 0 && !subjectTestData.isLoading &&
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                                <h3>{Strings.noSubjectTestAvailable}</h3>
                            </div>
                        </div>
                    }
                    {subjectTestData.tests.length === 0 && subjectTestData.isLoading &&
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                                <h3>{Strings.loading}</h3>
                            </div>
                        </div>
                    }
                    {subjectTestData.tests.length > 0 &&
                        <div className="row">
                            {subjectTestData.tests.map((item, index) => showSubjectTestItem(item, index))}
                        </div>
                    }
                </div>
            </div>
        </div>
      </div>
      {navigationData.showSubjectQuizVerifyPopUp ? <SubjectQuizConfirmation /> : null}
      {navigationData.showFreeTrialExpiredModal ? <FreeTrialExpiredACEGame onGoBack={() => hideFreeTrialExpiredACEGameModal()} /> : null}
      {navigationData.showSubscriptionPlanExpiredStatusModel ? <SubscriptionPlanExpired onGoBack={() => hideSubscriptionPlanExpiredModal()} /> : null}
    </Fragment>
  );
}
