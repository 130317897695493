import { randomNumber } from "utils";
import moment from "moment";
import { getUserId } from "services/AuthService";

export const REDUCER_TAG = "_FOR_TAG";
export const REDUCER_INDIVIDUAL = "_FOR_INDIVIDUAL";

export const getDateInYmdHisFormat = () => {
  const date = new Date();
  const formattedDate = moment(date).utc(true).format("YYYY-MM-DD HH:mm:ss");
  return formattedDate;
}

export const isProjectsScreen = (name) => {
  if (name === "/projects") return true;

  return false;
};

export const isProjectTaskScreen = (name) => {
  if (name === "/project/:tagId") return true;

  return false;
};

export const isUserScreen = (name) => {
  if (name === "/user/:userId") return true;

  return false;
};

export const isUserCompletedScreen = (name) => {
  if (name === "/user/:userId/completed") return true;

  return false;
};

export const isProjectCompletedScreen = (name) => {
  if (name === "/project/:tagId/completed") return true;

  return false;
};

export const isHomeScreen = (name) => {
  if (name === "/") return true;
  return false;
};

export const isHomeCompletedScreen = (name) => {
  if (name === "/completed") return true;

  return false;
};

export const currentDay = () => {
  return new moment(new Date()).utc(false).toDate();
};

export const parseToDateObject = (date) => {
  const dateString = `${date[0]}-${date[1]}-${date[2]}`;
  return new moment(dateString, "YYYY-MM-DD").utc(false).toDate();
};

export const parseToDateObjectFromString = (date) => {
  return new moment(date, "YYYY-MM-DD").utc(false).toDate();
};

export const parseToDateObjectFromTimestamp = (date) => {
  return new moment(date).utc(false).toDate();
};

export const formatDateForView = (date) => {
  return moment(date).format("MMM D");
};

export const formatDateWithYearForView = (date) => {
  return moment(date).format("MMM D YYYY");
};

export const formatDateForAPI = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatTimeForAPI = (date) => {
  return moment(date).format("hh:mm a ");
};

export const reorderTasks = (data, startIndex, endIndex) => {
  const [removed] = data.splice(startIndex, 1);
  data.splice(endIndex, 0, removed);
  return data;
};

export const parseUsers = (data) => {
  let newData = [];

  data.forEach((dat) => {
    let name = `${dat.firstName} ${dat.lastName}`;
    name = name.trim();

    if (name === "") {
      let str = dat.email ? dat.email : "";
      let nameParts = str.split("@");
      name = nameParts.length === 2 ? nameParts[0] : "No Name";
    }

    const field = { id: dat.id + "", display: name };
    newData.push(field);
  });

  return newData;
};

export const getIdsForTrigger = (value, trigger) => {
  let rx = `${trigger}\\[(.*?)\\]`;
  let regex = new RegExp(rx, "g");

  if (trigger === "$") regex = /\$\[(.*?)\]/g;

  let allIds = [];
  let m;

  do {
    m = regex.exec(value);
    if (m) {
      allIds.push(m[1]);
    }
  } while (m);

  return allIds;
};

export const getReducerType = (state, dataType, reducerType) => {
  let reducerTypeField = "taskData";

  if (reducerType === REDUCER_TAG) reducerTypeField = "tagData";
  else if (reducerType === REDUCER_INDIVIDUAL) reducerTypeField = "userData";

  return state[reducerTypeField][dataType];
};

export const getReducerTypeMain = (state, reducerType) => {
  let reducerTypeField = "taskData";

  if (reducerType === REDUCER_TAG) reducerTypeField = "tagData";
  else if (reducerType === REDUCER_INDIVIDUAL) reducerTypeField = "userData";

  return state[reducerTypeField];
};

export const createNewTaskComment = (
  value,
  tempId,
  taskType,
  tagId,
  assignee
) => {
  let task = {
    id: tempId,
    tempId: tempId,
    isTemp: true,
    name: value,
    tempName: value,
    tagId: tagId ? tagId : null,
    createdBy: assignee,
    modified: new Date().getTime(),
    taskType: taskType,
  };

  return { ...task };
};

/**
 * convertArrayToObject
 * @param {array} array
 * @param {number} key
 * @description Convert Array into Object
 */
export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

/**
 * parseChatMessages
 * @param {*} data
 * @description Parse Chat Messages
 */
export const parseChatMessages = (data, bannedUsers) => {
  let newData = [];
  const updatedData = data;
  updatedData.forEach((dat, _index) => {
    newData.push(parseChat(dat, bannedUsers));
  });
  return newData;
};

/**
 * parseChat
 * @param {*} chatItem
 * @param {*} readOnly
 * @param {*} stringDate
 * @description Parse Individual Chat Messages
 */
export const parseChat = (chatItem, bannedUsers) => {
  const userId = getUserId();
  const likedByUser = chatItem?.likedBy !== null && chatItem?.likedBy !== '' ? chatItem?.likedBy?.split(',') : [];
  const reportedByUser = chatItem?.reportedBy !== null && chatItem?.reportedBy !== '' ? chatItem?.reportedBy?.split(',') : [];
  const totalReportedBy = reportedByUser?.length || 0;
  const totalLikedBy = likedByUser?.length || 0;
  const isIamReported = reportedByUser.findIndex(userItemId => +userItemId === +userId);
  const isUserReported = bannedUsers.findIndex(userItemId => +userItemId === +chatItem?.sender?.uid);
  let chatMessageHidden = false;
  if (isIamReported >= 0) {
    chatMessageHidden = true;
  } else if (chatItem?.hidden) {
    chatMessageHidden = true;
  } else if (isUserReported >= 0) {
    chatMessageHidden = true;
  }

  return {
    id: chatItem.id ? chatItem.id : null,
    type: chatItem.messageType,
    name: chatItem.message,
    tempName: chatItem.message,
    file: chatItem.file,
    createdDate: chatItem.messageTime
      ? parseToDateObjectFromTimestamp(chatItem.messageTime)
      : null,
    timeAgo: chatItem.messageTime
    ? timeAgo(chatItem.messageTime)
    : null,
    comments:
    chatItem?.comments?.length > 0
        ? parseChatComments(chatItem?.comments, chatItem.id, bannedUsers)
        : [],
    tempId: randomNumber() + "",
    senderUser: chatItem.sender ? chatItem.sender : "",
    senderUserId: chatItem.sender ? chatItem?.sender?.uid : "",
    likedBy: chatItem.likedBy ? chatItem.likedBy : "",
    totalLikedBy: totalLikedBy,
    reply: chatItem.reply ? chatItem.reply : false,
    liked: chatItem.liked ? chatItem.liked : false,
    isSelected: false,
    inputComment: "",
    inputCommentType: "",
    reportedBy: chatItem?.reportedBy ? chatItem?.reportedBy : "",
    hidden: chatMessageHidden,
    reportedByUser: reportedByUser,
    totalReportedBy: totalReportedBy,
  };
};

/**
 * parseChatComments
 * @param {*} data
 * @description Parse Chat Comments
 */
const parseChatComments = (data, messageId, bannedUsers) => {
  let newData = [];
  data.forEach((dat, _index) => {
    newData.push(parseChatComment(dat, messageId, bannedUsers));
  });
  return newData;
};

/**
 * parseChatComment
 * @param {*} comment
 * @param {*} tag
 * @param {*} taskType
 * @description Parse Chat Comment
 */
export const parseChatComment = (comment, messageId, bannedUsers) => {
  const userId = getUserId();
  const reportedByUser = comment?.reportedBy !== null && comment?.reportedBy !== '' ? comment?.reportedBy?.split(',') : [];
  const totalReportedBy = reportedByUser?.length || 0;
  const isIamReported = reportedByUser.findIndex(userItemId => +userItemId === +userId);
  const isUserReported = bannedUsers.findIndex(userItemId => +userItemId === +comment?.commenter?.uid);
  let chatMessageHidden = false;
  if (isIamReported >= 0) {
    chatMessageHidden = true;
  } else if (comment?.hidden) {
    chatMessageHidden = true;
  } else if (isUserReported >= 0) {
    chatMessageHidden = true;
  }

  let data = {
    id: comment.id,
    tempId: randomNumber() + "",
    messageId: messageId,
    name: comment.name,
    tempName: comment.name,
    isTemp: false,
    modified: comment?.modified,
    createdBy: comment?.createdBy,
    senderUser: comment.commenter ? comment.commenter : "",
    createdDate: comment.commentTime
    ? parseToDateObjectFromTimestamp(comment.commentTime)
    : null,
    type: comment.type,
    hidden: chatMessageHidden,
    reportedBy: comment?.reportedBy ? comment?.reportedBy : "",
    totalReportedBy: totalReportedBy,
    reportedByUser: reportedByUser
  };

  return data;
};

const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

function getFormattedDate(date, preFormattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    minutes = `0${ minutes }`;
  }

  if (preFormattedDate) {
    return `${ preFormattedDate } at ${ hours }:${ minutes }`;
  }

  if (hideYear) {
    return `${ day }. ${ month } at ${ hours }:${ minutes }`;
  }

  return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
}

function timeAgo(dateParam) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();


  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${ seconds } seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${ minutes } minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today');
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday');
  } else if (isThisYear) {
    return getFormattedDate(date, false, true);
  }

  return getFormattedDate(date);
}

/**
 * parseSubjectTests
 * @param {*} data
 * @description Parse Subject Tests Listing
 */
export const parseSubjectTests = (data) => {
  let newData = [];
  const updatedData = data;
  updatedData.forEach((dat, _index) => {
    newData.push(parseSubjectTest(dat));
  });
  return newData;
};

/**
 * parseSubjectTest
 * @param {*} testItem
 * @description Parse Subject Test
 */
export const parseSubjectTest = (testItem) => {
  return {
    id: testItem.bsqc_id ? testItem.bsqc_id : null,
    attemptId: testItem.attempt_id ? testItem.attempt_id : null,
    categoryId: testItem.category_id ? testItem.category_id : null,
    categoryName: testItem.category ? testItem.category.category_name : null,
    categoryNameHindi: testItem.category ? testItem.category.category_name_hindi : null,
    topicId: testItem.topic_id ? testItem.topic_id : null,
    topicName: testItem.topic ? testItem.topic.topic_name : null,
    topicNameHindi: testItem.topic ? testItem.topic.topic_name_hindi : null,
    userId: testItem.user_id ? testItem.user_id : null,
    uniqueCode: testItem?.bsqc_unique_code,
    name: testItem?.bsqc_name_en,
    nameHindi: testItem?.bsqc_name_hi,
    timeStarted: testItem.time_starts
    ? parseToDateObjectFromTimestamp(testItem.time_starts)
    : null,
    timeEnded: testItem.time_ends
    ? parseToDateObjectFromTimestamp(testItem.time_ends)
    : null,
    totalQuestions: +testItem?.total_questions || 0,
    correctQuestions: +testItem?.correct_questions || 0,
    status: testItem?.status || 'pending',
    testTime: +testItem?.duration_minutes || 0,
    testTimeSeconds: (+testItem?.duration_minutes)*60 || 0,
    testQuestionsCount: +testItem?.bsqc_total_questions || 0
  };
};

/**
 * parseSubjectTestQuestions
 * @param {*} data
 * @description Parse Subject Test Options
 */
export const parseSubjectTestQuestions = (data) => {
    let newData = [];
    const updatedData = data;
    if (updatedData?.length > 0) {
      updatedData.forEach((dat, _index) => {
        newData.push(parseSubjectTestQuestion(dat));
      });
    }
    return newData;
};

/**
 * parseSubjectTestQuestion
 * @param {*} questionItem
 * @description Parse Subject Test Question
 */
export const parseSubjectTestQuestion = (questionItem) => {
    return {
      id: questionItem.qid ? questionItem.qid : null,
      categoryId: questionItem.cid ? questionItem.cid : null,
      levelId: questionItem.lid ? questionItem.lid : null,
      topicId: questionItem.topic_id ? questionItem.topic_id : null,
      questionType: questionItem.question_type ? questionItem.question_type : null,
      questionMediaType: questionItem.question_media_type ? questionItem.question_media_type : null,
      question: questionItem.question ? questionItem.question : null,
      questionHindi: questionItem.question_hindi ? questionItem.question_hindi : null,
      createdOn: questionItem.created_on
      ? parseToDateObjectFromTimestamp(questionItem.created_on)
      : null,
      justification: questionItem.justification ? questionItem.justification : null,
      justificationHindi: questionItem.justification_hindi ? questionItem.justification_hindi : null,
      description: questionItem.description ? questionItem.description : null,
      descriptionHindi: questionItem.description_hindi ? questionItem.description_hindi : null,
      options: parseSubjectTestOptions(questionItem.options),
      totalOptions: questionItem?.options?.length || 0,
      questionScore: +questionItem?.question_score || 0,
      selectedOption: null
    };
};

/**
 * parseSubjectTestOptions
 * @param {*} data
 * @description Parse Subject Test Options List
 */
export const parseSubjectTestOptions = (data) => {
  let newData = [];
  const updatedData = data;
  if (updatedData?.length > 0) {
    updatedData.forEach((dat, _index) => {
      newData.push(parseSubjectTestOption(dat));
    });
  }
  return newData;
};

/**
 * parseSubjectTestOption
 * @param {*} optionItem
 * @returns Parse Subject Test Option
 */
export const parseSubjectTestOption = (optionItem) => {
  return {
    id: optionItem.oid ? optionItem.oid : null,
    questionId: optionItem.qid ? optionItem.qid : null,
    isChecked: false,
    option: optionItem.q_option ? optionItem.q_option : null,
    optionHindi: optionItem.q_option_hindi ? optionItem.q_option_hindi : null,
    score: optionItem.score ? optionItem.score : null,
    isCorrect: optionItem.is_correct ? optionItem.is_correct : null,
    isSelected: optionItem.is_selected ? optionItem.is_selected : null
  };
};

/**
 * parseSelectedQuestionOptions
 * @param {*} questions
 * @description Parse Selected Question Options
 */
export const parseSelectedQuestionOptions = (questions) => {
  let newData = [];
  questions.forEach((questionItem, questionIndex) => {
    let answer = {
      'question': questionItem.id,
      'option' : ''
    };
    questionItem.options.forEach((optionItem, optionIndex) => {
      if (optionItem.isChecked) {
        const optionId = optionItem.id;
        answer = {
          'question': questionItem.id,
          'option' : optionId
        }
      }
    });
    newData.push(answer);
  });
  return newData;
}

/**
 * parseACEGame
 * @param {object} aceItem ace Game Object
 * @returns Parse ACE Game Object
 */
export const parseACEGame = (aceItem) => {
  return {
    id: aceItem.baqc_id ? aceItem.baqc_id : null,
    examId: aceItem.baqc_exam_id ? aceItem.baqc_exam_id : null,
    uniqueCode: aceItem.baqc_unique_code ? aceItem.baqc_unique_code : null,
    name: aceItem.baqc_name_en ? aceItem.baqc_name_en : null,
    nameHindi: aceItem.baqc_name_hi ? aceItem.baqc_name_hi : null,
    displayOrder: aceItem.baqc_display_order ? aceItem.baqc_display_order : null,
    testTime: +aceItem?.baqc_duration_minutes || 0,
    testQuestionsCount: +aceItem?.baqc_total_questions || 0,
    testTimeSeconds: (+aceItem?.baqc_duration_minutes)*60 || 0,
    testStatus: aceItem.baqc_status ? aceItem.baqc_status : null,
    attemptId: aceItem.attempt_id ? aceItem.attempt_id : null,
    userId: aceItem.user_id ? aceItem.user_id : null,
    isAgreed: aceItem.is_user_agree ? +aceItem.is_user_agree : 0,
    agreedTime: aceItem.agree_time
    ? parseToDateObjectFromTimestamp(aceItem.agree_time)
    : null,
    timeStarted: aceItem.time_starts
    ? parseToDateObjectFromTimestamp(aceItem.time_starts)
    : null,
    timeEnded: aceItem.time_ends
    ? parseToDateObjectFromTimestamp(aceItem.time_ends)
    : null,
    totalQuestions: +aceItem?.total_questions || 0,
    correctQuestions: +aceItem?.correct_questions || 0,
    attendedQuestions: +aceItem?.attended_questions || 0,
    totalTimeSeconds: +aceItem?.total_time_seconds || 0,
    totalPoints: +aceItem?.total_points || 0,
    correctPoints: +aceItem?.correct_points || 0,
    accuracyPoints: +aceItem?.accuracy_points || 0.00,
    language: aceItem.language ? aceItem.language : null,
    status: aceItem.status ? aceItem.status : 'pending',
    timeLapsed: +aceItem?.time_lapsed || 0
  };
};

/**
 * parseACEGameQuestionStatus
 * @param {*} data
 * @description Parse ACE Game Question Status Lists
 */
export const parseACEGameQuestionStatus = (data) => {
  let newData = [];
  const updatedData = data;
  updatedData.forEach((dat, _index) => {
    newData.push(parseACEGameQuestionStatusItem(dat));
  });
  return newData;
};

/**
 * parseACEGameQuestionStatusItem
 * @param {*} queItem
 * @description Parse ACE Game Question Status Item
 */
export const parseACEGameQuestionStatusItem = (queItem) => {
  return {
    questionId: queItem.question_id ? queItem.question_id : null,
    quizId: queItem.quiz_id ? queItem.quiz_id : null,
    questionStatus: queItem.attend_status ? queItem.attend_status : 'pending',
    answerStatus: queItem.answer_status === '1' && queItem.attend_status === 'answered' ? '1' : '0',
    displayOrder: queItem.question_order ? queItem.question_order : null,
    answerScore: queItem.answer_score ? queItem.answer_score : null,
    totalScore: queItem.total_score ? queItem.total_score : null,
  };
};

/**
 * parseACEGameQuestion
 * @param {*} questionItem
 * @description Parse ACE Game Question Data
 */
export const parseACEGameQuestion = (questionItem) => {
  return {
    id: questionItem.qid ? questionItem.qid : null,
    categoryId: questionItem.cid ? questionItem.cid : null,
    levelId: questionItem.lid ? questionItem.lid : null,
    topicId: questionItem.topic_id ? questionItem.topic_id : null,
    questionType: questionItem.question_type ? questionItem.question_type : null,
    questionMediaType: questionItem.question_media_type ? questionItem.question_media_type : null,
    question: questionItem.question ? questionItem.question : null,
    questionHindi: questionItem.question_hindi ? questionItem.question_hindi : null,
    createdOn: questionItem.created_on
      ? parseToDateObjectFromTimestamp(questionItem.created_on)
      : null,
    justification: questionItem.justification ? questionItem.justification : null,
    justificationHindi: questionItem.justification_hindi ? questionItem.justification_hindi : null,
    description: questionItem.description ? questionItem.description : null,
    descriptionHindi: questionItem.description_hindi ? questionItem.description_hindi : null,
    options: parseACEGameOptions(questionItem.options, questionItem.answer_id),
    totalOptions: questionItem.options.length,
    questionScore: +questionItem?.question_score || 0,
    selectedOption: null,
    userId: questionItem.user_id ? questionItem.user_id : null,
    attemptId: questionItem.attempt_id ? questionItem.attempt_id : null,
    queAnsId: questionItem.buqa_id ? questionItem.buqa_id : null,
    quizId: questionItem.quiz_id ? questionItem.quiz_id : null,
    quizType: questionItem.quiz_type ? questionItem.quiz_type : null,
    questionOrder: questionItem.question_order ? +questionItem.question_order : null,
    attendStatus: questionItem.attend_status ? questionItem.attend_status : null,
    answerStatus: questionItem.answer_status ? questionItem.answer_status : null,
    answerScore: questionItem.answer_score ? questionItem.answer_score : null,
    answerId: questionItem.answer_id ? questionItem.answer_id : null,
    answerOption: questionItem.answer_option ? questionItem.answer_option : null,
    answerTotalScore: questionItem.total_score ? questionItem.total_score : null,
    timeId: questionItem.buath_id ? questionItem.buath_id : null,
  };
};

/**
 * parseACEGameOptions
 * @param {*} data
 * @returns Parse ACE Game Options
 */
export const parseACEGameOptions = (data, answerId) => {
  let newData = [];
  const updatedData = data;
  updatedData.forEach((dat, _index) => {
    newData.push(parseACEGameOption(dat, answerId));
  });
  return newData;
};

/**
 * parseACEGameOption
 * @param {*} optionItem
 * @returns Parse ACE Game Option Data
 */
export const parseACEGameOption = (optionItem, answerId) => {
  return {
    id: optionItem.oid ? optionItem.oid : null,
    questionId: optionItem.qid ? optionItem.qid : null,
    isChecked: +optionItem.oid === +answerId ? true : false,
    option: optionItem.q_option ? optionItem.q_option : null,
    optionHindi: optionItem.q_option_hindi ? optionItem.q_option_hindi : null,
    score: optionItem.score ? optionItem.score : null,
    isCorrect: optionItem.is_correct ? optionItem.is_correct : null,
    isSelected: optionItem.is_selected ? optionItem.is_selected : null
  };
};

/**
 * parseACEGameResult
 * @param {object} resultItem
 * @returns Parse ACE Game Result Data
 */
export const parseACEGameResult = (resultItem) => {
  return {
    totalQuestions: resultItem.total_questions ? resultItem.total_questions : 0,
    attendedQuestions: resultItem.attended_questions ? resultItem.attended_questions : 0,
    correctQuestions: resultItem.correct_questions ? resultItem.correct_questions : 0,
    incorrectQuestions: resultItem.incorrect_questions ? resultItem.incorrect_questions : 0,
    skippedQuestions: resultItem.skipped_questions ? resultItem.skipped_questions : 0,
    unAnsweredQuestions: resultItem.unanswered_questions ? resultItem.unanswered_questions : 0,
    totalScore: resultItem.total_score ? resultItem.total_score : 0,
    attendedScore: resultItem.attended_score ? resultItem.attended_score : 0,
    correctScore: resultItem.correct_score ? resultItem.correct_score : 0,
    currentUserLevelId: resultItem?.userLevel?.currentUserLevelId ?? 0,
    currentUserLevel: resultItem?.userLevel?.currentUserLevel?.stage_name_en ?? '',
    questions: parseACEGameQuestionStatus(resultItem.questions)
  };
};

/**
 * parseEvents
 * @param {*} data
 * @description Parse Events Data
 */
export const parseEvents = (data) => {
  let newData = [];
  const updatedData = data;
  if (updatedData.length > 0) {
    updatedData.forEach((dat, _index) => {
      newData.push(parseEvent(dat, _index));
    });
  }
  return newData;
};

/**
 * parseACEGameOption
 * @param {*} optionItem
 * @returns Parse ACE Game Option Data
 */
export const parseEvent = (eventItem, eventIndex) => {
  return {
    title: eventItem.event_title ? eventItem.event_title : null,
    start: eventItem.event_date ? parseToDateObjectFromTimestamp(eventItem.event_date)
    : null,
    end: eventItem.event_date ? parseToDateObjectFromTimestamp(eventItem.event_date)
    : null,
    type: eventItem.event_type ? eventItem.event_type : null,
    allDay: null,
    resource: null,
  };
};

/**
 * parseChartAccuracy
 * @param {*} data
 * @description Parse Chart Accuracy
 */
export const parseChartAccuracy = (data) => {
  let weekData = [];
  let accuracyData = [];
  const updatedData = data;
  if (updatedData.length > 0) {
    updatedData.forEach((item, index) => {
      if (item.week !== '' && item.accuracy !== '') {
        const weekNo = 'Week ' + item.week;
        weekData.push(weekNo);
        accuracyData.push(item.accuracy ? Math.round(item.accuracy) : 0);
      }
    });
  }
  const chartData = {
    weekNo: weekData,
    accuracyPercentage: accuracyData
  };
  return chartData;
};