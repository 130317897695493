import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * startACEGame
 * @param {*} userToken
 * @param {*} userId
 * @param {*} language
 * @param {*} userAgree
 * @param {*} agreeTime
 * @description Start ACE Game
 */
export async function startACEGame(userToken, userId, language, userAgree, agreeTime, userLevel, quizId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "language": language,
        "is_user_agree": userAgree,
        "agree_time": agreeTime,
        "user_level": userLevel,
        "baqc_id": quizId
    };
    try {
        const response = await appClient.post(apiConfig.ace.startACEGame, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getACEGame
 * @param {*} userToken
 * @param {*} userId
 * @param {*} gameId
 * @description Get ACE Game Details
 */
export async function getACEGame(userToken, userId, gameId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "game_id": gameId
    };
    try {
        const response = await appClient.post(apiConfig.ace.getACEGame, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getACEGameQuestion
 * @param {*} userToken
 * @param {*} userId
 * @param {*} gameId
 * @param {*} attemptId
 * @param {*} order
 * @description Get New ACE Game Question
 */
export async function getACEGameQuestion(userToken, userId, gameId, attemptId, order) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "game_id": gameId,
        "attempt_id": attemptId,
        "order": order
    };
    try {
        const response = await appClient.post(apiConfig.ace.getACEGameQuestion, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * saveACEGameQuestion
 * @param {*} userToken
 * @param {*} userId
 * @param {*} quizId
 * @param {*} attemptId
 * @param {*} order
 * @param {*} timeId
 * @param {*} questionId
 * @param {*} answerId
 * @description Save ACE Game Question
 */
export async function saveACEGameQuestion(userToken, userId, quizId, attemptId, order, timeId,questionId, answerId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId,
        "order": order,
        "buath_id": timeId,
        "question_id": questionId,
        "answer_id": answerId,
    };
    try {
        const response = await appClient.post(apiConfig.ace.saveACEGameQuestion, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * endACEGame
 * @param {*} userToken
 * @param {*} userId
 * @param {*} quizId
 * @param {*} attemptId
 * @param {*} exam_id
 * @description End ACE Game
 */
export async function endACEGame(userToken, userId, quizId, attemptId, exam_id) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId,
        "exam_id": exam_id,
    };
    try {
        const response = await appClient.post(apiConfig.ace.endACEGame, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getACEGameStats
 * @param {*} userToken
 * @param {*} userId
 * @param {*} quizId
 * @param {*} attemptId
 * @description Get ACE Game Statistics
 */
export async function getACEGameStats(userToken, userId, quizId, attemptId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId
    };
    try {
        const response = await appClient.post(apiConfig.ace.getACEGameStats, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getMyLeaderboard
 * @param {*} userToken
 * @param {*} userId
 * @param {*} examId
 * @description Get My LeaderBoard Data
 */
export async function getMyLeaderboard(userToken, userId, examId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "exam_id": examId
    };
    try {
        const response = await appClient.post(apiConfig.ace.getLeaderBoard, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * submitACEPointsRedemption
 * @param {*} userToken
 * @param {*} userId
 * @param {*} examId
 * @description Submit ACE Points Redemptions
 */
export async function submitACEPointsRedemption(userToken, userId, examId, panNo, bankAccountNo, bankAccountName, ifscCode, bankName, accountType, comments) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "exam_id": examId,
        "pan_no" : panNo,
        "bank_account_no" : bankAccountNo,
        "bank_account_name" : bankAccountName,
        "ifsc_code" : ifscCode,
        "bank_name" : bankName,
        "account_type" : accountType,
        "comments" : comments
    };
    try {
        const response = await appClient.post(apiConfig.ace.submitACEPointsRedemption, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * verifyACEPointsCertificate
 * @param {*} userCode
 * @description Verify ACE Points Certificate
 */
export async function verifyACEPointsCertificate(userCode) {
    const payload = {
        'user_code': userCode
    };
    try {
        const response = await appClient.post(apiConfig.ace.verifyACEPoints, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * sendACEGameScoreCard
 * @param {*} userToken
 * @param {*} userId
 * @param {*} quizId
 * @param {*} attemptId
 * @param {*} exam_id
 * @description Send ACE Game ScoreCard
 */
export async function sendACEGameScoreCard(userToken, userId, quizId, attemptId, exam_id) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId,
        "exam_id": exam_id,
    };
    try {
        const response = await appClient.post(apiConfig.ace.sendScoreCard, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getACEGameScoreCard(userToken, userId, quizId, attemptId, exam_id) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId,
        "exam_id": exam_id,
    };
    try {
        const response = await appClient.post(apiConfig.ace.gerReportData, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getACEGameHistory(userToken, userId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
    };
    try {
        const response = await appClient.post(apiConfig.ace.aceGameHistory, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getAceGamePlayStatus(userToken, userId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
    };
    try {
        const response = await appClient.post(apiConfig.ace.aceGamePlayStatus, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getMyCertificates(userToken, userId) {
    const payload = {
        'user_id': userId,
        'token': userToken,
    };
    try {
        const response = await appClient.post(apiConfig.ace.getMyScholarshipCertificates, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}