import * as actions from "redux/action-types";
import {
    getMainCategories as getMainCategoriesAPI,
    getTopicsFromCategory as getTopicsFromCategoryAPI,
    getStudyMaterialFromTopic as getStudyMaterialFromTopicAPI,
} from "services/TopicService";

/**
 * setMainCategoriesLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State of Main Categories Data
 */
export function setMainCategoriesLoadingState(isLoading) {
    return {
        type: actions.LOADING_CATEGORY_DATA,
        isLoading,
    };
}

/**
 * setTopicsLoadingState
 * @param {*} isLoading
 * @description Set Topics Loading State
 */
export function setTopicsLoadingState(isLoading) {
    return {
        type: actions.LOADING_TOPIC_DATA,
        isLoading,
    };
}

/**
 * setReadingLoadingState
 * @param {*} isLoading
 * @description Set Reading Loading State
 */
export function setReadingLoadingState(isLoading) {
    return {
        type: actions.LOADING_READING_DATA,
        isLoading,
    };
}

/**
 * addLibraryFiles
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Library Files into State
 */
export function addCategoryData(items) {
    return {
        type: actions.ADD_CATEGORY_DATA,
        category: items,
    };
}

/**
 * addTopicData
 * @param {*} items
 * @description Add Topic Data
 */
export function addTopicData(items) {
    return {
        type: actions.ADD_TOPIC_DATA,
        topics: items,
    };
}

/**
 * addReadingData
 * @param {*} items
 * @description Add Reading Data
 */
export function addReadingData(items) {
    return {
        type: actions.ADD_READING_DATA,
        reading: items,
    };
}

/**
 * setCategoryAccuracy
 * @param {*} accuracy
 * @description Set Selected Category Accuracy
 */
export function setCategoryAccuracy(accuracy) {
    return {
        type: actions.SET_CATEGORY_ACCURACY,
        categoryAccuracy: accuracy,
    };
}

/**
 * getTopicMainCategories
 * @param {string} tokenId
 * @param {string} userId
 * @description Get Main Category Topics
 */
export function getTopicMainCategories(tokenId, userId) {
    return async (dispatch, getState) => {
        await dispatch(setMainCategoriesLoadingState(true));
        const { data, error } = await getMainCategoriesAPI(tokenId, userId);
        if (!error && data.status) {
            dispatch(addCategoryData(data.data));
        }
        dispatch(setMainCategoriesLoadingState(false));
    };
}

/**
 * getCategoryTopics
 * @param {string} tokenId
 * @param {string} catId
 * @param {string} userId
 * @description Get Category Topics
 */
export function getCategoryTopics(tokenId, catId, userId) {
    return async (dispatch, getState) => {
        await dispatch(setTopicsLoadingState(true));
        const { data, error } = await getTopicsFromCategoryAPI(tokenId, catId, userId);
        if (!error && data.status) {
            dispatch(setCategoryAccuracy(data.data.overall_accuracy));
            dispatch(addTopicData(data.data.topics));
        }
        dispatch(setTopicsLoadingState(false));
    };
}

/**
 * getTopicsReading
 * @param {string} tokenId
 * @param {string} catId
 * @param {string} topicId
 * @param {string} userId
 * @description Get Topics Reading
 */
export function getTopicsReading(tokenId, catId, topicId, userId) {
    return async (dispatch, getState) => {
        await dispatch(setReadingLoadingState(true));
        const { data, error } = await getStudyMaterialFromTopicAPI(tokenId, catId, topicId, userId);
        if (!error && data.status) {
            dispatch(addReadingData(data.data));
        }
        dispatch(setReadingLoadingState(false));
    };
}