import React, { useEffect, Suspense } from "react";
import { setPageTitle } from "utils";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import HeaderAuthenticated from "components/HeaderAuthenticated";
import LeftBar from "components/LeftBar";
import { getUserId, isUserLoggedIn } from "services/AuthService";
import "./ChatLayout.scss";
import { open, close } from "websocket/account";
import backLinkIcon from "assets/images/bottom/back.svg";
import chatLinkIcon from "assets/images/bottom/chat.svg";
import homeLinkIcon from "assets/images/bottom/home.svg";
import { Strings } from "resources";
import queryString from 'query-string';

export default function ChatLayout({
  component: Component,
  pageTitle,
  reducerType,
  ...rest
}) {

  const userId = getUserId();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);

  const setUserLanguage = async (language) => {
    dispatch(AccountActions.setUserLanguage(language));
    Strings.setLanguage(language);
  }

  useEffect(() => {
    if (urlParams?.lang === 'hi') {
      setUserLanguage(urlParams?.lang);
    }
  }, []);

  /**
   * websocketStatus
   * @description Get Current WebSocket Status
   */
  const websocketStatus = useSelector(
    (state) => state.chatData.websocketStatus
  );


  useEffect(() => {
    dispatch(AccountActions.getAccountInfo());
    /* Get Account Settings Details */
    dispatch(AccountActions.getAccountSettings(userId));
    /* Open WebSocket Connections */
    open();
    return () => {
        /* Close WebSocket Connections */
        close();
    };
  }, [dispatch, userId]);

  /**
   * getWebSocketLoaderTitle
   * @description Setup WebSocket Title
   */
  const getWebSocketLoaderTitle = () => {
    if (websocketStatus === "INIT")
      return "Establishing connection, Please wait...";

    return "Connection lost, we will try to reconnect...";
  };

  /* Setup Page Title */
  useEffect(() => {
    if (pageTitle) setPageTitle(pageTitle);
  }, [pageTitle]);
  const { status } = isUserLoggedIn();

  const goToHome = () => {
    history.push("/home");
  }

  const goToChat = () => {
    history.push("/quill-it");
  }

  const goToBack = () => {
    history.goBack();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        status ? (
        <div className="userLayout">
          <div className="userLayoutContainer">

            <Suspense fallback={null}>
                <LeftBar />
            </Suspense>
              <div className="userPageContainer">
                <HeaderAuthenticated currentRoute={props.match.path} isLoggedIn={true} pageTitle={pageTitle} />
                <div className="userPage">
                  <Component {...props} webSocketTitle={getWebSocketLoaderTitle()} webSocketHide={websocketStatus === "CONNECTED" ? true : false} webSocketStatus={websocketStatus} />
                </div>
                <div className="footerNavigationBar">
                  <span className="nItem" onClick={() => goToChat()}>
                    <img className="nIcon" src={chatLinkIcon} alt="Refer" title="Refer" />
                  </span>
                  <span className="nItem between" onClick={() => goToHome()}>
                    <img className="nIcon" src={homeLinkIcon} alt={Strings.refer} title={Strings.refer} />
                  </span>
                  <span className="nItem" onClick={() => goToBack()}>
                    <img className="nIcon" src={backLinkIcon} alt={Strings.refer} title={Strings.refer} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to={`/sign-in/?redirect=${location.pathname}`} />
        )
      }
    />
  );
}
