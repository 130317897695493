import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Strings } from "resources";
import { log } from "utils";
import "./Faq.scss";

export default function Faq(props) {
    const accountData = useSelector((state) => state.accountData);

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

  return (<Fragment>
    <Header showFullWidth={true} />
    <div className="AskedQuestionFAQs">
        <div className="container">
            <div className="FlexQuestion">
                <div className="FrequentlyAsked">
                    <h4>{Strings.faqPageTitle}</h4>
                </div>
                <div className="FrequentlyAskedContent">
                    <div className="flex-content">
                        <div className="content">
                            <h4>{Strings.faqs_1}</h4>
                            <p>{Strings.faqs_1_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_2}</h4>
                            <p>{Strings.faqs_2_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_3}</h4>
                            <p>{Strings.faqs_3_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_4}</h4>
                            <p>{Strings.faqs_4_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_5}</h4>
                            <p>{Strings.faqs_5_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_6}</h4>
                            <p>{Strings.faqs_6_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_7}</h4>
                            <p>{Strings.faqs_7_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_8}</h4>
                            <p>{Strings.faqs_8_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_9}</h4>
                            <p>{Strings.faqs_9_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_10}</h4>
                            <p>{Strings.faqs_10_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_11}</h4>
                            <p>{Strings.faqs_11_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_12}</h4>
                            <p>{Strings.faqs_12_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_13}</h4>
                            <p>{Strings.faqs_13_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_14}</h4>
                            <p>{Strings.faqs_14_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_15}</h4>
                            <p>{Strings.faqs_15_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_16}</h4>
                            <p>{Strings.faqs_16_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_17}</h4>
                            <p>{Strings.faqs_17_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_18}</h4>
                            <p>{Strings.faqs_18_description}</p>
                        </div>
                        <div className="content">
                            <h4>{Strings.faqs_19}</h4>
                            <p>{Strings.faqs_19_description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </Fragment>);
}
