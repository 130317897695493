import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserToken, getUserId, setUserCourseInApp, getUserDetails } from "services/AuthService";
import { Strings } from 'resources';
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import SmallButton from "components/UI/SmallButton";
import Select from 'react-select';
import { log } from "utils";
import "./Courses.scss";
import appConfig from 'config/config';
export default function Courses(props) {
  const alert = useAlert();
  const [subNavItem, setSubNavItem] = useState("all");
  const accountData = useSelector((state) => state.accountData);
  const courseData = useSelector((state) => state.courseData);
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userDetails = getUserDetails();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const [selectedOption, setSelectedOption] = useState({ value: 'all', label: Strings.all });

  /* Handle Site Language */
  useEffect(() => {
      log('Site Language Changed');
      return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get Courses Details */
  useEffect(() => {
    dispatch(CourseActions.getCoursesData(true, userToken));
  }, [dispatch, userToken]);

  /* Get My Active Course */
  useEffect(() => {
    dispatch(CourseActions.getActiveCourse(userToken, userId));
  }, [dispatch, userToken, userId]);

  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /**
   * goToSubscribePackage
   * @param {object} item
   * @param {number} index
   * @description Go To Subscription Package Selection Screen
   */
  const goToSubscribePackage = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push({
        pathname: '/select-subscription-package/',
        state: { examId: item.et_id }
      });
    } else {
      alert.error(Strings.unableToGoSubscriptionPackageScreen);
    }
  }

  /**
   * goToDashboard
   * @param {object} item
   * @param {number} index
   * @description Go To Dashboard Screen
   */
  const goToDashboard = (item, index) => {
    const { error } = setUserCourseInApp(item.et_id);
    if (!error) {
      history.push(`/home/`);
    } else {
      alert.error(Strings.unableToSetCourse);
    }
  }

  /**
   * allSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description All Subscription Item
   */
  const allSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description ;
    const activeCourseId = courseData?.active?.exam_id;
    const activeGroupId = courseData?.active?.group_id;
    const currentId = item.et_id;

    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 courseItemWrapper" key={`file-${index}`}>
        <div className="courseItem">
          <div className="courseHeading">
              <span className="courseTitle">{item.et_name}</span>
          </div>
          <div className="courseDescription" dangerouslySetInnerHTML={{__html : courseDescription}}></div>
          <div className="courseLinks">
              <span className="exploreLink" onClick={() => goToDashboard(item, index)}>{Strings.allExplore}</span>
              {activeCourseId !== currentId && activeGroupId !== '3' && <span className="subscribeLink" onClick={() => goToSubscribePackage(item, index)}>{Strings.allSubscribe}</span>}
              {activeCourseId === currentId && activeGroupId !== '3' && <span className="subscribeLink" onClick={() => goToSubscribePackage(item, index)}>{Strings.allSubscribe}</span>}
          </div>
        </div>
      </div>);
  }

  /**
   * currentSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description Current Subscription Item
   */
  const currentSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description ;
    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 courseItemWrapper" key={`file-${index}`}>
        <div className="courseItem">
          <div className="courseHeading">
              <span className="courseTitle">{item.et_name}</span>
              <span className="coursePackage">{userLanguage === 'hi' ? item.group_name_hindi : item.group_name}</span>
          </div>
          <div className="courseDescription" dangerouslySetInnerHTML={{__html : courseDescription}}></div>
          <div className="courseLinks center">
              <span className="exploreLink" onClick={() => goToDashboard(item, index)}>{Strings.currentEnter}</span>
          </div>
        </div>
      </div>);
  }

  /**
   * pastSubscriptionItem
   * @param {object} item
   * @param {number} index
   * @description Past Subscription Item
   */
  const pastSubscriptionItem = (item, index) => {
    const courseDescription = userLanguage === 'hi' ? item.et_description_hindi : item.et_description ;
    return (<div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4  courseItemWrapper" key={`file-${index}`}>
        <div className="courseItem">
          <div className="courseHeading">
              <span className="courseTitle">{item.et_name}</span>
              <span className="coursePackage">{userLanguage === 'hi' ? item.group_name_hindi : item.group_name}</span>
          </div>
          <div className="courseDescription" dangerouslySetInnerHTML={{__html : courseDescription}}></div>
        </div>
      </div>);
  }

  /**
   * allSubscriptions
   * @description Show All Subscriptions
   */
  const allSubscriptions = () => {

    // for redirect learn protal url 
    const username_encoded = Buffer.from(`${userDetails.first_name}  ${userDetails.last_name}`).toString('base64');
    const email_encoded = Buffer.from(`${userDetails.email}`).toString('base64');
    const password_encoded = localStorage.getItem('upass');
    const is_instructor = Buffer.from(`${userDetails.user_type}`).toString('base64');

    const learnUrl = `${appConfig.learnUrl}/register.php?key=${username_encoded}&key1=${password_encoded}&key2=${email_encoded}&key3=${is_instructor}`;

    return (<div className="row">
        {courseData.items.length === 0 && !courseData.isLoading &&
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
              <span className="noCourse">{Strings.allNoCourses}</span>
          </div>}
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 courseItemWrapper" >
            <div className="courseItem">
              <div className="courseHeading">
                <span className="courseTitle">{Strings.learnTitle}</span>
              </div>
              <div className="courseDescription"> <p> {Strings.learnDescription}</p></div>
            <div className="courseLinks center">
                <a href={learnUrl} className="exploreLink">{Strings.allExplore}</a>
              </div>
            </div>
          </div>
        {courseData.items.length === 0 && courseData.isLoading &&
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
            <span className="noCourse">{Strings.allLoadingCourses}</span>
          </div>
        }
        {courseData.items.length > 0 &&
          courseData.items.map((item, index) => allSubscriptionItem(item, index))
        }
      
      </div>);
  }

  /**
   * currentSubscriptions
   * @description Show Current Subscriptions
   */
  const currentSubscriptions = () => {
    return (<div className="row">
      {accountData.accountSettings.current_subscription.length === 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
          <span className="noCourse">{Strings.noCurrentSubscription}</span>
          <span className="noCourse mb-5">{Strings.goCurrentSubscriptions}</span>
          <SmallButton clicked={() => setSubNavItem('all')} btnType="Orange">{Strings.currentViewCourses}</SmallButton>
        </div>}
      {accountData.accountSettings.current_subscription.length > 0 &&
        accountData.accountSettings.current_subscription.map((item, index) => currentSubscriptionItem(item, index))
      }
    </div>);
  }

  /**
   * pastSubscriptions
   * @description Show Past Subscriptions
   */
  const pastSubscriptions = () => {
    return (<div className="row">
      {accountData.accountSettings.past_subscription.length === 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
          <span className="noCourse">{Strings.noPastSubscriptions}</span>
          <span className="noCourse mb-5">{Strings.goPastCourses}</span>
          <SmallButton clicked={() => setSubNavItem('all')} btnType="Orange">{Strings.pastViewCourses}</SmallButton>
        </div>}
      {accountData.accountSettings.past_subscription.length > 0 &&
        accountData.accountSettings.past_subscription.map((item, index) => pastSubscriptionItem(item, index))
      }
    </div>);
  }
  
  const mainPackageItems = () => {
    const items = [{ value: 'all', label: Strings.all}, { value: 'current-subscriptions', label: Strings.ongoingSubscription}, { value: 'past-subscriptions', label: Strings.pastSubscription}];
    return items;
  }

  const goToPackageItem = (item, index) => {
    setSelectedOption(item);
    setSubNavItem(item.value);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo">
        <div className="myPage myCourses">
            <div className="pageTitle">
              <h1>{Strings.coursesHeading}</h1>
            </div>
            <div className="subscriptionNavigationWrapper">
              <ul className="subscriptionNavigation">
                <li className={`item ${subNavItem === 'all' ? 'active' : ''}`} onClick={() => setSubNavItem('all')}><span>{Strings.all}</span></li>
                <li className={`item ${subNavItem === 'current-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('current-subscriptions')}><span>{Strings.ongoingSubscription}</span></li>
                <li className={`item ${subNavItem === 'past-subscriptions' ? 'active' : ''}`} onClick={() => setSubNavItem('past-subscriptions')}><span>{Strings.pastSubscription}</span></li>
              </ul>
            </div>
            <div className="mobileCategoryListings">
              <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h4 className="categoryTitle">{Strings.selected_subscription} : {selectedOption?.label}</h4>
                      <Select 
                        defaultValue={selectedOption}
                        value={selectedOption}
                        isSearchable
                        options={mainPackageItems()}
                        placeholder="Select Subscription"
                        onChange={(item, index) => goToPackageItem(item, index)}
                      />
                  </div>
              </div>
            </div>
            <div className="subscriptionListings">
              {subNavItem === 'all' && allSubscriptions()}
              {subNavItem === 'current-subscriptions' && currentSubscriptions()}
              {subNavItem === 'past-subscriptions' && pastSubscriptions()}
            </div>
        </div>
      </div>
    </Fragment>
  );
}
