import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import { Strings } from 'resources';
import { log } from "utils";
import "./Certificate.scss";

/* Images required for Certificate */
import BharatCETLogo from 'assets/images/logo/logoBeta.png';
import facebookBlack from "assets/images/certificate/facebookIcon.png";
import twitterBlack from "assets/images/certificate/twitterIcon.png";
import instagramBlack from "assets/images/certificate/instagramIcon.png";
import linkedInBlack from "assets/images/certificate/linkedInIcon.png";
import youTubeBlack from "assets/images/certificate/youTubeIcon.png";
import awardsIcon from "assets/images/certificate/awardsicon.png";

export default function Certificate(props) {
    const accountData = useSelector((state) => state.accountData);
        
    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);
    /**
     * getCurrentYear
     * @description Get Current Year
     */
    const getCurrentYear = () => {
        const date = new Date();
        const formattedDate = moment(date).utc(true).format("YYYY");
        return formattedDate;
    }

    /**
     * getCertificateDate
     * @param {*} date
     * @description Get Certificate Date
     */
    const getCertificateDate = (date) => {
        const formattedDate = moment(date).utc(true).format("DD-MMMM-YYYY");
        return formattedDate;
    }

    return (<>
        <div className="aceGameCertificate" id="my-acegame-certificate">
            <div className="logo">
                <img src={BharatCETLogo} alt={Strings.bharatCETName} title={Strings.bharatCETName} />
            </div>
            <h2>{Strings.scholarshipCertificate}</h2>
            <p>{Strings.hereByDelivered}</p>
            <h3>{props.user.first_name} {props.user.last_name}</h3>
            <div className="acePointDetail">
                <p>{Strings.forAchieving} <span>{props.redemptionDetails.ace_points} {Strings.myAcePoints}</span> {Strings.andReachingThe}</p>
                <div className="level">{props.currentLevel.stage_name_en} {Strings.stageLevel}</div>
            </div>
            <div className="extraInfo">
                <div className="awardIcon">
                    <img src={awardsIcon} alt={Strings.awards} title={Strings.awards} />
                    <p className="date">{getCertificateDate(props.redemptionDetails.created_on)}</p>
                </div>
                <div className="siteInfo">
                    <p>{Strings.verifyAt} <span target="_blank">https://portal.bharatcet.com/verify/</span><span>{props.user.user_code}</span></p>
                    <p>{Strings.confirmIdentity}</p>
                </div>
            </div>
            <div className="copyRightSection">
                <ul className="socialIcons">
                    <li><span><img src={twitterBlack} alt={Strings.twitter} title={Strings.twitter} /></span></li>
                    <li><span><img src={facebookBlack} alt={Strings.facebook} title={Strings.facebook} /></span></li>
                    <li><span><img src={linkedInBlack} alt={Strings.linkedIn} title={Strings.linkedIn} /></span></li>
                    <li><span><img src={instagramBlack} alt={Strings.instaGram} title={Strings.instaGram} /></span></li>
                    <li><span><img src={youTubeBlack} alt={Strings.youTube} title={Strings.youTube} /></span></li>
                </ul>
                <p>{Strings.certificate} &copy; {getCurrentYear()} {Strings.techProEduSys}</p>
            </div>
        </div>
    </>);

}
