import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AceTestActions from "redux/actions/AceTestActions";
import { Strings } from 'resources';
import "./Certificate.scss";
import apiConfig from "config/api";
import appConfig from "config/config";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton } from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon } from "react-share";
  import { log } from "utils";
import Certificate from "components/Certificate";

export default function ACEGameCertificate(props) {
    const dispatch = useDispatch();
    const aceTestData = useSelector((state) => state.aceTestData);
    const accountData = useSelector((state) => state.accountData);
    const shareUrl = 'https://portal.bharatcet.com/verify/'+accountData?.account?.user_code;
    const title = 'Certificate of Scholarship | BharatCET';

    useEffect(() => {
      const userUniqueCode = accountData?.account?.user_code;
      dispatch(AceTestActions.getACEGameCertificate(userUniqueCode));
    }, [dispatch, accountData]);

    /* Handle Site Language */
    useEffect(() => {
      log('Site Language Changed');
      return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    const downloadScholarshipCertificate = () => {
      const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadFile + "?file="+aceTestData?.certificate?.redemptionDetails?.file_url;
      fetch(fileDownloadUrl)
          .then(response => {
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = 'scholarship_certificate';
                  a.click();
              });
              //window.location.href = response.url;
      });
  }

  const downloadLinkDescription = (fileUrl) => {
    const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadPDF + "?file="+fileUrl;
    return '<a class="gameActionLink" href="'+fileUrl+'">Save</a>';
  }

    const showCertificateView = () => {
      return(<>
              <Certificate user={aceTestData?.certificate} currentLevel={aceTestData?.certificate?.currentLevel} redemptionDetails={aceTestData?.certificate?.redemptionDetails} />
              <div className="actions">
                <div className="saveBtn" dangerouslySetInnerHTML={{__html : downloadLinkDescription(aceTestData?.certificate?.redemptionDetails?.file_url)}}></div>
                <p>{Strings.certificateLetKnow}</p>
                <ul className="socialIcons">
                  <li>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                       <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </li>
                  <li>
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                         <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>
                        </li>
                    </ul>
                </div>
      </>);
    }

    const showNotFoundView = () => {
      return (<div className="notFoundCertificate">
                {aceTestData?.certificate?.user_code && !aceTestData?.isLoading && <p>{Strings.certificateUserWithCode} {aceTestData?.certificate?.user_code} {Strings.certificateNotEligible}</p>}
                {!aceTestData?.certificate?.user_code && !aceTestData?.isLoading && <p>{Strings.certificateUserWithCode} {accountData?.account?.user_code} {Strings.certificateDoNotExits}</p>}
                {aceTestData?.isLoading && <p>{Strings.certificateLoading}</p>}
              </div>);
    }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg noPadding">
        <div className="myPage">
          <div className="myPageWrapper certificatePage">
            <div className="certificateRow">
                {aceTestData?.certificate?.redemptionDetails && showCertificateView()}
                {!aceTestData?.certificate?.redemptionDetails && showNotFoundView()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
