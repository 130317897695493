import React, { Fragment, useState, useEffect } from "react";
import Header from 'components/Header';
import Footer from 'components/Footer';
import { getNotification as getNotificationAPI } from "services/AuthService";
import { Strings } from "resources";
import { log } from "utils";
import { useSelector } from "react-redux";
import KnowledgeNotification from "components/KnowledgeNotification";
import "./Notification.scss";

export default function Notification(props) {
  const [notificationList, setNotificationList] = useState([]);
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }, []);

   useEffect(() => {
    async function getNotification(){
        const { data } = await getNotificationAPI();
        if(data.status) {
          setNotificationList(data.data);
        }
    }
    getNotification();
  }, []);

  const notificationItemView = (item, index) => {
      return (<KnowledgeNotification itemData={item} index={index} />);
  }

  function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.id) {
            let index = oldData.findIndex(data => (data.id && data.id === newDataItem.id));
            if (index === -1) {
                oldData.push(newDataItem);
            }
        }
    });
    return oldData;
  }

  const fetchMoreData = async () => {
      const payload = {
        'page': 1,
        'limit': 10
      }
      const { data } = await getNotificationAPI(payload);
      if(data?.status) {
        const newNotifications = data.data;
        const notificationData = mergeData(notificationList, newNotifications);
        setNotificationList(notificationData);
      }
  }

  return (<Fragment>
    <Header showFullWidth={true} />
    <div className="notification-section">
        <div className="container">
            <div className="some-text-notification">
                <h3>{Strings.notifPageTitle}</h3>
                {notificationList.length > 0 &&
                  notificationList.map((item, index) => notificationItemView(item, index))
                }
            </div>
        </div>
    </div>
    <Footer />
    </Fragment>);
}
