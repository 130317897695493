import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { Strings } from "resources";
import { applyForRedemption as applyForRedemptionFromAPI } from "services/ReferService";
import { useAlert } from "react-alert";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as ReferActions from "redux/actions/ReferActions";
import { getUserId, getUserToken } from "services/AuthService";
import "./ReferralConfirmation.scss";

export default function ReferralConfirmation(props) {
  const alert = useAlert();
  const navigationData = useSelector((state) => state.navigationData);
  const referData = useSelector((state) => state.referData);
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const [accountType, setAccountType] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [verify, setVerify] = useState(false);
  const [fields, setFields] = useState([
    {
      name: Strings.accountName,
      key: "bank_account_name",
      value: "",
      type: "text",
      maxLength: 300,
      disabled: false,
      rules: "required|alpha|max:300",
      touched: false,
      placeholder: Strings.accountNamePlaceHolder
    },
    {
      name: Strings.bankName,
      key: "bank_name",
      value: "",
      type: "text",
      maxLength: 300,
      disabled: false,
      rules: "required|alpha|max:300",
      touched: false,
      placeholder: Strings.bankNamePlaceholder
    },
    {
      name: Strings.panNo,
      key: "pan_no",
      value: "",
      type: "text",
      maxLength: 30,
      disabled: true,
      rules: "required|max:30",
      touched: false,
      placeholder: Strings.panNoPlaceholder
    },
    {
      name: Strings.ifscCode,
      key: "ifsc_code",
      value: "",
      type: "text",
      maxLength: 30,
      disabled: false,
      rules: "required|max:30",
      touched: false,
      placeholder: Strings.ifscCodePlaceholder
    },
    {
      name: Strings.accountNumber,
      key: "bank_account_no",
      value: "",
      type: "text",
      maxLength: 30,
      disabled: false,
      rules: "required|max:30",
      touched: false,
      placeholder: Strings.accountNumberPlaceholder
    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);

  /**
   * Update User's PAN Card PreFilled
   */
  useEffect(() => {
    if (accountData && accountData.accountSettings) {
      const userPanCard = accountData.accountSettings.pan_card_no;
      const newFieldArray = [...fields];
      newFieldArray[2].value = userPanCard;
      newFieldArray[2].touched = true;
      setFields(newFieldArray);
    }
 }, [accountData]);

  /**
   * hideModal
   * @description Hide Referral Confirmation Success Modal
   */
  const hideModal = () => {
    dispatch(NavigationActions.closeReferralConfirmationModal());
  };

  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Handle Field Update
   */
  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description Check Validation Errors
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Field
   */
  const renderField = (field, index) => {
    return (
      <Input
        placeholder={field.placeholder}
        onChange={(event) => fieldUpdated(event.target.value, index)}
        onBlur={(event) => fieldUpdated(event.target.value, index)}
        key={index}
        touched={field.touched}
        value={field.value}
        disabled={disabled}
        errorMessage={
          field.errors && field.errors.length ? field.errors[0] : ""
        }
        secure={field.secure}
      />
    );
  };

  /**
   * editFormDetails
   * @param {*} isEdit
   * @description Edit Form Details
   */
  const editFormDetails = (isEdit) => {
    setVerify(isEdit);
    setDisabled(isEdit);
  }

  /**
   * submit
   * @param {*} event
   * @description Submit Redemption Action
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    if (verify) {
        confirmRedemptionAction(getPayload(fields));
    } else {
        setVerify(true);
        setDisabled(true);
    }
  };

  /**
   * confirmRedemptionAction
   * @param {*} payload
   * @description Confirm Redemption Action
   */
  const confirmRedemptionAction = async (payload) => {
    setLoadingState(true);
    const { data } = await applyForRedemptionFromAPI(userToken, userId, referData.amount, payload.bank_account_no, payload.bank_name, payload.ifsc_code, payload.bank_name, accountType);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return redemptionConfirmSuccess(data.data);
    }
  };

  /**
   * redemptionConfirmSuccess
   * @param {*} responseData
   * @description Redemption Confirm Success
   */
  const redemptionConfirmSuccess = (responseData) => {
    dispatch(ReferActions.getInvitedFriendsData(true, userId, userToken));
    dispatch(NavigationActions.toggleReferralConfirmationSuccessModal(true));
    dispatch(NavigationActions.closeReferralConfirmationModal());
    return true;
  };

  /**
   * getPayload
   * @param {*} fields
   * @description Get PayLoad
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  return (<>
    <div
      className={`popupWrapper dispersionLogo ${
        !navigationData.showReferralConfirmation ? "closed" : "opened"
      }`}
    >
       <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{verify ? Strings.confirmBankDetails : Strings.bankDetails}</h3>
            <form onSubmit={(event) => submit(event)}>
                {fields.map((field, index) => renderField(field, index))}
                <div className="input-container">
                    <div className="TextInput">
                        <select className="InputElement" onChange={e => setAccountType(e.target.value)} disabled={disabled}>
                            <option value="">{Strings.selectAccountType}</option>
                            <option value="savings">{Strings.savingsAccount}</option>
                            <option value="current">{Strings.currentAccount}</option>
                        </select>
                    </div>
                </div>
                {verify && <div className="buttonContainer">
                    <Button disabled={hasErrors} loading={isLoading} btnType="Orange">{Strings.confirm}</Button>
                    <div className="btn-container"><span className="Button White" onClick={() => editFormDetails(false)}>{Strings.goBack}</span></div>
                </div>}
                {!verify && <div className="buttonContainer">
                    <Button disabled={hasErrors} loading={isLoading} btnType="Orange">{Strings.submit}</Button>
                </div>}
            </form>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showReferralConfirmation ? "closed" : "opened"}`}></div>
  </>
  );
}
