import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import * as NavigationActions from "redux/actions/NavigationActions";
import userImage from "assets/images/page/featureImage.svg";
import "./FeatureNotAvailable.scss";

export default function FeatureNotAvailable(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * redirectToUpgrade
   * @description Redirect to Upgrade Screen
   */
  const redirectToUpgrade = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
    history.push({
        pathname: '/select-subscription-package/',
        state: { examId: '1' }
      });
  }

  return (<>
    <div
      className={`popupWrapper FeatureNotAvailable dispersionLogo ${
        !navigationData.showFeatureNotAvailableModal ? "closed" : "opened"
      }`}
    >
      <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
      <div className="popUpInner">
        <div className="featuredIconWrapper">
            <img className="featuredIcon" src={userImage} title={Strings.success} alt={Strings.success} />
        </div>
        <p>{Strings.featureNotAvailableText}</p>
        <SmallButton clicked={() => redirectToUpgrade()} btnType="Orange">{Strings.unlock}</SmallButton>
      </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showFeatureNotAvailableModal ? "closed" : "opened"}`}></div>
  </>
  );
}
