/**
 * config
 * application Configuration
 */

 let config = {
   appName: "BharatCET Game",
   siteUrl: "https://manage.bharatcet.com/v1/",
   apiUrl: "https://manage.bharatcet.com/v1/",
   webSocketUrl: "wss://13.127.143.191:8443/chat/websocket",
   chatAPIUrl: "https://13.127.143.191:8443/chat/api/v1/",
   redirectUrl: "https://portal.bharatcet.com/",
   learnUrl: "https://lms.bharatcet.in",
   environment: "local",
 };

const isProd = false;
const deploymentType = isProd ? "production" : "development";

if (deploymentType === "production") {
    config = {
      ...config,
      siteUrl: "https://sampark.bharatcet.com/v1/",
      apiUrl: "https://sampark.bharatcet.com/v1/",
      webSocketUrl: "wss://13.127.143.191:8443/chat/websocket",
      chatAPIUrl: "https://13.127.143.191:8443/chat/api/v1/",
      environment: "production",
      learnUrl: "https://learn.bharatcet.com",
    };
} else if (deploymentType === "staging") {
    config = {
        ...config,
        siteUrl: "https://manage.bharatcet.com/v1/",
        apiUrl: "https://manage.bharatcet.com/v1/",
        redirectUrl: "https://portal-dev.bharatcet.com/",
        webSocketUrl: "wss://13.127.143.191:8443/chat/websocket",
        chatAPIUrl: "https://13.127.143.191:8443/chat/api/v1/",
        environment: "staging",
    };
} else if (deploymentType === "development") {
    config = {
        ...config,
        environment: "development",
        redirectUrl: "https://portal-dev.bharatcet.com/",
    };
}

export default config;
