import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from "components/UI/Input";
import Button from "components/UI/Button";
import { Validation, log } from "utils";
import { Strings } from "resources";
import { GoogleLogout, GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { 
  createUser,
  loginUserInApp,
  doGoogleAuthentication as doGoogleAuthenticationFromAPI,
  doFacebookAuthentication as doFacebookAuthenticationFromAPI
} from 'services/AuthService';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import queryString from 'query-string';
import { useAlert } from "react-alert";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as ChatActions from "redux/actions/ChatActions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SignUp.scss";

export default function SignUp(props) {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigationData = useSelector((state) => state.navigationData);
  const [role, setRole] = useState('student');
  const [fields, setFields] = useState([
    {
      name: Strings.firstName,
      key: "firstName",
      value: "",
      type: "text",
      maxLength: 50,
      rules: "required|max:50",
      touched: false,
      icon: false,
      autoComplete: false,
      placeholder: Strings.firstName_placeholder
    },
    {
      name: Strings.lastName,
      key: "lastName",
      value: "",
      type: "text",
      maxLength: 50,
      rules: "required|max:50",
      touched: false,
      icon: false,
      autoComplete: false,
      placeholder: Strings.lastName_placeholder
    },
    {
      name: Strings.mobileNo,
      key: "mobileNumber",
      value: "",
      type: "text",
      maxLength: 10,
      minLength: 10,
      rules: "required|numeric|min:10|max:10",
      touched: false,
      icon: false,
      autoComplete: false,
      placeholder: Strings.mobileNo_placeholder
    },
    {
      name: Strings.email_address,
      key: "emailAddress",
      value: "",
      type: "text",
      maxLength: 50,
      rules: "required|email|max:50",
      touched: false,
      icon: false,
      autoComplete: false,
      placeholder: Strings.email_address_placeholder
    },
    {
      name: Strings.password,
      key: "password",
      value: "",
      type: "text",
      maxLength: 15,
      minLength: 8,
      rules: 'required|no_space|min:8|max:15',
      touched: false,
      secure: true,
      icon: true,
      autoComplete: false,
      placeholder: Strings.password_placeholder
    },
    {
      name: Strings.confirmPassword,
      key: "confirm_password",
      value: "",
      type: "text",
      minLength: 8,
      maxLength: 15,
      rules: 'required|no_space|match_index:4|min:8|max:15',
      touched: false,
      secure: true,
      icon: true,
      autoComplete: false,
      placeholder: Strings.confirmPassword_placeholder
    },
    {
      name: "role",
      key: "role",
      value: role,
      type: "hidden",
      rules: 'required',

    },
  ]);
  const [isLoading, setLoadingState] = useState(false);
  const [hasErrors, setHasErrors] = useState(true);
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  /**
   * fieldUpdated
   * @param {*} text
   * @param {*} index
   * @description Handle Input Field State Update
   */
  const fieldUpdated = (text, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].value = text;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
    const fieldsWithError = Validation.validate(newFieldArray);
    const error = checkErrors(fieldsWithError);
    setHasErrors(error);
  };

  /**
   * checkErrors
   * @param {*} fields
   * @description Check Input Field Validation Errors
   */
  const checkErrors = (fields) => {
    let hasError = false;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.errors && field.errors.length) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  /**
   * renderField
   * @param {*} field
   * @param {*} index
   * @description Render Input Field
   */
  const renderField = (field, index) => {
    if (field.key != 'role'){
      return (
        <Input
          placeholder={field.placeholder}
          onChange={(event) => fieldUpdated(event.target.value, index)}
          onBlur={(event) => fieldUpdated(event.target.value, index)}
          key={index}
          touched={field.touched}
          value={field.value}
          errorMessage={
            field.errors && field.errors.length ? field.errors[0] : ""
          }
          secure={field.secure}
          icon={field.icon}
          iconPressed={() => iconShowPressed(field.secure, index)}
        />
      );
    }
  };


  const updateRole = (text) => {
    setRole(text);
    const newFieldArray = [...fields];
    fieldUpdated(text, newFieldArray.length - 1)
  }


  /**
   * Select Box for user role
   */
  const SelectRole = () => {
    return (
      <div className="input-container">
        <select className="InputElement " style={{
          outline: '1px solid #e5e5e5', cursor: 'pointer', padding: '15px'
        }} onChange={(event) => {setRole(event.target.value);  fieldUpdated(event.target.value, fields.length-1);}} >
          <option value="student">Register as {Strings.singleStudent }</option>
          <option value="professor">Register as {Strings.instructor }</option>
        </select>
      </div>
    )
  }

  const iconShowPressed = (isSecure, index) => {
    const newFieldArray = [...fields];
    newFieldArray[index].secure = !isSecure;
    newFieldArray[index].touched = true;
    setFields(newFieldArray);
  }

  /**
   * submit
   * @param {*} event
   * @description Submit Input Action
   */
  const submit = (event) => {
    event.preventDefault();
    if (hasErrors || isLoading) return;
    createAccount(getPayload(fields));
  };

  /**
   * createAccount
   * @param {*} payload
   * @description Create Account
   */
  const createAccount = async (payload) => {
    setLoadingState(true);
    const { data } = await createUser(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return accountCreationSuccess(data);
    }
  };

  /**
   * accountCreationSuccess
   * @param {*} data
   * @description Account Creation Success
   */
  const accountCreationSuccess = (data) => {
   alert.success(Strings.successSignUp);
   const chatUserId = data?.data;
   dispatch(ChatActions.updateChatUserDetails(chatUserId));
   dispatch(NavigationActions.setActivateUserModalStatus(true));
  };

  const hideModal = async () => {
    dispatch(NavigationActions.setSignUpModalStatus(false));
  }

  /**
   * getPayload
   * @param {*} fields
   * @description Get PayLoad
   */
  const getPayload = (fields) => {
    let payload = {};
    fields.forEach((field, index) => {
      payload[field.key] = field.value;
    });
    return payload;
  };

  const success = async (response) => {
    const gEmail = response?.profileObj?.email;
    const gFirstName = response?.profileObj?.givenName;
    const gLastName = response?.profileObj?.familyName;
    const gGoogleId = response?.profileObj?.googleId;
    const gUserImage = response?.profileObj?.imageUrl;
    const gUserFullName = response?.profileObj?.name
    setLoadingState(true);
    const { data } = await doGoogleAuthenticationFromAPI(gEmail, gFirstName, gLastName, gGoogleId, gUserImage, gUserFullName);
    if (!data.status) {
      alert.error(Strings[data.message]);
      setLoadingState(false);
      return log(data.message);
    } else {
      return userLoginSuccess(data.data);
    }
  }

  const error = async (response) => {
    log('^^^ERROR^^^');
    console.error(response);
  }

  const loading = () => {
    log('^^^LOADING^^^');
  }

  /**
   * userLoginSuccess
   * @param {*} userData
   * @description User Login Success
   */
   const userLoginSuccess = (userData) => {
    const user = {
      token: userData.token ? userData.token : "",
      user: userData ? userData : {},
      role: userData ? userData.account_name : '',
    };
    loginUser(user);
  };

  /**
   * loginUser
   * @param {*} user
   * @description Login User Success Action
   */
  const loginUser = (user) => {
    const { error } = loginUserInApp(user);
    if (!error) {
      alert.success(Strings.login_success);
      dispatch(NavigationActions.closeAuthModalStatus());
      if (props.isRedirect) {
        history.push(props.redirectTo);
      } else {
        history.push("/my-courses");
      }
    }
    return true;
  };

  const responseFacebook = async (response) => {
    log(response);
    setLoadingState(true);
    const fEmail = response?.email;
    const fName = response?.name;
    const fbUserId = response?.userID;

    if (fbUserId === '' || fName === '' || fEmail === '') {
      alert.error(Strings.invalid_account_details_passed);
      setLoadingState(false);
      return log(Strings.invalid_account_details_passed);
    }
    
    const { data } = await doFacebookAuthenticationFromAPI(fEmail, fName, fbUserId, fName);
    if (!data.status) {
      alert.error(Strings[data.message]);
      setLoadingState(false);
      return log(data.message);
    } else {
      return fbUserLoginSuccess(data.data);
    }
  }

  /**
   * fbUserLoginSuccess
   * @param {*} userData
   * @description User Login Success
   */
   const fbUserLoginSuccess = (userData) => {
    const user = {
      token: userData?.token ? userData?.token : "",
      user: userData ? userData : {},
      role: userData ? userData?.account_name : '',
    };
    fbLoginUser(user);
  };

  /**
   * loginUser
   * @param {*} user
   * @description Login User Success Action
   */
  const fbLoginUser = (user) => {
    const { error } = loginUserInApp(user);
    if (!error) {
      alert.success(Strings.login_success);
      dispatch(NavigationActions.closeAuthModalStatus());
      //if (props.isRedirect) {
      //  history.push(props.redirectTo);
      //} else {
        const urlRedirect = urlParams?.redirect || '';
        if (urlRedirect === '' || urlRedirect === null) {
          history.push("/my-courses");
        } else {
          history.push(urlRedirect);
        }
      //}
    }
    return true;
  };

  return (<>
    <div
      className={`popupWrapper Auth SignUp dispersionLogo ${
        !navigationData.showSignUpModal ? "closed" : "opened"
      }`}
      style={{ overflowY: 'auto' }}
    >

    <span onClick={() => hideModal()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>

    <div className="popUpInner" >
    <h3 className="modal-heading"><span className="lTab" onClick={() => props.onLoginShow()}>{Strings.login}</span> | <span className="lTab Active">{Strings.signUp}</span></h3>
      <form onSubmit={(event) => submit(event)}>
          {SelectRole()}
          {fields.map((field, index) => renderField(field, index))}
          <Button disabled={hasErrors} loading={isLoading} btnType="Orange">
            {Strings.formRegister}
          </Button>
      </form>
      <div className="nav-link-container">
        <span className="resetPasswordLink">{Strings.haveCredentials} <span className="Link" onClick={() => props.onLoginShow()}>{Strings.login}</span></span>
      </div>
      <div className="nav-link-container socialLinkContainer">
          <span className="createAccountLink">{Strings.signUpWith} 
            <span className="SocialLink GoogleAppLogin">
              <GoogleLogin
                clientId="898486648506-0rpoug1q5k44ggf7dtpetfptkk072mhm.apps.googleusercontent.com"
                onSuccess={success}
                onFailure={error}
                onRequest={loading}
                approvalPrompt="force"
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                icon={false}
                style={{ backgroundColor: 'red' }}
              ><FontAwesomeIcon className="fa googleLogin" icon={faGoogle} />
              </GoogleLogin>
            </span>
            <span className="SocialLink FacebookAppLogin">
              <FacebookLogin
                appId="134950368646735"
                autoLoad={true}
                fields="name,email"
                callback={responseFacebook}
                cssClass="fb-button"
                icon="fa-facebook"
                textButton=""
              />
            </span>
          </span>
        </div>
    </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showSignUpModal ? "closed" : "opened"}`} onClick={() => hideModal()}></div>
  </>);
}
