import * as actions from "redux/action-types";

const initialState = {
  account: null,
  accountSettings: null,
  language: 'en',
  states: [],
  statistics: null,
  events: [],
  accuracy: {
    weekNo: [],
    accuracyPercentage: []
  },
  points: null,
  searchQuery: null,
  searchData: null,
  status: {
    group: '0',
    exam: '1',
    examAttempted: '0',
    valid: false
  }
};
export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actions.SET_FREE_TRIAL_VALIDATION:
      const status = {
        group: action.groupId,
        exam: action.examId,
        valid: action.isValid,
        examAttempted: action.examAttempted
      };
      return {
        ...state,
        status: status
      };
    case actions.SET_SEARCH_DATA:
      return {
        ...state,
        searchQuery: action.searchQuery,
        searchData: action.searchData,
      };
    case actions.CLEAR_SEARCH_DATA:
      return {
        ...state,
        searchQuery: null,
        searchData: null,
      };
    case actions.SET_USER_DASHBOARD_POINTS:
      return {
        ...state,
        points: action.points,
      };
    case actions.SET_CHART_ACCURACY_DETAILS:
      return {
        ...state,
        accuracy: action.accuracy,
      };
    case actions.ADD_SITE_STATISTICS:
      return {
        ...state,
        statistics: action.statistics,
      };
    case actions.ADD_SITE_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case actions.ADD_STATE_DATA:
      return {
        ...state,
        states: action.states,
      };
    case actions.ADD_USER_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case actions.ADD_ACCOUNT:
      return {
        ...state,
        account: action.account,
      };
    case actions.ADD_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountSettings: action.accountSettings,
      };
    default:
      return state;
  }
}
