import React, { Fragment, useState, useEffect } from "react";
import { log } from "utils";
import { openInNewTab } from "utils/helpers";
import { useSelector } from "react-redux";
import { sendWelcomeEmail as sendWelcomeEmailFromAPI } from "services/AuthService";
import { useAlert } from "react-alert";
import { Strings } from 'resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter, faLinkedin, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import moment from "moment";
import "./Footer.scss";
import { useHistory } from "react-router-dom";
import FooterPhoneImage from 'assets/images/v2/footer/footerPhoneImage.svg';

export default function Footer(props) {

    const alert = useAlert();
    const history = useHistory();
    const accountData = useSelector((state) => state.accountData);
    const [isLoading, setLoadingState] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);

    const getCurrentYear = () => {
        const date = new Date();
        const formattedDate = moment(date).utc(true).format("YYYY");
        return formattedDate;
    }

    const sendWelcomeEmail = async () => {
        if (userEmail === '' || userEmail === null) {
            alert.error(Strings.emailRequired);
            return log(Strings.emailRequired);
        }
        setLoadingState(true);
        const { data, error } = await sendWelcomeEmailFromAPI(userEmail);
        setLoadingState(false);
        if (error) {
            alert.error(error);
            return log(error);
        } else {
            return onWelcomeEmailSuccess(data.data);
        }
    };

    const onWelcomeEmailSuccess = (data) => {
        setUserEmail('');
        alert.success(Strings.welcomeEmailSentSuccess);
    };

    const goToFacebook = () => {
        openInNewTab(Strings.facebookURL);
    }

    const goToTwitter = () => {
        openInNewTab(Strings.twitterURL);
    }

    const goToLinkedIn = () => {
        openInNewTab(Strings.linkedInURL);
    }

    const goToInstagram = () => {
        openInNewTab(Strings.instagramURL);
    }

    const goToYouTube = () => {
        openInNewTab(Strings.youTubeURL);
    }

    const goToNRACET = () => {
        window.location.href = Strings.nraCETURL;
    }

    const goToHome = () => {
        window.location.href = Strings.websiteURL;
    }

    const goToHomeDashboard = () => {
        window.location.href = Strings.websiteURL;
    }

    const goToTermsConditions = () => {
        window.location.href = Strings.termsAndConditionsURL;
    }

    const goToPrivacyPolicy = () => {
        window.location.href = Strings.privacyPolicyURL;
    }

    const goToProcedurePolicy = () => {
        window.location.href = 'https://bharatcet.com/policies-procedures/#referralTab';
    }

    const goToContactUs = () => {
        window.location.href = Strings.contactUsURL;
    }

    const goToNotifications = () => {
        history.push("/notifications");
    }

    const goToFAQs = () => {
        history.push("/faqs");
    }

    return (<Fragment>
        <footer className="WithoutAuthFooter">
		    <div className="container">
			    <div className="flex-footer">
				    <div className="footer-mob-img">
					    <div className="footer-col">
                            <img src={FooterPhoneImage} alt={Strings.bharatCET} title={Strings.bharatCET} onClick={() => goToHomeDashboard()} />
					    </div>
    				</div>
				    <div className="footer-inner">
                        <div className="courses-menu">
                            <div className="footer-col">
                                <h4 className="title">{Strings.footerCourses}</h4>
                                <div className="menu-courses-menu-container">
                                    <ul id="menu-courses-menu" className="menu">
                                        <li className="menu-item"><span onClick={() => goToNRACET()}>{Strings.nraCET}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="foot-menu">
                            <div className="footer-col">
                                <h4 className="title">{Strings.footerLinks}</h4>
                                <div className="menu-footer-menus-container">
                                    <ul className="menu">
                                        <li className="menu-item"><span onClick={() => goToHome()}>{Strings.home}</span></li>
                                        <li className="menu-item"><span onClick={() => goToTermsConditions()}>{Strings.termsConditions}</span></li>
                                        <li className="menu-item"><span onClick={() => goToPrivacyPolicy()}>{Strings.privacyPolicy}</span></li>
                                        <li className="menu-item"><span onClick={() => goToProcedurePolicy()}>{Strings.policies} &amp; {Strings.procedures}</span></li>
                                        <li className="menu-item"><span onClick={() => goToContactUs()}>{Strings.contactUs}</span></li>
                                        <li className="menu-item"><span onClick={() => goToNotifications()}>{Strings.footerNotification}</span></li>
                                        <li className="menu-item"><span onClick={() => goToFAQs()}>{Strings.faqs} &amp; {Strings.troubleshooting}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
					<div className="getMoreDetails">
						<div className="footer-col">
							<h4>{Strings.getMoreDetails}</h4>
							<div className="footer-form">
                                <div className="sendWelcomeEmail">
                                    <input name="email" type="email" id="email" className="subscribe-email" value={userEmail} placeholder={Strings.sendEmailPlaceholder} onChange={(e) => { setUserEmail(e.target.value); }} />
                                    <button className="submit" type="submit" value="submit" onClick={() => sendWelcomeEmail()}>
                                        {isLoading && <span className="loading-con">
                                        <FontAwesomeIcon icon={faCircleNotch} spin />
                                        </span>}
                                        {!isLoading && <span>{Strings.sendEmailGo}</span>}
                                    </button>
                                </div>
							</div>
							<div className="footerSocialIconWrapper">
								<ul className="socialMLinks">
                                    <li><span onClick={() => goToFacebook()}><FontAwesomeIcon className="fa" icon={faFacebookF} /></span></li>
                                    <li><span onClick={() => goToLinkedIn()}><FontAwesomeIcon className="fa" icon={faLinkedin} /></span></li>
                                    <li><span onClick={() => goToInstagram()}><FontAwesomeIcon className="fa" icon={faInstagram} /></span></li>
                                    <li><span onClick={() => goToYouTube()}><FontAwesomeIcon className="fa" icon={faYoutube} /></span></li>
                                </ul>
							</div>
                            <div className="footer-sub-copyright">
                                <p>Techpro Edusys Private Limited | 904, Wing B, CTS NO 1070/1 To 6 Elanza, Off Link Road, Malad West, Mumbai 400 064, Maharashtra, India </p><p>GSTIN: 27AAICT2638J1Z0</p>
                                <p>Email: <span>social@bharatcet.com</span></p>
                            </div>
						</div>
					</div>

				</div>
			    </div>
			    <div className="clearfix"></div>
    			<div className="footer-copyright">
	    			<p>{Strings.certificate} &copy; {getCurrentYear()} {Strings.techProEduSys}.</p>
			    </div>
	        </div>
	    </footer>
        </Fragment>
    );

}
