import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as NavigationActions from "redux/actions/NavigationActions";
import { getUserId, getUserToken } from "services/AuthService";
import { getACEGameHistory as getACEGameHistoryFromAPI } from "services/AceTestService";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Strings } from 'resources';
import SmallButton from "components/UI/SmallButton";
import moment from 'moment';
import { log } from "utils";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import "./GameLists.scss";

export default function ACEGameLists(props) {
  const alert = useAlert();
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const referData = useSelector((state) => state.referData);
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const userLanguage = Strings.getLanguage();
  const userId = getUserId();
  const userToken = getUserToken();
  const [games, setGames] = useState([]);
  const [isLoading, setLoadingState] = useState(false);
  
  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get My Played ACE Game Lists */
  useEffect(() => {
    async function getACEGameLists(){
      setLoadingState(true);
      const { data } = await getACEGameHistoryFromAPI(userToken, userId);
      if (data.status) {
        setGames(data.data);
      }
      setLoadingState(false);
    }
    getACEGameLists();
  }, [dispatch, userId, userToken]);

  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const goToACEGameReport = (item, index) => {
    const status = checkACEGameExpireModal();
    if (status === '4') {
      history.push(`/ace-game/${+item?.quiz_id}/${+item?.attempt_id}/report`);
    } else {
      showFeatureNotAvailableModal();
    }
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
      dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  /**
   * aceGameItem
   * @param {object} item
   * @param {number} index
   * @description Library Files Individual Item
   */
  const aceGameItem = (item, index) => {
    let actionStatus = 'remind';
   if (item.status === 'completed') {
    actionStatus = 'earned';
   } else if (item.iu_user_status === 'subscribed' && item.iu_status === 'pending') {
    actionStatus = '-';
   }

   const totalMin = Math.round(item?.total_time_seconds/60);
      return (<tr key={`ace-${index}`}>
          <td>{item?.attempt_display_order}</td>
          <td>{item?.total_questions}</td>
          <td>{item?.attended_questions}</td>
          <td>{item?.correct_questions}</td>
          <td>{item?.total_points}</td>
          <td>{item?.correct_points}</td>
          <td>{item?.accuracy_points ? item?.accuracy_points+'%' : '-'}</td>
          <td>{totalMin}</td>
          <td><span className={`referAction ${actionStatus}`}>{userLanguage === 'hi' ? Strings[item?.status] : item?.status}</span></td>
          <td><span className="gameAction" onClick={() => goToACEGameReport(item, index)}><FontAwesomeIcon icon={faSearch} size="lg" className="codeCopyIcon" /></span></td>
        </tr>);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitleBox">
              <div className="titleBox">
                <h1>{Strings.playedAceGames}</h1>
              </div>
            </div>

            <div className="myPageWrapper ACEGameLists">
              <div className="referralSectionHistory">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{Strings.totalQuestions}</th>
                        <th>{Strings.attendedQuestions}</th>
                        <th>{Strings.correctQuestions}</th>
                        <th>{Strings.totalScore}</th>
                        <th>{Strings.correctScore}</th>
                        <th>{Strings.accuracyPoints}</th>
                        <th>{Strings.timeTaken}</th>
                        <th>{Strings.status}</th>
                        <th>{Strings.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {games.length === 0 && !isLoading &&
                      <tr>
                        <td colSpan="11" align="center">{Strings.you_have_not_played_any_ace_game_yet}</td>
                      </tr>
                    }
                    {games.length === 0 && isLoading &&
                        <tr>
                          <td colSpan="11" align="center">{Strings.loadingAceGameHistory}</td>
                        </tr>
                    }
                    {games.length > 0 &&
                      games.map((item, index) => aceGameItem(item, index))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </div>
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
    </Fragment>
  );
}
