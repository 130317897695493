import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { log } from "utils";
import "./input.scss";

export default function Input(props) {
  const accountData = useSelector((state) => state.accountData);

  const {
    autoCapitalize,
    placeholder,
    value,
    onChange,
    onBlur,
    icon,
    errorMessage,
    touched,
    secure,
    disabled,
  } = props;

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]); 

  const renderIcon = () => {
    const myIcon = secure ? faEye : faEyeSlash;
    if (icon)
      return (
        <span className="inputIcon">
          <FontAwesomeIcon icon={myIcon} onClick={() => props.iconPressed()} />
        </span>
      );
    return null;
  };

  const renderError = () => {
    if (errorMessage && touched)
      return <div className="input-error">{errorMessage}</div>;

    return null;
  };

  const renderElement = () => {
    let inputClasses = ["InputElement"];

    if (errorMessage && touched) {
      inputClasses.push("Invalid");
    }
    if(disabled) {
      inputClasses.push("Disabled")
    }
    return (
      <input
        autoCapitalize={autoCapitalize}
        className={inputClasses.join(" ")}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={secure ? 'password' : 'text'}
        disabled={disabled}
        autoComplete="off"
      />
    );
  };

  return (
    <div className="input-container">
        <div className={disabled ? "TextInput Disabled": "TextInput"}>
          {renderElement()}
          {renderIcon()}
        </div>
        {renderError()}
    </div>
  );
}
