import * as actions from "redux/action-types";
import { CancelToken } from "axios";

const initialState = {
    tests: [],
    isTestLive: false,
    activeTestStartTime: null,
    activeTestEndTime: null,
    activeTest: null,
    activeTestQuestions: [],
    testReport: [],
    isLoading: false,
    hasMore: true,
    cancelRequest: CancelToken.source()
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actions.SET_SUBJECT_GAME_REPORT:
      return {
        ...state,
        testReport: action.report,
      };

    case actions.ADD_SUBJECT_TEST:
      return {
        ...state,
        tests: action.tests,
      };
    case actions.LOADING_SUBJECT_TEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_SUBJECT_TEST_CANCEL_TOKEN:
      return {
        ...state,
        cancelRequest: action.token,
      };
    case actions.SET_SUBJECT_TEST_HAS_NO_MORE:
      return {
        ...state,
        hasMore: false,
      };

    case actions.SET_ACTIVE_SUBJECT_TEST:
      return {
        ...state,
        isTestLive: false,
        activeTestStartTime: null,
        activeTestEndTime: null,
        activeTest: action.activeTest,
        activeTestQuestions: [],
      };

    case actions.SET_ACTIVE_SUBJECT_TEST_QUESTIONS:
      return {
        ...state,
        isTestLive: true,
        activeTestStartTime: action.activeTestStartTime,
        activeTestEndTime: null,
        activeTestQuestions: action.activeTestQuestions,
      };

    case actions.TOGGLE_ACTIVE_SUBJECT_TEST_QUESTION_OPTION:
        const newQuestions = [...state.activeTestQuestions];
        let questionIndex = -1;
        if (newQuestions.length > 0) {
            questionIndex = newQuestions.findIndex((item) => item['id'] === action.questionId);
        }
        const newQuestionItem = newQuestions[questionIndex];
        let optionIndex = -1;
        if (newQuestionItem.options.length > 0) {
            optionIndex = newQuestionItem.options.findIndex((item) => item['id'] === action.optionId);
        }
        let updatedOptions = [];
        if (newQuestionItem.questionType === 'Multiple Choice Single Answer') {
            updatedOptions = newQuestionItem.options.map((item, index) => {
                if (index === optionIndex) {
                  return { ...item, isChecked : !item.isChecked };
                } else {
                  return { ...item, isChecked : false };
                }
              });
        }
        const updatedQuestions = [
            ...newQuestions.slice(0,questionIndex),
            {
                ...newQuestions[questionIndex],
                options: updatedOptions,
            },
            ...newQuestions.slice(questionIndex+1)
        ];
      return {
        ...state,
        activeTestQuestions: updatedQuestions
      };

    case actions.CLEAR_ACTIVE_SUBJECT_TEST:
      return {
        ...state,
        isTestLive: false,
        activeTestStartTime: null,
        activeTestEndTime: null,
        activeTest: null,
        activeTestQuestions: [],
      };

    default:
      return state;
  }
}
