import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Strings } from "resources";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallButton from "components/UI/SmallButton";
import * as NavigationActions from "redux/actions/NavigationActions";
import userImage from "assets/images/page/featureImage.svg";
import "./FreeTrialExpiredACEGame.scss";

export default function FreeTrialExpiredACEGame(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const aceTestData = useSelector((state) => state.aceTestData);
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToUpgrade = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(false));
    history.push({
        pathname: '/select-subscription-package/',
        state: { examId: '1' }
      });
  }

  return (<>
    <div
      className={`popupWrapper aceGame freeTrialExpiredACEGame ${
        !navigationData.showFreeTrialExpiredModal ? "closed" : "opened"
      }`}
    >
        <span onClick={() => props.onGoBack()} className="closeIcon"><FontAwesomeIcon icon={faTimes} size="lg" /></span>
        <div className="popUpInner">
            <h3 className="modal-heading">{Strings.free_trial_expired}</h3>
            <div className="featuredIconWrapper">
                <img className="featuredIcon" src={userImage} title={Strings.success} alt={Strings.success} />
            </div>
            <div className="modalContent">
                <div className="feature">
                    <div className="featureDesc"><p>{Strings.your_free_trial_is_expired_kindly_subscribe_and_unlock_max_benefits}</p></div>
                </div>
            </div>
            <div className="buttonContainer">
                <div className="btn-container">
                    <SmallButton clicked={() => redirectToUpgrade()} btnType="Orange" loading={aceTestData?.isLoading}>{Strings.subscribeBtn}</SmallButton>
                </div>
            </div>
       </div>
    </div>
    <div className={`popUpOverlay ${!navigationData.showFreeTrialExpiredModal ? "closed" : "opened"}`}></div>
  </>
  );
}
