import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as TopicActions from "redux/actions/TopicActions";
import { getUserId, getUserToken } from "services/AuthService";
import { Strings } from 'resources';
import Chart from "react-apexcharts";
import YoutubeIcon from "assets/images/dashboard/categoryYouTube.svg";
import TestIcon from "assets/images/dashboard/categoryTest.svg";
import BooksIcon from "assets/images/dashboard/categoryBooks.svg";
import AceIcon from "assets/images/dashboard/dashboardAceGame.svg";
import PlayACEGame from "modals/PlayACEGame";
import FreeTrialACEGame from "modals/FreeTrialACEGame";
import FreeTrialExpiredACEGame from "modals/FreeTrialExpiredACEGame";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import SubscriptionPlanExpired from "modals/SubscriptionPlanExpired";
import { log } from "utils";
import "./Dashboard.scss";
import moment from 'moment';

export default function Dashboard(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const topicData = useSelector((state) => state.topicData);
  const accountData = useSelector((state) => state.accountData);
  const courseData = useSelector((state) => state.courseData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const history = useHistory();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  // component did mount
  useEffect(() => {
     dispatch(AccountActions.getAccountSettings(userId));
     dispatch(AccountActions.getUserAccuracyCharts(userId));
     dispatch(AccountActions.getUserDashboardPoints());
  }, [dispatch, userId]);

  /* Get Main Categories */
  useEffect(() => {
    dispatch(TopicActions.getTopicMainCategories(userToken, userId));
  }, [dispatch, userToken, userId]);

  /**
   * Show Play ACE Game PopUp
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.localStorage) {
        let nextPopup = localStorage.getItem('nextACEPlayGame');
        if (nextPopup > new Date()) {
          return;
        }
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem('nextACEPlayGame', expires );
      }

      /* Show PopUp According to User Activity */
      const valExamId = accountData?.status?.exam;
      const valGroupId = accountData?.status?.group;
      const isValid = accountData?.status?.valid;
      if (valExamId === '1' && valGroupId === '1' && isValid) {
        dispatch(NavigationActions.toggleFreeTrialModal(true));
      } else if (valExamId === '1' && valGroupId === '2' && isValid) {
        dispatch(NavigationActions.togglePlayAceGameModal(true));
      } else if (valExamId === '1' && valGroupId === '3' && isValid) {
        dispatch(NavigationActions.togglePlayAceGameModal(true));
      } else if (valExamId === '1' && valGroupId === '2' && !isValid) {
        dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
      } else if (valExamId === '1' && valGroupId === '3' && !isValid) {
        dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
      } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
        dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
      } else {
        dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [dispatch, accountData]);

  /**
   * checkACEGameExpireModal
   * @description Check ACE Game Expire Modal
   */
  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '2' && !isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const showPlayACEGameModal = () => {
    dispatch(NavigationActions.togglePlayAceGameModal(true));
  }

  const hidePlayACEGameModal = () => {
    dispatch(NavigationActions.togglePlayAceGameModal(false));
  }

  const showFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(true));
  }

  const hideFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(false));
  }

  const showFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
  }

  const hideFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(false));
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  const hideSubscriptionPlanExpiredModal = () => {
    dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(false));
  }
  /**
   * goToCategoryHeatMap
   * @param {object} item
   * @param {number} index
   * @description Go To Category Heat Map
   */
  const goToCategoryHeatMap = (item, index) => {
    history.push(`/category/${+item.cid}/heat-map`);
  }

  /**
   * playACEGame
   * @description Play ACE Game
   */
  const playACEGame = () => {
    const status = checkACEGameExpireModal();
    if (status === '0') {
      showFreeTrialExpiredACEGameModal();
    } else if (status === '1') {
      showFreeTrialACEGameModal();
    } else if (status === '2') {
      history.push(`/ace-game-rules`);
    } else if (status === '4') {
      dispatch(NavigationActions.toggleSubscriptionPlanExpiredStatusModel(true));
    } else if (status === '3') {
      showFeatureNotAvailableModal();
    }
  }

  /**
   * viewCategoryItem
   * @param {object} item
   * @param {number} index
   * @description View Category Item
   */
  const viewCategoryItem = (item, index) => {
    const chartOptions = {
      chart: {
        height: 170,
        type: 'radialBar',
        offsetX: 0,
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#F5A32F"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 270,
          hollow: {
            margin: 0,
            size: '64%',
            background: 'transparent',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            show: true,
            background: '#fff',
            strokeWidth: '64%',
            opacity: 1,
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              formatter: function(val) {
                return parseInt(val)+'%';
              },
              color: '#000000',
              fontSize: '20px',
              show: true,
              offsetY: 7
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ["#E1246A"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['%'],
    };
    const chartSeries = [item.accuracy_points ? Math.round(item.accuracy_points) : 0];
    const categoryTitle = userLanguage === 'hi' ? item?.category_name_hindi : item?.category_name;

    return (<div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 categoryItemWrapper" key={`category-${index}`}>
      <div className="categoryItemView" onClick={() => goToCategoryHeatMap(item, index)}>
        <div className="categoryChartView">
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="radialBar"
            width="170"
          />
        </div>
        <div className="categoryTitleView">
          <span onClick={() => goToCategoryHeatMap(item, index)}>{categoryTitle}</span>
        </div>
        <div className="categoryLinksView">
          <span className="categoryLinkItem" onClick={() => goToCategoryHeatMap(item, index)}><img src={YoutubeIcon} alt={Strings.youTube} title={Strings.youTube} /></span>
          <span className="categoryLinkItem" onClick={() => goToCategoryHeatMap(item, index)}><img src={BooksIcon} alt={Strings.books} title={Strings.books} /></span>
          <span className="categoryLinkItem" onClick={() => goToCategoryHeatMap(item, index)}><img src={TestIcon} alt={Strings.test} title={Strings.test}  /></span>
        </div>
      </div>
    </div>
    );
  }

  /**
   * accuracyChartView
   * @description Show Accuracy Chart View
   */
  const accuracyChartView = () => {
    const chartOptions = {
      chart: {
        height: 280,
        type: 'line',
        foreColor: '#000000',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 5,
        curve: 'smooth'
      },
      tooltip: {
        theme: 'dark'
      },
      grid: {
        borderColor: "#EFEFEF",
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        categories: accountData?.accuracy?.weekNo,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          gradientToColors: ['#FFBF00', '#FF9933', '#B23227', '#E1246A'],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        },
      },
      markers: {
        size: 4,
        colors: ["#FF9933"],
        strokeColors: "#ffffff",
        strokeWidth: 3,
        hover: {
          size: 5,
        }
      },
      yaxis: {
        min: 0,
        max: 100
      }
    };
    const chartSeries = [
      {
        name: "Accuracy",
        data: accountData?.accuracy?.accuracyPercentage
      }
    ];
    return (<Chart
      options={chartOptions}
      series={chartSeries}
      type="line"
      height="280"
    />);
  }

  const examMonth = moment(accountData?.points?.examDate).utc(true).format("MMM - YYYY");
  const currentLevelId = +accountData?.points?.currentUserLevel?.bagsl_id || 0;
  const nextLevelId = +accountData?.points?.nextUserLevel?.bagsl_id || 10;

  const goToPricing = () => {
    history.push({
      pathname: '/select-subscription-package/',
      state: { examId: '1' }
    });
  }

  const isFeatureAvailable = () => {
    const groupId = courseData?.active?.group_id;
    const groupStatus = courseData?.active?.uge_status;
    const examId = courseData?.active?.exam_id;
    return groupStatus === '1' && groupId === '3' && examId === '1';
  }
  
  const user_stage_name = userLanguage === 'hi' ? accountData?.points?.nextUserLevel?.stage_name_hi : accountData?.points?.nextUserLevel?.stage_name_en;

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
          <div className="myPageWrapper myDashboard">

            <div className="pointsView">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">

                <div className="row">
                  {/*<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 pointBoxWrapper">
                    <div className="pointBox White">
                      <h3>{accountData?.points?.daysLeft}</h3>
                      <span className="pbInfo">{Strings.days_left_for_exam}</span>
                      <span className="pbSubInfo">{examMonth}</span>
                    </div>
                  </div>*/}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pointBoxWrapper">
                    <div className="pointBox LightBlue">
                      <h3>{accountData?.points?.accuracyPoints ? Math.round(accountData?.points?.accuracyPoints) : '0'}%</h3>
                      <span className="pbInfo">{Strings.accuracy}</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pointBoxWrapper">
                    <div className="pointBox LightOrange">
                      <h3>{accountData?.points?.remainingQuestions}</h3>
                      <span className="pbInfo">{Strings.questionsRemaining}</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pointBoxWrapper">
                    <div className="pointBox LightGreen">
                      <h3>{accountData?.points?.currentACEPoints}</h3>
                      <span className="pbInfo">{Strings.acePoints}</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 pointBoxWrapper">
                    <div className="pointBox LightYellow">
                      <h3>{accountData?.points?.hoursSpent}</h3>
                      <span className="pbInfo">{Strings.hours}</span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pointBoxWrapper">
                    <div className="pointBox LightBrown">
                      <h3>{accountData?.points?.leftGames}</h3>
                      <span className="pbInfo">{Strings.gamesLeft}</span>
                    </div>
                  </div>
                </div>

                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="accuracyChartView">
                    <h3 className="chartTitle">{Strings.weekly_accuracy_worm}</h3>
                    {!isFeatureAvailable() && <>{accuracyChartView()}
                    <div className="chartLayOver">
                      <h3>{Strings.weekly_accuracy_worm}</h3>
                      <p>{Strings.weekly_accuracy_worm_description}</p>
                    </div></>
                    }
                    {isFeatureAvailable() && accuracyChartView()}
                  </div>
                </div>

              </div>
            </div>

            <div className="categoryView">
              <div className="row">
                {topicData.category.length > 0 &&
                  topicData.category.map((item, index) => viewCategoryItem(item, index))
                }
              </div>
            </div>

            <div className="userLevelView">
                <div className="userLevelHeader">
                  <div className="ulLogo">
                    <img className="ulLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                    <span className="ulLogoTitle">{Strings.aceGame}</span>
                  </div>
                  <div className="ulInfo">
                    <span>{Strings.gather1} {accountData?.points?.requiredACEPointsForNextLevel} {Strings.pointsToReach} {user_stage_name} {Strings.level1}</span>
                  </div>
                  <div className="ulPlay">
                    <span className="ulPlayNow" onClick={() => playACEGame()}>{Strings.playNowAceGame}</span>
                  </div>
                </div>
                <div className="userLevelContent">
                  <div className="row">

                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox Beginner">
                        <h3 className="ulcTitle">{Strings.beginner}</h3>
                        {currentLevelId >= 1 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 1 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 5 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>

                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox Basic">
                        <h3 className="ulcTitle">{Strings.basic}</h3>
                        {currentLevelId >= 2 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 2 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 10 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox Intermediate">
                        <h3 className="ulcTitle">{Strings.intermediate}</h3>
                        {currentLevelId >= 3 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 3 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 51 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>

                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox Advance">
                        <h3 className="ulcTitle">{Strings.advanced}</h3>
                        {currentLevelId >= 4 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 4 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 101 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>

                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox Expert">
                        <h3 className="ulcTitle">{Strings.expert}</h3>
                        {currentLevelId >= 5 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 5 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 201 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 userLevelWrapper">
                      <div className="userLevelBox GrandMaster">
                        <h3 className="ulcTitle">{Strings.grandMaster}</h3>
                        {currentLevelId >= 6 && <><div className="ulcLogo">
                          <img className="ulcLogoImage" src={AceIcon} alt="ACE" title="ACE" />
                        </div><span className="ulcInfo">{Strings.levelCompleted}</span></>}
                        {currentLevelId < 6 && <div className="ulcPending">
                          <span className="ulcPendingDesc">{Strings.you_are_eligible_for} Rs. 599 {Strings.scholarship}</span>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="userLevelNote">
                  {!isFeatureAvailable() && <span className="userLevelNoteText">{Strings.redemption_feature_is_available_only_in_half_yearly_plans} <span className="userLevelNoteLink" onClick={() => goToPricing()}>{Strings.clickHere}</span> {Strings.and_get_full_benefits}</span>}
                </div>
            </div>

          </div>
        </div>
      </div>
      {navigationData.showPlayAceGameModal ? <PlayACEGame onGoBack={() => hidePlayACEGameModal()} /> : null}
      {navigationData.showFreeTrialModal ? <FreeTrialACEGame onGoBack={() => hideFreeTrialACEGameModal()} /> : null}
      {navigationData.showFreeTrialExpiredModal ? <FreeTrialExpiredACEGame onGoBack={() => hideFreeTrialExpiredACEGameModal()} /> : null}
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
      {navigationData.showSubscriptionPlanExpiredStatusModel ? <SubscriptionPlanExpired onGoBack={() => hideSubscriptionPlanExpiredModal()} /> : null}
    </Fragment>
  );
}
