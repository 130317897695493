import * as actions from "redux/action-types";
import { CancelToken } from "axios";

const initialState = {
    isTestValid: true,
    activeTest: null,
    activeTestQuestion: null,
    activeTestQuestionStatus: [],
    activeTestTimeLapsed: null,
    activeTestScore: null,
    isLoading: false,
    isLoadingQuestion: false,
    leaderBoard: null,
    certificate: null,
    testReport: null,
    aceTestsStatus: {},
    currentGameId: '',
    currentGameKey: '1-20',
    currentGameIndex: 0,
    cancelRequest: CancelToken.source()
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actions.SET_ACE_GAME_CURRENT_PLAY_KEY:
      return {
        ...state,
        currentGameKey: action.currentGameKey,
      };

    case actions.SET_ACE_GAME_CURRENT_PLAY_INDEX:
      return {
        ...state,
        currentGameIndex: action.currentGameIndex,
      };  

    case actions.SET_ACE_GAME_CURRENT_PLAY_STATUS:
      return {
        ...state,
        currentGameId: action.currentGameId,
      };

    case actions.SET_ACE_GAME_PLAY_STATUS:
      return {
        ...state,
        aceTestsStatus: action.aceTestsStatus,
      };

    case actions.SET_VALID_ACE_TEST:
      return {
        ...state,
        isTestValid: action.isTestValid,
      };

    case actions.SET_ACE_GAME_REPORT:
      return {
        ...state,
        testReport: action.report,
      };

    case actions.SET_ACE_CERTIFICATE:
      return {
        ...state,
        certificate: action.certificate,
      };

    case actions.CLEAR_ACE_CERTIFICATE:
      return {
        ...state,
        certificate: null,
      };

    case actions.SET_ACE_LEADERBOARD:
      return {
        ...state,
        leaderBoard: action.leaderBoard,
      };

    case actions.SET_ACE_SCORE:
      return {
        ...state,
        activeTestScore: action.activeTestScore,
      };

    case actions.SET_ACE_QUESTIONS_STATUS:
      return {
        ...state,
        activeTestQuestionStatus: action.status,
      };

    case actions.SET_ACE_QUESTION:
        return {
          ...state,
          activeTestQuestion: action.question,
        };

    case actions.SET_ACE_TEST:
      return {
        ...state,
        activeTest: action.activeTest,
        isTestValid: true,
      };
    case actions.CLEAR_ACE_TEST:
      return {
        ...state,
        isTestValid: true,
        activeTest: null,
        activeTestQuestion: null,
        activeTestQuestionStatus: [],
        activeTestTimeLapsed: null,
        activeTestScore: null,
        leaderBoard: null,
        certificate: null,
      };
    case actions.LOADING_ACE_TEST:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case actions.LOADING_ACE_TEST_QUESTION:
      return {
        ...state,
        isLoadingQuestion: action.isLoadingQuestion,
      };

    case actions.SET_ACE_TEST_CANCEL_TOKEN:
      return {
        ...state,
        cancelRequest: action.token,
      };
    case actions.SET_ACE_TIME:
      return {
        ...state,
        activeTestTimeLapsed: action.activeTestTimeLapsed,
      };
    case actions.TOGGLE_ACE_TEST_QUESTION_OPTION:
        const newQuestion = {...state.activeTestQuestion};
        let optionIndex = -1;
        if (newQuestion.options.length > 0) {
            optionIndex = newQuestion.options.findIndex((item) => item['id'] === action.optionId);
        }
        let updatedOptions = [];
        if (newQuestion.questionType === 'Multiple Choice Single Answer') {
            updatedOptions = newQuestion.options.map((item, index) => {
                if (index === optionIndex) {
                  return { ...item, isChecked : !item.isChecked };
                } else {
                  return { ...item, isChecked : false };
                }
              });
        } else if (newQuestion.questionType === 'Multiple Choice Multiple Answer') {
          updatedOptions = newQuestion.options.map((item, index) => {
              if (index === optionIndex) {
                return { ...item, isChecked : !item.isChecked };
              } else {
                return { ...item, isChecked : false };
              }
            });
      }
        const updatedQuestion = {
            ...newQuestion,
            options: updatedOptions,
        };
      return {
        ...state,
        activeTestQuestion: updatedQuestion
      };

    default:
      return state;
  }
}
