import React, { useEffect, Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { Strings } from "resources";
import { isUserLoggedIn, getUserToken, getUserId } from "services/AuthService";
import SmallButton from "components/UI/SmallButton";
import { getPaymentDetailsFromInvoice as getPaymentDetailsFromInvoiceAPI } from "services/PaymentService";
import FailureIcon from "assets/images/failure.svg";
import { log } from "utils";
import "./Failure.scss";

export default function PaymentSuccess(props) {
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [isLoading, setLoadingState] = useState(false);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Check Weather Student is Logged In or not */
  useEffect(() => {
    async function checkStatus(){
      const { status } = isUserLoggedIn();
      if(!status) {
        history.push('/');
      }
    }
    checkStatus();
  });

  /* Get User Account Settings Details */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /* Get Invoice Details of the Invoice Id */
  useEffect(() => {
    async function getInvoiceDetails(){
      setLoadingState(true);
      const { data } = await getPaymentDetailsFromInvoiceAPI(userToken, userId, invoiceId);
      if (data.status) {
        setInvoiceItem(data.data);
      }
      setLoadingState(false);
    }
    getInvoiceDetails();
  }, [userId, userToken, invoiceId]);

  /**
   * goToCourses
   * @description Go To Courses
   */
  const goToCourses = () => {
    history.push(`/my-courses/`);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer">
        <div className="paymentStatus">
          <div className="paymentStatusWrapper">
            <h2 className="sectionHeading">{Strings.paymentFailed}</h2>
            <div className="paymentStatusView">
                {!isLoading && invoiceItem && <div className="InvoiceInfo">
                  <img className="successIcon" src={FailureIcon} title={Strings.failure} alt={Strings.failure} />

                  <span className="message">{Strings.subscription} : {invoiceItem.payment_items[0].et_name} {invoiceItem.payment_items[0].group_name}</span>
                  <span className="message">{Strings.invoiceId} : {invoiceItem.payment_details.p_invoice_id}</span>
                  <span className="message">{Strings.transactionId} : {invoiceItem.payment_details.p_txn_id}</span>
                  <span className="message">{Strings.transactionStatus} : {invoiceItem.payment_details.p_txn_status === 'TXN_SUCCESS' ? Strings.success : Strings.failure}</span>
                  <span className="message">{Strings.transactionAmount} : {invoiceItem.payment_details.p_txn_amount}</span>

                  <span className="message">{Strings.payment_failure_try_again_through_subscription}</span>
                  <div className="courseView">
                    <SmallButton btnType="Orange" clicked={() => goToCourses()}>{Strings.goToCourses}</SmallButton>
                  </div>
                </div>}
                {!isLoading && !invoiceItem && <div className="InvoiceInfo">
                  <span className="message">{Strings.noInvoiceDetailsFound}</span>
                </div>}
                {isLoading && <div className="InvoiceInfo">
                  <span className="message">{Strings.loading}</span>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
