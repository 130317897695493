import * as actions from "redux/action-types";
import {
    startACEGame as startACEGameFromAPI,
    getACEGame as getACEGameFromAPI,
    getACEGameQuestion as getACEGameQuestionFromAPI,
    saveACEGameQuestion as saveACEGameQuestionFromAPI,
    endACEGame as endACEGameFromAPI,
    getACEGameStats as getACEGameStatsFromAPI,
    getMyLeaderboard as getMyLeaderboardFromAPI,
    verifyACEPointsCertificate as verifyACEPointsCertificateFromAPI,
    getAceGamePlayStatus as getAceGamePlayStatusFromAPI
} from "services/AceTestService";
import { Strings } from "resources";
import { CancelToken } from "axios";
import { parseACEGame, parseACEGameQuestionStatus, parseACEGameQuestion, parseACEGameResult } from "utils/chat";
import { getUserId, getUserToken } from "services/AuthService";

/**
 * setACETestLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setACETestLoadingState(isLoading) {
    return {
        type: actions.LOADING_ACE_TEST,
        isLoading,
    };
}

export function setACETestQuestionLoadingState(isLoadingQuestion) {
    return {
        type: actions.LOADING_ACE_TEST_QUESTION,
        isLoadingQuestion,
    };
}

export function clearACETest() {
    return {
        type: actions.CLEAR_ACE_TEST
    };
}

/**
 * addACETestData
 * @description Add Subject Test into State
 */
export function addACETestData(activeTest) {
    return {
        type: actions.SET_ACE_TEST,
        activeTest: activeTest
    };
}

/**
 * setACETestCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setACETestCancelToken(token) {
    return {
        type: actions.SET_ACE_TEST_CANCEL_TOKEN,
        token,
    };
}

export function setACEGameQuestionStatus(status) {
    return {
        type: actions.SET_ACE_QUESTIONS_STATUS,
        status: status
    };
}

export function setACEGameQuestion(question) {
    return {
        type: actions.SET_ACE_QUESTION,
        question: question
    };
}

export function toggleACETestQuestionOption(questionId, optionId) {
    return {
        type: actions.TOGGLE_ACE_TEST_QUESTION_OPTION,
        questionId: questionId,
        optionId: optionId
    };
}

export function setACEGameScore(score) {
    return {
        type: actions.SET_ACE_SCORE,
        activeTestScore: score
    };
}

export function setValidACEGameStatus(isTestValid) {
    return {
        type: actions.SET_VALID_ACE_TEST,
        isTestValid: isTestValid
    };
}

/**
 * addACEGameData
 * @param {*} data
 * @description Add ACE Game Data
 */
export function addACEGameData (data) {
    return async (dispatch, getState) => {
        await dispatch(clearACETest());
        await dispatch(addACETestData(parseACEGame(data)));
    }
}

/**
 * startACEGame
 * @param {*} userAgree
 * @param {*} agreeTime
 * @description Start ACE Game
 */
export function startACEGame(userAgree, agreeTime) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const siteLanguage = Strings.getLanguage();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestCancelToken(newRequestSource));
        await dispatch(addACETestData(null));
        await dispatch(setACETestLoadingState(true));
        const { data, error } = await startACEGameFromAPI(userToken, userId, siteLanguage, userAgree, agreeTime);
        if (!error && Array.isArray(data.data) && data.data.length) {
            dispatch(addACETestData(parseACEGame(data.data)));
        }
        dispatch(setACETestLoadingState(false));
    };
}

/**
 * getACEGame
 * @param {*} quizId
 * @description Get ACE Game
 */
export function getACEGame(quizId) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestLoadingState(true));
        await dispatch(setACETestCancelToken(newRequestSource));
        const { data } = await getACEGameFromAPI(userToken, userId, quizId);
        if (data.status) {
            const aceGame = parseACEGame(data.data);
            dispatch(setValidACEGameStatus(true));
            dispatch(addACETestData(aceGame));
            if (aceGame.id !== '' && aceGame.attemptId !== '') {
                dispatch(getACEGameQuestion(aceGame.id, aceGame.attemptId, 1));
            }
            // dispatch(setACETestLoadingState(false));
        } else {
            dispatch(addACETestData(null));
            dispatch(setValidACEGameStatus(false));
            dispatch(setACETestLoadingState(false));
        }
    };
}

/**
 * getACEGameQuestion
 * @param {*} quizId
 * @param {*} attemptId
 * @param {*} order
 * @description Get ACE Game Question
 */
export function getACEGameQuestion(quizId, attemptId, order) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestCancelToken(newRequestSource));
        dispatch(setACETestQuestionLoadingState(true));
        const { data, error } = await getACEGameQuestionFromAPI(userToken, userId, quizId, attemptId, order);
        if (!error && data.status) {
            const questionStatus = parseACEGameQuestionStatus(data.data.questions);
            const questionDetails = parseACEGameQuestion(data.data.question);
            dispatch(setACEGameQuestionStatus(questionStatus));
            dispatch(setACEGameQuestion(questionDetails));
            dispatch(setACETestQuestionLoadingState(false));
        }
        dispatch(setACETestLoadingState(false));
        dispatch(setACETestQuestionLoadingState(false));
    };
}

/**
 * saveACEGameQuestion
 * @param {*} quizId
 * @param {*} attemptId
 * @param {*} order
 * @param {*} timeId
 * @param {*} questionId
 * @param {*} answerId
 * @param {*} action
 * @param {*} goToOrder
 * @description Save ACE Game Question
 */
export function saveACEGameQuestion(quizId, attemptId, order, timeId, questionId, answerId, action, goToOrder) {
    return async (dispatch, getState) => {
        let state = await getState();
        let activeTest = state.aceTestData.activeTest;
        let activeTestQuestion = state.aceTestData.activeTestQuestion;
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestCancelToken(newRequestSource));
        dispatch(setACETestQuestionLoadingState(true));
        const { data, error } = await saveACEGameQuestionFromAPI(userToken, userId, quizId, attemptId, order, timeId, questionId, answerId);
        if (!error && data.status) {
            let queOrder = order;
            let questionOrder = +activeTestQuestion.questionOrder;
            const totalQuestions = +activeTest.testQuestionsCount;
            if (action === 'prev') {
                if (questionOrder === 1) {
                    queOrder = order;
                    dispatch(getACEGameQuestion(quizId, attemptId, queOrder));
                } else {
                    queOrder = order-1;
                    dispatch(getACEGameQuestion(quizId, attemptId, queOrder));
                }
            } else if (action === 'next') {
                if (questionOrder === totalQuestions) {
                    queOrder = order;
                    dispatch(getACEGameQuestion(quizId, attemptId, queOrder));
                } else {
                    queOrder = order+1;
                    dispatch(getACEGameQuestion(quizId, attemptId, queOrder));
                }
            } else if (action === 'goto') {
                dispatch(getACEGameQuestion(quizId, attemptId, goToOrder));
            } else if (action === 'end') {
                /*
                dispatch(getACEGameQuestion(quizId, attemptId, order));
                const questionStatus = parseACEGameQuestionStatus(data.data.questions);
                const questionDetails = parseACEGameQuestion(data.data.question);
                dispatch(setACEGameQuestionStatus(questionStatus));
                dispatch(setACEGameQuestion(questionDetails));
                */
                dispatch(setACETestQuestionLoadingState(false));
            }
        } else {
            dispatch(setACETestQuestionLoadingState(false));
        }
    };
}

/**
 * getACEGameStats
 * @param {*} quizId
 * @param {*} attemptId
 * @description Get Ace Game Stats
 */
export function getACEGameStats(quizId, attemptId) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestLoadingState(true));
        await dispatch(setACETestCancelToken(newRequestSource));
        const { data, error } = await getACEGameStatsFromAPI(userToken, userId, quizId, attemptId);
        if (!error && data.status) {
            const activeTestScore = parseACEGameResult(data.data);
            dispatch(setACEGameScore(activeTestScore));
        }
        await dispatch(setACETestLoadingState(false));
    };
}

/**
 * setACEGameLeaderBoard
 * @param {*} data
 * @description Set ACE Game Leaderboard Data
 */
export function setACEGameLeaderBoard(data) {
    return {
        type: actions.SET_ACE_LEADERBOARD,
        leaderBoard: data
    };
}

/**
 * getACEGameLeaderBoard
 * @param {*} examId
 * @description Get ACE Game LeaderBoard Data
 */
export function getACEGameLeaderBoard(examId) {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestCancelToken(newRequestSource));
        const { data, error } = await getMyLeaderboardFromAPI(userToken, userId, examId);
        if (!error && data.status) {
            dispatch(setACEGameLeaderBoard(data.data));
        }
    };
}

/**
 * setACECertificate
 * @param {*} data
 * @description Set ACE Certificate
 */
export function setACECertificate(data) {
    return {
        type: actions.SET_ACE_CERTIFICATE,
        certificate: data
    };
}

/**
 * clearACECertificate
 * @description Clear ACE Certificate Data
 */
export function clearACECertificate() {
    return {
        type: actions.CLEAR_ACE_CERTIFICATE
    };
}

/**
 * getACEGameCertificate
 * @param {*} userCode
 * @description Get ACE Game Certificate Data
 */
export function getACEGameCertificate(userCode) {
    return async (dispatch, getState) => {
        const newRequestSource = CancelToken.source();
        await dispatch(setACETestCancelToken(newRequestSource));
        await dispatch(setACETestLoadingState(true));
        const { data, error } = await verifyACEPointsCertificateFromAPI(userCode);
        if (!error && data.status) {
            dispatch(setACECertificate(data.data));
            await dispatch(setACETestLoadingState(false));
        } else {
            dispatch(clearACECertificate());
            await dispatch(setACETestLoadingState(false));
        }
    };
}

/**
 * onTimerComplete
 * @description On Timer Complete Action
 */
export function onTimerComplete() {
    return async (dispatch, getState) => {
        let state = await getState();
        let activeTest = state.aceTestData.activeTest;
        let activeTestQuestion = state.aceTestData.activeTestQuestion;
        const userId = getUserId();
        const userToken = getUserToken();
        const courseId = '1';
        const queOrder = activeTestQuestion?.questionOrder;
        const quizId = activeTest?.id;
        const attemptId = activeTest?.attemptId;
        const queTimeId = activeTestQuestion?.timeId;
        const questionId = activeTestQuestion?.id;
        let answerId = activeTestQuestion?.answerId;
        activeTestQuestion?.options.forEach((optionItem, optionIndex) => {
            if (optionItem.isChecked) {
                answerId = optionItem.id;
            }
        });
        const { data, error } = await saveACEGameQuestionFromAPI(userToken, userId, quizId, attemptId, queOrder, queTimeId, questionId, answerId);
        if (!error && data.status) {
            const { data } = await endACEGameFromAPI(userToken, userId, quizId, attemptId, courseId);
            if (data.status) {
                return true;
            }
        }
    }
}

export function setACEGameReport(data) {
    return {
        type: actions.SET_ACE_GAME_REPORT,
        report: data
    };
}

export function getACEGameStatus() {
    return async (dispatch, getState) => {
        const userId = getUserId();
        const userToken = getUserToken();
        const { data, error } = await getAceGamePlayStatusFromAPI(userToken, userId);
        if (!error && data.status) {
            const aceTests = data?.data;
            let newAceTests = {};
            let currentGameId = 0;
            let i, j, tempTests,chunk = 20;
            let latestActiveRange = '1-20';
            let latestActiveIndex = 0;
            for (i=0,j=aceTests.length; i<j; i+=chunk) {
                tempTests = aceTests.slice(i,i+chunk);
                const chunkName = (i+1)+'-'+(i+chunk);
                newAceTests[chunkName] = tempTests;
            }

            for (i=0; i<aceTests.length; i+=1) {
                if (aceTests[i].status === '') {
                    currentGameId = aceTests[i].baqc_id;
                    dispatch(setCurrentACEGameId(currentGameId));
                    break;
                }
            }

            let flag = false;
            for (const key in newAceTests) {
                if (newAceTests[key].length > 0) {
                    
                    for (let k=0; k<newAceTests[key].length; k++) {
                        if (+newAceTests[key][k].baqc_id === +currentGameId) {
                            latestActiveRange = key;
                            flag = true;
                            break;
                        }
                    }
                    if(flag){
                        break;
                    }
                    latestActiveIndex++;
                }
            }

            dispatch(setCurrentACEGameIndex(latestActiveIndex));
            dispatch(setCurrentACEGameKey(latestActiveRange));
            dispatch(setACEGamePlayStatus(newAceTests));
        }
    }
}

export function setACEGamePlayStatus(data) {
    return {
        type: actions.SET_ACE_GAME_PLAY_STATUS,
        aceTestsStatus: data
    };
}

export function setCurrentACEGameId(data) {
    return {
        type: actions.SET_ACE_GAME_CURRENT_PLAY_STATUS,
        currentGameId: data
    };
}

export function setCurrentACEGameKey(data) {
    return {
        type: actions.SET_ACE_GAME_CURRENT_PLAY_KEY,
        currentGameKey: data
    };
}

export function setCurrentACEGameIndex(data) {
    return {
        type: actions.SET_ACE_GAME_CURRENT_PLAY_INDEX,
        currentGameIndex: data
    };
}