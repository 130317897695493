import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getLibraryFiles
 * @param {*} tokenId string
 * @param {*} examId number
 * @param {*} pageSize number
 * @param {*} pageNo number
 * @description Get Library Files Listing
 */
export async function getLibraryFiles(tokenId, examId, examCategory, examCategoryType, pageSize, pageNo) {
    const payload = {
        'exam_category': examCategory,
        'exam_category_type': examCategoryType,
        'token': tokenId,
        'exam_id': examId,
        'limit': pageSize,
        'page': pageNo,
    };
    try {
        const response = await appClient.post(apiConfig.library.getLibraryFiles, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}
