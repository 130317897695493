import React, { useEffect, Fragment, useState, useRef } from "react";
import { log } from "utils";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import { getUserId, getUserToken } from "services/AuthService";
import SmallButton from "components/UI/SmallButton";
import { uploadProfileImage, saveUserAccountSettings, removeProfilePic } from "services/AuthService";
import { Strings } from "resources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import { parseISO, getYear, getMonth } from 'date-fns';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import "./Account.scss";
import UserPlaceHolder from 'assets/images/page/userPlaceHolder.png';
import PrivacyPolicyModal from "modals/PrivacyPolicyModal";
import TermsConditionsModal from "modals/TermsConditionsModal";

/* Get Years of Range */
const rangeYears = (start, end) => {
  let ans = [];
  for (let i = start; i <= end; i++) {
      ans.push(i);
  }
  return ans;
}

export default function AccountSettings(props) {
  const alert = useAlert();
  const history = useHistory();
  const [isLoading, setLoadingState] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [panCard, setPanCard] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [userProfileImage, setUserProfileImage] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);
  const courseData = useSelector((state) => state.courseData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const hiddenFileInput = useRef(null);
  const [regularAlerts, setRegularAlerts] = useState(false);
  const [policyAgree, setPolicyAgree] = useState(false);
  const activeGroupId = courseData?.active?.group_id || '';
  const [selectedOption, setSelectedOption] = useState({});

  const years = rangeYears(1940, getYear(new Date()), 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
    dispatch(AccountActions.getUserStates());
  }, [dispatch, userId]);

  /* Get My Active Course */
  useEffect(() => {
    dispatch(CourseActions.getActiveCourse(userToken, userId));
  }, [dispatch, userToken, userId]);

  const getStatesOptions = () => {
    const stateItems = [];
    accountData.states.map((item, index) => {
      stateItems.push({ value: item.state_name, label: item.state_name });
    });
    return stateItems;
  }

  useEffect(() => {
    if (accountData && accountData.accountSettings) {
      const userDob = accountData.accountSettings.dob ? parseISO(accountData.accountSettings.dob) : null;
      const userGender = accountData.accountSettings.gender;
      const userFirstName = accountData.accountSettings.first_name;
      const userLastName = accountData.accountSettings.last_name;
      const userEmail = accountData.accountSettings.email;
      const userCity = accountData.accountSettings.city;
      const userState = accountData.accountSettings.state;
      const userContactNo = accountData.accountSettings.contact_no;
      const userPanCard = accountData.accountSettings.pan_card_no;
      const userGSTNo = accountData.accountSettings.gst_no;
      const userPhoto = accountData.accountSettings.photo === '' || accountData.accountSettings.photo === null ? UserPlaceHolder : accountData.accountSettings.photo;
      const regularAlertsValue = accountData.accountSettings.regular_alerts === '1' ? true : false;
      const policyAgreeValue = accountData.accountSettings.policy_agree === '1' ? true : false;
      const subscriptionExpiryDate = accountData.accountSettings.subscription_expired !== '' || accountData.accountSettings.subscription_expired !== '0000-00-00 00:00:00' ? moment(accountData.accountSettings.subscription_expired).format('DD MMM YYYY') : '';

      /* Setup States */
      let selectedState = accountData.states.filter(function (item) {
        return item.state_name === accountData?.accountSettings?.state;
      });
      if (selectedState.length > 0) {
        setSelectedOption({ value: selectedState[0].state_name, label: selectedState[0].state_name});
      }

      setDob(userDob);
      setGender(userGender);
      setFirstName(userFirstName);
      setLastName(userLastName);
      setEmail(userEmail);
      setCity(userCity);
      setState(userState);
      setContactNo(userContactNo);
      setUserProfileImage(userPhoto);
      setGstNo(userGSTNo);
      setPanCard(userPanCard);
      setPolicyAgree(policyAgreeValue);
      setRegularAlerts(regularAlertsValue);
      setExpiryDate(subscriptionExpiryDate);
    }
 }, [accountData]);

  /**
   * formatDate
   * @param {object} date
   * @description Format Date Object
   */
  const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  const saveUserSettings = async () => {

    if (!regularAlerts) {
      alert.error(Strings.selectRegularAlerts);
      return false;
    }

    if (!policyAgree) {
      alert.error(Strings.selectPrivacyPolicy);
      return false;
    }

    if (dob === null || dob === '') {
      alert.error('Please select date of birth.');
      return false;
    }

    if (contactNo === '' || contactNo === null) {
      alert.error('Contact is required');
      return log('Contact is required');
    }
  
    if (!validatePhoneNumber(contactNo)) {
      alert.error('Please enter valid mobile number');
      return log('Please enter valid mobile number');
    }

    setLoadingState(true);
    const payload = {
      user_id : userId,
      contact_no: contactNo,
      photo : userProfileImage,
      dob : dob ? formatDate(dob) : '',
      gender : gender,
      state : state,
      city : city,
      pan_card_no: panCard,
      gst_no: gstNo,
      regular_alerts: regularAlerts ? 1 : 0,
      policy_agree: policyAgree ? 1 : 0,
      token: userToken
    }
    const { data } = await saveUserAccountSettings(payload);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return onAccountSettingsSuccess(data, data.message);
    }
  };

  const onAccountSettingsSuccess = (data, message) => {
    dispatch(AccountActions.addAccountSettings(data));
    alert.success(Strings[message]);
    history.push('/my-courses/');
  };

  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setUserProfileImage(e.target.result);
      };
      log(event.target.files[0]);
      uploadUserProfileImage(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const uploadUserProfileImage = async (selectedFileDetails) => {
    const { data } = await uploadProfileImage(selectedFileDetails, userId, userToken);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      const profileImageUrl = data.data;
      setUserProfileImage(profileImageUrl);
      return false;
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const removeImage = async () => {
    const { data } = await removeProfilePic(userId, userToken);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      dispatch(AccountActions.getAccountSettings(userId));
      setUserProfileImage(UserPlaceHolder);
      return false;
    }
  }

  const validatePhoneNumber = (contact) => {
    const re = /^[0-9]{10}$/im;
    return re.test(contact);
  }

  /**
    * showTermsPage
    * @description Show Terms Page
    */
  const showTermsPage = () => {
    dispatch(NavigationActions.toggleTermsConditionsModal(true));
  }

  /**
    * showCopyrightPage
    * @description Show Copyright Page
    */
  const showCopyrightPage = () => {
    dispatch(NavigationActions.togglePrivacyPolicyModal(true));
  }

  return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo">
        <div className="myPage">
            <div className="myPageWrapper myProfile">

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 offset-lg-4 col-lg-8 offset-xl-3 col-xl-9">
                <div className="pageTitle">
                  <h1>{Strings.myProfileName}</h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
                <div className="userIntroduction">
                  <span className="greetings">Hello,</span>
                  <span className="firstName">{firstName} !</span>
                </div>
                <div className="userProfileImage">
                  <img className="profileImage" src={userProfileImage} title="User" alt="User" />
                  <span className="photoSelectWrapper" onClick={(event) => handleClick(event)}><FontAwesomeIcon icon={faCamera} size="lg" className="photoSelect" /></span>

                  <span className="removeIconWrapper" onClick={() => removeImage()}><FontAwesomeIcon icon={faTimes} size="lg" className="removeIcon" /></span>

                  <input type="file" accept="image/*" ref={hiddenFileInput} style={{ display:'none' }} onChange={(event) => onFileChange(event)} />
                </div>
                <div className="userIntroduction">
                  <span className="defaultImage" onClick={() => removeImage()}>{Strings.setAsDefault}</span>
                  <span className="firstName">{accountData?.accountSettings?.user_code}</span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">

              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.firstName}</label>
                      <input className="inputField" type="text" value={firstName} placeholder={Strings.firstName_placeholder} disabled={true} readOnly />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.lastName}</label>
                      <input className="inputField" type="text" value={lastName} placeholder={Strings.lastName_placeholder} disabled={true} readOnly />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.email}</label>
                      <input className="inputField" type="text" value={email} placeholder={Strings.enterEmail} onChange={(e) => { setEmail(e.target.value); }} disabled={true} readOnly />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.contactNumber}</label>
                      <input className="inputField" type="text" value={contactNo} placeholder={Strings.enterContactNo} onChange={(e) => { setContactNo(e.target.value); }} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.city}</label>
                      <input className="inputField" type="text" value={city} placeholder={Strings.enterCity} onChange={(e) => { setCity(e.target.value); }} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.state}</label>
                      {/*<select className="inputSelect" value={state} onChange={e => setState(e.currentTarget.value)}>
                        <option>Select State</option>
                        {accountData.states.map((stateItem, stateIndex) => {
                          return (<option selected={state === stateItem.state_name} value={stateItem.state_name} key={`state-` + stateIndex}>{stateItem.state_name}</option>);
                        })}
                      </select>*/}
                      <Select 
                      defaultValue={selectedOption}
                      value={selectedOption}
                      isSearchable
                      options={getStatesOptions()}
                      placeholder="Select State"
                      onChange={(item, index) => {
                        setState(item.value);
                        setSelectedOption({ value: item.value, label: item.value });
                      }}
                      />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.selectGender}</label>
                      <div className="checkBoxInline">
                        <label className="radioContainer Inline">{Strings.male}
                          <input type="radio" value="male" checked={gender === "male"} onChange={(e) => { setGender(e.target.value); }} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radioContainer Inline">{Strings.female}
                          <input type="radio" value="female" checked={gender === "female"} onChange={(e) => { setGender(e.target.value); }} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.dob}</label>
                      <DatePicker
                        className="inputDate"
                        selected={dob}
                        placeholderText={Strings.click_to_select_DOB}
                        showYearDropdown
                        scrollableYearDropdown
                        maxDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                        onChange={date => { return setDob(date); }}
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        )}
                      />

                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.PANCard}</label>
                      <input className="inputField" type="text" value={panCard} placeholder={Strings.enterPanCard} onChange={(e) => { setPanCard(e.target.value); }} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.gstNumber}</label>
                      <input className="inputField" type="text" value={gstNo} placeholder={Strings.enterGSTNumber} onChange={(e) => { setGstNo(e.target.value); }} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.currentSubscriptionPlan}</label>
                      <div className="checkBoxInline">
                        <label className="radioContainer Inline">{Strings.packageMonthly}
                          <input type="radio" checked={activeGroupId === "2"} disabled={true} />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radioContainer Inline">{Strings.packageHalfYearly}
                          <input type="radio" checked={activeGroupId === "3"} disabled={true} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {activeGroupId !== '' && activeGroupId !== '1' && <span className="planDesc">{Strings.your_plan_is_valid_till} {expiryDate}</span>}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup">
                    <label className="checkboxContainer">{Strings.checkConditions}
                      <input type="checkbox" onChange={e => { setRegularAlerts(e.target.checked); }} checked={regularAlerts} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup">
                    <label className="checkboxContainer">{Strings.paymentRead} <span className="openLink" onClick={() => showTermsPage()}>{Strings.paymentTerms}</span> {Strings.paymentAdhere} <span className="openLink" onClick={() => showCopyrightPage()}>{Strings.paymentCopyright}</span>.
                      <input type="checkbox" onChange={e => { setPolicyAgree(e.target.checked); }} checked={policyAgree} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup formSubmit">
                    <SmallButton clicked={() => saveUserSettings()} btnType="Orange" disabled={!policyAgree} loading={isLoading}>{Strings.update}</SmallButton>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {navigationData.showPrivacyPolicyModal ? <PrivacyPolicyModal /> : null}
      {navigationData.showTermsConditionsModal ? <TermsConditionsModal /> : null}
    </Fragment>
  );
}
