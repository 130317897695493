import * as actions from "redux/action-types";
// import { CancelToken } from "axios";
// import { log } from "utils";

const initialState = {
    autoFocus: null,
    websocketStatus: "INIT",
    currentUserId: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CURRENT_USER_ID:
      return {
        ...state,
        currentUserId: action.id,
    };

    default:
      return state;
  }
}
