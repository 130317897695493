import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as NavigationActions from "redux/actions/NavigationActions";
import { getUserId, getUserToken } from "services/AuthService";
import { getMyCertificates as getMyCertificatesFromAPI } from "services/AceTestService";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Strings } from 'resources';
import SmallButton from "components/UI/SmallButton";
import { log } from "utils";
import apiConfig from "config/api";
import appConfig from "config/config";
import moment from 'moment';
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import "./ListCertificates.scss";

export default function ListCertificates(props) {
  const alert = useAlert();
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const referData = useSelector((state) => state.referData);
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const [certificates, setCertificates] = useState([]);
  const [isLoading, setLoadingState] = useState(false);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Get My Played ACE Game Lists */
  useEffect(() => {
    async function getACEGameLCertificates(){
      setLoadingState(true);
      const { data } = await getMyCertificatesFromAPI(userToken, userId);
      if (data.status) {
        setCertificates(data.data);
      }
      setLoadingState(false);
    }
    getACEGameLCertificates();
  }, [dispatch, userId, userToken]);

  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const downloadMyScholarshipCertificate = (fileUrl, fileName) => {
    const status = checkACEGameExpireModal();
    if (status === '4') {
        downloadLibraryFile(fileUrl, fileName);
    } else {
      showFeatureNotAvailableModal();
    }
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
      dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  const downloadLibraryFile = (fileUrl, fileName) => {
    const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadFile + "?file="+fileUrl;
    fetch(fileDownloadUrl)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
            //window.location.href = response.url;
    });
}

const getGeneratedTime = (date) => {
    return moment(date).format('DD MMM YYYY');
}

const downloadLinkDescription = (fileUrl) => {
  const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadPDF + "?file="+fileUrl;
  return '<a class="gameActionLink" href="'+fileUrl+'">Download</a>';
}

  /**
   * aceGameItem
   * @param {object} item
   * @param {number} index
   * @description Library Files Individual Item
   */
  const aceGameItem = (item, index) => {
      return (<tr key={`ace-${index}`}>
          <td>{item?.certid}</td>
          <td>{item?.stage_name_en}</td>
          <td>{item?.ace_points}</td>
          <td>{getGeneratedTime(item?.created_on)}</td>
          <td>
            <span className="actionWrapper" dangerouslySetInnerHTML={{__html : downloadLinkDescription(item?.file_url)}}></span>
            {/*<span className="gameAction" onClick={() => downloadMyScholarshipCertificate(item?.file_url, 'scholarshipCertificate')}><FontAwesomeIcon icon={faDownload} size="lg" className="codeCopyIcon" /></span>*/}
          </td>
        </tr>);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitleBox">
              <div className="titleBox">
                <h1>{Strings.my_level_wise_certificates}</h1>
              </div>
            </div>

            <div className="myPageWrapper ACEGameLists">
              <div className="referralSectionHistory">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{Strings.certificateLevel}</th>
                        <th>{Strings.certificateAcePoints}</th>
                        <th>{Strings.certificateCreatedOn}</th>
                        <th>{Strings.action}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {certificates.length === 0 && !isLoading &&
                      <tr>
                        <td colSpan="4" align="center">{Strings.you_have_not_achieved_any_certificates_yet}</td>
                      </tr>
                    }
                    {certificates.length === 0 && isLoading &&
                        <tr>
                          <td colSpan="4" align="center">{Strings.loading_certificates}</td>
                        </tr>
                    }
                    {certificates.length > 0 &&
                      certificates.map((item, index) => aceGameItem(item, index))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
      </div>
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
    </Fragment>
  );
}
