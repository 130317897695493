const languageStrings = {
  hi: "Hi",
  free: "Free",
  monthly: "Monthly",
  halfYearly: "Half Yearly",
  freePrice: "0",
  monthlyPrice: "99",
  halfYearlyPrice: "599",
  gstTax: "GST",
  freeDesc: "Register and get access to the ACE Game. You are allowed to play 20 ACE Games containing 400 questions as a sample. Free subscription will give you all features provided by Rs 599 plan and will expire at the end of the 20th game. You will get detailed Dashboard of your performance, compared to other students that will help you decide your next step.",
  monthlyDesc: "Get access to features such as dynamic Dashboard, over 800 ACE games, peer to peer communication - Quill it, wide range of study material and videos.",
  halfYearlyDesc: "Access to full suite of BharatCET features that include scholarship with cash benefits, leadership board and maturity stages, detailed report, study material and most importantly the accuracy worm that shows you how you are getting better.",
  priceSectionTitle:"Select what suits you best",
  upcomingEventsTitle: "Upcoming events for the month",
  noUpcomingEvents: "No Upcoming Events Found",
  dynamicDashboardTitle: "Dynamic Dashboard",
  dynamicDashboardDesc: "Unique dynamic dashboard gives the students single click access to multiple BharatCET portal features such as personal scoreboard, Quill it, subject wise self studies, hamburger indexation, upcoming events, referral program and none the less, the ACE Game.",
  dynamicDashboardButton: "Explore",
  referTitle: "Refer a Friend",
  referDesc: "Bring along a friend and win a chance to get referral bonus straight into you bank account. For more details, read the referral program guidelines.",
  referButton: "Refer Now",
  quillItTitle: "Quill It...!",
  quillItDesc: "Quill it is our unique peer-to-peer connecting tool where students could discuss subjects, ask questions, provide answers and help each other through knowledge sharing on a regular basis.",
  quillItButton: "Find out more",
  accessTitle: "Access BharatCET anytime, anywhere",
  accessDesc: "We have designed the application such that the basic smartphones shall also have the capability to seamlessly use the features. Keeping in mind that students are always busy, students can watch the videos, read the material, take the exams and play the ACE Game – anytime, anywhere",
  accessButton: "Explore",
  learningTitle: "Self Learning",
  learningDesc: "Study at convenience and at a pace comfortable to you. We have massive collection of reference study material, short videos and past exam papers in the library that you can access easily and solve the problems given therein",
  learningButton: "Explore",
  registerTitle: "Students Registered",
  registeredUsers: "Registered Users",
  registerButton: "Subscribe",
  startNow: "Start Now",
  oneStepSuccess: "One step towards success",
  termsAndConditionsURL: "https://bharatcet.com/terms-and-conditions/",
  privacyPolicyURL: "https://bharatcet.com/privacy-policy/",
  contactUsURL: "https://bharatcet.com/contact-us/",
  facebookURL: "https://www.facebook.com/bharatcet",
  twitterURL: "https://twitter.com/BharatCet",
  youTubeURL: "https://www.youtube.com/channel/UCc-KSPeNWgg7QOHUWo-gBZA",
  linkedInURL: "https://www.linkedin.com/company/bharatcet/about/",
  instagramURL: "https://www.instagram.com/bharatcet/",
  websiteURL: "https://bharatcet.com/",
  nraCETURL: "https://bharatcet.com/nra-cet/",
  sendEmailGo: "Go",
  sendEmailPlaceholder: "Enter Your Email",
  getMoreDetails: "Get More Details",
  footerLinks: "Links",
  footerCourses: "Courses",
  bharatCET: "BharatCET",
  bharatCETDescription: "BharatCET is the exclusive offering of Techpro Edusys Pvt Limited. While designing the portal, we have kept in mind the growing needs of students to have the competitive advantage across various examinations and helping them prepare to succeed.",
  welcomeEmailSentSuccess: "Welcome Email Sent Successfully",
  nraCET: "NRACET",
  home: "Home",
  dashboard: "Dashboard",
  library: "Library",
  scoreboard: "Scoreboard",
  refer: "Refer",
  ace_game_history: "Ace Game History",
  ace_history: "ACE History",
  settings: "Settings",
  termsConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  contactUs: "Contact Us",
  firstName: "First Name",
  firstName_placeholder: "Enter First Name",
  lastName: "Last Name",
  lastName_placeholder: "Enter Last Name",
  mobileNo: "Mobile Number",
  mobileNo_placeholder: "Enter Mobile Number",
  otpNo: "OTP",
  otpNo_placeholder: "Enter OTP",
  password: "Password",
  password_placeholder: "Enter Password",
  confirmPassword: "Confirm Password",
  confirmPassword_placeholder: "Enter Password to confirm",
  email: "Email",
  enterEmail: "Enter Email",
  contactNumber: "Contact Number",
  enterContactNo: "Enter Contact No",
  city: "City",
  enterCity: "Enter City",
  state: "State",
  selectGender:"Select Gender",
  male: "Male",
  female: "Female",
  dob: "DOB",
  click_to_select_DOB: "Click to select a DOB",
  PANCard: "PAN Card",
  enterPanCard: "Enter PAN Card", 
  gstNumber: "GST Number", 
  enterGSTNumber: "Enter GST Number",
  currentSubscriptionPlan: "Current Subscription Plan",
  email_address: "Email Address",
  email_address_placeholder: "Enter Email Address",
  register_submit: "Register",
  account_activated_success: "Your account is successfully activated!",
  account_success: "Your account is successfully created. OTP has been sent to Registered Mobile number and Email ID.",
  login_success: "You have logged in successfully!",
  login: "Login",
  crackNRACET: "Crack NRA CET",
  forgotPassword: "Forgot Password",
  formSubmit: "Submit",
  dontHaveAccount: "Don't have an account?",
  cantLogin: "Can't Login?",
  haveCredentials: "Have Credentials?",
  createAccount: "Create an Account",
  resetYourPassword: "Reset your password",
  resetPassword: "Reset Password",
  successForgotPassword: "OTP has been sent to Registered Mobile number and Email ID.",
  successResetPassword: "Password Changed Successfully",
  changePassword: "Change Password",
  signUp: "Sign Up",
  formRegister: "Register",
  successSignUp: "Account Created Successfully. OTP has been sent to Registered Mobile number and Email ID.",
  failureSignUpAlreadyExists: "User with this Email or Mobile already exists",
  successUserActivated: "User Activated Successfully",
  activateUser: "Activate User",
  activate: "Activate",
  myAccount: "My Account",
  myProfile: "My Profile",
  mySubscription: "Subscription",
  myTermsConditions: "Terms and Conditions",
  myPrivacyPolicy: "Copyright and Privacy Policy",
  myContactUs: "Contact Us",
  mySignOut: "Sign Out",
  logout: "Logout",
  operation_canceled_by_user: "Operation canceled by user",
  otp_incorrect_try_again: "OTP Incorrect, Try again.",
  paymentHistoryPlans: "Plans",
  paymentHistoryPaymentStatus: "Payment Status",
  paymentHistoryPaymentMode: "Payment Mode",
  paymentHistoryHeading: "Payment History",
  paymentHistoryNotFound: "No Payment History Found",
  paymentHistoryLoading: "Loading...",
  paymentHistorySuccess: "Success",
  paymentHistoryFailure: "Failure",
  paymentHistoryNetBanking: "Net Banking",
  noInvoiceDetailsFound: "No Invoice details found.",
  goToCourses: "Go to Courses",
  subscription: "Subscription",
  invoiceId: "Invoice Id",
  transactionId: "Transaction Id",
  transactionStatus: "Transaction Status",
  transactionAmount: "Transaction Amount",
  success: "Success",
  failure: "Failure",
  paymentFailed: "Oops ! You were trying to make a payment that was not successful.",
  paymentSuccess: "Hooray ! Your Payment is Successful.",
  coursesHeading: "Courses",
  all: "All",
  ongoingSubscription: "Ongoing Subscription",
  pastSubscription: "Past Subscription",
  noPastSubscriptions: "You have not any past subscriptions.",
  goPastCourses: "Browse all courses and get yourself enrolled now.",
  pastViewCourses: "View Courses",
  noCurrentSubscription: "You have not yet subscribed.",
  goCurrentSubscriptions: "Browse all courses and get yourself enrolled now.",
  currentViewCourses: "View Courses",
  allNoCourses: "No Courses Found",
  allLoadingCourses: "Loading...",
  currentEnter: "Enter",
  allSubscribe: "Subscribe",
  allExplore: "Explore",
  unableToSetCourse: "Unable to Set Course",
  unableToGoSubscriptionPackageScreen: "Unable to go to subscription package",
  emailRequired: "Email is required",
  subjectRequired: "Subject is required",
  messageRequired: "Message is required",
  contactRequestSuccess: "Contact Request Sent successfully!",
  contactHear: "We want to hear from you",
  typeSubject: "Type your subject",
  typeMessage: "Type your message",
  contactSubmit: "Submit",
  whatDoYouGet: "What do you get?",
  packageMonthly: "Monthly",
  priceMonthly: "99",
  packageHalfYearly: "Half Yearly",
  priceHalfYearly: "599",
  verifyUpdateProfile: "Update Profile",
  verifyHeading: "Submit Billing Details",
  verifyDescription: "There are some data is missing in your profile to update. Please update that and then go for payment.",
  paymentHeading: "Payment",
  paymentBillingDetails: "Billing details",
  paymentFirstName: "First Name",
  paymentLastName: "Last Name",
  paymentEmail: "Email",
  paymentContact: "Contact No",
  paymentCity: "City",
  paymentState: "State",
  paymentUserCode: "User Code",
  paymentPanCard: "PAN Card",
  paymentReferral: "Referral Code",
  paymentSubscriptionPlan: "Subscription Plan",
  paymentFees: "Fees",
  paymentGST: "GST (18%)",
  paymentTotal: "Total",
  paymentCurrency: "Rs.",
  paymentTerms: "Terms and Conditions",
  paymentCopyright: "Copyright and Privacy Norms",
  paymentPayNow: "Pay Now",
  paymentRead: "I have read and agree with all the",
  paymentAdhere: "and will adhere to the",
  paymentReferralPlaceHolder: "Enter Referral Code",
  hmNoTopicsFound: "No Topics Found under this category",
  hmLoading: "Loading...",
  hmAccuracyRate: "Accuracy Rate",
  hmHeading: "Subject wise Learning",
  smLoading: "Loading...",
  smNoReadingFound: "No Study Material Found",
  smReading: "Reading",
  tap_to_take_test: "Tap to take test",
  completed: "Completed",
  pending: "Pending",
  subjectReportSuccessSent: "A detailed report with answers has been sent to your registered email address.",
  correctAnswer: "Correct Answer",
  emailAnswers: "Email Answers",
  submitAnswers: "Submit answers",
  answerLoading: "Loading...",
  NoQuestionsAvailable: "No Questions Available",
  justification: "Justification",
  daysLeft: "Days Left",
  acePoints: "Ace Points",
  endTheGame: "End the game",
  a: "A",
  b: "B",
  c: "C",
  d: "D",
  e: "E",
  endTheGameTitle: "End the game",
  endTheGameConfirm: "Are you sure you want to submit the answers and end the game?",
  endTheGameSubmit: "Submit",
  endTheGameGoBack: "Go Back",
  playMore: "Play More !",
  forDetailedReport: "For detailed report",
  clickHere: "Click here",
  basicStage: "Basic Stage",
  redeemNow: "Redeem !",
  congratulations: "Congratulations",
  youHaveScored: "You have scored",
  correctAnswers: "Correct Answers",
  inCorrectAnswers: "Incorrect Answers",
  unAnswered: "Unanswered",
  super: "SUPER",
  youHaveAccumulated: "You have accumulated",
  acePointsGathered: "ACE Points",
  welcomeToACEGame: "Welcome to the ACE Game",
  aceGameAgreeTerms: "I agree to this terms and conditions to proceed the game.",
  playNowAceGame: "Play Now",
  playACEGameText: "You can now play the ACE Game",
  featureNotAvailableText: "This feature is not available in your current plan. Kindly subscribe and unlock maximum benefits.",
  unlock: "Unlock",
  certificateTitle: "Certificate of Scholarship | BharatCET",
  certificateDesc: "Certificate of Scholarship | BharatCET",
  certificateLoading: "Loading...",
  certificateUserWithCode: "User with code#",
  certificateNotEligible: "is not eligible for certificate yet.",
  certificateDoNotExits: "do not exists",
  certificateSave: "Save",
  certificateLetKnow: "Let everyone know.",
  congratulationsTitle: "Congratulations !!!",
  certificateDescription: "Your scholarship will be credited to your bank account within 7 working days.",
  your: "Your",
  certificateWord: "Certificate",
  isReady: "is ready",
  notEnoughACEPoints: "You do not have enough ACE points to redeem.",
  areYouSure: "Are you sure ?",
  areYouSureDescription: "You are few games away and there is still some learning left, many more games to play and many more ACE points to win.",
  proceed: "Proceed",
  toRedeem: "to redeem",
  toCancelRedemption: "to cancel redemption",
  accuracy_rate: "Accuracy Rate",
  total_ace_points_for_correct_answer: "Total # of ace points for correct answers",
  total_of_eligible_ace_points_for_the_game: "Total # of eligible ace points for the game",
  total_of_ace_points_for_correct_answers_on_the_subject: "Total # of ace points for correct answers on the subject",
  total_of_eligible_ace_points_for_the_game_for_the_game_on_the_subject: "Total # of eligible ace points for the game on the subject",
  total_of_ace_points_for_correct_answers_on_the_sub_topic: "Total # of ace points for correct answers on the sub-topic",
  total_of_eligible_ace_points_for_the_game_for_the_sub_topic: "Total # of eligible ace points for the game on the sub-topic",
  total_of_ace_points_for_correct_ans_from: "Total # of ace points for correct answers from",
  game_played_to_last_game: "game played to Last game",
  total_of_eligible_ace_points_for_the_game_from: "Total # of eligible ace points for the game from",
  game_played_on_sunday_to_last_game_on_saturday: "game played on Sunday to Last game on Saturday",
  total_of_ace_points_for_correct_answers_of_all_players_at_national_level: "Total # of ace points for correct answers of all players at national level",
  total_of_eligible_ace_points_for_the_game_of_all_players_at_national_level: "Total # of eligible ace points for the game of all players at national level",
  subject_quiz_report_for: "Subject Quiz Report for",
  loading_subject_game_report: "Loading Subject Game Report...",
  iAgree: "I Agree",
  copyRightAndPrivacyNorms: "Copyright and Privacy Norms",
  termsAndConditions: "Terms and Conditions",
  terms: "Terms",
  conditions: "Conditions",
  playSubjectTest: "Play Subject Wise Game",
  playSubjectTestDesc: "If you want to play subject wise quiz then click on play now",
  subjectTestPlayNow: "Play Now",
  accountName: "Account Name",
  accountNamePlaceHolder: "Enter name as per bank records",
  bankName: "Bank Name",
  bankNamePlaceholder: "Please share your Bank name",
  panNo: "PAN No",
  panNoPlaceholder: "Your PAN Number",
  ifscCode: "IFSC Code",
  ifscCodePlaceholder: "Your Bank's IFSC Code",
  accountNumber: "Account Number",
  accountNumberPlaceholder: "Your Account Number",
  submit: "Submit",
  confirm: "Confirm",
  goBack: "Go Back",
  confirmBankDetails: "Confirm Bank details",
  bankDetails: "Bank details",
  selectAccountType: "Select Your Account Type",
  savingsAccount: "Savings Account",
  currentAccount: "Current Account",
  certificate: "Copyright",
  techProEduSys: "Techpro Edusys Pvt Ltd",
  facebook: "Facebook",
  twitter: "Twitter",
  youTube: "YouTube",
  instaGram: "InstaGram",
  linkedIn: "LinkedIn",
  confirmIdentity: "BharatCET confirms the identity of this individual.",
  verifyAt: "Verify at",
  awards: "Awards",
  bharatCETName: "BharatCET",
  menuName: "Menu",
  notificationName: "Notification",
  languageName: "Language",
  searchName: "Search",
  quillItName: "Quill It",
  myProfileName: "My Profile",
  scholarshipCertificate: "Scholarship Certificate",
  hereByDelivered: "is hereby awarded to",
  forAchieving: "for achieving",
  myAcePoints: "ACE points",
  andReachingThe: "and reaching the",
  stageLevel: "Level",
  placeholderSearch: "Type what you are searching for ...",
  paymentHistory: "Payment History",
  books: "Books",
  test: "Test",
  practiceToExcel: "Practice to Excel !!!",
  scoreBoard: "Scoreboard",
  playMoreText: "Play More",
  redeem: "Redeem",
  levelWiseStudents: "Level wise # of students as of",
  setAsDefault: "Set as default",
  changePasswordMenu: "Change Password",
  changePasswordPageTitle: "Change My Password",
  passwordsDoNotMatch: "Passwords do not match",
  passwordAtLeastSixChars: "Password should contain at least 6 characters",
  updatePasswordButton: "Update Password",
  pass: "New Password",
  passPlaceHolder: "Enter New Password",
  confirmPass: "Confirm New Password",
  confirmPassPlaceHolder: "Enter New Password to confirm",
  passwordNotSame: "Password and Confirm Password are not same.",
  passwordAtLeast6Chars: "Password should be at least 8 chars long.",
  invalid_user_credentials: "Invalid User Credentials",
  selectRegularAlerts: "Please select Regular Alerts",
  selectPrivacyPolicy: "Please select Privacy Policy",
  email_do_not_exists: "User with this email do not exists",
  contact_do_not_exists: "User with this contact number do not exists",
  user_with_this_email_or_contact_number_do_not_exists: "User with this email and contact do not exists",
  failed_to_send_forgot_password_email: "Failed to send forgot password email",
  this_user_is_already_subscribed: "User is already registered in BharatCET",
  invitation_sent_successfully: "An SMS has been sent to your friend for inviting to BharatCET",
  already_invited: "Your friend with this number already invited",
  this_mobile_number_is_not_in_your_invited_users_list: "Mobile number is not in your invited users list",
  reminder_sent_successfully: "An Reminder SMS has been sent to your friend for inviting to BharatCET",
  account_is_inactive: "Your Account is inactive",
  notification_read_successfully: "Notification Read Successfully",
  must_agree_terms_conditions:	"Must agree with terms and conditions",
  ace_game_fetched_successfully:	"ACE Game fetched successfully",
  failed_to_add_ace_game_attempt:	"Failed to add ACE Game attempt",
  ace_game_not_present:	"ACE Game not present",
  missing_request_params:	"Missing request params",
  ace_game_already_completed:	"ACE Game already completed",
  ace_game_attempt_not_present:	"ACE Game attempt not present",
  question_status_fetched: "Question status fetched",
  question_answer_updated: "Question answer updated",
  failed_to_update_data:"Failed to update data",
  game_successfully_ended: "Game successfully ended",
  no_ace_game_stats_present: "No ACE Game stats present",
  leaderboard_fetched_successfully: "Leaderboard fetched successfully",
  invalid_token: "Invoice token",
  unauthorized_user: "Unauthorized user",
  accuracy_chart_fetched_successfully: "Accuracy chart fetched successfully",
  no_data_found: "No data found",
  verify_ace_point_certificate_fetched_successfully: "Verify ACE point certificate fetched successfully",
  something_went_wrong: "Something went wrong",
  invalid_user_code: "Invalid user code",
  user_code_required:	"User code required",
  package_not_valid: "Invalid package",
  trial_mode_expired:	"Trial mode expired",
  score_card_sent: "Score card sent",
  acegame_scorecard_fetched_successfully:	"ACE Game scorecard fetched successfully",
  score_card_data_fetched_successfully:	"Score card data fetched successfully",
  categorywise_Indian_accuracy_successfully: "Category wise indian accuracy successfully",
  user_status_fetched_successfully:	"User status fetched successfully",
  no_ace_game_details_found: "No ACE Game details found",
  ace_game_played_status_fetch_successfully: "ACE Game played status fetch successfully",
  you_are_not_eligible_for_submit_redemption:	"You are not eligible for submit redemption",
  your_ace_point_redemption_request_submitted: "Your ACE point redemption request submitted",
  redemption_history:	"Redemption History",
  successfully_logged_in:	"Successfully logged in",
  user_email_is_not_verified:	"User email is not verified",
  email_and_password_are_required: "Email and password are required",
  successfully_logged_out: "Successfully logged out",
  invalid_user_or_token: "Invalid user or token",
  user_id_and_jwt_token_are_required:	"User id and JWT Token are required",
  forgot_password_email_sent:	"Forgot password email sent",
  email_and_contact_number_are_required: "Email and contact number are required",
  reset_password_link_is_valid:	"Reset password link is valid",
  reset_password_link_expired: "Reset password link expired",
  reset_password_token_is_required:	"Reset password token is required",
  user_not_registered_with_google: "User not registered with google",
  invalid_google_account:	"Invalid google account",
  email_not_registered:	"Email not registered",
  enter_required_field:	"Enter required field",
  confirm_password_required: "Confirm password required",
  password_and_confirm_password_not_matched: "Password and confirm password not matched",
  password_updated_successfully: "Password updated successfully",
  failed_to_update_user_password:	"Failed to update user password",
  reset_password_code_expired: "Reset password code expired",
  reset_password_token_and_new_password_are_required:	"Reset password token and new password are required",
  failed_to_send_user_activation_email:	"Failed to send user activation email",
  user_created_successfully: "User created successfully",
  failed_to_create_new_User: "Failed to create new user",
  user_with_this_mobile_or_email_already_exists: "User with this mobile or email already exists",
  user_details_are_required: "User details are required",
  user_activated_successfully: "User activated successfully",
  user_activation_failed:	"User activation failed",
  user_activation_code_expired:	"User activation code expired",
  user_activation_token_is_required: "User activation token is required",
  please_fill_all_billing_details: "Please fill all billing details",
  user_details_ok: "User details ok",
  password_not_matched:	"Password not matched",
  userid_or_password_is_missing: "User id or password is missing",
  banner_fetch_successfully: "Banner fetch successfully",
  no_banner_found: "No banner found",
  category_fetched_successfully: "Category fetched successfully",
  no_category_found: "No category found",
  email_sent_successfully: "Email send successfully",
  no_record_found: "No record found",
  cron_run_successfully: "Cron run successfully",
  option_updated_successfully: "Option update successfully",
  containers_generated_successfully: "Container generated successfully",
  subject_quiz_containers_generated_successfully:	"Subject quiz containers generated successfully",
  yesterdays_pending_ace_games_completed_successfully: "Yesterdays pending ace games completed successfully",
  referral_users_tree_generated_successfully:	"Referral users tree generated successfully",
  questions_tree_generated_successfully: "Question tree generated successfully",
  questions_should_be_20:	"Questions should be 20",
  container_level_is_missing:	"Container level is missing",
  error_in_generating_container: "Error in generating container",
  subscription_expiry_status:	"Subscription expiry status",
  certificate_sent:	"Certificate sent",
  user_id_email_and_certificate_url_required:	"User id email and certificate url required",
  events_fetch_successfully: "Events fetch successfully",
  no_events_found: "No events found",
  exam_list_fetched_successfully:	"Exam list fetched successfully",
  exam_list_not_found: "Exam list not found",
  subscription_group_fetched_successfully: "Subscription group fetched successfully",
  no_subscription_group_found: "No subscription group found",
  exam_package_fetch_successfully: "Exam package fetch successfully",
  course_group_details_no_found: "Course group details no found",
  exam_id_required:	"Exam id required",
  subject_quiz_details_fetch_successfully: "Subject quiz details fetch successfully",
  no_subject_quiz_details_found: "No subject quiz details found",
  libraries_fetch_successfully:	"Libraries fetch successfully",
  no_library_found:	"No library found",
  mail_sent_successfully:	"Mail send successfully",
  failed_to_sent_mail: "Fail to sent mail",
  notifications_fetched_successfully:	"Notifications fetched successfully",
  user_id_is_required: "User id is required",
  token_is_required: "Token is required",
  status_updated_successfully: "Status updated successfully",
  user_id_and_read_status_is_required: "User id and read status is required",
  please_complete_your_profile:	"Please complete your profile",
  invoice_details_fetched_successfully:	"Invoice details fetched successfully",
  invoice_not_found: "Invoice Not found",
  my_payment_history_fetched_successfully: "My payment history fetched successfully",
  payment_history_not_found: "Payment history not found",
  containers_fetched_successfully: "Containers fetched successfully",
  no_container_found:	"No container found",
  questions_fetched_successfully:	"Question fetched successfully",
  no_question_found: "No question found",
  Quiz_Submitted_successfully: "Quiz submitted successfully",
  Error_In_submission: "Error in submission",
  already_quiz_present:	"Already quiz present",
  question_answer_sent:	"Question answer sent",
  package_not_valid_or_trial_mode_expired: "Package not valid or trial mode expired",
  subject_game_scorecard_fetched_successfully: "Subject game score card fetched successfully",
  your_redemption_submitted: "Your redemption submitted",
  you_are_not_eligible_for_redemption: "You are not eligible for redemption",
  redemption_history_fetched_successfully: "Redemption history fetched successfully",
  no_redemption_found: "No redemption found",
  invited_user_fetched_successfully: "Invited user fetched successfully",
  invited_user_not_found:	"Invited user not found",
  this_mobile_number_already_register: "This mobile number already register",
  search_result_fetched_successfully:	"Search result fetched successfully",
  no_search_result_found:	"No search result found",
  study_materials_fetched_successfully: "Study materials fetched successfully",
  no_study_materials_found:	"No study materials found",
  topics_fetched_successfully: "Topics fetched successfully",
  current_subscription_fetch_successfully: "Current subscription fetch successfully",
  no_current_subscription_is_there:	"No current subscription is there",
  user_fetched_successfully: "User fetched successfully",
  username_is_required:	"Username is required",
  user_profile_pic_removed_successfully:	"User profile pic removed successfully",
  user_details_updated_successfully: "User details updated successfully",
  token_and_user_id_are_required:	"Token and User Id are required",
  password_successfully_updated: "Password successfully updated",
  failed_to_update_password: "Failed to update  password",
  userId_or_password_is_missing: "User id and Password in missing",
  failed_to_upload_profile_pic:	"Failed to upload profit pic",
  profile_pic_updated: "Profile pic updated",
  state_fetch_successfully: "State fetched successfully",
  statistics_fetched_successfully: "Statistics fetched successfully",
  no_statistics_found: "No statistics found",
  score_fetch_successfully:	"Score fetched successfully",
  invalid_referral_code: "Invalid referral code",
  code_validated_successfully: "Code validated successfully",
  you_are_eligible:	"You are eligible",
  you_are_not_eligible:	"You are not eligible",
  this_is_a_free_trial_you_to_understand_the_game :"This is a free trial, for you to understand the game. What you get out of it?",
  set_20_random_questions: "Set of 20 random questions.",
  exact_replica_of_the_real_ace_game: "Exact replica of the real ACE game",
  detailed_performance_report: "Detailed performance report.",
  answer_to_all_questions_available_for_review: "Answers to all the questions available for review.",
  ok_take_me_to_the_rules: "Ok, Take me to the rules !",
  free_trial_expired: "Free Trial Expired",
  your_free_trial_is_expired_kindly_subscribe_and_unlock_max_benefits: "Your Free Trial is expired. Kindly subscribe and unlock maximum benefits.",
  subscribeBtn: "Subscribe",
  redemption_applied_successfully: "Redemption Applied Successfully. Money will be credited to your account within 10 working days.",
  are_you_sure_report_this_comment_of: "Are you sure to report this comment of ",
  as_objectionable: " as objectionable?",
  thanks_for_reporting_objectionable_comment: "Thanks for reporting objectionable comment.",
  go_to_quillit: "Go to QuillIt",
  quillIt: "QuillIt",
  we_are_making_nice_vide_tutorial: "We are making a nice tutorial video.",
  it_will_be_made_available_shortly: "It will be made available shortly.",
  oops: "Oops !",
  no_notification_found: "No Notifications Found",
  mark_all_as_read: "Mark all as read", 
  introductory_price: "Introductory Price",
  page_not_found: "Page not Found",
  sorry_we_can_not_find_page_you_are_looking_for: "Sorry, we can't find the page you're looking for.",
  return_to_home_page: "Return to Home Page",
  your_response_registered: "Your response registered",
  the_previous_ace_game_is_over: "The Previous ACE Game is Over.",
  loadingAceGame: "Loading ACE Game...",
  playedAceGames: "Played ACE Games",
  code: "Code",
  totalQuestions: "Total Questions",
  attendedQuestions: "Attended Questions",
  correctQuestions: "Correct Questions",
  totalScore: "Total Score",
  correctScore: "Correct Score",
  accuracyPoints: "Accuracy Points",
  accuracy: "Accuracy",
  timeTaken: "Time Taken (minutes)",
  status: "Status",
  action: "Action",
  you_have_not_played_any_ace_game_yet: "You have not played any ACE Game yet.",
  loadingAceGameHistory: "Loading ACE Game History...",
  gameRule1: "Ace Game is a multi choice questions-based game which offers the player an opportunity to score and win Ace Points",
  gameRule2: "Ace Points have pre-decided scoring system, where each question has a unique score, based on the complexity to answer. Ace points would either be 2, 5 or 10.",
  gameRule3: "There will be no negative marking for any incorrect answer.",
  gameRule4: 'Every correct answer will win the player a score equivalent called as "Ace points"',
  gameRule5: "Each ACE Game will be unique set of 20 questions carrying varying marks based on complexity",
  gameRule6: "Through the journey of random ACE Games, the player shall keep gathering ACE Points which that will move the player on the leadership board",
  gameRule7: "The questions will be thrown at random and each ACE Game has a pre-set time limit of 30 minutes",
  gameRule8: "The player may decide to skip a question and revisit it at a later stage or modify the answers, provided the time permits and doesn’t not cross 30 minutes, after which the game will auto-stop and points collected at the end of the 30th minutes shall be considered as a final score", 
  gameRule9: "TEPL shall not be responsible should there be any communication or data issue at the players end while the time is clicking",
  gameRule10: "At the end of each game, the player shall understand the score and accuracy rate on a high level and on clicking on 'Detailed Report', the player shall get a detailed time till date summary of the total Ace Points, current game points, accuracy rate and also a comparative score of accuracy with other players on the platform.",
  gameRule11: "The student shall not be permitted to copy, edit, reproduce or share the questions in the ACE Game",
  gameRule12: "For any registered visitor, who is not a subscribed member, BharatCET shall offer 20 ACE games for free",
  getDetailedReport: "Get Detailed Report",
  copyright: "Copyright",
  get_your_doubts_cleared_on_a_click: "Get your doubts cleared on a click.",
  get_subjectwise_test: "Get better with subject level tests",
  get_subjectwise_test_desc: "There are over 23,000 questions across over 90+ subjects that we have put together for you. Choose the subject you want to practice and take the tests. Each test consists of 10 questions and after giving the test, you will get to see the answers and explanation/logic for each of the question. So why wait, take these subject wise tests everyday.",
  get_subjectwise_test_desc1: "We also encourage you to take ACE Games - short mock tests of 20 questions with mix of all subjects to gauge your accuracy rate.",
  six_steps_to_conquer: "Six steps to conquer.",
  getStarted: "Get Started",
  practice_tests_play_quick_ace_games: "Practice Tests - Play Quick ACE Games",
  subject_wise_practice: "Subject Wise Practice",
  quick_access: "Quick Access",
  chat: "Chat",
  no_files_found: "We are refreshing a library. You will be notified, Please stay tuned.",
  loadMore: "Load More",
  payment_failure_try_again_through_subscription: "Kindly try again through the Subscription Page. If you wish to go the Dashboard, please click the Home Icon in the left menu bar.",
  loading: "Loading...",
  policies: "Policies",
  procedures: "Procedures",
  faqs: "FAQs",
  troubleshooting: "Troubleshooting",
  earn_using_friend_who_subscribe: "Earn 10% with every friend who subscribes",
  your_referral_code: "Your referral code",
  invite: "Invite",
  available_rewards: "Available Rewards",
  encash: "Encash",
  invitedOn: "Invited On",
  subscriptionStatus: "Subscription Status",
  codeStatus: "Code Status",
  youEarned: "You Earned",
  not_invited_yet_start_inviting_and_earn_points: "Not invited yet. Start inviting and earn points.",
  loading_invited_friend: "Loading Invited Friends...",
  your_answer: "Your Answer",
  questionsRemaining: "Questions Remaining",
  hours: "Hours",
  gamesLeft: "Games Left",
  aceGame: "ACE Game",
  earnedPoints: "Earned Points",
  totalAcePoints: "Total ACE Points",
  you_are_going_great_keep_it_up: "You are going great. Keep it up.",
  your_accuracy_rate: "Your Accuracy Rate",
  logicalReasoning: "Logical Reasoning",
  quantitativeAptitude: "Quantitative Aptitude",
  verbal: "Verbal",
  generalKnowledge: "General Knowledge",
  total: "Total",
  game: "Game",
  overall: "Overall",
  indiaAverage: "India Average",
  accuracyRateCalculations: "Accuracy Rate Calculations",
  broadCategory_reasoning_ability_quantitative_aptitude_eng_lang_general_awareness: "Broad category = Reasoning Ability, Quantitative Aptitude, English language, General Awareness",
  questions_will_further_have_subcategories_but_user_will_not_see_any: "Questions will further have subcategories but user will not see any",
  for_ace_game_the_accuracy_rate_will_be_maintained_at :"For Ace Game, the accuracy rate will be maintained at",
  ace_game_level: "Ace game level",
  subject_level: "Subject level",
  subTopicLevel: "Sub-topic level",
  cumulative_ace_games_level : "Cumulative Ace Games Level",
  weekly_ace_games_level: "Weekly Ace Games Level",
  national_ace_games_average_levels : "National Ace Games Average Levels",
  answersReport: "Answers Report",
  atGameLevel: "... at game level",
  atSubjectLevel: "... at subject level",
  atSubTopicLevel: "... at sub-topic level",
  forCumulativeDashboard: "... for Cumulative Dashboard",
  forWeeklyWormTrend: "... for weekly worm trend",
  forNationalAverage: "... for national average",
  aceGamePerformanceReportWithAnswers: "Ace Game Performance Report With Answers",
  loadingACEGameReport : "Loading ACE Game Report...",
  eventType: "Event Type",
  eventDate: "Event Date",
  searchResultsFor: "Search Results for",
  noLibraryFilesFound: "No Library Files Found",
  events: "Events",
  studyMaterial: "Study Material",
  your_plan_is_valid_till : "Your Plan is valid till ",
  checkConditions : "Please send me regular alerts and notifications on my registered email id and mobile number.",
  update: "Update",
  choose_your_subscription_plan : "Choose your subscription plan",
  packageDescription1: "Single screen dynamic view of performance, platform features, quill it, easy access to self study.",
  packageDescription2: "Get access to over 20,000 questions in the form of ace games. Enables test paper solving through gamification, points gathering and eligible for scholarship.",
  packageDescription3: "This is our PAN India unique Peer to Peer chat window to help fellow students in sharing resources, asking question and getting quick answers.",
  packageDescription4: "Access to performance analytics such as accuracy rates( consolidated and subject wise), key statistics such as days to exam , questions remaining, ACE points gathered, total hours studied and games left.",
  keyIndicators: "Key Indicators",
  packageDescription5: "Access to subject level short videos that can help students learn and understand concepts on mobile(youTube channel) on the go.",
  accessToVideos: "Access to videos",
  packageDescription6: "Choose your language of study and to makes the students comfortable.",
  languageSelection: "Language selection",
  packageDescription7: "Every ACE point earned makes the student eligible to get cash rewards in the form of scholarship based on various stages of game progression.",
  scholarship: "Scholarship",
  packageDescription8: "6 stages of ACE game that helps student to gain more points, with accuracy, making them eligible for scholarship.",
  knowledge_maturity_stages: "Knowledge maturity stages",
  packageDescription9: "Detailed report will be shown with correct answers for every ACE game, points scored by the student and other key performance statistics.",
  detailedReport: "Detailed report",
  packageDescription10: "Access to various curated study material along with features such as bookmarking for future easy readability, downloading for ease of future access.",
  packageDescription11: "Week on week accuracy tracking to help students be better focused to maintain momentum in solving questions and moving up the knowledge maturity stage.",
  accuracyWorm: "Accuracy Worm",
  practiceTests: "Practice Tests",
  selectedCategory: "Selected Category",
  selectedTopic: "Selected Topic",
  yet_not_answered: "Yet not answered",
  viewAnswers: "View Answers",
  upcomingEvents: "Upcoming Events",
  dates: "Dates",
  this_number_already_registered_with_other_user: "This contact number already register with other user",
  remarks: "Remarks",
  pleaseRefer: "Please refer",
  youtube_video_url: "YouTube Video URL",
  for_learning_logical_reasoning_topics: "for learning Logical Reasoning Topics",
  for_learning_verbal_topics: "for learning Verbal Topics",
  please_refer_youtube_videos_for_learning_general_knowledge_topics: "Please refer YouTube Videos for learning General Knowledge Topics",
  practice_will_make_you_better: "Practice will make you better",
  certificateLevel: "Level",
  certificateAcePoints: "Ace Points",
  certificateCreatedOn: "Created On",
  you_have_not_achieved_any_certificates_yet: "You have not achieved any certificates yet.",
  loading_certificates: "Loading certificates...",
  days_left_for_exam: "Days Left For Exam",
  notifPageTitle: "Notifications",
  notifWOD: "WORD OF THE DAY",
  notifCF: "CURRENT AFFAIRS",
  notifN: "NOTIFICATIONS",
  faqPageTitle: "Frequently Asked Questions",
  landingNotifTagLine: "Get daily dose of knowledge",
  landingNotifTitle: "Notifications",
  the_most_powerful_and_useful_features: "The most powerful and useful features",
  landing_dynamic_view_of_performance_features_quill_easy_access_to_self_study: "Single screen dynamic view of performance, platform features, quill it, easy access to self study.",
  quill_it: "Quill it..!",
  landing_quill_it_chat_window_to_clarify_doubts: "Chat window to clarify doubts, reach out to professors, students, ask/answer questions.",
  aceGames: "ACE Games",
  landing_acegame_feature_description: "Get access to over 20,000 questions in the form of ace Games, Enables test paper solving through gamification, points gathering and eligibility for scholarship.",
  analytics: "Analytics",
  landing_analytics_features_description:"Access to performance analytics such as accuracy rates (consolidated and subject wise), key statistics such as questions remaining. ACE points gathered, total hours studied and games left.",
  landing_scholarship_features_description: "Every ACE point earned is eligible to get cash rewards in the form of scholarship based on various stages of game progression (Beginner to Grand Master).",
  leadershipLevels: "Leadership Levels",
  landing_leadershipLevels_description: "6 stages of ACE game that help compare performance with other students across India.",
  past_question_papers: "Past Question Papers",
  landing_past_question_paper_description: "Access to various curated study material along with features such as past question papers in PDF format.",
  landing_accuracyWorm : "Accuracy Worm",
  landing_accuracyWorm_description: "Work on week accuracy tracking to be better focused, maintain momentum to practise solving questions.",
  validity: "Validity",
  "questions": "Questions",
  monthlyFeatures: "Features",
  monthlyPlanFeatures: "Monthly Plan Features",
  pastAceGame: "Past ACE Games",
  landing_half_yearly: "HALF YEARLY",
  landing_plan_select_title: "Select a plan that best suits your needs",
  landing_plan_select_details1: "You can choose one amongst the monthly plan or the half yearly plan based on your requirement. To help you understand BharatCET better we also offer a free plan wherein you will get all features of a half yearly plan and can attempt 20 ACE games for free (total of 400 questions).",
  landing_plan_select_details2: "You may begin with a monthly plan and upgrade by paying the half yearly subscription fees. You can retain your ACE points provided the upgrade happens before your monthly subscription expires.",
  landing_frequently_ask_question: "Frequently Asked Questions",
  landing_faqs_details: "BharatCET goes beyond mock tests and believes that our students deserve motivation, in monetary terms, for their efforts and accuracy. We have converted the mock tests in form of dynamic ACE Games whereby, every correct answer builds up the students ranking on the leadership board and the students can redeem the points anytime in order to get the scholarship in their bank account.",
  landing_faqs_1: "What are Ace Games and Ace Points?",
  landing_faqs_2: "How do I use my referral code?",
  landing_faqs_3: "What happens after the expiry of my subscription plan?",
  landing_faqs_4: "When do I redeem the scholarship earned?",
  landing_faqs_5: "I want to register myself as a professor/teacher. What do I do?",
  faqs_1: "What are Ace Games and Ace Points?",
  faqs_1_description: "Ace Games are short 20 questions mock tests in game form with MCQs consisting of Logical Reasoning, Quantitative Aptitude, Verbal and GK subjects. Each question has a score based on complexity and getting the answer right will earn the student ACE points equal to the marks. The more the student gives right answers, more the ACE Points and more the cash scholarship",
  faqs_2: "How do I use my referral code?",
  faqs_2_description: "Go to the Refer a Friend Option, Insert the mobile number of your friends that would be used for registering with BharatCET. An SMS will be sent to that phone number with your Profile ID Code. Your friend needs to insert this ID while making the payment so that you would get a referral fee. Referring the friend through BharatCET is mandatory to establish relationship",
  faqs_3: "What happens after the expiry of my subscription plan?",
  faqs_3_description: "Your ID will get automatically deactivated after expiry and to restore the data, you will need to renew it by paying the subscription amount using your log in credentials",
  faqs_4: "Does the period of my paid subscription begin from date of my registration or date of payment for paid plan?",
  faqs_4_description: "Your subscription period will begin from the date you make the actual payment and not from the date your registered. So even if you enjoy playing the first 20 Free ACE Games and make a payment at the end, you subscription period will begin from date you make the payment",
  faqs_5: "When do I redeem the scholarship earned?",
  faqs_5_description: "It is always good to earn as many ACE Points to reach maximum levels and earn maximum scholarship possible. To redeem your scholarship and get the money in your bank account follow simple steps that the app will guide you to. You will also get a scholarship certificate that you can share with your friends on social media",
  faqs_6: "Can I download the past question papers in the library?",
  faqs_6_description: "No. One cannot download the past solved question papers",
  faqs_7: "What do I do when my transaction fails or I face any technology problem?",
  faqs_7_description: "Do not worry. Kindly take a screen shot and send a mail to help@bharatcet.com and we will ensure a speedy investigation and fastest resolution to your query",
  faqs_8: "I want to register myself as a professor/teacher. What do I do?",
  faqs_8_description: "It is simple. By default we recognize the registered user as a student. But as a professor, once you download and register yourself on BharatCET app, you would need to send us the screen shot of your profile to compliance@bharatcet.com. You would get a call from our onboarding team. After some quick checks, our backend technology would change your profile characteristics to a ‘Professor”.",
  faqs_9 : "Do I get a refund once I make the payment?",
  faqs_9_description : "Given that we give Free subscription to test our features, play games, etc. we never thought that you would feel like taking a refund. So once the payment is made, we do not refund.",
  faqs_10: "Can I move from Monthly plan to Half yearly Plan?",
  faqs_10_description: "Yes. You can move from monthly plan to half yearly plan but not vice versa",
  faqs_11: "Can I play the same ACE Game multiple times?",
  faqs_11_description: "With over 20000+ unique questions, we are sure you would be interested to play more and attempt more questions. Hence once you play one ACE Game, you would not be given that question or game again",
  faqs_12: "I was logged on my mobile and when I tried to log in my laptop it did not allow. What do I do?",
  faqs_12_description: "For your system security purpose, we have purposefully kept one gadget log in feature such that your ID is not misused by anyone else. If you wish to switch from one gadget to other, please log out from earlier gadget and log in once again to the new one",
  faqs_13: "What is the Accuracy Worm?",
  faqs_13_description: "Accuracy Worm tracks your weekly performance in terms of overall accuracy.",
  faqs_14: "How do I read the heatmap?",
  faqs_14_description: "Heatmap tells you the subtopic level performance. Red indicates you are weak and need to do a lot more practice in that subject, Amber means you are good but need to practice more and green is your strong area.",
  faqs_15: "After my subscription expires can I access my ACE Games?",
  faqs_15_description: "Ace Games can be accessed till your subscription lasts. You may renew the subscription by taking the Rs 599+GST plan and continue to access and play more ACE Games",
  faqs_16: "What if I do not have GST or PAN Card at the time of creating my profile?",
  faqs_16_description: "If you do no have a GST or PAN Card you may simply put “Not Available”.",
  faqs_17: "I had asked a question on Quill it…! 3 days back but I do not see that question now",
  faqs_17_description: "Quill it…! data is active for only 24 hours. After which the data gets auto deleted",
  faqs_18: "What are the payment modes?",
  faqs_18_description: "Quill it…! data is active for only 24 hours. After which the data gets auto deleted",
  faqs_19: "Blank White Screen is coming when I try to play ACE Game. What is the solution?",
  faqs_19_description: "There is one update issue for certain phones regarding web view. Please update 'Android System WebView' application in your android device to the latest version to avoid this issue.",
  footerNotification: "Notifications",
  downloadApp: "Download App",
  download: "Download",
  signIn: "Sign In",
  signUpWith: "Sign up with",
  myCertificates: "My Certificates",
  commentReport: "Report",
  are_you_sure_report_this_user: "Are you sure to report this user ",
  as_objectionable_comment_poster_user: "as objectionable comment poster user?",
  thanksForReporting: "Thanks for reporting ", 
  asObjectionableCommentPosterUser: "as objectionable comment poster user.",
  are_you_sure_to_report_this_question_of: "Are you sure to report this question of ",
  as_objectionable_question_poster_user: " as objectionable question poster user",
  question_mark: "?",
  someone_else_has_used_your_credentials_and_has_logged_in_from_another_machine: "Someone else has used your credentials and has logged in from another machine/mobile. If you suspect a breach, kindly send us a note at help@bharatcet.com with date and time and your User ID/Code.",
  signIn_to_the_dashboard: "Sign In to the Dashboard",
  click_below_button_to_go_ace_point_bonus: "Please click below button to get 50 bonus ACE Points",
  claimOffer: "Claim Offer",
  offer_is_not_valid_for_you_now: "Offer is not valid for you now.",
  goToHome: "Go to Home",
  you_have_already_claimed_50_bonus_ace_points: "You have already claimed 50 bonus ACE Points.",
  fifty_ace_points_claimed_successfully: "50 ACE points claimed successfully",
  opps_not_valid_request: "Oops ! This is not valid request.",
  my_level_wise_certificates: "My Level Wise Certificates",
  weekly_accuracy_worm: "Weekly Accuracy Worm",
  weekly_accuracy_worm_description: "This feature is not available in your current plan. Kindly subscribe to the half yearly plan and unlock maximum benefits.",
  beginner: "Beginner",
  levelCompleted: "Level Completed",
  you_are_eligible_for: "You are eligible for",
  selected_subscription: "Selected Subscription",
  basic: "Basic",
  advanced: "Advanced",
  expert: "Expert",
  intermediate: "Intermediate",
  grandMaster: "Grand Master",
  redemption_feature_is_available_only_in_half_yearly_plans: "Redemption feature is available only in half yearly plans.",
  and_get_full_benefits: "and get full benefits.",
  verify: "Verify",
  discount: "Discount",
  discountedFees: "Discounted Fees",
  invitedFriends: "Invited Friends",  
  tests:"Tests",
  select_topic: "Select Topic",
  test_event_title: "Test Event Title",
  timesUp: "Time's Up",
  bharatCETPortal: "BharatCET Portal",
  enterFriendMobileNumber: "Enter mobile number",
  contactIsRequired: "Contact is required",
  enterValidMobileNumber: "Please enter valid mobile number",
  remindStatus: "remind",
  earnedStatus: "earned",
  aceGameRules: "ACE Game Rules",
  gather1: "Gather",
  pointsToReach: "points more to reach",
  level1: "Level",
  chooseLanguage: "Language",
  instructor: "Instructor",
  singleStudent: "Student",
  students: "Students",
  points1: "points",
  copyToClipboard: "User Code Copied to Clipboard",
  isRequired: "is required.",
  isNotValid: "is not valid.",
  should_be_valid_US_number: "should be a valid US number.",
  should_only_consist_of_alpha_char: "should only consist of alphabetic characters.",
  may_not_greater_than: "may not be greater than",
  characters: "characters.",
  must_be_at_least: "must be at least",
  total_may_not_greater_than: "total may not be greater than",
  total_must_be_least_of_total: "total must be at least of total",
  should_only_consist_numeric_char: "should only consist of numeric characters.",
  should_not_have_any_space_in_between: "should not have any space in between.",
  does_not_match_with: "does not match with",
  invalid_account_details_passed: "Invalid account details passed",
  signInWith: "Sign In with",
  your_subscription_expired_please_renew: "Your Subscription is Expired. Please renew subscription",
  noSubjectTestAvailable: "No Subject quiz available for this topic",
  preHalfYearlyplanCharges: "2399",
  preMonthlyCharge: "399",
  strUsed : "Used",
  strNotUsed : "Not Used",
  strSubscribed: "Subscribed",
  strNotSubscribed: "Not Subscribed",
  ongoing: "Ongoing",
  subscribeNow: "Subscribe Now",
  takeSubjectExamNotice: "Select any category and then click on any topic to take test",

  packagetitle: "Select Package",
  examwise: "Examwise",
  subjectwise:"Subjectwise",
  cglplan: "CGL PLANS",
  chslplan: "CHSL PLANS",
  mtsplan: "MTS PLANS",
  cpo: "CPO PLANS",
  cdconstable: "GD CONSTABLE PLANS",
  stenographer:"STENOGRAPHER",
  allsubject: "all subjects",
  logicalreasoning: "Logical Reasoning",
  quantitativeaptitude: "Quantitative aptitude",
  verbal: "Verbal",
  generalknowledge: "General Knowledge",
  fullpackheading: "Select a package that suits your need",
  plan:"Plan",
  rs: "₹250",
  validity: "validity",
  days: "365 days",
  mock: "mock tests",
  mocktest: "50",
  pastquestionpapers : "past question papers",
  papercount: "23",
  info: "info",
  cgl: "cgl",
  /** lms link card  */
  learnTitle: "Classroom",
  learnDescription: "This is a classroom for teachers and students. Click here if you wish to attend classes as a student. Or, click here if you are a teacher and wish to offer courses to students.",
};

export default languageStrings;
