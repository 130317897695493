import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import * as AccountActions from "redux/actions/AccountActions";
import * as SubjectTestActions from "redux/actions/SubjectTestActions";
import { getUserId, getUserToken } from "services/AuthService";
import { getSubjectGameScoreCard as getSubjectGameScoreCardFromAPI } from "services/SubjectTestService";
import { Strings } from 'resources';
import { log } from "utils";
import "./SubjectGame.scss";

export default function SubjectGameReport(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userToken = getUserToken();
  const { catId, topicId, quizId, attemptId } = useParams();
  const [isLoading, setLoadingState] = useState(false);
  const aceTestData = useSelector((state) => state.aceTestData);
  const subjectTestData = useSelector((state) => state.subjectTestData);
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Load User Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [dispatch, userId]);

  /* Handle Mouse Right Click Context Menu */
  const handleContextMenu = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    dispatch(SubjectTestActions.getMySubjectQuizDetails(quizId));
  }, [dispatch, quizId]);

  useEffect(() => {
    async function getInvoiceDetails(){
      setLoadingState(true);
        const { data, error } = await getSubjectGameScoreCardFromAPI(userToken, userId, quizId, attemptId, '1');
        if (!error && data.status) {
          log(data);
          setLoadingState(false);
          dispatch(SubjectTestActions.setSubjectGameReport(data.data));
        }
      setLoadingState(false);
    }
    getInvoiceDetails();
  }, [dispatch, userId, userToken, quizId, attemptId]);
  

  const questionItemView = (item, index) => {
    const isAnswerCorrect = +item?.is_correct > 0;
    const newQuestionName = item?.question?.replaceAll('&#34;', '"');
    return (<div className="row mb-4" key={`index-${index}`}>
        <div className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div className="questionItem">
            <div className="sequenceNumber"><p>{index+1}.</p></div>
            <div className="questionContentWrapper">
                <div className="questionContentDescription" dangerouslySetInnerHTML={{__html : item?.description}}></div>
                <div className="questionContent" dangerouslySetInnerHTML={{__html : newQuestionName}}></div>
                <div className={`myAnswerView ${isAnswerCorrect ? 'Correct' : 'Wrong'}`}>{Strings.your_answer}: <div className="myContent" dangerouslySetInnerHTML={{__html : item?.q_option}}></div></div>
                <div className="correctAnswerView Correct">{Strings.correctAnswer}: <div className="myContent" dangerouslySetInnerHTML={{__html : item?.correct_answer}}></div></div>
                <div className="justificationView">{Strings.justification}:</div>
                <div className="questionContentJustification" dangerouslySetInnerHTML={{__html : item?.justification}}></div>
            </div>
          </div>
        </div>
      </div>);
  }

  const reportView = () => {
    return (<div className="examQuestionsListing">
            {subjectTestData?.testReport?.length > 0 &&
              subjectTestData?.testReport.map((item, index) => questionItemView(item, index))
            }
          </div>);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.subject_quiz_report_for} {subjectTestData?.activeTest?.categoryName}/{subjectTestData?.activeTest?.topicName}/{subjectTestData?.activeTest?.name}</h1>
            </div>
            <div className="myPageWrapper SubjectGameReport">

              {isLoading && <p>{Strings.loading_subject_game_report}</p>}
              {!isLoading && reportView()}

            </div>
        </div>
      </div>
    </Fragment>
  );
}
