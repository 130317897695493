import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Strings } from 'resources';
import { useSelector } from "react-redux";
import "./Landing.scss";
import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import Header from 'components/Header';
import { getNotification as getNotificationAPI } from "services/AuthService";
import Footer from 'components/Footer';
import moment from 'moment';
import { isUserLoggedIn } from "services/AuthService";
import { openInNewTab } from "utils/helpers";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import { log } from "utils";
import KnowledgeNotification from "components/KnowledgeNotification";
import DashboardImage from 'assets/images/v2/landing/dashboard.png';
import QuillItImage from 'assets/images/v2/landing/quillit.png';
import CertificateImage from 'assets/images/v2/landing/certificate.png';
import ScoreboardImage from 'assets/images/v2/landing/scoreboard.png';
import HeatMapImage from 'assets/images/v2/landing/heatmap.png';
import LibraryImage from 'assets/images/v2/landing/b6.svg';
import AccuracyWormImage from 'assets/images/v2/landing/accuracy_worm.png';
import AceGameImage from 'assets/images/v2/landing/acegame.png';


import greenCheckBoxImage from 'assets/images/v2/landing/greenCheckBox.svg';
import greenMaskImage from 'assets/images/v2/landing/greenMask.svg';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import RegisteredUsers from 'assets/images/landing/registeredUsers.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(Draggable);
gsap.config({
  nullTargetWarn: false
});

export default function Landing(props) {
  const { user } = isUserLoggedIn();
  const accountData = useSelector((state) => state.accountData);
  const navigationData = useSelector((state) => state.navigationData);
  const { status } = isUserLoggedIn();
  const dispatch = useDispatch();
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const sliderRef = useRef(null);

  const settings = {
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    swipeToSlide: true,
  };

  const faqSettings = {
    autoplay: true,
    arrows: false,
    dots: false,
    slidesToShow: 4,
    centerPadding: "10px",
    draggable: false,
    infinite: true,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    vertical: true,
    verticalScrolling: true,
    speed: 1000,
    autoplaySpeed: 2000,
    useTransform: true,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    adaptiveHeight: true,
    swipeToSlide: true,
  };

  const bannerSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    dots: false,
    draggable: true,
    pauseOnHover: false,
    swipe: true,
    touchMove: true,
    centerPadding: "150px 0px 100px",
    slidesToShow: 1,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    adaptiveHeight: true,
    swipeToSlide: true,
    focusOnSelect:true,
    responsive: [
        {
          breakpoint: 1600,
          settings: {
            centerPadding: "150px 0px 100px",
          }
        },
        {
            breakpoint: 1200,
            settings: {
              centerPadding: "120px 0px 70px",
            }
        },
        {
            breakpoint: 991,
            settings: {
              centerPadding: "70px 0px 50px",
            }
        },
        {
          breakpoint: 600,
          settings: {
            centerPadding: "0px 0px 0px",
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: "0px 0px 0px",
          }
        }
      ]
  };

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  useEffect(() => {
    dispatch(AccountActions.getAccountInfo());
    dispatch(AccountActions.getSiteStatistics());
    dispatch(AccountActions.getSiteEvents());
    // On Load
    return () => {
      // On UnLoad
    };
  }, [dispatch]);

  useEffect(() => {
    const userId = user?.uid ?? '';
    const userToken = user?.token ?? '';
    if (userId !== '' && userToken !== '') {
        dispatch(AccountActions.getAccountSettings(userId));
        dispatch(CourseActions.getActiveCourse(userToken, userId));
    }
  }, [dispatch]);

  /* User Redirect if Logged In */
  useEffect(() => {
    async function checkStatus() {
      const { status } = isUserLoggedIn();
      if(status) {
        history.push('/my-courses');
      }
    }
    checkStatus();
  });

  useEffect(() => {
    async function getNotification(){
        const payload = {
            'page': 0,
            'limit': 5
          }
        const { data } = await getNotificationAPI(payload);
        console.log("data ",data);
        if(data.status) {
          setNotificationList(data.data);
        }
    }
    getNotification();
  }, []);

  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    /*
    log(sliderRef.current.clientWidth, sliderRef.current.innerWidth);
    Draggable.create(sliderRef.current, {
      type: "x",
      bounds: {
        minX: -sliderRef.current.clientWidth + window.innerWidth * 0.88,
        maxX: 0
      }
    });
    */
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            let sliderImagesBox = document.querySelectorAll('.homeCardsBox');
            sliderImagesBox.forEach(el => {
                console.log(el);
                console.log('^^SLIDER BOXES^^');
                let imageNodes = el.querySelectorAll('.notifCard:not(.hide)')
                let arrIndexes = [];
                (() => {
                    let start = 0;
                    while (imageNodes.length > start) {
                        arrIndexes.push(start++);
                    }
                })();

                let setIndex = (arr) => {
                    for (let i = 0; i < imageNodes.length; i++) {
                        imageNodes[i].dataset.slide = arr[i];
                    }
                }
                el.addEventListener('click', () => {
                    arrIndexes.unshift(arrIndexes.pop());
                    setIndex(arrIndexes)
                })
                setIndex(arrIndexes)
            });
        }, 1250);
        return () => clearTimeout(timer);
    }, []);
   
  const eventItemView = (item, index) => {
    const formattedDate = moment(item.start).utc(true).format("DD-MMMM");
    let className = 'orange';
    if (item.type === 'holiday') {
        className = 'green';
    } else if (item.type === 'exam') {
        className = 'orange';
    } else if (item.type === 'class') {
        className = 'red';
    }
    return (<li key={`event-${index}`}><span className={`event-date ${className}`}>{formattedDate}</span><span className="event-title">{item.title}</span></li>);
  }

  const Event = ({ event }) => {
    return (<span className={`eventDots ${event.type}`}>&nbsp;</span>)
  }

  const startNow = () => {
    setIsRedirect(false);
    if (status) {
        history.push("/dashboard/");
    } else {
        toggleLoginModal();
    }
  }

  const goToQuillIt = () => {
    setIsRedirect(false);
    if (status) {
        history.push("/quill-it/");
    } else {
        const pathRedirect = {
            pathname: '/quill-it/'
        };
        setRedirectTo(pathRedirect);
        setIsRedirect(true);
        toggleLoginModal();
    }
  }

  const goToMyCourses = () => {
    setIsRedirect(false);
    if (status) {
        history.push("/my-courses/");
    } else {
        toggleLoginModal();
    }
  }

  const toggleResetPasswordModal = async () => {
    dispatch(NavigationActions.setResetPasswordModalStatus(true));
  }

  const toggleSignUpModal = async () => {
    dispatch(NavigationActions.setSignUpModalStatus(true));
  }

  const toggleUserActivateModal = async () => {
    dispatch(NavigationActions.setActivateUserModalStatus(true));
  }

  const toggleLoginModal = async () => {
    dispatch(NavigationActions.setLoginModalStatus(true));
  }

  const toggleForgotPasswordModal = async () => {
    dispatch(NavigationActions.setForgotPasswordModalStatus(true));
  }

  const goToProcedurePolicy = () => {
    window.location.href = 'https://bharatcet.com/policies-procedures/#referralTab';
  }

  const goToYouTube = () => {
    openInNewTab(Strings.youTubeURL);
  }

  const goToPackageSelection = (packageId) => {
    setIsRedirect(false);
    if (status) {
        if (packageId !== '1') {
            history.push({
                pathname: '/payment/',
                state: { examId: '1', groupId: packageId }
            });
        } else {
            history.push({
                pathname: '/my-courses/',
                state: { showFreePopUp: true }
            });
        }
        /*
        history.push({
            pathname: '/select-subscription-package/',
            state: { examId: '1', packageId: packageId  }
        });
        */
        history.push({
            pathname: '/payment/',
            state: { examId: '1', groupId: packageId }
        });
    } else {
        let pathRedirect = {};
        if (packageId !== '1') {
            pathRedirect = {
                pathname: '/payment/',
                state: { examId: '1', groupId: packageId }
            };
        } else {
            pathRedirect = {
                pathname: '/my-courses/',
                state: { showFreePopUp: true }
            };
        }
        setRedirectTo(pathRedirect);
        setIsRedirect(true);
        toggleLoginModal();
    }
  }

    const notificationItemView = (item, index) => {
        return (<KnowledgeNotification key={`notif-item-${index}`} itemData={item} index={index} />);
    }

    const goToFAQs = () => {
        history.push("/faqs");
    }

return (<>
   <Header isLoggedIn={status} showFullWidth={true} />

   {/* Features Section Starts */}
    <div className="useful-feature">
        <div className="full-width-wrapper">
            <div className="feature">
                <div className="container">
                    <h2>{Strings.the_most_powerful_and_useful_features}</h2>
                </div>
                <div className="fluid-container">
                     <div className="featuresWrapper" ref={sliderRef}>
                <Slider {...bannerSettings}>
                <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={DashboardImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.dynamicDashboardTitle}</h4>
                            <p>{Strings.landing_dynamic_view_of_performance_features_quill_easy_access_to_self_study}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={QuillItImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.quill_it}</h4>
                            <p>{Strings.landing_quill_it_chat_window_to_clarify_doubts}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={AceGameImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.aceGames}</h4>
                            <p>{Strings.landing_acegame_feature_description}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={HeatMapImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.analytics}</h4>
                            <p>{Strings.landing_analytics_features_description}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={CertificateImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.scholarship}</h4>
                            <p>{Strings.landing_scholarship_features_description}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={ScoreboardImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.leadershipLevels}</h4>
                            <p>{Strings.landing_leadershipLevels_description}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={LibraryImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.past_question_papers}</h4>
                            <p>{Strings.landing_past_question_paper_description}</p>
                        </div>
                    </div>
                    <div className="dynamicDashboard">
                        <div className="dashboardImg">
                            <img src={AccuracyWormImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                        </div>
                        <div className="dynamic-content">
                            <h4>{Strings.landing_accuracyWorm}</h4>
                            <p>{Strings.landing_accuracyWorm_description}</p>
                        </div>
                    </div>
                </Slider>
                </div>
                </div>
            </div>
        </div>
    </div>
    {/* Features Section Ends */}

    {/* New Pricing Plan Starts */}
    <div className="PricingPlanSection">
        <div className="container">
            <div className="PricingPlanSectionFlex">
                <div className="left-content-area">
                    <h3>{Strings.landing_plan_select_title}</h3>
                    <p>{Strings.landing_plan_select_details1}</p>
                    <p>{Strings.landing_plan_select_details2}</p>
                    <div className="explore">
                        <span onClick={() => goToPackageSelection('3')}>{Strings.allExplore}</span>
                    </div>
                </div>
               <div className="right-main-content-area">
                
                    <Slider {...settings}>
                        <div className="right-content-area">
                            <div className="right-plan-area">
                                <div className="right-title-area">
                                    <div className="right-title-area-price-content">
                                        <p className="packagePrice"><sup></sup></p>
                                        <p>{Strings.validity} : <span>400 {Strings.questions}</span> </p>
                                    </div>
                                    <h3 onClick={() => goToPackageSelection('3')}>{Strings.free}</h3>
                                    <p>{Strings.monthlyPlanFeatures} <span onClick={() => goToPackageSelection('3')} >+</span> </p>
                                </div>
                                <div className="right-feature-area">
                                    <div className="plan-feature">
                                        <ul>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.accuracyWorm}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.pastAceGame}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.detailedReport}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-feature-subscribe">
                                        <span className="subscribe" onClick={() => goToPackageSelection('3')}>{Strings.subscribeBtn}</span>
                                    </div>
                                    <p className="right-bottom-text">&nbsp;</p>
                                    <div className="right-price-tag">
                                        <div className="price-tag">
                                            <img src={greenMaskImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                            <div className="plan-price">
                                                <h3>&#8377;0</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="right-content-area">
                            <div className="right-plan-area">
                                <div className="right-title-area">
                                    <div className="right-title-area-price-content">
                                        <p className="packagePrice">&#8377;{Strings.preMonthlyCharge}<sup>+{Strings.gstTax}</sup></p>
                                        <p>{Strings.validity} : <span>20K+ {Strings.questions}</span> </p>
                                    </div>
                                    <h3 onClick={() => goToPackageSelection('3')}>{Strings.monthly}</h3>
                                    <p>{Strings.monthlyFeatures} </p>
                                </div>
                                <div className="right-feature-area">
                                    <div className="plan-feature">
                                        <ul>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.dashboard}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.keyIndicators}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.accessToVideos}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.quillItTitle}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.pastAceGame}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-feature-subscribe">
                                        <span className="subscribe" onClick={() => goToPackageSelection('3')}>{Strings.subscribeBtn}</span>
                                    </div>
                                    <p className="right-bottom-text">*{Strings.introductory_price}</p>
                                    <div className="right-price-tag">
                                        <div className="price-tag">
                                            <img src={greenMaskImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                            <div className="plan-price">
                                                <h3>&#8377;{Strings.monthlyPrice}* <span>+{Strings.gstTax}</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-content-area">
                            <div className="right-plan-area">
                                <div className="right-title-area">
                                    <div className="right-title-area-price-content">
                                        <p className="packagePrice">&#8377;{Strings.preHalfYearlyplanCharges}<sup>+{Strings.gstTax}</sup></p>
                                        <p>{Strings.validity} : <span>20K+ {Strings.questions}</span> </p>
                                    </div>
                                    <h3 onClick={() => goToPackageSelection('3')}>{Strings.landing_half_yearly}</h3>
                                    <p>{Strings.monthlyPlanFeatures} <span onClick={() => goToPackageSelection('3')} >+</span> </p>
                                </div>
                                <div className="right-feature-area">
                                    <div className="plan-feature">
                                        <ul>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.dashboard}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.keyIndicators}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.accessToVideos}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.quillItTitle}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.detailedReport}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.scholarship}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.accuracyWorm}</p>
                                            </li>
                                            <li>
                                                <img src={greenCheckBoxImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                                <p>{Strings.pastAceGame}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-feature-subscribe">
                                        <span className="subscribe" onClick={() => goToPackageSelection('3')}>{Strings.subscribeBtn}</span>
                                    </div>
                                    <p className="right-bottom-text">*{Strings.introductory_price}</p>
                                    <div className="right-price-tag">
                                        <div className="price-tag">
                                            <img src={greenMaskImage} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
                                            <div className="plan-price">
                                                <h3>&#8377;{Strings.halfYearlyPrice}* <span>+{Strings.gstTax}</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
               </div>
            </div>
        </div>
    </div>
    {/* New Pricing Plan Ends */}


    {/* Notification Section Starts */}
    <div className="notification-section">
        <div className="container">
            <div className="some-text-notification">
                <h4>{Strings.landingNotifTagLine}</h4>
                <h3>{Strings.landingNotifTitle}</h3>
                <div className="cardsBoxWrapper">
                    <div className="homeCardsBox">
                        {notificationList.length > 0 &&
                        notificationList.map((item, index) => notificationItemView(item, index))
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Notification Section Ends */}

    {/* FAQs Section Starts */}
    <div className="AskedQuestion">
        <div className="FlexQuestion">
            <div className="frequently-asked">
                <h6>{Strings.faqs} &amp; {Strings.troubleshooting}</h6>
                <h4>{Strings.landing_frequently_ask_question}</h4>
                <p>{Strings.landing_faqs_details}</p>
                <div className="explore">
                    <span onClick={() => goToFAQs()}>{Strings.allExplore}</span>
                </div>
            </div>
            <div className="frequentlyContent">
                <Slider {...faqSettings}>
                    <p className="current" onClick={() => goToFAQs()}>{Strings.landing_faqs_1}</p>
                    <p onClick={() => goToFAQs()}>{Strings.landing_faqs_2}</p>
                    <p onClick={() => goToFAQs()}>{Strings.landing_faqs_3}</p>
                    <p onClick={() => goToFAQs()}>{Strings.landing_faqs_4}</p>
                    <p onClick={() => goToFAQs()}>{Strings.landing_faqs_5}</p>
                    <p onClick={() => goToFAQs()}>{Strings.faqs_19}</p>
                </Slider>
            </div>
        </div>
    </div>
    {/* FAQs Section Starts */}

    {/* Show User Registered Block */}
    <div className="registrationSection">
        <div className="container">
            <div className="user-registered-section">
                <h4>{accountData?.statistics?.totalUsers}+ {Strings.registerTitle}</h4>
                <img src={RegisteredUsers} title={Strings.bharatCETName} alt={Strings.bharatCETName} />
            </div>
        </div>
    </div>
    {/* Ends User Registered Block */}

   <Footer />

   {navigationData.showLoginModal ? <Login redirectTo={redirectTo} isRedirect={isRedirect} onForgotPasswordShow={toggleForgotPasswordModal} onSignUpShow={toggleSignUpModal}  /> : null}
   {navigationData.showForgotPasswordModal ? <ForgotPassword onLoginShow={toggleLoginModal} onResetPasswordShow={toggleResetPasswordModal} onSignUpShow={toggleSignUpModal} /> : null}
   {navigationData.showResetPasswordModal ? <ResetPassword onLoginShow={toggleLoginModal} /> : null}
   {navigationData.showSignUpModal ? <SignUp onLoginShow={toggleLoginModal} onUserActivateShow={toggleUserActivateModal} /> : null}
   {navigationData.showUserActivateModal ? <ActivateUser onLoginShow={toggleLoginModal} /> : null}
</>
);

}
