import axios from "axios";
import { hasSuccess, hasError } from "services/ApiHelpers";
import { appClient } from "services/NetworkService";
import apiConfig from "config/api";

/**
 * getAllSubjectQuizzes
 * @param {string} userId
 * @param {string} categoryId
 * @param {string} topicId
 */
export async function getAllSubjectQuizzes(userToken, userId, categoryId, topicId) {
    const payload = {
        'token': userToken,
        'category_id': categoryId,
        'topic_id': topicId,
        'user_id': userId
    };
    try {
        const response = await appClient.post(apiConfig.subject.getAllSubjectQuizzes, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * getAllSubjectQuizQuestions
 * @param {string} userId
 * @param {string} quizId
 */
export async function getAllSubjectQuizQuestions(userToken, userId, quizId) {
    const payload = {
        'token': userToken,
        'user_id': userId,
        'quiz_id': quizId
    };
    try {
        const response = await appClient.post(apiConfig.subject.getAllSubjectQuizQuestions, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * submitSubjectQuizAnswers
 * @param {string} userToken
 * @param {string} userId
 * @param {string} quizId
 * @param {string} questions
 * @description Submit Subject Wise Quiz
 */
export async function submitSubjectQuizAnswers(userToken, userId, quizId, questions, timeStarts, language) {
    const payload = {
        'token': userToken,
        'user_id': userId,
        'quiz_id': quizId,
        'questions': JSON.stringify(questions),
        'time_starts': timeStarts,
        'language': language
    };
    try {
        const response = await appClient.post(apiConfig.subject.submitSubjectQuizAnswers, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

/**
 * sendQuizReportCard
 * @param {string} userToken
 * @param {string} userId
 * @param {string} quizId
 * @description Send Subject Wise Quiz Report Card in Email
 */
export async function sendQuizReportCard(userToken, userId, quizId, attemptId) {
    const payload = {
        'token': userToken,
        'user_id': userId,
        'quiz_id': quizId,
        'attempt_id': attemptId
    };
    try {
        const response = await appClient.post(apiConfig.subject.sendSubjectQuizReportCard, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getSubjectQuizDetails(userToken, userId, quizId) {
    const payload = {
        'token': userToken,
        'user_id': userId,
        'quiz_id': quizId
    };
    try {
        const response = await appClient.post(apiConfig.subject.getSubjectQuiz, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}

export async function getSubjectGameScoreCard(userToken, userId, quizId, attemptId, exam_id) {
    const payload = {
        'user_id': userId,
        'token': userToken,
        "quiz_id": quizId,
        "attempt_id": attemptId,
        "exam_id": exam_id,
    };
    try {
        const response = await appClient.post(apiConfig.subject.getReportData, payload);
        return hasSuccess(response.data);
    } catch (error) {
        /* istanbul ignore next */
        if (!axios.isCancel(error)) {
            return hasError(error);
        }
    }
}