const languageStrings = {
    hi: "नमस्ते",
    free: "नि: शुल्क",
    monthly: "महीने के",
    halfYearly: "अर्धवार्षिक",
    freePrice: "0",
    monthlyPrice: "99",
    halfYearlyPrice: "599",
    gstTax: "GST टैक्स",
    freeDesc: "रजिस्टर करें और ACE Game तक पहुंच प्राप्त करें। आपको एक नमूने के रूप में 400 प्रश्नों वाले 20 ACE खेल खेलने की अनुमति है। मुफ्त सदस्यता आपको 599 रुपये की योजना द्वारा प्रदान की जाने वाली सभी सुविधाएँ प्रदान करेगी और 20 वें गेम के अंत में समाप्त हो जाएगी। अन्य छात्रों की तुलना में आपको अपने प्रदर्शन का विस्तृत Dashboard मिलेगा जो आपको अपना अगला कदम तय करने में मदद करेगा।",
    monthlyDesc: "डायनेमिक Dashboard, 800 से अधिक एसीई गेम, पीयर टू पीयर कम्युनिकेशन - क्विल इट, विस्तृत अध्ययन सामग्री और वीडियो जैसी सुविधाओं तक पहुंच प्राप्त करें।",
    halfYearlyDesc: "BharatCET सुविधाओं के पूर्ण सूट तक पहुंच जिसमें नकद लाभ के साथ छात्रवृत्ति, नेतृत्व बोर्ड और परिपक्वता चरण, विस्तृत रिपोर्ट, अध्ययन सामग्री और सबसे महत्वपूर्ण सटीकता कीड़ा शामिल है जो आपको दिखाता है कि आप कैसे बेहतर हो रहे हैं।",
    priceSectionTitle:"चुनें कि आपको सबसे अच्छा क्या लगता है",
    upcomingEventsTitle: "महीने के लिए आगामी कार्यक्रम",
    noUpcomingEvents: "कोई आगामी कार्यक्रम नहीं मिला",
    dynamicDashboardTitle: "Dynamic Dashboard",
    dynamicDashboardDesc: "अद्वितीय Dynamic Dashboard छात्रों को व्यक्तिगत स्कोरबोर्ड, क्विल इट, विषयवार स्व अध्ययन, हैमबर्गर इंडेक्सेशन, आगामी कार्यक्रम, रेफरल कार्यक्रम और कम से कम, एसीई गेम जैसी कई भारतसीईटी पोर्टल सुविधाओं के लिए सिंगल क्लिक एक्सेस प्रदान करता है।",
    dynamicDashboardButton: "अन्वेषण करना",
    referTitle: "मित्र को आमंत्रित करें",
    referDesc: "एक दोस्त को साथ लाएं और सीधे अपने बैंक खाते में रेफ़रल बोनस प्राप्त करने का मौका जीतें। अधिक जानकारी के लिए, रेफ़रल कार्यक्रम दिशानिर्देश पढ़ें।",
    referButton: "अभी देखें",
    quillItTitle: "चैट करें...!",
    quillItDesc: "Quill It यह हमारा अनूठा पीयर-टू-पीयर कनेक्टिंग टूल है जहां छात्र विषयों पर चर्चा कर सकते हैं, प्रश्न पूछ सकते हैं, उत्तर दे सकते हैं और नियमित रूप से ज्ञान साझा करने के माध्यम से एक-दूसरे की मदद कर सकते हैं।",
    quillItButton: "और अधिक जानकारी प्राप्त करें",
    accessTitle: "BharatCET को कभी भी, कहीं भी एक्सेस करें",
    accessDesc: "हमने एप्लिकेशन को इस तरह डिजाइन किया है कि बुनियादी स्मार्टफोन में भी सुविधाओं का निर्बाध रूप से उपयोग करने की क्षमता होगी। यह ध्यान में रखते हुए कि छात्र हमेशा व्यस्त रहते हैं, छात्र वीडियो देख सकते हैं, सामग्री पढ़ सकते हैं, परीक्षा दे सकते हैं और एसीई गेम खेल सकते हैं - कभी भी, कहीं भी",
    accessButton: "अन्वेषण करना",
    learningTitle: "स्वयं सीखना",
    learningDesc: "आराम से और अपने लिए आरामदायक गति से अध्ययन करें। हमारे पास पुस्तकालय में संदर्भ अध्ययन सामग्री, लघु वीडियो और पिछले परीक्षा पत्रों का विशाल संग्रह है जिसे आप आसानी से एक्सेस कर सकते हैं और उसमें दी गई समस्याओं को हल कर सकते हैं।",
    learningButton: "अन्वेषण करना",
    registerTitle: "छात्र पंजीकृत",
    registeredUsers: "पंजीकृत उपयोगकर्ता",
    registerButton: "सदस्यता लेने के",
    startNow: "अभी शुरू करो",
    oneStepSuccess: "एक कदम सफलता की ओर",
    termsAndConditionsURL: "https://bharatcet.com/terms-and-conditions/",
    privacyPolicyURL: "https://bharatcet.com/privacy-policy/",
    contactUsURL: "https://bharatcet.com/contact-us/",
    facebookURL: "https://www.facebook.com/bharatcet",
    twitterURL: "https://twitter.com/BharatCet",
    youTubeURL: "https://www.youtube.com/channel/UCc-KSPeNWgg7QOHUWo-gBZA",
    linkedInURL: "https://www.linkedin.com/company/bharatcet/about/",
    instagramURL: "https://www.instagram.com/bharatcet/",
    websiteURL: "https://bharatcet.com/",
    nraCETURL: "https://bharatcet.com/nra-cet/",
    sendEmailGo: "जाओ",
    sendEmailPlaceholder: "अपना ईमेल दर्ज करें",
    getMoreDetails: "अधिक जानकारी प्राप्त करें",
    footerLinks: "लिंक",
    footerCourses: "पाठ्यक्रम",
    bharatCET: "BharatCET",
    bharatCETDescription: "BharatCET Techpro Edusys Pvt Limited की विशेष पेशकश है। पोर्टल को डिजाइन करते समय, हमने विभिन्न परीक्षाओं में प्रतिस्पर्धात्मक लाभ प्राप्त करने और उन्हें सफल होने के लिए तैयार करने में मदद करने के लिए छात्रों की बढ़ती जरूरतों को ध्यान में रखा है।",
    welcomeEmailSentSuccess: "स्वागत ईमेल सफलतापूर्वक भेजा गया",
    nraCET: "NRACET",
    home: "Home",
    dashboard: "Dashboard",
    library: "पुस्तकालय",
    scoreboard: "स्कोर बोर्ड",
    refer: "आमंत्रित करें",
    ace_game_history: "ACE Game इतिहास",
    ace_history: "ACE Game इतिहास",
    settings: "समायोजन",
    termsConditions: "नियम और शर्तें",
    privacyPolicy: "गोपनीयता नीति",
    contactUs: "संपर्क करें",
    firstName: "पहला नाम",
    firstName_placeholder: "पहला नाम दर्ज करें",
    lastName: "उपनाम",
    lastName_placeholder: "अंतिम नाम दर्ज करो",
    mobileNo: "मोबाइल नंबर",
    mobileNo_placeholder: "मोबाइल नंबर दर्ज करें",
    otpNo: "अनु",
    otpNo_placeholder: "ओटीपी दर्ज करें",
    password: "कुंजिका",
    password_placeholder: "पास वर्ड दर्ज करें",
    confirmPassword: "पासवर्ड की पुष्टि कीजिये",
    confirmPassword_placeholder: "पुष्टि करने के लिए पासवर्ड दर्ज करें",
    email: "ईमेल",
    enterEmail: "ईमेल दर्ज करें",
    contactNumber: "संपर्क संख्या",
    enterContactNo: "संपर्क नंबर दर्ज करें",
    city: "शहर",
    enterCity: "शहर दर्ज करें",
    state: "राज्य",
    selectGender:"लिंग चुनें",
    male: "पुरुष",
    female: "महिला",
    dob: "जन्म तिथि",
    click_to_select_DOB: "जन्मतिथि चुनें",
    PANCard: "PAN कार्ड",
    enterPanCard: "PAN कार्ड दर्ज करें", 
    gstNumber: "GST नंबर", 
    enterGSTNumber: "GST नंबर दर्ज करें",
    currentSubscriptionPlan: "वर्तमान सदस्यता योजना",
    email_address: "ईमेल पता",
    email_address_placeholder: "ईमेल पता दर्ज",
    register_submit: "रजिस्टर करें",
    account_activated_success: "आपका खाता सफलतापूर्वक सक्रिय हो गया है!",
    account_success: "आपका खाता सफलतापूर्वक बनाया गया है। आपके ईमेल पते पर OTP भेजा गया।",
    login_success: "आपने सफलतापूर्वक लॉग इन किया है!",
    login: "लॉग इन",
    crackNRACET: "क्रैक एनआरए सीईटी",
    forgotPassword: "पासवर्ड भूल गए",
    formSubmit: "प्रस्तुत",
    dontHaveAccount: "खाता नहीं है?",
    cantLogin: "लॉग इन नहीं कर सकते?",
    haveCredentials: "क्रेडेंशियल हैं?",
    createAccount: "खाता बनाएं",
    resetYourPassword: "अपना पासवर्ड रीसेट करें",
    resetPassword: "पासवर्ड रीसेट",
    successForgotPassword: "आपके ईमेल पते पर ओटीपी कोड भेजा गया।",
    successResetPassword: "पासवर्ड सफलतापूर्वक बदला गया",
    changePassword: "पासवर्ड बदलें",
    signUp: "साइन अप करें",
    formRegister: "रजिस्टर करें",
    successSignUp: "खाता सफलतापूर्वक बनाया गया। OTP आपके ईमेल पर भेजा गया।",
    failureSignUpAlreadyExists: "इस ईमेल या मोबाइल के साथ उपयोगकर्ता पहले से मौजूद है",
    successUserActivated: "उपयोगकर्ता सक्रिय रूप से सक्रिय",
    activateUser: "उपयोगकर्ता को सक्रिय करें",
    activate: "सक्रिय",
    myAccount: "मेरा खाता",
    myProfile: "मेरी प्रोफाइल",
    mySubscription: "अंशदान",
    myTermsConditions: "नियम और शर्तें",
    myPrivacyPolicy: "कॉपीराइट और गोपनीयता नीति",
    myContactUs: "संपर्क करें",
    mySignOut: "प्रस्थान करें",
    logout: "लॉग आउट",
    operation_canceled_by_user: "उपयोगकर्ता द्वारा रद्द किया गया ऑपरेशन",
    otp_incorrect_try_again: "OTP गलत है, पुन: प्रयास करें।",
    paymentHistoryPlans: "योजनाओं",
    paymentHistoryPaymentStatus: "भुगतान की स्थिति",
    paymentHistoryPaymentMode: "भुगतान का प्रकार",
    paymentHistoryHeading: "भुगतान इतिहास",
    paymentHistoryNotFound: "कोई भुगतान इतिहास नहीं मिला",
    paymentHistoryLoading: "लोड हो रहा है...",
    paymentHistorySuccess: "सफलता",
    paymentHistoryFailure: "असफलता",
    paymentHistoryNetBanking: "नेट बैंकिंग",
    noInvoiceDetailsFound: "कोई चालान विवरण नहीं मिला।",
    goToCourses: "पाठ्यक्रमों पर जाएं",
    subscription: "अंशदान",
    invoiceId: "चालान आईडी",
    transactionId: "लेन-देन आईडी",
    transactionStatus: "लेन-देन की स्थिति",
    transactionAmount: "लेन - देन की राशि",
    success: "सफलता",
    failure: "असफलता",
    paymentFailed: "आप ऐसा भुगतान करने का प्रयास कर रहे थे जो सफल नहीं था।",
    paymentSuccess: "आपका भुगतान सफल रहा।",
    coursesHeading: "पाठ्यक्रम",
    all: "सब",
    ongoingSubscription: "सदस्यता चल रही",
    pastSubscription: "पिछली सदस्यता",
    noPastSubscriptions: "आपके पास कोई पिछली सदस्यता नहीं है।",
    goPastCourses: "सभी पाठ्यक्रमों को ब्राउज़ करें और अभी अपना नामांकन कराएं।",
    pastViewCourses: "पाठ्यक्रम देखें",
    noCurrentSubscription: "आपने अभी तक सदस्यता नहीं ली है।",
    goCurrentSubscriptions: "सभी पाठ्यक्रमों को ब्राउज़ करें और अभी अपना नामांकन कराएं।",
    currentViewCourses: "पाठ्यक्रम देखें",
    allNoCourses: "कोई कोर्स नहीं मिला",
    allLoadingCourses: "लोड हो रहा है...",
    currentEnter: "दर्ज",
    allSubscribe: "सदस्यता लेने के",
    allExplore: "अन्वेषण करना",
    unableToSetCourse: "कोर्स सेट करने में असमर्थ",
    unableToGoSubscriptionPackageScreen: "सदस्यता पैकेज में जाने में असमर्थ",
    emailRequired: "ईमेल की आवश्यकता है",
    subjectRequired: "विषय आवश्यक है",
    messageRequired: "संदेश की आवश्यकता है",
    contactRequestSuccess: "संपर्क अनुरोध सफलतापूर्वक भेजा गया!",
    contactHear: "हम तुम से सुनना चाहते है",
    typeSubject: "अपना विषय टाइप करें",
    typeMessage: "अपना संदेश टाइप करें",
    contactSubmit: "प्रस्तुत",
    whatDoYouGet: "आपको क्या मिलेगा?",
    packageMonthly: "महीने के",
    priceMonthly: "99",
    packageHalfYearly: "अर्धवार्षिक",
    priceHalfYearly: "599",
    verifyUpdateProfile: "प्रोफ़ाइल अपडेट करें",
    verifyHeading: "बिलिंग विवरण जमा करें",
    verifyDescription: "अपडेट करने के लिए आपकी प्रोफ़ाइल में कुछ डेटा अनुपलब्ध है। कृपया इसे अपडेट करें और फिर भुगतान के लिए जाएं।",
    paymentHeading: "भुगतान",
    paymentBillingDetails: "बिलिंग विवरण",
    paymentFirstName: "पहला नाम",
    paymentLastName: "अंतिम नाम",
    paymentEmail: "ईमेल",
    paymentContact: "संपर्क नंबर",
    paymentCity: "शहर",
    paymentState: "राज्य",
    paymentUserCode: "उपयोगकर्ता कोड",
    paymentPanCard: "PAN कार्ड",
    paymentReferral: "रेफरल कोड",
    paymentSubscriptionPlan: "सदस्यता योजना",
    paymentFees: "फीस",
    paymentGST: "GST (18%)",
    paymentTotal: "संपूर्ण",
    paymentCurrency: "रुपये.",
    paymentTerms: "नियम और शर्तें",
    paymentCopyright: "कॉपीराइट और गोपनीयता मानदंड",
    paymentPayNow: "अब भुगतान करें",
    paymentRead: "मैंने सभी को पढ़ा है और उनसे सहमत हूं",
    paymentAdhere: "और का पालन करेंगे",
    paymentReferralPlaceHolder: "रेफरल कोड दर्ज करें",
    hmNoTopicsFound: "इस श्रेणी के अंतर्गत कोई विषय नहीं मिला",
    hmLoading: "लोड हो रहा है...",
    hmAccuracyRate: "सटीकता दर",
    hmHeading: "विषयवार सीखना",
    smLoading: "लोड हो रहा है...",
    smNoReadingFound: "कोई अध्ययन सामग्री नहीं मिली",
    smReading: "पढ़ना",
    tap_to_take_test: "टेस्ट लेने के लिए टैप करें",
    completed: "पूरा हो चुका है",
    pending: "विचाराधीन",
    subjectReportSuccessSent: "उत्तरों के साथ एक विस्तृत रिपोर्ट आपके पंजीकृत ईमेल पते पर भेज दी गई है।",
    correctAnswer: "सही जवाब",
    emailAnswers: "Email Answers",
    submitAnswers: "उत्तर जमा करें",
    answerLoading: "लोड हो रहा है...",
    NoQuestionsAvailable: "कोई प्रश्न उपलब्ध नहीं",
    justification: "औचित्य",
    daysLeft: "दिन शेष",
    acePoints: "ACE पॉइंट्स",
    endTheGame: "खेल खत्म करो",
    a: "A",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    endTheGameTitle: "खेल खत्म करो",
    endTheGameConfirm: "क्या आप वाकई जवाब सबमिट करना चाहते हैं और गेम खत्म करना चाहते हैं?",
    endTheGameSubmit: "प्रस्तुत",
    endTheGameGoBack: "वापस जाओ",
    playMore: "अधिक खेलो !",
    forDetailedReport: "विस्तृत रिपोर्ट के लिए",
    clickHere: "यहाँ क्लिक करें",
    basicStage: "मूल चरण",
    redeemNow: "छुड़ाना !",
    congratulations: "बधाई हो",
    youHaveScored: "आपने स्कोर किया है",
    correctAnswers: "सही उत्तर",
    inCorrectAnswers: "गलत उत्तर",
    unAnswered: "अनुत्तरित",
    super: "सुपर",
    youHaveAccumulated: "आपने जमा किया है",
    acePointsGathered: "ACE अंक",
    welcomeToACEGame: "ACE Game में आपका स्वागत है",
    aceGameAgreeTerms: "मैं खेल को आगे बढ़ाने के लिए इस नियम और शर्तों से सहमत हूं।",
    playNowAceGame: "अब खेलते हैं",
    playACEGameText: "अब आप ACE Game खेल सकते हैं",
    featureNotAvailableText: "यह सुविधा आपके वर्तमान प्लान में उपलब्ध नहीं है। कृपया सदस्यता लें और अधिकतम लाभ अनलॉक करें।",
    unlock: "अनलॉक",
    certificateTitle: "छात्रवृत्ति का प्रमाण पत्र | भारतसीईटी",
    certificateDesc: "छात्रवृत्ति का प्रमाण पत्र | भारतसीईटी",
    certificateLoading: "लोड हो रहा है...",
    certificateUserWithCode: "कोड के साथ उपयोगकर्ता#",
    certificateNotEligible: "अभी तक प्रमाण पत्र के लिए पात्र नहीं है।",
    certificateDoNotExits: "मौजूद नहीं है",
    certificateSave: "बचाना",
    certificateLetKnow: "सबको पता है।",
    congratulationsTitle: "बधाई हो !!!",
    certificateDescription: "आपकी छात्रवृत्ति 7 कार्य दिवसों के भीतर आपके बैंक खाते में जमा कर दी जाएगी।",
    your: "तुम्हारी",
    certificateWord: "प्रमाणपत्र",
    isReady: "तैयार हो गया है",
    notEnoughACEPoints: "आपके पास रिडीम करने के लिए पर्याप्त ACE अंक नहीं हैं।",
    areYouSure: "क्या आपको यकीन है ?",
    areYouSureDescription: "आप कुछ गेम दूर हैं और अभी भी कुछ सीखना बाकी है, कई और गेम खेलने हैं और कई और ACE पॉइंट जीतने हैं।",
    proceed: "बढ़ना",
    toRedeem: "छुड़ाना में लेना",
    toCancelRedemption: "मोचन रद्द करने के लिए",
    accuracy_rate: "सटीकता दर",
    total_ace_points_for_correct_answer: "सही उत्तरों के लिए कुल # इक्का अंक",
    total_of_eligible_ace_points_for_the_game: "खेल के लिए योग्य इक्का अंकों की कुल # ",
    total_of_ace_points_for_correct_answers_on_the_subject: "विषय पर सही उत्तरों के लिए कुल # इक्का अंक",
    total_of_eligible_ace_points_for_the_game_for_the_game_on_the_subject: "विषय पर खेल के लिए पात्र इक्का अंकों की कुल # ",
    total_of_ace_points_for_correct_answers_on_the_sub_topic: "उप-विषय पर सही उत्तरों के लिए कुल # इक्का अंक",
    total_of_eligible_ace_points_for_the_game_for_the_sub_topic: "उप-विषय पर खेल के लिए कुल # योग्य इक्का अंक",
    total_of_ace_points_for_correct_ans_from: "से सही उत्तरों के लिए कुल # इक्का अंक",
    game_played_to_last_game: "अंतिम गेम तक खेला गया खेल ",
    total_of_eligible_ace_points_for_the_game_from: "से खेल के लिए पात्र इक्का अंकों में से कुल #",
    game_played_on_sunday_to_last_game_on_saturday: "रविवार को खेला जाने वाला खेल शनिवार को अंतिम खेल",
    total_of_ace_points_for_correct_answers_of_all_players_at_national_level: "राष्ट्रीय स्तर पर सभी खिलाड़ियों के सही उत्तरों के लिए कुल # इक्का अंक",
    total_of_eligible_ace_points_for_the_game_of_all_players_at_national_level: "राष्ट्रीय स्तर पर सभी खिलाड़ियों के खेल के लिए कुल # योग्य इक्का अंक #",
    subject_quiz_report_for: "विषय प्रश्नोत्तरी रिपोर्ट",
    loading_subject_game_report: "विषय गेम रिपोर्ट लोड हो रहा है...",
    iAgree: "मैं सहमत हूं",
    copyRightAndPrivacyNorms: "कॉपीराइट और गोपनीयता मानदंड",
    termsAndConditions: "नियम और शर्तें",
    terms: "मामले",
    conditions: "शर्तेँ",
    playSubjectTest: "सब्जेक्ट वाइज गेम खेलें",
    playSubjectTestDesc: "यदि आप विषयवार प्रश्नोत्तरी खेलना चाहते हैं तो अभी खेलें पर क्लिक करें",
    subjectTestPlayNow: "अब खेलते हैं",
    accountName: "खाता नाम",
    accountNamePlaceHolder: "बैंक रिकॉर्ड के अनुसार नाम दर्ज करें",
    bankName: "बैंक का नाम",
    bankNamePlaceholder: "कृपया अपना बैंक का नाम साझा करें",
    panNo: "PAN नंबर",
    panNoPlaceholder: "आपका PAN नंबर",
    ifscCode: "IFSC कोड",
    ifscCodePlaceholder: "आपके बैंक का IFSC कोड",
    accountNumber: "खाता संख्या",
    accountNumberPlaceholder: "आपका खाता संख्या",
    submit: "प्रस्तुत",
    confirm: "पुष्टि करें",
    goBack: "वापस जाओ",
    confirmBankDetails: "बैंक विवरण की पुष्टि करें",
    bankDetails: "बैंक विवरण",
    selectAccountType: "अपना खाता प्रकार चुनें",
    savingsAccount: "बचत खाता",
    currentAccount: "चालू खाता",
    certificate: "कॉपीराइट",
    techProEduSys: "टेकप्रो एडुसिस प्राइवेट लिमिटेड",
    facebook: "फेसबुक",
    twitter: "ट्विटर",
    youTube: "यूट्यूब",
    instaGram: "इंस्टाग्राम",
    linkedIn: "लिंक्डइन",
    confirmIdentity: "BharatCET इस व्यक्ति की पहचान की पुष्टि करता है।",
    verifyAt: "पर सत्यापित करें",
    awards: "पुरस्कार",
    bharatCETName: "भारतसीईटी",
    menuName: "मेन्यू",
    notificationName: "अधिसूचना",
    languageName: "भाषा",
    searchName: "खोज",
    quillItName: "चैट",
    myProfileName: "मेरी प्रोफाइल",
    scholarshipCertificate: "छात्रवृत्ति प्रमाण पत्र",
    hereByDelivered: "एतद्द्वारा सम्मानित किया जाता है",
    forAchieving: "हासिल करने के लिए",
    myAcePoints: "ACE अंक",
    andReachingThe: "और पहुंचना",
    stageLevel: "स्तर",
    placeholderSearch: "आप जो खोज रहे हैं उसे टाइप करें ...",
    paymentHistory: "भुगतान इतिहास",
    books: "पुस्तकें",
    test: "परीक्षा",
    practiceToExcel: "हम आपको अभ्यास करवाते हैं ताकि आप उत्कृष्टता प्राप्त करें !!!",
    scoreBoard: "स्कोर बोर्ड",
    playMoreText: "अधिक खेलो",
    redeem: "छुड़ाना",
    levelWiseStudents: "छात्रों के स्तर के अनुसार #",
    setAsDefault: "डिफाल्ट के रूप में सेट",
    changePasswordMenu: "पासवर्ड बदलें",
    changePasswordPageTitle: "मेरा पासवर्ड बदलो",
    passwordsDoNotMatch: "सांकेतिक शब्द मेल नहीं खाते",
    passwordAtLeastSixChars: "पासवर्ड में कम से कम 6 अक्षर होने चाहिए",
    updatePasswordButton: "पासवर्ड अपडेट करें",
    pass: "नया पासवर्ड",
    passPlaceHolder: "नया पासवर्ड दर्ज करें",
    confirmPass: "नए पासवर्ड की पुष्टि करें",
    confirmPassPlaceHolder: "पुष्टि करने के लिए नया पासवर्ड दर्ज करें",
    passwordNotSame: "पासवर्ड और कन्फर्म पासवर्ड समान नहीं हैं।",
    passwordAtLeast6Chars: "पासवर्ड कम से कम 8 वर्ण लंबा होना चाहिए।",
    invalid_user_credentials: "अमान्य उपयोगकर्ता क्रेडेंशियल",
    selectRegularAlerts: "कृपया नियमित अलर्ट चुनें",
    selectPrivacyPolicy: "कृपया गोपनीयता नीति चुनें",
    email_do_not_exists: "इस ईमेल वाला उपयोगकर्ता मौजूद नहीं है",
    contact_do_not_exists: "इस संपर्क नंबर वाला उपयोगकर्ता मौजूद नहीं है",
    user_with_this_email_or_contact_number_do_not_exists: "इस ईमेल और संपर्क वाला उपयोगकर्ता मौजूद नहीं है",
    failed_to_send_forgot_password_email: "पासवर्ड भूल गए ईमेल भेजने में विफल",
    invitation_sent_successfully: "BharatCET में आमंत्रित करने के लिए आपके मित्र को एक एसएमएस भेजा गया है",
    already_invited: "इस नंबर वाले आपके मित्र को पहले ही आमंत्रित किया जा चुका है",
    this_mobile_number_is_not_in_your_invited_users_list: "मोबाइल नंबर आपकी आमंत्रित उपयोगकर्ता सूची में नहीं है",
    reminder_sent_successfully: "BharatCET में आमंत्रित करने के लिए आपके मित्र को एक रिमाइंडर एसएमएस भेजा गया है",
    account_is_inactive: "आपका खाता निष्क्रिय है",
    notification_read_successfully: "अधिसूचना सफलतापूर्वक पढ़ें",
    must_agree_terms_conditions: "नियम और शर्तों से सहमत होना चाहिए",
    failed_to_add_ace_game_attempt:	"ACE Game का प्रयास जोड़ने में विफल",
    ace_game_not_present: "ACE Game मौजूद नहीं है",
    missing_request_params:	"गुम अनुरोध पैरामीटर",
    ace_game_already_completed:	"ACE Game पहले ही पूरा हो चुका है",
    ace_game_fetched_successfully: "ACE Game सफलतापूर्वक लाया गया",
    ace_game_attempt_not_present: "ACE Game प्रयास मौजूद नहीं है",
    question_status_fetched: "प्रश्न स्थिति प्राप्त हुई",
    question_answer_updated: "प्रश्न उत्तर अपडेट किया गया",
    failed_to_update_data: "डेटा अपडेट करने में विफल",
    game_successfully_ended: "गेम सफलतापूर्वक समाप्त हुआ",
    no_ace_game_stats_present: "कोई ACE Game आँकड़े मौजूद नहीं हैं",
    leaderboard_fetched_successfully: "लीडरबोर्ड सफलतापूर्वक लाया गया",
    invalid_token: "चालान टोकन",
    unauthorized_user: "अनधिकृत उपयोगकर्ता",
    accuracy_chart_fetched_successfully: "सटीकता चार्ट सफलतापूर्वक लाया गया",
    no_data_found: "डाटा प्राप्त नहीं हुआ",
    verify_ace_point_certificate_fetched_successfully: "सत्यापित करें कि ACE पॉइंट प्रमाणपत्र सफलतापूर्वक प्राप्त हुआ है",
    something_went_wrong: "कुछ गलत हो गया",
    invalid_user_code: "अमान्य उपयोगकर्ता कोड",
    user_code_required:	"उपयोगकर्ता कोड आवश्यक",
    package_not_valid: "अमान्य पैकेज",
    trial_mode_expired:	"परीक्षण मोड समाप्त हो गया",
    score_card_sent: "स्कोर कार्ड भेजा गया",
    acegame_scorecard_fetched_successfully:	"ACE Game स्कोरकार्ड सफलतापूर्वक प्राप्त किया गया",
    score_card_data_fetched_successfully: "स्कोर कार्ड डेटा सफलतापूर्वक प्राप्त किया गया",
    categorywise_Indian_accuracy_successfully: "श्रेणीवार भारतीय सटीकता सफलतापूर्वक",
    user_status_fetched_successfully: "उपयोगकर्ता स्थिति सफलतापूर्वक प्राप्त हुई",
    no_ace_game_details_found: "कोई ACE Game विवरण नहीं मिला",
    ace_game_played_status_fetch_successfully: "ACE Game खेला गया स्टेटस फ़ेच सफलतापूर्वक",
    you_are_not_eligible_for_submit_redemption:	"आप रिडेम्पशन सबमिट करने के योग्य नहीं हैं",
    your_ace_point_redemption_request_submitted: "आपका ACE पॉइंट रिडेम्पशन अनुरोध सबमिट किया गया",
    redemption_history:	"मोचन इतिहास",
    successfully_logged_in:	"सफलतापूर्वक लॉग इन किया",
    user_email_is_not_verified:	"उपयोगकर्ता ईमेल सत्यापित नहीं है",
    email_and_password_are_required: "ईमेल और पासवर्ड की आवश्यकता है",
    successfully_logged_out: "सफलतापूर्वक लॉग आउट किया गया",
    invalid_user_or_token: "अमान्य उपयोगकर्ता या टोकन",
    user_id_and_jwt_token_are_required:	"यूजर आईडी और जेडब्ल्यूटी टोकन आवश्यक हैं",
    forgot_password_email_sent:	"पासवर्ड भूल गए ईमेल भेजा गया",
    email_and_contact_number_are_required: "ईमेल और संपर्क नंबर आवश्यक हैं",
    reset_password_link_is_valid: "पासवर्ड रीसेट करें लिंक मान्य है",
    reset_password_link_expired: "पासवर्ड रीसेट करें लिंक समाप्त हो गया",
    reset_password_token_is_required: "रीसेट पासवर्ड टोकन आवश्यक है",
    user_not_registered_with_google: "उपयोगकर्ता Google के साथ पंजीकृत नहीं है",
    invalid_google_account:	"अमान्य गूगल खाता",
    email_not_registered: "ईमेल पंजीकृत नहीं",
    enter_required_field: "आवश्यक फ़ील्ड दर्ज करें",
    confirm_password_required: "आवश्यक पासवर्ड की पुष्टि करें",
    password_and_confirm_password_not_matched: "पासवर्ड और कन्फर्म पासवर्ड का मिलान नहीं हुआ",
    password_updated_successfully: "पासवर्ड सफलतापूर्वक अद्यतन",
    failed_to_update_user_password:	"उपयोगकर्ता पासवर्ड अपडेट करने में विफल",
    reset_password_code_expired: "पासवर्ड रीसेट करें कोड समाप्त हो गया",
    reset_password_token_and_new_password_are_required:	"पासवर्ड रीसेट करें टोकन और नया पासवर्ड आवश्यक है",
    failed_to_send_user_activation_email: "उपयोगकर्ता सक्रियण ईमेल भेजने में विफल",
    user_created_successfully: "उपयोगकर्ता सफलतापूर्वक बनाया गया",
    failed_to_create_new_User: "नया उपयोगकर्ता बनाने में विफल",
    user_with_this_mobile_or_email_already_exists: "इस मोबाइल या ईमेल वाला उपयोगकर्ता पहले से मौजूद है",
    user_details_are_required: "उपयोगकर्ता विवरण आवश्यक हैं",
    user_activated_successfully: "उपयोगकर्ता सफलतापूर्वक सक्रिय हो गया",
    user_activation_failed:	"उपयोगकर्ता सक्रियण विफल",
    user_activation_code_expired: "उपयोगकर्ता सक्रियण कोड समाप्त हो गया",
    user_activation_token_is_required: "उपयोगकर्ता सक्रियण टोकन आवश्यक है",
    please_fill_all_billing_details: "कृपया सभी बिलिंग विवरण भरें",
    user_details_ok: "उपयोगकर्ता विवरण ठीक",
    password_not_matched: "पासवर्ड का मिलान नहीं हुआ",
    userid_or_password_is_missing: "यूजर आईडी या पासवर्ड गायब है",
    banner_fetch_successfully: "बैनर सफलतापूर्वक लाया गया",
    no_banner_found: "कोई बैनर नहीं मिला",
    category_fetched_successfully: "श्रेणी सफलतापूर्वक लाई गई",
    no_category_found: "कोई श्रेणी नहीं मिली",
    email_sent_successfully: "ईमेल सफलतापूर्वक भेजें",
    no_record_found: "कोई रिकॉर्ड नहीं मिला",
    cron_run_successfully: "क्रोन सफलतापूर्वक चला",
    option_updated_successfully: "विकल्प अपडेट सफलतापूर्वक",
    containers_generated_successfully: "कंटेनर सफलतापूर्वक उत्पन्न हुआ",
    subject_quiz_containers_generated_successfully:	"विषय प्रश्नोत्तरी कंटेनर सफलतापूर्वक उत्पन्न हुए",
    yesterdays_pending_ace_games_completed_successfully: "कल के लंबित इक्का खेलों को सफलतापूर्वक पूरा किया गया",
    referral_users_tree_generated_successfully:	"रेफ़रल उपयोगकर्ता ट्री सफलतापूर्वक उत्पन्न हुआ",
    questions_tree_generated_successfully: "प्रश्न वृक्ष सफलतापूर्वक उत्पन्न हुआ",
    questions_should_be_20:	"प्रश्न 20 . होने चाहिए",
    container_level_is_missing:	"कंटेनर स्तर गुम है",
    error_in_generating_container: "कंटेनर जनरेट करने में त्रुटि",
    subscription_expiry_status:	"सदस्यता समाप्ति की स्थिति",
    certificate_sent: "प्रमाणपत्र भेजा गया",
    user_id_email_and_certificate_url_required:	"यूजर आईडी ईमेल और सर्टिफिकेट यूआरएल जरूरी",
    events_fetch_successfully: "ईवेंट सफलतापूर्वक प्राप्त हुए",
    no_events_found: "कोई घटना नहीं मिली",
    exam_list_fetched_successfully:	"परीक्षा सूची सफलतापूर्वक प्राप्त हुई",
    exam_list_not_found: "परीक्षा सूची नहीं मिली",
    subscription_group_fetched_successfully: "सदस्यता समूह सफलतापूर्वक लाया गया",
    no_subscription_group_found: "कोई सदस्यता समूह नहीं मिला",
    exam_package_fetch_successfully: "परीक्षा पैकेज सफलतापूर्वक लाया गया",
    course_group_details_no_found: "पाठ्यक्रम समूह विवरण नहीं मिला",
    exam_id_required: "परीक्षा आईडी आवश्यक",
    subject_quiz_details_fetch_successfully: "विषय प्रश्नोत्तरी विवरण सफलतापूर्वक प्राप्त करें",
    no_subject_quiz_details_found: "कोई विषय प्रश्नोत्तरी विवरण नहीं मिला",
    libraries_fetch_successfully: "पुस्तकालय सफलतापूर्वक प्राप्त हुए",
    no_library_found: "कोई पुस्तकालय नहीं मिला",
    mail_sent_successfully:	"मेल सफलतापूर्वक भेजें",
    failed_to_sent_mail: "मेल भेजने में विफल",
    notifications_fetched_successfully:	"सूचनाएं सफलतापूर्वक प्राप्त की गईं",
    user_id_is_required: "यूजर आईडी आवश्यक है",
    token_is_required: "टोकन आवश्यक है",
    status_updated_successfully: "स्थिति सफलतापूर्वक अपडेट की गई",
    user_id_and_read_status_is_required: "उपयोगकर्ता आईडी और पढ़ने की स्थिति आवश्यक है",
    please_complete_your_profile: "कृपया अपना प्रोफ़ाइल पूरा करें",
    invoice_details_fetched_successfully: "चालान विवरण सफलतापूर्वक प्राप्त किया गया",
    invoice_not_found: "चालान नहीं मिला",
    my_payment_history_fetched_successfully: "मेरा भुगतान इतिहास सफलतापूर्वक प्राप्त हुआ",
    payment_history_not_found: "भुगतान इतिहास नहीं मिला",
    containers_fetched_successfully: "कंटेनर सफलतापूर्वक लाए गए",
    no_container_found:	"कोई कंटेनर नहीं मिला",
    questions_fetched_successfully:	"प्रश्न सफलतापूर्वक लाया गया",
    no_question_found: "कोई प्रश्न नहीं मिला",
    Quiz_Submitted_successfully: "प्रश्नोत्तरी सफलतापूर्वक सबमिट की गई",
    Error_In_submission: "प्रस्तुत करने में त्रुटि",
    already_quiz_present: "प्रश्नोत्तरी पहले से मौजूद है",
    question_answer_sent: "प्रश्न उत्तर भेजा गया",
    package_not_valid_or_trial_mode_expired: "पैकेज मान्य नहीं है या परीक्षण मोड समाप्त हो गया है",
    subject_game_scorecard_fetched_successfully: "विषय गेम स्कोर कार्ड सफलतापूर्वक प्राप्त किया गया",
    your_redemption_submitted: "आपका मोचन सबमिट किया गया",
    you_are_not_eligible_for_redemption: "आप छुटकारे के लिए पात्र नहीं हैं",
    redemption_history_fetched_successfully: "रिडेम्पशन इतिहास सफलतापूर्वक प्राप्त किया गया",
    no_redemption_found: "कोई मोचन नहीं मिला",
    this_user_is_already_subscribed: "यह उपयोगकर्ता पहले ही सदस्यता ले चुका है",
    invited_user_fetched_successfully: "आमंत्रित उपयोगकर्ता सफलतापूर्वक लाया गया",
    invited_user_not_found:	"आमंत्रित उपयोगकर्ता नहीं मिला",
    this_mobile_number_already_register: "यह मोबाइल नंबर पहले से रजिस्टर है",
    search_result_fetched_successfully:	"खोज परिणाम सफलतापूर्वक प्राप्त हुआ",
    no_search_result_found:	"कोई खोज परिणाम नहीं मिला",
    study_materials_fetched_successfully: "अध्ययन सामग्री सफलतापूर्वक प्राप्त हुई",
    no_study_materials_found: "कोई अध्ययन सामग्री नहीं मिली",
    topics_fetched_successfully: "विषय सफलतापूर्वक लाए गए",
    current_subscription_fetch_successfully: "वर्तमान सदस्यता सफलतापूर्वक प्राप्त हुई",
    no_current_subscription_is_there: "कोई वर्तमान सदस्यता नहीं है",
    user_fetched_successfully: "उपयोगकर्ता को सफलतापूर्वक लाया गया",
    username_is_required: "उपयोगकर्ता नाम आवश्यक है",
    user_profile_pic_removed_successfully:	"उपयोगकर्ता प्रोफ़ाइल चित्र सफलतापूर्वक निकाला गया",
    user_details_updated_successfully: "उपयोगकर्ता विवरण सफलतापूर्वक अपडेट किया गया",
    token_and_user_id_are_required:	"टोकन और यूजर आईडी आवश्यक है",
    password_successfully_updated: "पासवर्ड सफलतापूर्वक अपडेट किया गया",
    failed_to_update_password: "पासवर्ड अपडेट करने में विफल",
    userId_or_password_is_missing: "यूजर आईडी और पासवर्ड गायब है",
    failed_to_upload_profile_pic: "लाभ तस्वीर अपलोड करने में विफल",
    profile_pic_updated: "प्रोफ़ाइल तस्वीर अपडेट की गई",
    state_fetch_successfully: "राज्य सफलतापूर्वक लाया गया",
    statistics_fetched_successfully: "आंकड़े सफलतापूर्वक लाए गए",
    no_statistics_found: "कोई आंकड़े नहीं मिले",
    score_fetch_successfully: "स्कोर सफलतापूर्वक प्राप्त किया गया",
    invalid_referral_code: "अमान्य रेफरल कोड",
    code_validated_successfully: "कोड सफलतापूर्वक सत्यापित किया गया",
    you_are_eligible: "आप योग्य हैं",
    you_are_not_eligible: "आप पात्र नहीं हैं",
    this_is_a_free_trial_you_to_understand_the_game :"खेल को समझने के लिए यह एक नि:शुल्क परीक्षण है। आपको इससे क्या मिलता है?",
    set_20_random_questions: "20 यादृच्छिक प्रश्नों का सेट।",
    exact_replica_of_the_real_ace_game: "असली ACE Game की सटीक प्रतिकृति",
    detailed_performance_report: "विस्तृत प्रदर्शन रिपोर्ट।",
    answer_to_all_questions_available_for_review: "समीक्षा के लिए उपलब्ध सभी सवालों के जवाब।",
    ok_take_me_to_the_rules: "ठीक है, मुझे नियमों पर ले चलो!",
    free_trial_expired: "नि:शुल्क परीक्षण की समय सीमा समाप्त",
    your_free_trial_is_expired_kindly_subscribe_and_unlock_max_benefits: "आपका नि:शुल्क परीक्षण समाप्त हो गया है। कृपया सदस्यता लें और अधिकतम लाभ अनलॉक करें।",
    subscribeBtn: "सदस्यता लेने के",
    redemption_applied_successfully: "रिडेम्पशन सफलतापूर्वक लागू किया गया। 10 कार्य दिवसों के भीतर आपके खाते में पैसा जमा कर दिया जाएगा।",
    are_you_sure_report_this_comment_of: "क्या आप . की इस टिप्पणी की रिपोर्ट करना सुनिश्चित कर रहे हैं ",
    as_objectionable: " आपत्तिजनक के रूप में?",
    thanks_for_reporting_objectionable_comment: "आपत्तिजनक टिप्पणी की रिपोर्ट करने के लिए धन्यवाद।",
    go_to_quillit: "Go to Quill It",
    quillIt: "Quill It",
    we_are_making_nice_vide_tutorial: "हम एक अच्छा ट्यूटोरियल वीडियो बना रहे हैं।",
    it_will_be_made_available_shortly: "जल्द ही इसे उपलब्ध करा दिया जाएगा।",
    oops: "उफ़!",
    no_notification_found: "कोई सूचना नहीं मिली",
    mark_all_as_read: "सभी को पढ़ा हुआ मार्क करें", 
    introductory_price: "प्रावेशिक मूल्य",
    page_not_found: "पृष्ठ नहीं मिला",
    sorry_we_can_not_find_page_you_are_looking_for: "क्षमा करें, हम वह पृष्ठ नहीं खोज सकते जिसे आप खोज रहे हैं।",
    return_to_home_page: "होमपेज पर वापस आएं",
    your_response_registered: "आपकी प्रतिक्रिया पंजीकृत",
    the_previous_ace_game_is_over: "पिछला ACE Game खत्म हो गया है।",
    loadingAceGame: "लोड हो रहा है ACE Game...",
    playedAceGames: "ACE Games खेले",
    code: "कोड",
    totalQuestions: "कुल सवाल",
    attendedQuestions: "उपस्थित प्रश्न",
    correctQuestions: "सही प्रश्न",
    totalScore: "कुल स्कोर",
    correctScore: "सही स्कोर",
    accuracyPoints: "सटीकता अंक",
    accuracy: "शुद्धता",
    timeTaken: "लिया गया समय (minutes)",
    status: "स्थिति",
    action: "कार्य",
    you_have_not_played_any_ace_game_yet: "आपने अभी तक कोई ACE Game नहीं खेला है।",
    loadingAceGameHistory: "ACE गेम इतिहास लोड हो रहा है...",
    gameRule1: "ACE Game एक बहुविकल्पीय प्रश्न-आधारित गेम है जो खिलाड़ी को ACE पॉइंट्स स्कोर करने और जीतने का अवसर प्रदान करता है",
    gameRule2: "ACE पॉइंट्स में पूर्व-निर्धारित स्कोरिंग प्रणाली होती है, जहाँ प्रत्येक प्रश्न का एक अद्वितीय स्कोर होता है, जो उत्तर देने की जटिलता पर आधारित होता है। ACE अंक या तो 2, 5 या 10 होंगे।",
    gameRule3: "किसी भी गलत उत्तर के लिए कोई नकारात्मक अंकन नहीं होगा।",
    gameRule4: 'प्रत्येक सही उत्तर खिलाड़ी को एक समान अंक दिलाएगा जिसे ACE अंक कहा जाता है',
    gameRule5: "प्रत्येक ACE Game जटिलता के आधार पर अलग-अलग अंकों के 20 प्रश्नों का अनूठा सेट होगा",
    gameRule6: "रैंडम ACE गेम्स की यात्रा के माध्यम से, खिलाड़ी ACE पॉइंट्स इकट्ठा करता रहेगा जो खिलाड़ी को लीडरशिप बोर्ड पर ले जाएगा।",
    gameRule7: "प्रश्न यादृच्छिक रूप से फेंके जाएंगे और प्रत्येक ACE गेम में 30 मिनट की पूर्व-निर्धारित समय सीमा होती है",
    gameRule8: "खिलाड़ी किसी प्रश्न को छोड़ने और बाद के चरण में उसे फिर से देखने या उत्तरों को संशोधित करने का निर्णय ले सकता है, बशर्ते समय अनुमति देता है और 30 मिनट से अधिक नहीं होता है, जिसके बाद खेल स्वतः बंद हो जाएगा और 30 वें के अंत में एकत्र किए गए अंक मिनटों को अंतिम स्कोर माना जाएगा", 
    gameRule9: "टीईपीएल जिम्मेदार नहीं होगा, समय क्लिक करने के दौरान खिलाड़ियों के अंत में कोई संचार या डेटा समस्या होनी चाहिए",
    gameRule10: "प्रत्येक खेल के अंत में, खिलाड़ी उच्च स्तर पर स्कोर और सटीकता दर को समझेगा और 'विस्तृत रिपोर्ट' पर क्लिक करने पर, खिलाड़ी को कुल ACE पॉइंट्स, वर्तमान गेम पॉइंट्स, सटीकता का विस्तृत समय तक सारांश मिलेगा। दर और मंच पर अन्य खिलाड़ियों के साथ सटीकता का तुलनात्मक स्कोर भी।",
    gameRule11: "छात्र को ACE गेम में प्रश्नों को कॉपी करने, संपादित करने, पुन: प्रस्तुत करने या साझा करने की अनुमति नहीं होगी",
    gameRule12: "किसी भी पंजीकृत आगंतुक के लिए, जो सदस्य नहीं है, BharatCET 20 ACE गेम मुफ्त में पेश करेगा",
    getDetailedReport: "विस्तृत रिपोर्ट प्राप्त करें",
    copyright: "कॉपीराइट",
    get_your_doubts_cleared_on_a_click: "एक क्लिक पर अपनी शंकाओं को दूर करें।",
    get_subjectwise_test: "विषय स्तरीय परीक्षणों के साथ बेहतर बनें",
    get_subjectwise_test_desc: "हमने आपके लिए 90 से अधिक विषयों में 23,000 से अधिक प्रश्न एकत्रित किए हैं। वह विषय चुनें जिसका आप अभ्यास करना चाहते हैं और परीक्षा देना चाहते हैं। प्रत्येक परीक्षण में 10 प्रश्न होते हैं और परीक्षण देने के बाद, आपको प्रत्येक प्रश्न के उत्तर और स्पष्टीकरण/तर्क देखने को मिलेंगे। तो देर किस बात की, इन विषयों पर परीक्षाओं को रोज हल करें।",
    get_subjectwise_test_desc1: "हम आपको आपकी सटीकता दर का आकलन करने के लिए सभी विषयों के मिश्रण के साथ 20 प्रश्नों के अभ्यास परीक्षण का एक ACE गेम खेलने के लिए भी प्रोत्साहित करते हैं",
    six_steps_to_conquer: "जीतने के लिए छह कदम।",
    getStarted: "शुरू हो जाओ",
    practice_tests_play_quick_ace_games: "अभ्यास परीक्षण - त्वरित ACE Game खेलें",
    subject_wise_practice: "विषयवार अभ्यास",
    quick_access: "त्वरित ऐक्सेस",
    chat: "चैट",
    no_files_found: "हम एक पुस्तकालय को ताज़ा कर रहे हैं। आपको सूचित किया जाएगा, कृपया बने रहें।",
    loadMore: "और लोड करें",
    payment_failure_try_again_through_subscription: "कृपया सदस्यता पृष्ठ के माध्यम से पुन: प्रयास करें। यदि आप Dashboard पर जाना चाहते हैं, तो कृपया बाएं मेनू बार में होम आइकन पर क्लिक करें।",
    loading: "लोड हो रहा है...",
    policies: "नीतियों",
    procedures: "प्रक्रियाओं",
    faqs: "पूछे जाने वाले प्रश्न",
    troubleshooting: "समस्या निवारण",
    earn_using_friend_who_subscribe: "सदस्यता लेने वाले प्रत्येक मित्र के साथ 10% कमाएं",
    your_referral_code: "आपका रेफरल कोड",
    invite: "आमंत्रण",
    available_rewards: "उपलब्ध पुरस्कार",
    encash: "भुनाना",
    invitedOn: "पर आमंत्रित",
    subscriptionStatus: "सदस्यता स्थिति",
    codeStatus: "कोड स्थिति",
    youEarned: "आपने अर्जित",
    not_invited_yet_start_inviting_and_earn_points: "अभी तक आमंत्रित नहीं किया गया है। आमंत्रित करना शुरू करें और अंक अर्जित करें।",
    loading_invited_friend: "आमंत्रित मित्र लोड हो रहे हैं...",
    your_answer: "आपका उत्तर",
    questionsRemaining: "शेष प्रश्न",
    hours: "घंटे",
    gamesLeft: "खेल छोड़ दिया",
    aceGame: "ACE Game",
    earnedPoints: "अर्जित अंक",
    totalAcePoints: "कुल एसीई अंक",
    you_are_going_great_keep_it_up: "तुम बढ़िया जा रहे हो। कीप आईटी उप।",
    your_accuracy_rate: "आपकी सटीकता दर",
    logicalReasoning: "तार्किक विचार",
    quantitativeAptitude: "मात्रात्मक रूझान",
    verbal: "मौखिक",
    generalKnowledge: "सामान्य ज्ञान",
    total: "संपूर्ण",
    game: "खेल",
    overall: "संपूर्ण",
    indiaAverage: "भारत औसत",
    accuracyRateCalculations: "शुद्धता दर गणना",
    broadCategory_reasoning_ability_quantitative_aptitude_eng_lang_general_awareness: "व्यापक श्रेणी = तर्क क्षमता, मात्रात्मक योग्यता, अंग्रेजी भाषा, सामान्य जागरूकता",
    questions_will_further_have_subcategories_but_user_will_not_see_any: "प्रश्नों में आगे उपश्रेणियाँ होंगी लेकिन उपयोगकर्ता को कोई दिखाई नहीं देगा",
    for_ace_game_the_accuracy_rate_will_be_maintained_at :"ACE Game के लिए, सटीकता दर को बनाए रखा जाएगा",
    ace_game_level: "ACE Game स्तर",
    subject_level: "विषय स्तर",
    subTopicLevel: "उप-विषय स्तर",
    cumulative_ace_games_level : "संचयी ACE खेल स्तर",
    weekly_ace_games_level: "वीकली ACE Games लेवल",
    national_ace_games_average_levels : "राष्ट्रीय ACE Games का औसत स्तर",
    answersReport: "उत्तर रिपोर्ट",
    atGameLevel: "... खेल के स्तर पर",
    atSubjectLevel: "... विषय स्तर पर",
    atSubTopicLevel: "... उप-विषय स्तर पर",
    forCumulativeDashboard: "... संचयी Dashboard के लिए",
    forWeeklyWormTrend: "... साप्ताहिक कृमि प्रवृत्ति के लिए",
    forNationalAverage: "... राष्ट्रीय औसत के लिए",
    aceGamePerformanceReportWithAnswers: "उत्तर के साथ ACE Game प्रदर्शन रिपोर्ट",
    loadingACEGameReport : "ACE Game रिपोर्ट लोड हो रही है...",
    eventType: "घटना प्रकार",
    eventDate: "आयोजन दिनांक",
    searchResultsFor: "के लिए खोज परिणाम",
    noLibraryFilesFound: "कोई लाइब्रेरी फाइल नहीं मिली",
    events: "आयोजन",
    studyMaterial: "अध्ययन सामग्री",
    your_plan_is_valid_till : "आपकी योजना तब तक वैध है ",
    checkConditions : "कृपया मुझे मेरी पंजीकृत ईमेल आईडी और मोबाइल नंबर पर नियमित अलर्ट और सूचनाएं भेजें।",
    update: "अपडेट करें",
    choose_your_subscription_plan : "अपनी सदस्यता योजना चुनें",
    packageDescription1: "सिंगल स्क्रीन डायनामिक व्यू ऑफ परफॉर्मेंस, प्लेटफॉर्म फीचर्स, क्विल इट, सेल्फ स्टडी तक आसान पहुंच।",
    packageDescription2: "इक्का खेलों के रूप में 20,000 से अधिक प्रश्नों तक पहुंच प्राप्त करें। सरलीकरण, अंक एकत्र करने और छात्रवृत्ति के लिए पात्र के माध्यम से टेस्ट पेपर हल करने में सक्षम बनाता है।",
    packageDescription3: "संसाधनों को साझा करने, प्रश्न पूछने और त्वरित उत्तर प्राप्त करने में साथी छात्रों की मदद करने के लिए यह हमारी अखिल भारतीय अद्वितीय पीयर टू पीयर चैट विंडो है।",
    packageDescription4: "सटीकता दर (समेकित और विषयवार) जैसे प्रदर्शन विश्लेषण तक पहुंच, मुख्य आंकड़े जैसे परीक्षा के दिन, शेष प्रश्न, एकत्रित एसीई अंक, अध्ययन किए गए कुल घंटे और खेल शेष।",
    keyIndicators: "महत्वपूर्ण संकेतक",
    packageDescription5: "विषय स्तर के लघु वीडियो तक पहुंच जो छात्रों को चलते-फिरते मोबाइल (यूट्यूब चैनल) पर अवधारणाओं को सीखने और समझने में मदद कर सकते हैं।",
    accessToVideos: "वीडियो तक पहुंच",
    packageDescription6: "अपने अध्ययन की भाषा चुनें और छात्रों को सहज बनाने के लिए।",
    languageSelection: "भाषा चयन",
    packageDescription7: "अर्जित किया गया प्रत्येक ACE अंक छात्र को खेल प्रगति के विभिन्न चरणों के आधार पर छात्रवृत्ति के रूप में नकद पुरस्कार प्राप्त करने के योग्य बनाता है।",
    scholarship: "छात्रवृत्ति",
    packageDescription8: "एसीई गेम के 6 चरण जो छात्र को अधिक अंक हासिल करने में मदद करते हैं, सटीकता के साथ, उन्हें छात्रवृत्ति के लिए योग्य बनाते हैं।",
    knowledge_maturity_stages: "ज्ञान परिपक्वता चरण",
    packageDescription9: "प्रत्येक एसीई गेम के लिए सही उत्तरों के साथ विस्तृत रिपोर्ट दिखाई जाएगी, छात्र द्वारा बनाए गए अंक और अन्य प्रमुख प्रदर्शन आंकड़े।",
    detailedReport: "चिट्ठा",
    packageDescription10: "भविष्य की आसान पठनीयता के लिए बुकमार्क करने, भविष्य में आसानी के लिए डाउनलोड करने जैसी सुविधाओं के साथ-साथ विभिन्न क्यूरेटेड अध्ययन सामग्री तक पहुंच।",
    packageDescription11: "प्रश्नों को हल करने और ज्ञान परिपक्वता चरण को आगे बढ़ाने में गति बनाए रखने के लिए छात्रों को बेहतर ध्यान केंद्रित करने में मदद करने के लिए सप्ताह दर सप्ताह सटीकता ट्रैकिंग।",
    accuracyWorm: "शुद्धता कीड़ा",
    practiceTests: "अभ्यास परीक्षण",
    selectedCategory: "चयनित श्रेणी",
    selectedTopic: "चयनित विषय",
    yet_not_answered: "फिर भी जवाब नहीं दिया",
    viewAnswers: "उत्तर देखें",
    upcomingEvents: "आगामी कार्यक्रम",
    dates: "तारीख",
    this_number_already_registered_with_other_user: "यह संपर्क नंबर पहले से ही अन्य उपयोगकर्ता के साथ पंजीकृत है",
    remarks: "टिप्पणियों",
    pleaseRefer: "कृपया संदर्भ",
    youtube_video_url: "यूट्यूब वीडियो यूआरएल",
    for_learning_logical_reasoning_topics: "तार्किक तर्क विषय सीखने के लिए",
    for_learning_verbal_topics: "मौखिक विषय सीखने के लिए",
    please_refer_youtube_videos_for_learning_general_knowledge_topics: "सामान्य ज्ञान के विषय सीखने के लिए कृपया YouTube वीडियो देखें",
    practice_will_make_you_better: "अभ्यास आपको बेहतर बनाएगा",
    certificateLevel: "स्तर",
    certificateAcePoints: "ACE पॉइंट्स",
    certificateCreatedOn: "पर बनाया",
    you_have_not_achieved_any_certificates_yet: "आपने अभी तक कोई प्रमाणपत्र हासिल नहीं किया है।",
    loading_certificates: "प्रमाणपत्र लोड हो रहे हैं...",
    days_left_for_exam: "परीक्षा के लिए शेष दिन",
    notifPageTitle: "सूचनाएं",
    notifWOD: "आज का शब्द",
    notifCF: "सामयिकी",
    notifN: "सूचनाएं",
    faqPageTitle: "लगातार पूछे जाने वाले प्रश्न",
    landingNotifTagLine: "ज्ञान की दैनिक खुराक प्राप्त करें",
    landingNotifTitle: "सूचनाएं",
    the_most_powerful_and_useful_features: "सबसे शक्तिशाली और उपयोगी विशेषताएं",
    landing_dynamic_view_of_performance_features_quill_easy_access_to_self_study: "सिंगल स्क्रीन डायनामिक व्यू ऑफ परफॉर्मेंस, प्लेटफॉर्म फीचर्स, क्विल इट, सेल्फ स्टडी तक आसान पहुंच।",
    quill_it: "Quill It..!",
    landing_quill_it_chat_window_to_clarify_doubts: "शंकाओं को दूर करने के लिए चैट विंडो, प्रोफेसरों, छात्रों तक पहुंचें, प्रश्न पूछें/जवाब दें।",
    aceGames: "ACE Games",
    landing_acegame_feature_description: "इक्का खेलों के रूप में 20,000 से अधिक प्रश्नों तक पहुंच प्राप्त करें, सरलीकरण, अंक एकत्र करने और छात्रवृत्ति के लिए पात्रता के माध्यम से टेस्ट पेपर हल करने में सक्षम बनाता है।",
    analytics: "एनालिटिक्स",
    landing_analytics_features_description:"सटीकता दर (समेकित और विषयवार) जैसे प्रदर्शन विश्लेषण तक पहुंच, प्रमुख आंकड़े जैसे शेष प्रश्न। ACE अंक एकत्रित हुए, कुल घंटों का अध्ययन किया गया और खेल शेष रहे।",
    landing_scholarship_features_description: "अर्जित प्रत्येक एसीई अंक खेल प्रगति के विभिन्न चरणों (शुरुआती से ग्रैंड मास्टर) के आधार पर छात्रवृत्ति के रूप में नकद पुरस्कार प्राप्त करने के लिए पात्र है।",
    leadershipLevels: "नेतृत्व स्तर",
    landing_leadershipLevels_description: "ACE गेम के 6 चरण जो पूरे भारत में अन्य छात्रों के साथ प्रदर्शन की तुलना करने में मदद करते हैं।",
    past_question_papers: "पिछले प्रश्न पत्र",
    landing_past_question_paper_description: "पीडीएफ प्रारूप में पिछले प्रश्न पत्रों जैसी सुविधाओं के साथ विभिन्न क्यूरेटेड अध्ययन सामग्री तक पहुंच।",
    landing_accuracyWorm : "शुद्धता कीड़ा",
    landing_accuracyWorm_description: "बेहतर ध्यान केंद्रित करने के लिए सप्ताह सटीकता ट्रैकिंग पर काम करें, प्रश्नों को हल करने का अभ्यास करने के लिए गति बनाए रखें।",
    validity: "वैधता",
    questions: "प्रशन",
    monthlyFeatures: "विशेषताएं",
    monthlyPlanFeatures: "मासिक योजना की विशेषताएं",
    pastAceGame: "पिछले ACE Games",
    landing_half_yearly: "अर्धवार्षिक",
    landing_plan_select_title: "एक ऐसी योजना चुनें जो आपकी आवश्यकताओं के लिए सबसे उपयुक्त हो",
    landing_plan_select_details1: "आप अपनी आवश्यकता के आधार पर मासिक योजना या अर्धवार्षिक योजना में से किसी एक को चुन सकते हैं। BharatCET को बेहतर ढंग से समझने में आपकी मदद करने के लिए हम एक मुफ्त योजना भी प्रदान करते हैं जिसमें आपको अर्ध-वार्षिक योजना की सभी सुविधाएँ मिलेंगी और आप 20 ACE खेलों को मुफ्त में (कुल 400 प्रश्न) करने का प्रयास कर सकते हैं।",
    landing_plan_select_details2: "आप मासिक योजना के साथ शुरुआत कर सकते हैं और अर्धवार्षिक सदस्यता शुल्क का भुगतान करके अपग्रेड कर सकते हैं। आप अपने एसीई अंक बरकरार रख सकते हैं बशर्ते अपग्रेड आपकी मासिक सदस्यता समाप्त होने से पहले हो।",
    landing_frequently_ask_question: "लगातार पूछे जाने वाले प्रश्न",
    landing_faqs_details: "BharatCET मॉक टेस्ट से परे है और मानता है कि हमारे छात्र अपने प्रयासों और सटीकता के लिए, मौद्रिक दृष्टि से प्रेरणा के पात्र हैं। हमने मॉक टेस्ट को डायनेमिक ACE Games के रूप में परिवर्तित किया है, जिससे प्रत्येक सही उत्तर छात्रों की लीडरशिप बोर्ड में रैंकिंग बनाता है और छात्र अपने बैंक खाते में छात्रवृत्ति प्राप्त करने के लिए कभी भी अंकों को भुना सकते हैं।",
    landing_faqs_1: "ACE Game और ACE पॉइंट्स क्या हैं?",
    landing_faqs_2: "मैं अपने रेफरल कोड का उपयोग कैसे करूं?",
    landing_faqs_3: "मेरी सदस्यता योजना की समाप्ति के बाद क्या होता है?",
    landing_faqs_4: "मैं अर्जित छात्रवृत्ति को कब भुना सकता हूं?",
    landing_faqs_5: "मैं खुद को प्रोफेसर/शिक्षक के रूप में पंजीकृत कराना चाहता हूं। मैं क्या करूं?",
    faqs_1: "ACE Game और ACE पॉइंट्स क्या हैं?",
    faqs_1_description: "ACE Game फॉर्म में छोटे 20 प्रश्न मॉक टेस्ट हैं जिनमें एमसीक्यू शामिल हैं जिनमें लॉजिकल रीजनिंग, क्वांटिटेटिव एप्टीट्यूड, वर्बल और जीके विषय शामिल हैं। प्रत्येक प्रश्न में जटिलता के आधार पर एक अंक होता है और सही उत्तर प्राप्त करने से छात्र को अंकों के बराबर एसीई अंक प्राप्त होंगे। छात्र जितना अधिक सही उत्तर देता है, उतने अधिक ACE अंक और अधिक नकद छात्रवृत्ति",
    faqs_2: "मैं अपने रेफरल कोड का उपयोग कैसे करूं?",
    faqs_2_description: "रेफर ए फ्रेंड ऑप्शन पर जाएं, अपने दोस्तों का मोबाइल नंबर डालें, जिसका इस्तेमाल भारतसीईटी के साथ पंजीकरण के लिए किया जाएगा। आपके प्रोफाइल आईडी कोड के साथ उस फोन नंबर पर एक एसएमएस भेजा जाएगा। भुगतान करते समय आपके मित्र को इस आईडी को सम्मिलित करना होगा ताकि आपको एक रेफरल शुल्क प्राप्त हो। संबंध स्थापित करने के लिए मित्र को BharatCET के माध्यम से रेफर करना अनिवार्य है",
    faqs_3: "मेरी सदस्यता योजना की समाप्ति के बाद क्या होता है?",
    faqs_3_description: "समाप्ति के बाद आपकी आईडी स्वतः निष्क्रिय हो जाएगी और डेटा को पुनर्स्थापित करने के लिए, आपको अपने लॉग इन क्रेडेंशियल्स का उपयोग करके सदस्यता राशि का भुगतान करके इसे नवीनीकृत करना होगा",
    faqs_4: "क्या मेरी सशुल्क सदस्यता की अवधि मेरे पंजीकरण की तारीख या सशुल्क योजना के भुगतान की तारीख से शुरू होती है?",
    faqs_4_description: "आपकी सदस्यता अवधि आपके द्वारा वास्तविक भुगतान करने की तिथि से शुरू होगी न कि आपके पंजीकृत होने की तिथि से। इसलिए भले ही आपको पहले २० फ्री ACE गेम्स खेलने में मज़ा आता हो और अंत में आप भुगतान करते हों, आपकी सदस्यता अवधि आपके द्वारा भुगतान किए जाने की तारीख से शुरू हो जाएगी।",
    faqs_5: "मैं अर्जित छात्रवृत्ति को कब भुना सकता हूं?",
    faqs_5_description: "अधिकतम स्तर तक पहुंचने और अधिकतम संभव छात्रवृत्ति अर्जित करने के लिए अधिक से अधिक ACE अंक अर्जित करना हमेशा अच्छा होता है। अपनी छात्रवृत्ति को भुनाने और अपने बैंक खाते में धन प्राप्त करने के लिए उन सरल चरणों का पालन करें जिनके लिए ऐप आपका मार्गदर्शन करेगा। आपको एक छात्रवृत्ति प्रमाणपत्र भी मिलेगा जिसे आप सोशल मीडिया पर अपने दोस्तों के साथ साझा कर सकते हैं",
    faqs_6: "क्या मैं पिछले प्रश्न पत्रों को पुस्तकालय में डाउनलोड कर सकता हूँ?",
    faqs_6_description: "नहीं। कोई पिछले हल किए गए प्रश्न पत्रों को डाउनलोड नहीं कर सकता है",
    faqs_7: "जब मेरा लेनदेन विफल हो जाता है या मुझे किसी तकनीकी समस्या का सामना करना पड़ता है तो मैं क्या करूँ?",
    faqs_7_description: "परेशान मत होइये। कृपया एक स्क्रीन शॉट लें और help@bharatcet.com पर एक मेल भेजें और हम आपकी क्वेरी की त्वरित जांच और सबसे तेज़ समाधान सुनिश्चित करेंगे।",
    faqs_8: "मैं खुद को प्रोफेसर/शिक्षक के रूप में पंजीकृत कराना चाहता हूं। मैं क्या करूं?",
    faqs_8_description: "यह सरल है। डिफ़ॉल्ट रूप से हम पंजीकृत उपयोगकर्ता को एक छात्र के रूप में पहचानते हैं। लेकिन एक प्रोफेसर के रूप में, एक बार जब आप BharatCET ऐप को डाउनलोड और रजिस्टर कर लेते हैं, तो आपको हमें अपने प्रोफाइल का स्क्रीन शॉट Compliance@bharatcet.com पर भेजना होगा। आपको हमारी ऑनबोर्डिंग टीम से कॉल आएगी। कुछ त्वरित जाँचों के बाद, हमारी बैकएंड तकनीक आपकी प्रोफ़ाइल विशेषताओं को एक प्रोफेसर में बदल देगी।",
    faqs_9 : "भुगतान करने के बाद क्या मुझे धनवापसी मिलेगी?",
    faqs_9_description : "यह देखते हुए कि हम अपनी सुविधाओं का परीक्षण करने, गेम खेलने आदि के लिए मुफ्त सदस्यता देते हैं, हमने कभी नहीं सोचा था कि आप धनवापसी लेने का मन करेंगे। इसलिए एक बार भुगतान हो जाने के बाद, हम धनवापसी नहीं करते हैं।",
    faqs_10: "क्या मैं मासिक योजना से अर्धवार्षिक योजना में जा सकता हूँ?",
    faqs_10_description: "हाँ। आप मासिक योजना से अर्ध-वार्षिक योजना में जा सकते हैं लेकिन इसके विपरीत नहीं।",
    faqs_11: "क्या मैं एक ही ACE Game को कई बार खेल सकता हूँ?",
    faqs_11_description: "20000+ से अधिक अद्वितीय प्रश्नों के साथ, हमें यकीन है कि आप और अधिक खेलने और अधिक प्रश्नों का प्रयास करने के इच्छुक होंगे। इसलिए एक बार जब आप एक ACE गेम खेलते हैं, तो आपको वह प्रश्न या गेम दोबारा नहीं दिया जाएगा",
    faqs_12: "मैं अपने मोबाइल पर लॉग इन था और जब मैंने अपने लैपटॉप में लॉग इन करने की कोशिश की तो उसने अनुमति नहीं दी। मैं क्या करूं?",
    faqs_12_description: "आपके सिस्टम सुरक्षा उद्देश्य के लिए, हमने उद्देश्यपूर्ण ढंग से एक गैजेट लॉग इन फीचर रखा है ताकि आपकी आईडी का कोई और दुरुपयोग न करे। यदि आप एक गैजेट से दूसरे में स्विच करना चाहते हैं, तो कृपया पुराने गैजेट से लॉग आउट करें और एक बार फिर से नए में लॉग इन करें",
    faqs_13: "शुद्धता कीड़ा क्या है?",
    faqs_13_description: "शुद्धता कीड़ा समग्र सटीकता के संदर्भ में आपके साप्ताहिक प्रदर्शन को ट्रैक करता है।",
    faqs_14: "मैं हीटमैप कैसे पढ़ूं?",
    faqs_14_description: "हीटमैप आपको सबटॉपिक स्तर का प्रदर्शन बताता है। लाल इंगित करता है कि आप कमजोर हैं और उस विषय में बहुत अधिक अभ्यास करने की आवश्यकता है, एम्बर का अर्थ है कि आप अच्छे हैं लेकिन अधिक अभ्यास करने की आवश्यकता है और हरा आपका मजबूत क्षेत्र है।",
    faqs_15: "मेरी सदस्यता समाप्त होने के बाद क्या मैं अपने एसीई गेम्स तक पहुंच सकता हूं?",
    faqs_15_description: "ACE Games को आपकी सदस्यता समाप्त होने तक एक्सेस किया जा सकता है। आप ५९९ रुपये+GST योजना लेकर सदस्यता को नवीनीकृत कर सकते हैं और अधिक एसीई खेलों का उपयोग और खेलना जारी रख सकते हैं",
    faqs_16: "अगर प्रोफाइल बनाते समय मेरे पास GST या PAN कार्ड नहीं है तो क्या होगा?",
    faqs_16_description: "अगर आपके पास GST या PAN कार्ड नहीं है तो आप नॉट अवेलेबल डाल सकते हैं।",
    faqs_17: "मैंने क्विल इट पर एक सवाल पूछा था...! 3 दिन पहले लेकिन मुझे अब वह प्रश्न नहीं दिख रहा है",
    faqs_17_description: "छोडो...! डेटा केवल 24 घंटे के लिए सक्रिय है। जिसके बाद डेटा अपने आप डिलीट हो जाता है",
    faqs_18: "भुगतान के तरीके क्या हैं?",
    faqs_18_description: "छोडो...! डेटा केवल 24 घंटे के लिए सक्रिय है। जिसके बाद डेटा अपने आप डिलीट हो जाता है",
    faqs_19: "जब मैं एसीई गेम खेलने की कोशिश करता हूं तो ब्लैंक व्हाइट स्क्रीन आ रही है। उपाय क्या है?",
    faqs_19_description: "वेब दृश्य के संबंध में कुछ फ़ोनों के लिए एक अद्यतन समस्या है। कृपया इस समस्या से बचने के लिए अपने एंड्रॉइड डिवाइस में 'एंड्रॉइड सिस्टम वेबव्यू' एप्लिकेशन को नवीनतम संस्करण में अपडेट करें।",
    footerNotification: "सूचनाएं",
    downloadApp: "ऐप डाउनलोड करें",
    download: "डाउनलोड करें",
    signIn: "दाखिल करना",
    signUpWith: "इसके साथ साइन अप करें",
    myCertificates: "मेरे प्रमाणपत्र",
    commentReport: "रिपोर्ट",
    are_you_sure_report_this_user: "क्या आप वाकई इस उपयोगकर्ता की रिपोर्ट करना चाहते हैं ",
    as_objectionable_comment_poster_user: "आपत्तिजनक टिप्पणी पोस्टर उपयोगकर्ता के रूप में?",
    thanksForReporting: "रिपोर्टिंग के लिए धन्यवाद ", 
    asObjectionableCommentPosterUser: "आपत्तिजनक टिप्पणी पोस्टर उपयोगकर्ता के रूप में।",
    are_you_sure_to_report_this_question_of: "क्या आप question के इस प्रश्न की रिपोर्ट करना सुनिश्चित कर रहे हैं? ",
    as_objectionable_question_poster_user: " आपत्तिजनक प्रश्न पोस्टर उपयोगकर्ता के रूप में",
    question_mark: "?",
    someone_else_has_used_your_credentials_and_has_logged_in_from_another_machine: "किसी और ने आपकी साख का उपयोग किया है और किसी अन्य मशीन/मोबाइल से लॉग इन किया है। यदि आपको उल्लंघन का संदेह है, तो कृपया हमें help@bharatcet.com पर दिनांक और समय और अपनी यूजर आईडी/कोड के साथ एक नोट भेजें।",
    signIn_to_the_dashboard: "Dashboard में साइन इन करें",
    click_below_button_to_go_ace_point_bonus: "५० बोनस एसीई अंक प्राप्त करने के लिए कृपया नीचे दिए गए बटन पर क्लिक करें",
    claimOffer: "दावा प्रस्ताव",
    offer_is_not_valid_for_you_now: "ऑफ़र अभी आपके लिए मान्य नहीं है.",
    goToHome: "Home जाओ",
    you_have_already_claimed_50_bonus_ace_points: "आप पहले ही 50 बोनस ACE अंक का दावा कर चुके हैं।",
    fifty_ace_points_claimed_successfully: "५० ACE अंक का सफलतापूर्वक दावा किया गया",
    opps_not_valid_request: "यह मान्य अनुरोध नहीं है।",
    my_level_wise_certificates: "माई लेवल वाइज सर्टिफिकेट",
    weekly_accuracy_worm: "साप्ताहिक शुद्धता प्रमाण",
    weekly_accuracy_worm_description: "यह सुविधा आपके वर्तमान प्लान में उपलब्ध नहीं है। कृपया अर्धवार्षिक योजना की सदस्यता लें और अधिकतम लाभ प्राप्त करें।",
    beginner: "शुरुआती",
    levelCompleted: "स्तर पुर्ण",
    you_are_eligible_for: "आप इसके लिए पात्र हैं",
    selected_subscription: "चयनित सदस्यता",
    basic: "बुनियादी",
    advanced: "उन्नत",
    expert: "विशेषज्ञ",
    intermediate: "मध्यम",
    grandMaster: "ग्रैंड मास्टर",
    redemption_feature_is_available_only_in_half_yearly_plans: "रिडेम्पशन फीचर केवल अर्धवार्षिक योजनाओं में उपलब्ध है।",
    and_get_full_benefits: "और पूर्ण लाभ प्राप्त करें।",
    verify: "सत्यापित करें",
    discount: "छूट",
    discountedFees: "रियायती शुल्क",
    invitedFriends: "आमंत्रित मित्र",  
    tests:"परीक्षण",
    select_topic: "विषय का चयन करें",
    test_event_title: "टेस्ट इवेंट का शीर्षक",
    timesUp: "समय पूर्ण हुआ",
    bharatCETPortal: "भारतसीईटी पोर्टल",
    enterFriendMobileNumber: "मोबाइल नंबर दर्ज करें",
    contactIsRequired: "संपर्क आवश्यक है",
    enterValidMobileNumber: "कृपया मान्य मोबाइल नंबर दर्ज करें",
    remindStatus: "ध्यान दिलाना",
    earnedStatus: "अर्जित",
    aceGameRules: "ACE खेल नियम",
    gather1: "इकट्ठा करें",
    pointsToReach: "अंक",
    level1: "स्तर पहुंचने के लिए",
    chooseLanguage: "भाषा चुनें",
    instructor: "अनुदेशक",
    singleStudent: "छात्र",
    students: "छात्र",
    points1: "अंक",
    copyToClipboard: "उपयोगकर्ता कोड क्लिपबोर्ड पर कॉपी किया गया",
    isRequired: "आवश्यक है।",
    isNotValid: "मान्य नहीं है।",
    should_be_valid_US_number: "एक वैध यूएस नंबर होना चाहिए।",
    should_only_consist_of_alpha_char: "केवल वर्णमाला के अक्षर होने चाहिए।",
    may_not_greater_than: "से बड़ा नहीं हो सकता",
    characters: "पात्र.",
    must_be_at_least: "कम से कम होना चाहिए",
    total_may_not_greater_than: "कुल से अधिक नहीं हो सकता है",
    total_must_be_least_of_total: "कुल कम से कम कुल का होना चाहिए",
    should_only_consist_numeric_char: "केवल अंकीय वर्ण होने चाहिए।",
    should_not_have_any_space_in_between: "बीच में जगह नहीं होनी चाहिए।",
    does_not_match_with: "से मेल नहीं खाता",
    invalid_account_details_passed: "अमान्य खाता विवरण पारित",
    signInWith: "इसके साथ साइन इन करें",
    your_subscription_expired_please_renew: "आपकी सदस्यता समाप्त हो गई है। कृपया सदस्यता नवीनीकृत करें।",
    noSubjectTestAvailable: "इस विषय के लिए कोई विषय प्रश्नोत्तरी उपलब्ध नहीं है।",
    preHalfYearlyplanCharges: "2399",
    preMonthlyCharge: "399",
    strUsed : "उपयोग किया गया",
    strNotUsed : "उपयोग नहीं किया",
    strSubscribed: "सदस्यता लिया",
    strNotSubscribed: "सदस्यता लिया नहीं है",
    ongoing: "चल रही है",
    subscribeNow: "अब सदस्यता लें",
    takeSubjectExamNotice: "किसी भी श्रेणी का चयन करें और फिर परीक्षा देने के लिए किसी भी विषय पर क्लिक करें",


    /** lms link card  */
    learnTitle: "कक्षा",
    learnDescription: "यह शिक्षकों और छात्रों के लिए एक कक्षा है। यदि आप एक छात्र के रूप में कक्षाओं में भाग लेना चाहते हैं तो यहां क्लिक करें। या, यदि आप एक शिक्षक हैं और छात्रों को पाठ्यक्रम प्रदान करना चाहते हैं तो यहां क्लिक करें।",
};

export default languageStrings;
