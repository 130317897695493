import React, { useEffect, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import { Strings } from "resources";
import { isUserLoggedIn, getUserToken, getUserId, checkBillingDetails } from "services/AuthService";
import { getCourseGroups as getCourseGroupsAPI } from "services/CourseService";
import { validateReferralCode as validateReferralCodeFromAPI } from "services/PaymentService";
import appConfig from "config/config";
import md5 from 'md5';
import BillingDetailsVerification from "modals/BillingDetailsVerification";
import PrivacyPolicyModal from "modals/PrivacyPolicyModal";
import TermsConditionsModal from "modals/TermsConditionsModal";
import SmallButton from "components/UI/SmallButton";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { log } from "utils";
import { useAlert } from "react-alert";
import "./Payment.scss";

export default function Payment(props) {
  const alert = useAlert();
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const paymentSiteUrl = appConfig.siteUrl;
  const paymentUrl = paymentSiteUrl+'pgredirects';
  const [isDetailsUpdated, setDetailsUpdated] = useState(true);
  const [isTermsAgree, setTermsAgree] = useState(false);
  const selectedCourseId = props?.location?.state?.examId ?? '1';
  const selectedGroupId = props?.location?.state?.groupId ?? '';
  const [courseGroup, setCourseGroup] = useState({});
  const [isLoading, setLoadingState] = useState(false);

  /* Setup Course Price and all */
  const [codeStatus, setCodeStatus] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [appliedCode, setAppliedCode] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [tax, setTax] = useState('');
  const [totalAmount, setTotalAmount] = useState('');


  /* Handle Site Language */
  useEffect(() => {
    log('Language Changed');
    return () => {
      log('Language Unmounted...');
    }
  }, [accountData.language]);

  /**
   * Check weather User is Logged In or not
   */
  useEffect(() => {
    async function checkStatus(){
      const { status } = isUserLoggedIn();
      if(!status) {
        history.push('/');
      }
    }
    checkStatus();
  });

  /**
   * Check Billing Details of User Upon Page Load
   */
  useEffect(() => {
    async function checkBillingDetailsOfUser(){
      const { data } = await checkBillingDetails(userId, userToken);
      if (!data.status) {
        setDetailsUpdated(data.status);
        dispatch(NavigationActions.setBillingVerificationModal());
      }
    }
    checkBillingDetailsOfUser();
  }, [dispatch, userId, userToken]);

  /**
   * Get User Account Details
   */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /* Get Course Group Details */
  useEffect(() => {
    async function getCourseGroupDetails(){
      const { data } = await getCourseGroupsAPI(userToken, selectedCourseId);
      if (data.status) {
        const selectedCourseItem = data.data.filter((courseItem) => courseItem.gid === selectedGroupId)
        setCourseGroup(selectedCourseItem.length > 0 ? selectedCourseItem[0]: {});
        if (selectedCourseItem.length > 0) {
          setPrice(selectedCourseItem[0]?.price);
          setDiscountedPrice('');
          setDiscount('');
          setTax(selectedCourseItem[0]?.tax_amount);
          setTotalAmount(selectedCourseItem[0]?.total_amount);
        }
      }
    }
    getCourseGroupDetails();
  }, [selectedCourseId, selectedGroupId, userToken]);

  /**
   * convertToMd5
   * @param {string} inputText
   * @description Convert to Md5
   */
  const convertToMd5 = (inputText) => {
    let encodedVal = md5(inputText+'_BharatCET_Kraken');
    return encodedVal;
  };

  /**
   * isDate
   * @param {string} val
   * @description Check if Date is available or not
   */
  const isDate = (val) => {
    return Object.prototype.toString.call(val) === '[object Date]'
  }

  /**
   * isObj
   * @param {string} value
   * @description Check if Object is there or not
   */
  const isObj = (val) => {
    return typeof val === 'object'
  }

  /**
   * stringifyValue
   * @param {object} val
   * @description Stringify the Object Value
   */
  const stringifyValue = (val) => {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }

  /**
   * buildForm
   * @param {object} param
   * @description Build Form of Payment
   */
  const buildForm = ({ action, target, params }) => {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
    form.setAttribute('target', target)
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
    return form
  }

  /**
   * post
   * @param {object} details
   * @description Post the Payment Form Content
   */
  const post = (details) => {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }

  /**
   * onPayment
   * @param {object} e
   * @description Handle On Payment Functionality
   */
 const onPayment = async (e) => {
  e.preventDefault();

  if (!isDetailsUpdated) {
    dispatch(NavigationActions.setBillingVerificationModal());
    return false;
  } else {
    try {
      /*
      const { data } = await validateReferralCodeFromAPI(referralCode, selectedCourseId, selectedGroupId, userToken, userId);
      setLoadingState(false);
      if (!data.status) {
        setReferralCode('');
        setAppliedCode('');
        alert.error(Strings[data.message]);
        return log(data.message);
      }
      */

      const paymentKey = convertToMd5(userId);
      const payload = {
        userId: userId,
        paymentKey: paymentKey,
        referral_code: appliedCode,
        exams: [{
          exam_id: selectedCourseId,
          group_id: selectedGroupId
        }]
      };
      const paymentFormDetails = {
        action: paymentUrl,
        params : payload,
        target: '_self'
      };
      post(paymentFormDetails);
     } catch {
     }
  }
 }

 /**
  * showTermsPage
  * @description Show Terms Page
  */
 const showTermsPage = () => {
  dispatch(NavigationActions.toggleTermsConditionsModal(true));
 }

 /**
  * showCopyrightPage
  * @description Show Copyright Page
  */
 const showCopyrightPage = () => {
  dispatch(NavigationActions.togglePrivacyPolicyModal(true));
 }

 const clearReferralCode = async () => {
  setLoadingState(true);
  const { data, error } = await validateReferralCodeFromAPI('', selectedCourseId, selectedGroupId, userToken, userId);
  setLoadingState(false);
  if (!data.status) {
    setReferralCode('');
    setAppliedCode('');
    alert.error(Strings[data.message]);
    return log(data.message);
  } else {
    alert.success(Strings[data.message]);
    return onClearCodeSuccess(data.data);
  }
 }

 const onClearCodeSuccess = (data) => {
  setCodeStatus(false);
  setPrice(data?.price);
  setDiscount(+data?.discount > 0 ? data?.discount : '');
  setDiscountedPrice(data?.discountedPrice);
  setTax(data?.gst_amount);
  setTotalAmount(data?.total_amount);
  setReferralCode('');
  setAppliedCode('');
};

 /**
  * verifyReferralCode
  * @description Verify Referral Code
  */
 const verifyReferralCode = async () => {
  setLoadingState(true);
  const { data, error } = await validateReferralCodeFromAPI(referralCode, selectedCourseId, selectedGroupId, userToken, userId);
  setLoadingState(false);
  if (!data.status) {
    setReferralCode('');
    setAppliedCode('');
    alert.error(Strings[data.message]);
    return log(data.message);
  } else {
    alert.success(Strings[data.message]);
    return onVerifyCodeSuccess(data.data);
  }
 }

 /**
  * onVerifyCodeSuccess
  * @param {*} data
  * @description Verify Code Success
  */
  const onVerifyCodeSuccess = (data) => {
    setCodeStatus(true);
    setPrice(data?.price);
    setDiscount(+data?.discount > 0 ? data?.discount : '');
    setDiscountedPrice(data?.discountedPrice);
    setTax(data?.gst_amount);
    setTotalAmount(data?.total_amount);
    setReferralCode('');
    setAppliedCode(data?.referral_code);
  };

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.paymentHeading}</h1>
            </div>
            <div className="myPageWrapper myPayment">

              <div className="UserBillingDetails">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h3>{Strings.paymentBillingDetails}</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentFirstName}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.first_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentLastName}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.last_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentEmail}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentContact}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.contact_no}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentCity}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.city}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentState}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.state}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentUserCode}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.user_code}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-3">
                        <h4>{Strings.paymentPanCard}</h4>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-xl-9">
                        <p>{accountData?.accountSettings?.pan_card_no || '-'}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ReferralCodeDetails">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                    <h3>{Strings.paymentReferral}</h3>
                  </div>
                  <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                    <input type="text" value={referralCode} placeholder={Strings.paymentReferralPlaceHolder} onChange={(e) => { setReferralCode(e.target.value); }} className="referralInputElement"  />
                    <SmallButton clicked={() => verifyReferralCode()} btnType="PaymentVerify" loading={isLoading} disabled={referralCode === ''}>{Strings.verify}</SmallButton>
                    {codeStatus && <span className="couponCode">{appliedCode} <span onClick={() => clearReferralCode()} className="closeIcon"><FontAwesomeIcon icon={faTimes} className="closeCouponIcon" size="lg" /></span></span>}
                  </div>
                </div>
              </div>

              <div className="PaymentDetails">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h3>{Strings.paymentSubscriptionPlan}</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p>{courseGroup?.exam_details?.et_name} {courseGroup?.group_name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h4>{Strings.paymentFees}</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p>{Strings.paymentCurrency} {price}</p>
                  </div>
                </div>
                {discount > 0 && <div className="row">
                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h4>{Strings.discount} (5%)</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p>{Strings.paymentCurrency} {discount}</p>
                  </div>
                </div>}
                {discount > 0 && <div className="row">
                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h4>{Strings.discountedFees}</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p>{Strings.paymentCurrency} {discountedPrice}</p>
                  </div>
                </div>}
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h4>{Strings.paymentGST}</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <p>{Strings.paymentCurrency} {tax}</p>
                  </div>
                </div>
                <div className="row totalRow">
                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                    <h3>{Strings.paymentTotal}</h3>
                  </div>
                  <div className="col-6 col-sm-6 col-md-9 col-lg-9 col-xl-9">
                    <h3>{Strings.paymentCurrency} {totalAmount}</h3>
                  </div>
                </div>
              </div>

              <div className="SubmitDetails">
                <form onSubmit={onPayment}>
                  <label className="checkboxContainer">{Strings.paymentRead} <span className="openLink" onClick={() => showTermsPage()}>{Strings.paymentTerms}</span> {Strings.paymentAdhere} <span className="openLink" onClick={() => showCopyrightPage()}>{Strings.paymentCopyright}</span>.
                    <input type="checkbox" onChange={e => { setTermsAgree(e.target.checked); }} checked={isTermsAgree} />
                    <span className="checkmark"></span>
                  </label>
                  <input type="submit" className="PayNow" value={Strings.paymentPayNow} disabled={!isTermsAgree} />
                </form>
              </div>

            </div>
        </div>
      </div>
      {navigationData.showBillingVerification ? <BillingDetailsVerification /> : null}
      {navigationData.showPrivacyPolicyModal ? <PrivacyPolicyModal /> : null}
      {navigationData.showTermsConditionsModal ? <TermsConditionsModal /> : null}
    </Fragment>
  );
}
