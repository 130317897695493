import React, { useRef, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Strings } from 'resources';
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import Header from 'components/Header';
import { getNotification as getNotificationAPI } from "services/AuthService";
import Footer from 'components/Footer';
import moment from 'moment';
import { isUserLoggedIn } from "services/AuthService";
import { openInNewTab } from "utils/helpers";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import { log } from "utils";
import KnowledgeNotification from "components/KnowledgeNotification";
import DashboardImage from 'assets/images/v2/landing/dashboard.png';
import QuillItImage from 'assets/images/v2/landing/quillit.png';
import CertificateImage from 'assets/images/v2/landing/certificate.png';
import ScoreboardImage from 'assets/images/v2/landing/scoreboard.png';
import HeatMapImage from 'assets/images/v2/landing/heatmap.png';
import LibraryImage from 'assets/images/v2/landing/b6.svg';
import AccuracyWormImage from 'assets/images/v2/landing/accuracy_worm.png';
import AceGameImage from 'assets/images/v2/landing/acegame.png';

//exam category page
import "./answerScreen.scss"

import greenCheckBoxImage from 'assets/images/v2/landing/greenCheckBox.svg';
import greenMaskImage from 'assets/images/v2/landing/greenMask.svg';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import RegisteredUsers from 'assets/images/landing/registeredUsers.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(Draggable);
gsap.config({
    nullTargetWarn: false
});

export default function AnswerScreen(props) {
    const { user } = isUserLoggedIn();
    const accountData = useSelector((state) => state.accountData);
    const navigationData = useSelector((state) => state.navigationData);
    const { status } = isUserLoggedIn();
    const dispatch = useDispatch();
    const history = useHistory();
    const [redirectTo, setRedirectTo] = useState({});
    const [isRedirect, setIsRedirect] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const sliderRef = useRef(null);


    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]);




    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);



    const notificationItemView = (item, index) => {
        return (<KnowledgeNotification key={`notif-item-${index}`} itemData={item} index={index} />);
    }



    return (

        <>
            <div className="container-fluid answer_wrap pageScreenContainer dispersionLogo">
                <h5>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco l</h5>
                <p className="text-capitalize ">answer <span>C</span></p>
                 <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad miniLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</h6>
                 <h5>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco l</h5>
                <p className="text-capitalize ">answer <span>C</span></p>
                 <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad miniLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</h6>
                 <h5>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco l</h5>
                <p className="text-capitalize ">answer <span>C</span></p>
                 <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad miniLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</h6>
                 <h5>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco l</h5>
                <p className="text-capitalize ">answer <span>C</span></p>
                 <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad miniLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</h6>
                 <h5>1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco l</h5>
                <p className="text-capitalize ">answer <span>C</span></p>
                 <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad miniLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</h6>
                 
            </div>

        </>
    );

    

}

