import React, { useRef, useEffect, useState } from "react";

import { useHistory, Link } from "react-router-dom";
import { Strings } from 'resources';
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import Header from 'components/Header';
import { getNotification as getNotificationAPI } from "services/AuthService";
import Footer from 'components/Footer';
import moment from 'moment';
import { isUserLoggedIn } from "services/AuthService";
import { openInNewTab } from "utils/helpers";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import { log } from "utils";
import KnowledgeNotification from "components/KnowledgeNotification";
import DashboardImage from 'assets/images/v2/landing/dashboard.png';
import QuillItImage from 'assets/images/v2/landing/quillit.png';
import CertificateImage from 'assets/images/v2/landing/certificate.png';
import ScoreboardImage from 'assets/images/v2/landing/scoreboard.png';
import HeatMapImage from 'assets/images/v2/landing/heatmap.png';
import LibraryImage from 'assets/images/v2/landing/b6.svg';
import AccuracyWormImage from 'assets/images/v2/landing/accuracy_worm.png';
import AceGameImage from 'assets/images/v2/landing/acegame.png';

//exam category page
import './mocktest.scss'
import logo from "../../../assets/images/icons/logo1.png"
import alert from "../../../assets/images/icons/alert.png"
import greenCheckBoxImage from 'assets/images/v2/landing/greenCheckBox.svg';
import greenMaskImage from 'assets/images/v2/landing/greenMask.svg';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import RegisteredUsers from 'assets/images/landing/registeredUsers.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(Draggable);
gsap.config({
  nullTargetWarn: false
});

export default function MockTestMainScreen(props) {
  const [subject, setsubject] = useState("all");
  const [show, setshow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = isUserLoggedIn();
  const accountData = useSelector((state) => state.accountData);
  const navigationData = useSelector((state) => state.navigationData);
  const { status } = isUserLoggedIn();
  const dispatch = useDispatch();
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const sliderRef = useRef(null);


  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);




  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);



  const notificationItemView = (item, index) => {
    return (<KnowledgeNotification key={`notif-item-${index}`} itemData={item} index={index} />);
  }

  // const current = new Date();
  // const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total, seconds
    };
  }


  const startTimer = (e) => {
    let { total, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to 
      // add '0' at the begining of the variable
      setTimer(
        (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }


  const clearTimer = (e) => {
    setTimer('10');
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  }

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);


  const allSubject = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 col-xxl-6">
            <div className="paragraph">
              <p>Paragraph 1- One of the easiest ways to establish a savings habit is to participate in your employer's 401(k) plan. Funds are withheld from each paycheck and deposited into your account. If your employer matches part of your contribution — and many do! — you will accumulate yet more. A second way to consistently save is with an automatic savings transfer program with your financial institution. You decide how much and when you want funds transferred from your checking account into a savings account. You can also use a payroll deduction plan from your employer and get the same results.</p>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xxl-6">
            <div className="paragraph">
              <h5>Q1. Which of the following can be inferred as the theme of the passage?</h5>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="optradio" />(a)  Certificate of deposits is the best way to establish savings habit.
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="optradio" />(b)  Establishing a consistent saving habit and also smart saving with CDs.
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="optradio" />(c)  Employer's 401(k) plan provides a convenient way for consistent and smart savings.
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="optradio" />(d) Your savings decide how well you flourish and grow.
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="optradio" />(e)  None of the Above
                </label>
              </div>
            </div>
          </div>
        </div>


      </>
    )
  }

  const NumericalAbility = () => {
    return (
      <>
        <h1>hii</h1>

      </>
    )
  }
  const Intraction = () =>{
    return(
      <>
        <div className="container-fluid Instruction">
              <h4>General Instructions</h4>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            <p>1.<span> The clock will be set at the server. The c Lorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataLorem sum dummy dataountdown timer at the top right. Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data Lorem sum dummy data</span></p>
            </div>
      </>
    )
  }


  const Popup = props => {
    return (
      <div className="popup-box">
        <div className="box">
        <h3 className="text-center">Are you sure you want to switch section?</h3>
        <p>You will not be able to come back to this section. Please submit the section to move to next section.</p>   
       
       <div className="text-center"> <button  className="btn close-icon cencel_pop" onClick={props.handleClose}>CANCEL</button>
       <button className="btn sumbit_pop">SUBMIT</button>
        </div>
      
          {props.content}
        </div>
      </div>
    );
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
 
  return (
    <>
      <nav className="container-fluid Mocktest_mainscreen">
        <div className="flex flex-row justify-content-between flex-wrap">
          <div className="flex flex-row align-items-end flex-wrap">
            <img src={logo} className="img-fluid" alt="logo" />
            <div className="ibps">
              <h5 className="mb-0">IBPS PO Prelims Paper 2015 English</h5>
              <span>Monday, 04 January 2021 </span>
            </div>
          </div>

          <ul className="flex flex-row align-items-center flex-wrap">
            <li>View in
              <select>
                <option>English</option>
                <option>Hindi</option>
              </select>

            </li>
            <li>Time Left <span>{timer}</span> </li>
          </ul>
        </div>
      </nav>
      <div className="center_div">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-9 col-xxl-9 top_div mt-3 order-last order-xl-first  order-lg-first order-md-first">

            <ul class="flex flex-row flex-wrap justify-content-between">
              <div className="text-uppercase mt-2 Sections">Sections</div>
              <li className={`item ${subject === 'all' ? 'active' : ''}`} onClick={() => setsubject('all')}><span>General English</span></li>
              <li className={`item ${subject === 'Numerical_Ability' ? 'active' : ''}`} onClick={() => setsubject('Numerical_Ability')}>Numerical Ability</li>
              <li>Reasoning Ability</li>
              <li>Quantitative Aptitude</li>

            </ul>
            <div className="heading_wraper">
              <p>Directions (1-4): Read the following passage and answer the questions as directed.</p>
            </div>
            <div className="pageScreen logoBg">
              {subject === 'all' && allSubject()}
              {subject === 'Numerical_Ability' && NumericalAbility()}
              {subject === 'intraction' && Intraction()}
              {/* {subject === 'sumbit_test' && sumbitTest()} */}
              <div className="markreview d-flex justify-content-between">

                <div className="ml-2">
                  <button type="button" class="btn ">MARK FOR REVIEW</button>
                  <button type="button" class="btn">CLEAR RESPONSE</button>
                </div>
                <div className="sumbitandNext">
                  <button type="button" class="btn">SUBMIT AND NEXT</button>
                 
                </div>
              </div>

            </div>

          </div>


          <div className="col-12 col-md-6 col-lg-3 col-xxl-3 first_div order-xxl-1 order-first order-xl-last order-lg-last order-md-last
">
            <div className="flex flex-row ">
              
              <h6 className="align-items-end">Report </h6>
              <img onClick={()=>setshow(!show)} src={alert} className="img-fluid ml-2" alt="logo" />
              
            </div>
            {show ? (
                  <div className="imgpop_up">               
                   <p>Wrong Question</p>
                   <p>Formatting Issue</p>
                   <p>Wrong Translation</p>
                   <p>Other</p>
                  </div>
                ) : ""}
            <div className="row mt-4">
              <div className="col-6 col-md-6 col-lg-5 col-xl-5 d-flex">
                <div className="Answered"></div>
                <p>Answered</p>
              </div>
              <div className="col-6 col-md-6 col-lg-7 col-xl-7 d-flex">
                <div className="not_visit"></div>
                <p>Not Visited</p>
              </div>

              <div className="col-6 col-md-6 col-lg-5 col-xl-5 d-flex">
                <div className="Marked_Review"></div>
                <p>Marked For Review</p>
              </div>
              <div className="col-6 col-md-6 col-lg-7 col-xl-7 d-flex">
                <div className="Reviewed"></div>
                <p>Reviewed but Not answered</p>

              </div>
            </div>
            <div class="flex flex-row align-content-center justify-content-center flex-wrap answerWraper">
              <div className="answer">1</div>
              <div className="answer">2</div>
              <div className="answerwhite">3</div>
              <div className="answer">4</div>
              <div className="answer">5</div>
              <div className="answerg">6</div>
              <div className="answerwhite">7</div>
              <div className="answerwhite">8</div>
              <div className="answerwhite">9</div>
              <div className="answerwhite">10</div>
              <div className="answerwhite">11</div>
              <div className="answerwhite">12</div>
              <div className="answerwhite">13</div>
              <div className="answerwhite">14</div>
              <div className="answerred">15</div>
              <div className="answerred">16</div>
              <div className="answerwhite">17</div>
              <div className="answerwhite">18</div>
              <div className="answerwhite">19</div>
              <div className="answerwhite">20</div>
              <div className="answerwhite">21</div>
              <div className="answerwhite">22</div>
              <div className="answerwhite">23</div>
              <div className="answerwhite">24</div>
              <div className="answerwhite">25</div>
            </div>

            <div className="d-flex flex-wrap   mt-5 justify-content-center  instraction">
              <button type="button" className="btn  mx-3 "  onClick={togglePopup}  >Submit Section</button>
              <button type="button"className={`item ${subject === 'intraction' ? 'active' : ''}`} onClick={() => setsubject('intraction')}>Instructions</button>

            </div>
            <div className="d-flex justify-content-center sumbit">
              
              <button type="button" class={`btn ${subject === "sumbit_test" ? 'active' : ''}`}  onClick={() => setsubject('sumbit_test')}>SUBMIT TEST</button>
            </div>
          </div>
        </div>
      </div>
      <div>
    {isOpen && <Popup
      content={<>
      </>}
      handleClose={togglePopup}
    />}
  </div>

    </>
  );

}
