import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Strings } from 'resources';

/* Landing Screens */
import Landing from "screens/Landing";

/* Auth Screens */
import SignIn from "screens/Auth/SignIn";
import SignUp from "screens/Auth/SignUp";
import ForgotPassword from "screens/Auth/ForgotPassword";
import ResetPassword from "screens/Auth/ResetPassword";
import ActivateUser from "screens/Auth/ActivateUser";
import Faq from "screens/Auth/Faq";

/* Misc Screens */
import PageNotFound from "screens/PageNotFound";

/* User Screens */
import Dashboard from "screens/User/Dashboard";
import Home from "screens/User/Home";

/* -- User Settings Screen */
import AccountSettings from "screens/User/Settings/Account";
import ChangeUserPassword from "screens/User/Settings/ChangePassword";

/* -- User Policy Related Pages -- */
import TermsAndConditions from "screens/User/TermsAndConditions";
import CopyrightAndPrivacyPolicy from "screens/User/CopyrightAndPrivacyPolicy";
import PoliciesAndProcedures from "screens/User/PoliciesAndProcedures";

import Search from "screens/User/Search";
import Library from "screens/User/Library";
import ExamTypes from "screens/User/Library/ExamTypes";
import FileView from "screens/User/Library/FileView";
import UpcomingEvents from "screens/User/UpcomingEvents";
import Courses from "screens/User/Courses";
import ContactUs from "screens/User/ContactUs";
import ReferAFriend from "screens/User/ReferAFriend";
import SubscriptionPackage from "screens/User/SubscriptionPackage";
import Payment from "screens/User/Payment";
import PaymentSuccess from "screens/User/Payment/Success";
import PaymentFailure from "screens/User/Payment/Failure";
import PaymentHistory from "screens/User/Payment/History";

import TopicsHeatMap from "screens/User/Topics/HeatMap";
import TopicsReading from "screens/User/Topics/Reading";
import TopicsQuizLists from "screens/User/Topics/QuizLists";
import TopicQuiz from "screens/User/Topics/Quiz";

import QuillIt from "screens/User/QuillIt";

/* ACE Game Screens */
import ACEGameHistory from "screens/User/ACE/GameLists";
import ListCertificates from "screens/User/ACE/ListCertificates";
import ACEGameRules from "screens/User/ACE/GameRules";
import ACEGame from "screens/User/ACE/Game";
import ACEScoreboard from "screens/User/ACE/Scoreboard";
import ACEGameScoreCard from "screens/User/ACE/ScoreCard";
import ACEGameCertificate from "screens/User/ACE/Certificate";
import VerifyCertificate from "screens/Auth/VerifyCertificate";
import ACEGameReport from "screens/User/Report/ACEGame";
import SubjectGameReport from "screens/User/Report/SubjectGame";
import ClaimOffer from "screens/Auth/ClaimOffer";
import Notifications from "screens/Auth/Notification";

/* Layout Files */
import AuthLayout from "layouts/AuthLayout";
import AuthLayoutWithoutAuth from "layouts/AuthLayout/WithoutAuth";
import UserLayout from "layouts/UserLayout";
import ChatLayout from "layouts/ChatLayout";
import ACEGameLayout from "layouts/ExamLayout";
import ExamLayout from "layouts/ExamLayout"

// future exam routes
import LandingPage from "screens/FutureExams/LandingPage";
import PackCgl from "screens/FutureExams/PACK CGL";
import Package from "screens/FutureExams/Package"
import Subscription from "screens/FutureExams/Subscription"
import Instruction from "screens/FutureExams/Instruction"
import MockTestMainScreen from "screens/FutureExams/MockTestMainScreen"
import AnswerScreen from "screens/FutureExams/AnswerScreen"
import DetailedAnalysis from "screens/FutureExams/DetailedAnalysis"
/* Store Files */
import store from "redux/store";
import { Provider as ReduxProvider } from "react-redux";
import { positions, transitions, Provider as AlertProvider } from "react-alert";

/* Templates */
import AlertTemplate from "utils/AlertTemplate";

const alertOptions = {
  timeout: 3000,
  position: positions.MIDDLE,
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 9999999
  }
};

export default function Routing() {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <ReduxProvider store={store}>
        <Router>
          <Switch>
            <AuthLayoutWithoutAuth
              exact
              path="/"
              component={Landing}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/sign-in"
              component={SignIn}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/sign-up"
              component={SignUp}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/forgot-password"
              component={ForgotPassword}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/reset-password"
              component={ResetPassword}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/activate-user"
              component={ActivateUser}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/verify/:userCode"
              component={VerifyCertificate}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/claim-offer/:user_id/:appKey"
              component={ClaimOffer}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/notifications"
              component={Notifications}
              pageTitle={Strings.bharatCETPortal}
            />
            <AuthLayoutWithoutAuth
              exact
              path="/faqs"
              component={Faq}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/home"
              component={Home}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/dashboard"
              component={Dashboard}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/library/:category"
              component={Library}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/library/:category/:type"
              component={ExamTypes}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/library-file-view"
              component={FileView}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/events"
              component={UpcomingEvents}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/my-courses"
              component={Courses}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/copyright-and-privacy-policy"
              component={CopyrightAndPrivacyPolicy}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/policies-and-procedures"
              component={PoliciesAndProcedures}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/contact-us"
              component={ContactUs}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/my-profile"
              component={AccountSettings}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/change-password"
              component={ChangeUserPassword}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/refer-a-friend"
              component={ReferAFriend}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/select-subscription-package"
              component={SubscriptionPackage}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/payment"
              component={Payment}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/payment-history"
              component={PaymentHistory}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/payment-success/:invoiceId"
              component={PaymentSuccess}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/payment-failure/:invoiceId"
              component={PaymentFailure}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/category/:catId/heat-map"
              component={TopicsHeatMap}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/category/:catId/topic/:topicId/reading"
              component={TopicsReading}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/category/:catId/topic/:topicId/quiz-lists"
              component={TopicsQuizLists}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/category/:catId/topic/:topicId/quiz/:quizId"
              component={TopicQuiz}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/category/:catId/topic/:topicId/quiz/:quizId/attempt/:attemptId/report"
              component={SubjectGameReport}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/ace-game-history"
              component={ACEGameHistory}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/certificate-history"
              component={ListCertificates}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/leader-board"
              component={ACEScoreboard}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/search/:query"
              component={Search}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/ace-certificate"
              component={ACEGameCertificate}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/ace-game/:quizId/:attemptId/score-card"
              component={ACEGameScoreCard}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/ace-game/:quizId/:attemptId/report"
              component={ACEGameReport}
              pageTitle={Strings.bharatCETPortal}
            />
            <ACEGameLayout
              exact
              path="/ace-game-rules"
              component={ACEGameRules}
              pageTitle={Strings.bharatCETPortal}
            />
            <ACEGameLayout
              exact
              path="/ace-game/:quizId/attempt/:attemptId/play"
              component={ACEGame}
              pageTitle={Strings.bharatCETPortal}
            />
            <ChatLayout
              exact
              path="/quill-it"
              component={QuillIt}
              pageTitle={Strings.bharatCETPortal}
            />
            {/* custome routes */}
              <UserLayout
              exact
              path="/exam-category"
              component={LandingPage}
              pageTitle={Strings.bharatCETPortal}
            />
             <UserLayout
              exact
              path="/pack-cgl"
              component={PackCgl}
              pageTitle={Strings.bharatCETPortal}
            />
             <UserLayout
              exact
              path="/Package"
              component={Package}
              pageTitle={Strings.bharatCETPortal}
            />
             <UserLayout
              exact
              path="/Subscription"
              component={Subscription}
              pageTitle={Strings.bharatCETPortal}
            />
            <UserLayout
              exact
              path="/Instruction"
              component={Instruction}
              pageTitle={Strings.bharatCETPortal}
            />
            <ExamLayout
              exact
              path="/MockTestMainScreen"
              component={MockTestMainScreen}
              pageTitle={Strings.bharatCETPortal}
            />
             <UserLayout
              exact
              path="/DetailedAnalysis"
              component={DetailedAnalysis}
              pageTitle={Strings.bharatCETPortal}
            />
              <UserLayout
              exact
              path="/AnswerScreen"
              component={AnswerScreen}
              pageTitle={Strings.bharatCETPortal}
            />
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </Router>
      </ReduxProvider>
    </AlertProvider>
  );
}
