import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import * as AccountActions from "redux/actions/AccountActions";
import * as AceTestActions from "redux/actions/AceTestActions";
import { getUserId, getUserToken } from "services/AuthService";
import { getACEGameScoreCard as getACEGameScoreCardFromAPI } from "services/AceTestService";
import { Strings } from 'resources';
import { log } from "utils";
import "./ACEGame.scss";

export default function ACEGameReport(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = getUserId();
  const userToken = getUserToken();
  const { quizId, attemptId } = useParams();
  const [isLoading, setLoadingState] = useState(false);
  const aceTestData = useSelector((state) => state.aceTestData);
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  /* Load User Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [dispatch, userId]);

  /* Handle Mouse Right Click Context Menu */
  const handleContextMenu = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    dispatch(AceTestActions.getACEGame(quizId));
  }, [dispatch, quizId]);

  useEffect(() => {
    async function getACEScoreCardDetails(){
      setLoadingState(true);
        const { data, error } = await getACEGameScoreCardFromAPI(userToken, userId, quizId, attemptId, '1');
        if (!error && data.status) {
          log(data);
          setLoadingState(false);
          dispatch(AceTestActions.setACEGameReport(data.data));
        }
      setLoadingState(false);
    }
    getACEScoreCardDetails();
  }, [dispatch, userId, userToken, quizId, attemptId]);

  const questionItemView = (item, index) => {
    const isAnswerCorrect = +item?.is_correct > 0;
    const newQuestionName = item?.question ? item?.question.replaceAll('&#34;', '"') : '';
    const newQuestionDesc = item?.description ? item?.description.replaceAll('&#34;', '"') : '';

    return (<div className="row mb-4" key={`index-${index}`}>
        <div className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
          <div className="questionItem">
            <div className="sequenceNumber"><p>{index+1}.</p></div>
            <div className="questionContentWrapper">
                <div className="questionContentDescription" dangerouslySetInnerHTML={{__html : newQuestionDesc}}></div>
                <div className="questionContent" dangerouslySetInnerHTML={{__html : newQuestionName}}></div>
                <div className={`myAnswerView ${isAnswerCorrect ? 'Correct' : 'Wrong'}`}>{Strings.your_answer}: <div className="myContent" dangerouslySetInnerHTML={{__html : item?.q_option}}></div></div>
                <div className="correctAnswerView Correct">{Strings.correctAnswer}: <div className="myContent" dangerouslySetInnerHTML={{__html : item?.correct_answer}}></div></div>
                <div className="justificationView">{Strings.justification}:</div>
                <div className="questionContentJustification" dangerouslySetInnerHTML={{__html : item?.justification}}></div>
            </div>
          </div>
        </div>
      </div>);
  }

  const questionStatusView = (queItem, queIndex) => {
    let className = 'black';
    if (queItem.attend_status === 'skipped' || queItem.attend_status === 'pending') {
      className = 'blue';
    } else if (queItem.attend_status === 'answered' && queItem.answer_status === '0') {
      className = 'yellow';
    } else if (queItem.attend_status === 'answered' && queItem.answer_status === '1') {
        className = 'green';
    }
    return (<span className={className} key={`question-status-${queIndex}`}>{queItem.question_order}</span>);
  }

  const goToQAVideos = () => {
    window.location.href = 'https://www.youtube.com/watch?v=PQRWtO53ZnQ&list=PLF0mggHqHKOdFUU0ik5K5Ia2NFfQNkxxo';
  }

  const goToVerbalVideos = () => {
    window.location.href = 'https://www.youtube.com/watch?v=Xr6HEA4SL3Y&list=PLF0mggHqHKOf6DBGQFDJSNdc5bQYcDYr2';
  }

  const goToLRVideos = () => {
    window.location.href = 'https://www.youtube.com/watch?v=XuErRoUIGrw&list=PLF0mggHqHKOfEFQKxKMURZt0-w-G8AzGI';
  }

  const reportView = () => {
    return (<>
    <div className="pointsView">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

      <div className="row">
        {/*<div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 pointBoxWrapper">
          <div className="pointBox White">
            <h3>{aceTestData?.testReport?.days_left}</h3>
            <span className="pbInfo">{Strings.daysLeft}</span>
          </div>
        </div>*/}
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pointBoxWrapper">
          <div className="pointBox LightBlue">
            <h3>{aceTestData?.testReport?.accuracy_points ? Math.round(aceTestData?.testReport?.accuracy_points) : '0'}%</h3>
            <span className="pbInfo">{Strings.accuracy}</span>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pointBoxWrapper">
          <div className="pointBox LightOrange">
            <h3>{aceTestData?.testReport?.remaining_question}</h3>
            <span className="pbInfo">{Strings.questionsRemaining}</span>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pointBoxWrapper">
          <div className="pointBox LightGreen">
            <h3>{aceTestData?.testReport?.acePoints}</h3>
            <span className="pbInfo">{Strings.acePoints}</span>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 pointBoxWrapper">
          <div className="pointBox LightYellow">
            <h3>{aceTestData?.testReport?.hours}</h3>
            <span className="pbInfo">{Strings.hours}</span>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pointBoxWrapper">
          <div className="pointBox LightBrown">
            <h3>{aceTestData?.testReport?.game_left}</h3>
            <span className="pbInfo">{Strings.gamesLeft}</span>
          </div>
        </div>
      </div>

      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div className="accuracyChartView">
          <h3 className="gameName">{Strings.aceGame} # {aceTestData?.testReport?.quiz_order}</h3>
          <h3 className="userName">{aceTestData?.testReport?.user_name}</h3>
          <h3 className="userCode">{aceTestData?.testReport?.user_code}</h3>
          <h3 className="address">{aceTestData?.testReport?.address}</h3>
          <h3 className="currentLevel">{aceTestData?.testReport?.currentLevel}</h3>
          <h3 className="totalEarnedPoints">{Strings.earnedPoints} : <span>{aceTestData?.testReport?.total_earn_points}</span></h3>
          <h3 className="totalACEPoints">{Strings.totalAcePoints} : <span>{aceTestData?.testReport?.total_ace_points}</span></h3>
        </div>
      </div>

    </div>
  </div>

    <div className="questionsView">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">

      {aceTestData?.testReport?.questions.length > 0 &&
        <div className="questions">
          {aceTestData?.testReport?.questions.map((queItem, queIndex) => questionStatusView(queItem, queIndex))}
        </div>
      }

      </div>
      <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div className="accuracyChartView remarks">
          <h3 className="gameName">{Strings.remarks}</h3>
          <ol>
            {+aceTestData?.testReport?.game_accuracy_points[0]?.categoryAccuracyPoints <= +aceTestData?.testReport?.indian_accuracy_points[0]?.categoryAccuracyPoints && <li>{Strings.pleaseRefer} <span className="yTLinks" onClick={() => goToLRVideos()}>{Strings.youtube_video_url}</span> {Strings.for_learning_logical_reasoning_topics}</li>}
            
            {+aceTestData?.testReport?.game_accuracy_points[1]?.categoryAccuracyPoints <= +aceTestData?.testReport?.indian_accuracy_points[1]?.categoryAccuracyPoints && <li>{Strings.pleaseRefer} <span className="yTLinks" onClick={() => goToQAVideos()}>{Strings.youtube_video_url}</span> {Strings.for_learning_logical_reasoning_topics}</li>}
            
            {+aceTestData?.testReport?.game_accuracy_points[2]?.categoryAccuracyPoints <= +aceTestData?.testReport?.indian_accuracy_points[2]?.categoryAccuracyPoints && <li>{Strings.pleaseRefer} <span className="yTLinks" onClick={() => goToVerbalVideos()}>{Strings.youtube_video_url}</span> {Strings.for_learning_verbal_topics}</li>}
            
            {+aceTestData?.testReport?.game_accuracy_points[3]?.categoryAccuracyPoints <= +aceTestData?.testReport?.indian_accuracy_points[3]?.categoryAccuracyPoints && <li>{Strings.please_refer_youtube_videos_for_learning_general_knowledge_topics}</li>}

            {+aceTestData?.testReport?.game_accuracy_points[0]?.categoryAccuracyPoints > +aceTestData?.testReport?.indian_accuracy_points[0]?.categoryAccuracyPoints && +aceTestData?.testReport?.game_accuracy_points[1]?.categoryAccuracyPoints > +aceTestData?.testReport?.indian_accuracy_points[1]?.categoryAccuracyPoints && +aceTestData?.testReport?.game_accuracy_points[2]?.categoryAccuracyPoints > +aceTestData?.testReport?.indian_accuracy_points[2]?.categoryAccuracyPoints && +aceTestData?.testReport?.game_accuracy_points[3]?.categoryAccuracyPoints > +aceTestData?.testReport?.indian_accuracy_points[3]?.categoryAccuracyPoints && <li>{Strings.you_are_going_great_keep_it_up}</li>}
          </ol>
        </div>
      </div>
    </div>
  </div>

    <h2>{Strings.your_accuracy_rate}</h2>
    <div className="accuracyRateHistory">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>{Strings.logicalReasoning}</th>
              <th>{Strings.quantitativeAptitude}</th>
              <th>{Strings.verbal}</th>
              <th>{Strings.generalKnowledge}</th>
              <th>{Strings.total}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Strings.game}</td>
              <td>{aceTestData?.testReport?.game_accuracy_points[0]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.game_accuracy_points[1]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.game_accuracy_points[2]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.game_accuracy_points[3]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.total_game_accuracy_points?.categoryAccuracyPoints}%</td>
            </tr>
            <tr>
              <td>{Strings.overall}</td>
              <td>{aceTestData?.testReport?.overall_accuracy_points[0]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.overall_accuracy_points[1]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.overall_accuracy_points[2]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.overall_accuracy_points[3]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.total_overall_accuracy_points?.categoryAccuracyPoints}%</td>
            </tr>
            <tr>
              <td>{Strings.indiaAverage}</td>
              <td>{aceTestData?.testReport?.indian_accuracy_points[0]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.indian_accuracy_points[1]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.indian_accuracy_points[2]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.indian_accuracy_points[3]?.categoryAccuracyPoints}%</td>
              <td>{aceTestData?.testReport?.total_indian_accuracy_points?.categoryAccuracyPoints}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2>{Strings.answersReport}</h2>
    <div className="examQuestionsListing">
       {aceTestData?.testReport?.questionAnswerList?.length > 0 &&
          aceTestData?.testReport?.questionAnswerList.map((item, index) => questionItemView(item, index))
       }
     </div>
     <h2>{Strings.accuracyRateCalculations}</h2>
     <div className="accuracyRateCalculations">
      <ol>
        <li>{Strings.broadCategory_reasoning_ability_quantitative_aptitude_eng_lang_general_awareness}</li>
         <li>{Strings.questions_will_further_have_subcategories_but_user_will_not_see_any}</li>
         <li>{Strings.for_ace_game_the_accuracy_rate_will_be_maintained_at}</li>
          <ol type="i">
            <li>{Strings.ace_game_level}</li>
            <li>{Strings.subject_level}</li>
            <li>{Strings.subTopicLevel}</li>
            <li>{Strings.cumulative_ace_games_level}</li>
            <li>{Strings.weekly_ace_games_level}</li>
            <li>{Strings.national_ace_games_average_levels}</li>
          </ol>
      </ol>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_ace_points_for_correct_answer}) / ({Strings.total_of_eligible_ace_points_for_the_game})</li>
      </ul>
      <strong><em>{Strings.atGameLevel}</em></strong>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_of_ace_points_for_correct_answers_on_the_subject}) / ({Strings.total_of_eligible_ace_points_for_the_game_for_the_game_on_the_subject})</li>
      </ul>
      <em><strong>{Strings.atSubjectLevel}</strong></em>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_of_ace_points_for_correct_answers_on_the_sub_topic}) / ({Strings.total_of_eligible_ace_points_for_the_game_for_the_sub_topic})</li>
      </ul>
      <em><strong>{Strings.atSubTopicLevel}</strong></em>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_of_ace_points_for_correct_ans_from}  1<sup>st</sup> {Strings.game_played_to_last_game})/({Strings.total_of_eligible_ace_points_for_the_game_from} 1<sup>st</sup> {Strings.game_played_to_last_game})</li>
      </ul>
      <em><strong>{Strings.forCumulativeDashboard}</strong></em>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_of_ace_points_for_correct_ans_from} 1<sup>st</sup> {Strings.game_played_on_sunday_to_last_game_on_saturday}) / ({Strings.total_of_eligible_ace_points_for_the_game_from} 1<sup>st</sup> {Strings.game_played_on_sunday_to_last_game_on_saturday})
        </li>
      </ul>
      <em><strong>{Strings.forWeeklyWormTrend}</strong></em>
      <ul>
        <li>{Strings.accuracy_rate} = ({Strings.total_of_ace_points_for_correct_answers_of_all_players_at_national_level}) / ({Strings.total_of_eligible_ace_points_for_the_game_of_all_players_at_national_level})</li>
      </ul>
      <em><strong>{Strings.forNationalAverage}</strong></em>
     </div>
    </>);
  }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1>{Strings.aceGamePerformanceReportWithAnswers}</h1>
            </div>
            <div className="myPageWrapper AceGameReport">
              {isLoading && <p>{Strings.loadingACEGameReport}</p>}
              {!isLoading && reportView()}
            </div>
        </div>
      </div>
    </Fragment>
  );
}
