import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as SubjectTestActions from "redux/actions/SubjectTestActions";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { getUserId, getUserToken } from "services/AuthService";
import { submitSubjectQuizAnswers as submitSubjectQuizAnswersFromAPI, sendQuizReportCard as sendQuizReportCardFromAPI } from "services/SubjectTestService";
import { parseSelectedQuestionOptions } from "utils/chat";
import { Strings } from 'resources';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SmallButton from "components/UI/SmallButton";
import SubjectQuizResult from "modals/SubjectQuizResult";
import { parseSubjectTests } from "utils/chat";
import { log } from "utils";
import "./Quiz.scss";

export default function Quiz(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const subjectTestData = useSelector((state) => state.subjectTestData);
  const accountData = useSelector((state) => state.accountData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const { catId, topicId, quizId } = useParams();
  const history = useHistory();
  const [isLoading, setLoadingState] = useState(false);
  const alert = useAlert();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get My Subject Test Questions */
  useEffect(() => {
    dispatch(SubjectTestActions.getMySubjectQuizDetails(quizId));
  }, [dispatch, quizId]);

  /**
   * showCategoryAccuracy
   * @description Show Category Accuracy Timer
   */
  const showCategoryAccuracy = () => {
    return ( <CountdownCircleTimer
      isPlaying
      onComplete={() => {
        submitQuizResult();
      }}
      size={80}
      strokeWidth={6}
      duration={600}
      colors={[
        ['#FF9933', 1],
      ]}
    >{timerChildren}</CountdownCircleTimer>);
}

/**
 * timerChildren
 * @param {object} remainingTime
 * @description Show Timer Counter Children
 */
const timerChildren = ({ remainingTime }) => {
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  return (<span className="quizTime">{minutes}:{seconds}</span>);
}

/**
 * questionItemView
 * @param {object} item
 * @param {number} index
 * @description Show Question Item View
 */
const questionItemView = (item, index) => {
  let questionClass = 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12';
  let questionStatus = false;
  if (subjectTestData?.activeTest?.status === 'completed') {
    questionClass = 'col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8';
    questionStatus = true;
  }
  let questionContent = item.description ? item.description + '<br/>' + item.question : item.question;

  if(userLanguage === 'hi') {
    questionContent = item.descriptionHindi ? item.descriptionHindi + '<br/>' + item.questionHindi : item.questionHindi;
  }

  const newQuestionName = questionContent;
  return (<div className="row mb-4" key={`question-${index}`}>
  <div className={questionClass}>
    <div className="questionItem">
      <div className="question">
        <div className="sequenceNumber"><p>{index+1}.</p></div>
        <div className="questionContent" dangerouslySetInnerHTML={{__html : newQuestionName}}></div>
      </div>
      {item.options.length > 0 &&
        item.options.map((optionItem, optionIndex) => optionItemView(optionItem, optionIndex, item.id))
      }
    </div>
  </div>
  {questionStatus && answerItemView(item)}
</div>);
}

/**
 * answerItemView
 * @param {object} item
 * @description Show Answer Item View
 */
const answerItemView = (item) => {
  let justification = '';
  if (subjectTestData?.activeTest?.status === 'completed') {
    let optionJustification = userLanguage === 'hi' ? item.justificationHindi : item.justification;
    let optionClass = 'Correct';
    if (item.options.length > 0) {
      let isCorrect = 0;
      let optionLetter = '';
      let optionName = '';
      item.options.forEach((optionItem, optionIndex) => {
        isCorrect = optionItem.isCorrect;
        switch (optionIndex) {
          case 0:
            optionName = 'A';
            break;

          case 1:
            optionName = 'B';
            break;

          case 2:
            optionName = 'C';
            break;

          case 3:
            optionName = 'D';
            break;

          case 4:
            optionName = 'E';
            break;

          default:
            optionName = '';
            break;
        }
        if (isCorrect === '1') {
          optionLetter = optionName;
        }
      });
      justification = (<div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div className="questionAnswer">
        <h4 className="answerHeading">{Strings.correctAnswer}</h4>
        <h3 className={`answerOption ${optionClass}`}>{optionLetter}</h3>
        <div className="justification" dangerouslySetInnerHTML={{__html : optionJustification}}></div>
      </div>
    </div>);
    }
  }
  return (justification);
}

/**
 * optionItemView
 * @param {*} item
 * @param {*} index
 * @param {*} questionId
 * @description Show Option Item View
 */
const optionItemView = (item, index, questionId) => {
  let isChecked = false;
  let isSelected = 0;
  let isCorrect = 0;
  let optionClass = '';
  if (subjectTestData?.activeTest?.status === 'completed') {
    isCorrect = item.isCorrect;
    isSelected = item.isSelected;
    isChecked = item.isChecked;
    if (isCorrect === '1' && isSelected === '1' && isCorrect === isSelected) {
      optionClass = 'Correct';
      isChecked = true;
    } else if (isCorrect === '0' && isSelected === '0' && isCorrect === isSelected) {
      optionClass = '';
      isChecked = false;
    } else if (isCorrect === '1' && isSelected === '0' && isCorrect !== isSelected) {
      optionClass = 'Correct';
      isChecked = true;
    } else if (isCorrect === '0' && isSelected === '1' && isCorrect !== isSelected) {
      optionClass = 'Wrong';
      isChecked = true;
    }
  } else {
    isChecked = item.isChecked;
  }

  let queOption = userLanguage === 'hi' ? item.optionHindi : item.option;  

  return (<div className="option" key={`option-${index}`}>
            <div className="sequenceNumber">
              <label className="radioBox">
                <input type="radio" value={item.id} checked={isChecked} onChange={() =>  onOptionChange(questionId, item.id, item.isChecked)} onClick={() =>  onOptionClick(questionId, item.id, item.isChecked)} />
                <span className={`checkmark ${optionClass}`}></span>
              </label>
            </div>
            <div className="optionContent" onClick={() => onOptionChange(questionId, item.id)} dangerouslySetInnerHTML={{__html : queOption}}></div>
          </div>);
}

/**
 * onOptionChange
 * @param {string} value
 * @param {string} questionId
 * @param {string} optionId
 * @description Handle On Option Change Event
 */
const onOptionChange = (questionId, optionId) => {
  if (subjectTestData?.activeTest?.status === 'completed') {
    return false;
  }
  dispatch(SubjectTestActions.toggleSubjectTestQuestionOption(questionId, optionId));
}

/**
 * onOptionClick
 * @param {string} value
 * @param {number} questionId
 * @param {number} optionId
 * @param {boolean} isChecked
 * @description Handle On Option Click Event
 */
const onOptionClick = (questionId, optionId, isChecked) => {
  if (subjectTestData?.activeTest?.status === 'completed') {
    return false;
  }
  if (isChecked) {
    dispatch(SubjectTestActions.toggleSubjectTestQuestionOption(questionId, optionId));
  }
}

/**
 * submitQuizResult
 * @description Submit Quiz Result
 */
const submitQuizResult = async () => {
  if (subjectTestData?.activeTest?.status === 'completed') {
    return false;
  }
  setLoadingState(true);
  const questionItems = parseSelectedQuestionOptions(subjectTestData.activeTestQuestions);
  const timeStarts = subjectTestData.activeTestStartTime;
  const { data, error } = await submitSubjectQuizAnswersFromAPI(userToken, userId, quizId, questionItems, timeStarts, userLanguage);
  setLoadingState(false);
  if (!error && Array.isArray(data.data) && data.data.length) {
    alert.success(Strings[data.message]);
    const subjectTestData = parseSubjectTests(data.data);
    if (subjectTestData.length > 0) {
      dispatch(SubjectTestActions.setActiveSubjectTestDetails(subjectTestData[0]));
      dispatch(SubjectTestActions.getMySubjectTestQuestions(quizId));
    }
  } else {
    alert.error(Strings[data.message]);
  }
}


const checkACEGameExpireModal = () => {
  let status = '0';
  const valExamId = accountData?.status?.exam;
  const valGroupId = accountData?.status?.group;
  const isValid = accountData?.status?.valid;
  if (valExamId === '1' && valGroupId === '1' && isValid) {
    status = '1';
  } else if (valExamId === '1' && valGroupId === '2' && isValid) {
    status = '2';
  } else if (valExamId === '1' && valGroupId === '3' && isValid) {
    status = '4';
  } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
    status = '3';
  }
  return status;
}


const showFeatureNotAvailableModal = () => {
  dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
}

/**
 * emailQuizResult
 * @description Email Subject Wise Quiz Result PDF in the User's Email Address
 */
const emailQuizResult = async () => {
  const status = checkACEGameExpireModal();
  if (status === '4') {
    const attemptId = subjectTestData?.activeTest?.attemptId;
    history.push(`/category/${+catId}/topic/${+topicId}/quiz/${+quizId}/attempt/${+attemptId}/report`);
  } else {
    showFeatureNotAvailableModal();
  }

  /* if (subjectTestData?.activeTest?.status === 'pending') {
    return false;
  }

  const attemptId = subjectTestData?.activeTest?.attemptId;
  history.push(`/category/${+catId}/topic/${+topicId}/quiz/${+quizId}/attempt/${+attemptId}/report`); */

  /*
  const attemptId = subjectTestData?.activeTest?.attemptId;
  setLoadingState(true);
  const { data, error } = await sendQuizReportCardFromAPI(userToken, userId, quizId, attemptId);
  if (!error && data.status) {
    dispatch(NavigationActions.toggleSubjectQuizResultModal(true));
    setLoadingState(false);
  }
  setLoadingState(false);
  */
}

/**
 * onClosePopUp
 * @description Close Success PopUp and redirect to Category HeatMap
 */
const onClosePopUp = () => {
  dispatch(NavigationActions.toggleSubjectQuizResultModal(false));
  history.push(`/category/${catId}/topic/${topicId}/quiz-lists`);
}

/**
 * pageHeading
 * @description Test game title category and topic
 */
const pageHeading = () => {
  let categoryName = subjectTestData?.activeTest?.categoryName;
  let topicName = subjectTestData?.activeTest?.topicName;
  let testGameName = subjectTestData?.activeTest?.name;
  if(userLanguage === 'hi') {
      categoryName = subjectTestData?.activeTest?.categoryNameHindi;
      topicName = subjectTestData?.activeTest?.topicNameHindi;
      testGameName = subjectTestData?.activeTest?.nameHindi;
  }

  return (
    <div className="pageHeading">
      <h3>{categoryName} &#8594; {topicName} &#8594; {testGameName}</h3>
    </div>
  );
}

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg noPadding">
        <div className="examPage">
            <div className="pageTitle">
              {pageHeading()}
              {subjectTestData?.activeTest?.status === 'pending' && <div className="categoryAccuracy">
                <div className="accuracyChart">
                  {showCategoryAccuracy()}
                </div>
              </div>}
            </div>
            <div className="examQuestionsListing">

              {subjectTestData?.activeTestQuestions?.length > 0 &&
                 subjectTestData?.activeTestQuestions.map((item, index) => questionItemView(item, index))
              }
              {subjectTestData?.activeTestQuestions?.length === 0 && !subjectTestData.isLoading &&
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                    <h3>{Strings.NoQuestionsAvailable}</h3>
                  </div>
                </div>
              }
              {subjectTestData.isLoading &&
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                    <h3>{Strings.answerLoading}</h3>
                  </div>
                </div>
              }
              {subjectTestData?.activeTest?.status === 'pending' && <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                  <SmallButton btnType="Orange" loading={isLoading} clicked={() => submitQuizResult()}>{Strings.submitAnswers}</SmallButton>
                  </div>
                </div>}
              {subjectTestData?.activeTest?.status === 'completed' && <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                  <SmallButton btnType="Orange" loading={isLoading} clicked={() => emailQuizResult()}>{Strings.viewAnswers}</SmallButton>
                  </div>
                </div>}

            </div>
        </div>
      </div>
      {navigationData.showSubjectQuizResultModal ? <SubjectQuizResult onClosePopUp={() => onClosePopUp()} /> : null}
    </Fragment>
  );
}
