import React, { useEffect, Fragment, useState } from "react";
import { log } from "utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { getUserId, getUserToken, logOutUserFromApp } from "services/AuthService";
import SmallButton from "components/UI/SmallButton";
import { changeUserPassword } from "services/AuthService";
import { Strings } from "resources";
import { useAlert } from "react-alert";
import "./ChangePassword.scss";

export default function ChangePassword(props) {
  const alert = useAlert();
  const history = useHistory();
  const accountData = useSelector((state) => state.accountData);
  const [isLoading, setLoadingState] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  

  /* Get User Account Settings */
  useEffect(() => {
    dispatch(AccountActions.getAccountSettings(userId));
  }, [dispatch, userId]);

  /**
   * saveUserSettings
   * @description Save User Password Settings
   */
  const saveUserSettings = async () => {
    /* Validate Both Passwords */
    if (password !== confirmPassword) {
        alert.error(Strings.passwordsDoNotMatch);
        return false;
    }
    /* Validate Password Chars Length */
    if (password.length < 8) {
        alert.error(Strings.passwordAtLeastSixChars);
        return false;
    }

    setLoadingState(true);
    const { data } = await changeUserPassword(userId, userToken, password, confirmPassword);
    setLoadingState(false);
    if (!data.status) {
      alert.error(Strings[data.message]);
      return log(data.message);
    } else {
      return onAccountSettingsSuccess(data);
    }
  };

  /**
   * onAccountSettingsSuccess
   * @param {*} data
   * @description Save Password Change Success
   */
  const onAccountSettingsSuccess = (data) => {
    alert.success(Strings[data.message]);
    logOutCurrentUser();
  };

  const logOutCurrentUser = async () => {
    const logoutResponse = await logOutUserFromApp();
    if (logoutResponse) {
      goToHome();
    }
  }

  /**
   * goToHome
   * @description Go To Home Page Upon Logo Click
   */
   const goToHome = () => {
    history.push("/");
   }

  return (
    <Fragment>
      <div className="pageScreenContainer dispersionLogo">
        <div className="myPage">
            <div className="myPageWrapper myProfile">

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="pageTitle">
                  <h1>{Strings.changePasswordPageTitle}</h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.pass}</label>
                      <input className="inputField" type="password" value={password} placeholder={Strings.passPlaceHolder} onChange={(e) => { setPassword(e.target.value); }} />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup">
                      <label className="inputTitle">{Strings.confirmPass}</label>
                      <input className="inputField" type="password" value={confirmPassword} placeholder={Strings.confirmPassPlaceHolder} onChange={(e) => { setConfirmPassword(e.target.value); }} />
                      {password !== confirmPassword && <label className="error">{Strings.passwordNotSame}</label>}
                      {password.length !== 0 &&password.length < 8 && <label className="error">{Strings.passwordAtLeast6Chars}</label>}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="formGroup formSubmit">
                    <SmallButton clicked={() => saveUserSettings()} btnType="Orange" loading={isLoading} disabled={password !== confirmPassword || password === '' || password.length < 6}>{Strings.updatePasswordButton}</SmallButton>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
