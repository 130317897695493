import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as AceTestActions from "redux/actions/AceTestActions";
import { Strings } from 'resources';
import ACEPointsRedeemConfirmation from "modals/ACEPointsRedeemConfirmation";
import ACEGameCertificate from "modals/ACEGameCertificate";
import ACEPointsRedeem from "modals/ACEPointsRedeem";
import moment from "moment";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Scoreboard.scss";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import FreeTrialACEGame from "modals/FreeTrialACEGame";
import FreeTrialExpiredACEGame from "modals/FreeTrialExpiredACEGame";
import { log } from "utils";

export default function Scoreboard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { quizId } = useParams();
  const accountData = useSelector((state) => state.accountData);
  const aceTestData = useSelector((state) => state.aceTestData);
  const navigationData = useSelector((state) => state.navigationData);
  const examId = '1';
  const userLanguage = Strings.getLanguage();

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  
  /* Get ACE Game Details */
  useEffect(() => {
     dispatch(AceTestActions.getACEGameLeaderBoard(examId));
  }, [dispatch, quizId]);

  const showFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(true));
  }

  const hideFreeTrialACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialModal(false));
  }

  const showFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(true));
  }

  const hideFreeTrialExpiredACEGameModal = () => {
    dispatch(NavigationActions.toggleFreeTrialExpiredModal(false));
  }

  const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
  }

  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  const playMoreACEGame = () => {
    const status = checkACEGameExpireModal();
    if (status === '0') {
      showFreeTrialExpiredACEGameModal();
    } else if (status === '1') {
      showFreeTrialACEGameModal();
    } else if (status === '2') {
      history.push(`/ace-game-rules`);
    } else if (status === '3') {
      showFeatureNotAvailableModal();
    } else if (status === '4') {
      history.push(`/ace-game-rules`);
    } 
  }

  /**
   * getMonthYear
   * @description Get Month Year Format
   */
  const getMonthYear = () => {
    const date = new Date();
    const formattedDate = moment(date).utc(true).format("MMM YYYY");
    return formattedDate;
  }

  /**
   * levelItemView
   * @param {*} levelItem
   * @param {*} levelIndex
   * @description Get User Level Item View
   */
  const levelItemView = (levelItem, levelIndex) => {
    let className = '';
    switch (levelItem.bagsl_id) {
        case '1':
            className = 'beginnerLevel';
            break;

        case '2':
            className = 'basicLevel';
            break;

        case '3':
            className = 'intermediateLevel';
            break;

        case '4':
            className = 'advanceLevel';
            break;

        case '5':
            className = 'expertLevel';
            break;

        case '6':
            className = 'masterLevel';
            break;

        default:
            className = 'beginnerLevel';
            break;
    }

    const levelTitle = userLanguage === 'hi' ? levelItem.stage_name_hi : levelItem.stage_name_en;

    return (<div className={`level ${className}`} key={`level-status-${levelIndex}`}>
        <div className="studentNo">
            <h4><span className="studentText">{levelItem.stage_name_en}</span>  {levelItem.totalUsers} <span className="studentText">{Strings.students}</span></h4>
        </div>
        <div className="details">
            <div className="levelName">{levelTitle}</div>
            <div className="pointsDesc">
              {+aceTestData?.leaderBoard?.userLevel?.user_level !== +levelItem.bagsl_id && <div className="points">{levelItem.base_range*1000} {Strings.points1}</div>}
              {+aceTestData?.leaderBoard?.userLevel?.user_level === +levelItem.bagsl_id && <div className="student">
                <FontAwesomeIcon icon={faUser} size="lg" className="User" /><span className="name">{aceTestData?.leaderBoard?.userDetail?.first_name}</span>
              </div>}
              <div className="price">₹ {levelItem.scholarship_amount}</div>
            </div>
        </div>
    </div>);
  }

  /**
   * onGoBackModal
   * @description Close Redeem Confirmation Modal
   */
  const onGoBackModal = () => {
    dispatch(NavigationActions.toggleACEGameRedeemConfirmationModal(false));
  }

  /**
   * onGoBackRedeemModal
   * @description Close Redeem Modal
   */
  const onGoBackRedeemModal = () => {
    dispatch(NavigationActions.toggleACEGameRedeemModal(false));
  }

  /**
   * redeemACEPointsScholarship
   * @description ACE Game Redeem Confirmation Modal
   */
  const redeemACEPointsScholarship = () => {
    const status = checkACEGameExpireModal();
    if (status === '4') {
      dispatch(NavigationActions.toggleACEGameRedeemConfirmationModal(true));
    } else {
      dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
    }
  }

  /**
   * proceedRedemption
   * @description Redeem Confirmation Modal Close and Redeem Modal Opens
   */
  const proceedRedemption = () => {
    dispatch(NavigationActions.toggleACEGameRedeemConfirmationModal(false));
    dispatch(NavigationActions.toggleACEGameRedeemModal(true));
  }

  const hideFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
  }

  /**
   * submitRedemption
   * @description Submit Redemption Modal Opens
   */
  const submitRedemption = () => {

  }

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage ScoreBoard">
            <div className="titleRow">
                <h1>{Strings.scoreBoard}</h1>
                <div className="actionLinks">
                    <span className="playLink" onClick={() => playMoreACEGame()}>{Strings.playMoreText}</span>
                    <span className="redeemLink" onClick={() => redeemACEPointsScholarship()}>{Strings.redeem}</span>
                </div>
            </div>
            <div className="myPageWrapper LeaderBoard">
                <section className="pointsSection">
                    <div className="custom-container">
                        <div className="title">
                            <h3>{Strings.levelWiseStudents} {getMonthYear()}</h3>
                        </div>
                        {aceTestData?.leaderBoard?.leaderboard?.length > 0 &&
                            <div className="pointsArea">
                                {aceTestData?.leaderBoard?.leaderboard?.map((levelItem, levelIndex) => levelItemView(levelItem, levelIndex))}
                            </div>
                        }
                    </div>
                </section>
            </div>
        </div>
      </div>
      {navigationData.showACEPointsRedeemConfirmationModal ? <ACEPointsRedeemConfirmation onGoBack={() => onGoBackModal()} onCancelRedeem={() => onGoBackModal()} onProceedRedeem={() => proceedRedemption()} /> : null}
      {navigationData.showACEPointsRedeemModal ? <ACEPointsRedeem onGoBack={() => onGoBackRedeemModal()} onCancelRedeem={() => onGoBackRedeemModal()} onProceedRedeem={() => submitRedemption()} /> : null}
      {navigationData.showACEPointsCertificateModal ? <ACEGameCertificate /> : null}
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
      {navigationData.showFreeTrialModal ? <FreeTrialACEGame onGoBack={() => hideFreeTrialACEGameModal()} /> : null}
      {navigationData.showFreeTrialExpiredModal ? <FreeTrialExpiredACEGame onGoBack={() => hideFreeTrialExpiredACEGameModal()} /> : null}
    </Fragment>
  );
}
