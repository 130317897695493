import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as LibraryActions from "redux/actions/LibraryActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import { getUserToken } from "services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PDFIcon from "assets/images/page/pdf.svg";
import apiConfig from "config/api";
import appConfig from "config/config";
import { Strings } from 'resources';
import SmallButton from "components/UI/SmallButton";
import FeatureNotAvailable from "modals/FeatureNotAvailable";
import { log } from "utils";
import "./ExamTypes.scss";

export default function ExamTypes(props) {
  const navigationData = useSelector((state) => state.navigationData);
  const accountData = useSelector((state) => state.accountData);
  const libraryData = useSelector((state) => state.libraryData);
  const dispatch = useDispatch();
  const history = useHistory();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const { category, type } = useParams();


  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);

  /* Get Library Files */
  useEffect(() => {
     dispatch(LibraryActions.getLibraryFilesData(true, 1, category, type, userToken ));
  }, [dispatch, userToken, category, type]);

  const checkACEGameExpireModal = () => {
    let status = '0';
    const valExamId = accountData?.status?.exam;
    const valGroupId = accountData?.status?.group;
    const isValid = accountData?.status?.valid;
    if (valExamId === '1' && valGroupId === '1' && isValid) {
      status = '1';
    } else if (valExamId === '1' && valGroupId === '2' && isValid) {
      status = '2';
    } else if (valExamId === '1' && valGroupId === '3' && isValid) {
      status = '4';
    } else if (valExamId === '1' && valGroupId !== '1' && !isValid) {
      status = '3';
    }
    return status;
  }

  /**
   * fetchMoreData
   * @description Fetch More Data
   */
  const fetchMoreData = () => {
    dispatch(LibraryActions.getLibraryFilesData(false, libraryData.page, category, type, userToken));
  }

/**
 * downloadLibraryFile
 * @param {string} fileUrl
 * @param {string} fileName
 * @description Download Library File
 */
const downloadLibraryFile = (fileUrl, fileName) => {
    const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadFile + "?file="+fileUrl;
    fetch(fileDownloadUrl)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
            //window.location.href = response.url;
    });
}

const viewLibraryFilePDF = (fileTitle, fileUrl, fileName) => {
    const status = checkACEGameExpireModal();
    if (status === '4') {
        const fileDownloadUrl = appConfig.apiUrl + apiConfig.file.downloadFile + "?file="+fileUrl;
        history.push({
          pathname: '/library-file-view/',
          state: { fileTitle: fileTitle, fileName: fileName, fileUrl: fileDownloadUrl }
        });
    } else {
        showFeatureNotAvailableModal();
    }
}

const showFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(true));
}

const hideFeatureNotAvailableModal = () => {
    dispatch(NavigationActions.toggleFeatureNotAvailableModal(false));
}

/**
 * libraryFileItem
 * @param {object} item
 * @param {number} index
 * @description Library Files Individual Item
 */
const libraryFileItem = (item, index) => {
    const fileTitle = userLanguage === 'hi' ? item.ltitle_hindi : item.ltitle ;
    const fileUrl = userLanguage === 'hi' && item.lfile_hindi !== '' ? item.lfile_hindi : item.lfile ;
    return (<div className="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 fileItemWrapper" key={`file-${index}`}>
        <div className="fileItem">
            <div className="fileThumb">
                <img src={PDFIcon} alt="File" title="File" />
            </div>
            <div className="fileDesc">
                <span className="titleName" onClick={() => viewLibraryFilePDF(fileTitle, fileUrl,'file.pdf')}>{fileTitle}</span>
                <span className="icon" onClick={() => viewLibraryFilePDF(fileTitle, fileUrl,'file.pdf')}><FontAwesomeIcon icon={faCloudDownloadAlt} size="lg" className="download" /></span>
            </div>
        </div>
    </div>);
}

const goToLibrary = () => {
  history.goBack();
}

  return (
    <Fragment>
      <div className="pageScreenContainer noBg">
        <div className="myPage">
            <div className="pageTitle">
              <h1><span className="goBackViewFile" onClick={() => goToLibrary()}><FontAwesomeIcon icon={faArrowLeft} size="lg" className="closeDrawerIcon" /></span>{Strings.library}</h1>
            </div>
            <div className="myPageWrapper myLibrary">
                {libraryData.items.length === 0 && !libraryData.isLoading &&
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                            <h3>{Strings.no_files_found}</h3>
                        </div>
                    </div>
                }
                {libraryData.items.length === 0 && libraryData.isLoading &&
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                            <h3>{Strings.paymentHistoryLoading}</h3>
                        </div>
                    </div>
                }
                {libraryData.items.length > 0 &&
                    <div className="row">
                        {libraryData.items.map((item, index) => libraryFileItem(item, index))}
                    </div>
                }
                {libraryData.items.length > 0 && libraryData.hasMore && <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 loadMore">
                        <SmallButton clicked={() => fetchMoreData()} loading={libraryData.isLoading} btnType="Orange">{Strings.loadMore}</SmallButton>
                    </div>
                </div>}
            </div>
        </div>
      </div>
      {navigationData.showFeatureNotAvailableModal ? <FeatureNotAvailable onGoBack={() => hideFeatureNotAvailableModal()} /> : null}
    </Fragment>
  );
}
