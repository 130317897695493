import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { log } from "utils";
import moment from "moment";
import { Strings } from "resources";
import "./KnowledgeNotification.scss";

export default function KnowledgeNotification(props) {

    const index = props.index;
    const item = props.itemData;
    const accountData = useSelector((state) => state.accountData);

    /* Handle Site Language */
    useEffect(() => {
        log('Site Language Changed');
        return () => log('Site Language Unmounted...');
    }, [accountData.language]); 

    const getNotificationDate = (date) => {
        const formattedDate = moment(date).utc(true).format("Do MMM YYYY");
        return formattedDate;
    }
 
    return (<div className="notification-area notifCard" key={`notif-item-${index}`}>
                <h5>{getNotificationDate(item?.date)}</h5>
                <div className="flex-notification">
                    <div className="notificationItem">
                        <h4>{Strings.notifWOD}</h4>
                        <div className="notification-content" dangerouslySetInnerHTML={{__html : item?.word_of_the_day}}></div>
                    </div>
                    <div className="notificationItem">
                        <h4>{Strings.notifCF}</h4>
                        <div className="notification-content" dangerouslySetInnerHTML={{__html : item?.current_affairs}}></div>
                    </div>
                    <div className="notificationItem">
                        <h4>{Strings.notifN}</h4>
                        <div className="notification-content" dangerouslySetInnerHTML={{__html : item?.content}}></div>
                    </div>
                </div>
            </div>);
}
