import React, { useRef, useEffect, useState } from "react";

import { useHistory, Link } from "react-router-dom";
import { Strings } from 'resources';
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import * as CourseActions from "redux/actions/CourseAction";
import Header from 'components/Header';
import { getNotification as getNotificationAPI } from "services/AuthService";
import Footer from 'components/Footer';
import moment from 'moment';
import { isUserLoggedIn } from "services/AuthService";
import { openInNewTab } from "utils/helpers";
import Login from "modals/Login";
import ForgotPassword from "modals/ForgotPassword";
import ResetPassword from "modals/ResetPassword";
import ActivateUser from "modals/ActivateUser";
import SignUp from "modals/SignUp";
import { log } from "utils";
import KnowledgeNotification from "components/KnowledgeNotification";
import DashboardImage from 'assets/images/v2/landing/dashboard.png';
import QuillItImage from 'assets/images/v2/landing/quillit.png';
import CertificateImage from 'assets/images/v2/landing/certificate.png';
import ScoreboardImage from 'assets/images/v2/landing/scoreboard.png';
import HeatMapImage from 'assets/images/v2/landing/heatmap.png';
import LibraryImage from 'assets/images/v2/landing/b6.svg';
import AccuracyWormImage from 'assets/images/v2/landing/accuracy_worm.png';
import AceGameImage from 'assets/images/v2/landing/acegame.png';

//exam category page
import './LandingPage.scss'
import banner from "assets/images/exam-category/hdr.jpg"


import greenCheckBoxImage from 'assets/images/v2/landing/greenCheckBox.svg';
import greenMaskImage from 'assets/images/v2/landing/greenMask.svg';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

import RegisteredUsers from 'assets/images/landing/registeredUsers.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

gsap.registerPlugin(Draggable);
gsap.config({
  nullTargetWarn: false
});

export default function LandingPage(props) {
  const [list, setlist] = useState("all");
  const { user } = isUserLoggedIn();
  const accountData = useSelector((state) => state.accountData);
  const navigationData = useSelector((state) => state.navigationData);
  const { status } = isUserLoggedIn();
  const dispatch = useDispatch();
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState({});
  const [isRedirect, setIsRedirect] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const sliderRef = useRef(null);


  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);




  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);



  const AllList = () => {
    return (
      <>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="cgl_tier text-center px-5 mt-4 pb-0">

            <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier I</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier II</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier III</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
        </div>
      </>
    )
  }

  const Chls = () => {
    return (
      <>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="cgl_tier text-center px-5 mt-4 pb-0">

            <h5 class="text-uppercase">Chsl <br /> <span class="text-capitalize">tier I</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">Chsl <br /> <span class="text-capitalize">tier II</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">Chsl  <br /> <span class="text-capitalize">tier III</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
        </div>
      </>
    )
  }

  const mts = () => {
    return (
      <>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="cgl_tier text-center px-5 mt-4 pb-0">

            <h5 class="text-uppercase">mts <br /> <span class="text-capitalize">tier I</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">mts <br /> <span class="text-capitalize">tier II</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">mts  <br /> <span class="text-capitalize">tier III</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
        </div>
      </>
    )
  }


  const cpo = () => {
    return (
      <>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="cgl_tier text-center px-5 mt-4 pb-0">

            <h5 class="text-uppercase">cpo <br /> <span class="text-capitalize">tier I</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">cpo <br /> <span class="text-capitalize">tier II</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-5 mt-4 pb-0">
            <h5 class="text-uppercase">cpo  <br /> <span class="text-capitalize">tier III</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
        </div>
      </>
    )
  }

  const Gdcontable = () => {
    return (
      <div className="d-flex flex-wrap justify-content-between">
      <div className="cgl_tier text-center px-4 mt-4 pb-0">

        <h5 class="text-uppercase">GD CONSTABLE <br /> <span class="text-capitalize">tier I</span></h5>
        <p>5 past paper</p>
        <p>20 Mock Tests</p>
      </div>
      <div className="cgl_tier text-center px-4 mt-4 pb-0">
        <h5 class="text-uppercase">GD CONSTABLE <br /> <span class="text-capitalize">tier II</span></h5>
        <p>5 past paper</p>
        <p>20 Mock Tests</p>
      </div>
      <div className="cgl_tier text-center px-4 mt-4 pb-0">
        <h5 class="text-uppercase">GD CONSTABLE  <br /> <span class="text-capitalize">tier III</span></h5>
        <p>5 past paper</p>
        <p>20 Mock Tests</p>
      </div>
    </div>
  )
  }

  const Stenographer = () =>{
         return(
          <div className="d-flex flex-wrap justify-content-between">
          <div className="cgl_tier text-center px-4 mt-4 pb-0">
    
            <h5 class="text-uppercase">STENOGRAPHER <br /> <span class="text-capitalize">tier I</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-4 mt-4 pb-0">
            <h5 class="text-uppercase">STENOGRAPHERE <br /> <span class="text-capitalize">tier II</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
          <div className="cgl_tier text-center px-4 mt-4 pb-0">
            <h5 class="text-uppercase">STENOGRAPHER  <br /> <span class="text-capitalize">tier III</span></h5>
            <p>5 past paper</p>
            <p>20 Mock Tests</p>
          </div>
        </div>
         )  
  }
  const notificationItemView = (item, index) => {
    return (<KnowledgeNotification key={`notif-item-${index}`} itemData={item} index={index} />);
  }



  return (

    <>
      <div className="container-fluid">
        <img src={banner} className="banners" alt="banner" />
        
      </div>
      <div className="container">
        <div className="flex flex-row flex-wrap landing_page ">
          <div className="row">
            <div className="col-12 col-sm-10 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="getstart">
                <h1>Get started with... <br />
                  complete package...
                </h1>


              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
              <div class="prents">
                <div class="child">
                  <h5>cgl <br /> Exams</h5>
                  <p>8 Course</p>
                  <p>20 free test</p>
                </div>
                <div class="child">
                  <h5>mts <br /> Exams</h5>
                  <p>8 Course</p>
                  <p>20 free test</p>
                </div>
                <div class="child">
                  <h5>gd <br /> constable</h5>
                  <p>8 Course</p>
                  <p>20 free test</p>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div>

        </div>

      </div>

      <div className="container-fluid">
        <div className="flex flex-row flex-wrap  justify-content-around ">
          <div className="Landingpage_para">
            <p>from any of these exam at an introductory prices offered at 75%
              discount.Each package shall offer both Prelims and Mains, as
              applicable. and will give you not just past papers but full access to
              bharatCET's designed mock tests with subject wise analytics.</p>
            <p>Select any exams to explore</p>
          </div>
          <div class="prents">
            <div class="child">
              <h5>chsl <br /> Exams</h5>
              <p>8 Course</p>
              <p>20 free test</p>
            </div>
            <div class="child">
              <h5>cpo <br /> Exams</h5>
              <p>8 Course</p>
              <p>20 free test</p>
            </div>
            <div class="child">
              <h5>stenographer <br /> exams</h5>
              <p>8 Course</p>
              <p>20 free test</p>
            </div>

          </div>
        </div>

        <div>

        </div>

      </div>
      <div className="container-fluid ">
        <div className="tiers">
          <div className="container row">
            <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8">
              <div className="tier">
                  <ul className="tier_list">
                    <li className={`item ${list === 'all' ? 'active' : ''}`} onClick={() => setlist('all')}><span>cgl</span></li>
                    <li className={`item ${list === 'chsl' ? 'active' : ''}`} onClick={() => setlist('chsl')}><span>chsl</span></li>
                    <li className={`item ${list === 'mts' ? 'active' : ''}`} onClick={() => setlist('mts')}><span>mts</span></li>
                    <li className={`item ${list === 'cpo' ? 'active' : ''}`} onClick={() => setlist('cpo')}><span>cpo</span></li>
                    <li className={`item ${list === 'gd_contable' ? 'active' : ''}`} onClick={() => setlist('gd_contable')}><span>gd constable</span></li>
                    <li className={`item ${list === 'stenographer' ? 'active' : ''}`} onClick={() => setlist('stenographer')}><span>stenographer</span></li>

                  </ul>
         
              </div>
              <div >
                {list === 'all' && AllList()}
                {list === 'chsl' && Chls()}
                {list === 'mts' && mts()}
                {list === 'cpo' && cpo()}
                {list === 'gd_contable' && Gdcontable()}
                {list  === 'stenographer' && Stenographer()}
                {/* <div className="cgl_tier text-center px-5 mt-4 pb-0">
                  <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier I</span></h5>
                   <p>5 past paper</p>
                  <p>20 Mock Tests</p>
                </div> */}
                {/* <div className="cgl_tier text-center px-5 mt-4 pb-0">
                  <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier II</span></h5>
                  <p>5 past paper</p>
                  <p>20 Mock Tests</p>
                </div> */}
                {/* <div className="cgl_tier text-center px-5 mt-4 pb-0">
                  <h5 class="text-uppercase">cgl <br /> <span class="text-capitalize">tier III</span></h5>
                  <p>5 past paper</p>
                  <p>20 Mock Tests</p>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-end align-items-center">
              <div className="specific ">
                <h1 className="">...specific exams</h1>
                <p className="">if you wish to excel by preparing for specific exams, you may subscribe to those specific tests containing past question papers and BharatCET's designed mock tests that will help you succeed.</p>
                <p>Click and select any exam for only ₹75 each </p>
                <button type="button" class="btn text-white font-weight-bold">unlock</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row subject">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="row mx-auto">
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"><h1>Subject Wise <br/> Learning</h1></div>
              <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 subject_para">Access to 20,000 questions across 90+ sub topics with answers, detailed analysis about accuracy that will help you strengthen your conceptual understanding and Prepare you to Excel</div>
            </div>
            <div className="row mt-2">
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 logical_Reas ">
                <h4>Logical Reasoning</h4>
                <p>Major part of government exams and carries more scoring sections covering both verbal and non - verbal reasoning.</p>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 logical_Reas">
                <h4>Quantitative Aptitude</h4>
                <p>This tests the analytical, mathematical and problem solving skills of students and practice can make one perfect.</p>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 logical_Reas">
                <h4>Verbal</h4>
                <p>English is a language that is widely used in banking and financial sector especially after adoption of technology. Reading and interpreting skills are tested in exams.</p>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 logical_Reas ">
                <h4>General Awareness</h4>
                <p>Worldly knowledge is a sign of a well read professional and bank and financial institution respect professions with strong general awareness.</p>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 logical_Reas">
                <p>Click and subscribe for only ₹250*</p>
                <button type="button" class="btn text-white font-weight-bold px-4">unlock</button>
              </div>
            </div>

          </div>

        </div>
      </div>

    </>
  );

}
