import React, { useEffect, Fragment, useState, useRef } from "react";
import { log } from "utils";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as ChatActions from "redux/actions/ChatActions";
import * as NavigationActions from "redux/actions/NavigationActions";
import { getUserId, getUserToken } from "services/AuthService";
import { submitImageQuestion, submitImageComment } from "services/ChatService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faFlag, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { useAlert } from "react-alert";
import { Strings } from 'resources';
import { ltrim } from "utils/helpers";
import { parseChat } from "utils/chat";
import quillIcon from "assets/images/page/quillIcon.svg";
import moment from 'moment';
import MultiRef from 'react-multi-ref';
import ReportMessage from "modals/ReportMessage";
import ReportUser from "modals/ReportUser";
import ReportCommentUser from "modals/ReportCommentUser";
import ReportComment from "modals/ReportComment";
import "./QuillIt.scss";

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ behavior: "smooth" }));
    return <div ref={elementRef} />;
};

export default function QuillIt(props) {
  const alert = useAlert();
  const chatData = useSelector((state) => state.chatData);
  const accountData = useSelector((state) => state.accountData);
  const navigationData = useSelector((state) => state.navigationData);
  const dispatch = useDispatch();
  const userId = getUserId();
  const userToken = getUserToken();
  const userLanguage = Strings.getLanguage();
  const history = useHistory();
  const [message, setMessage] = useState('');
  const hiddenFileInput = useRef(null);
  const chatListRef = useRef(null);
  const commentFileRefs = new MultiRef();
  const [chatImage, setChatImage] = useState('');
  const [scrollDir, setScrollDir] = useState('down');
  const [reportItem, setReportItem] = useState({});

  /* Handle Site Language */
  useEffect(() => {
    log('Site Language Changed');
    return () => log('Site Language Unmounted...');
  }, [accountData.language]);
  
  useEffect(() => {
    const threshold = 0;
    const chatBox = document.getElementById('chatList');
    let lastScrollY = chatBox.scrollTop;
    let ticking = false;

    const isTop = (el) => {
      const lastScrollY1 = el.scrollTop;
      return lastScrollY1 <= threshold;
    }

    const updateScrollDir = () => {
      if (isTop(chatBox)) {
        if (scrollDir === 'up'){
          if (chatData?.hasMore) {
            dispatch(ChatActions.getChatMessages(false));
          }
        }
      }

      const scrollY = chatBox.scrollTop;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    chatBox.addEventListener("scroll", onScroll);

    return () => chatBox.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  /**
   * handleCommentClick
   * @param {*} event
   * @param {*} item
   * @description Handle Comment Click
   */
  const handleCommentClick = (event, item) => {
    commentFileRefs.map.get(item.id).click();
  }

  /**
   * onCommentFileChange
   * @param {*} event
   * @param {*} item
   * @description On Comment File Change Event
   */
  const onCommentFileChange = (event, item) => {
    if (event.target.files && event.target.files[0]) {

        /* Check for Image Validation */
        if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
            alert.error('Uploaded file is invalid');
            return false;
        }

        /* Max 2 MB */
        const maxSize = '2048';
        const fSize = event.target.files[0].size/1024;
        if(fSize > maxSize) {
            alert.error('File size should not exceed 2 MB');
            return false;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          setChatImage(e.target.result);
        };
        uploadQuestionCommentImage(event.target.files[0], item);
        reader.readAsDataURL(event.target.files[0]);
    }
  }

  /**
   * uploadQuestionCommentImage
   * @param {*} selectedFileDetails
   * @param {*} item
   * @description Upload Question Comment Image
   */
  const uploadQuestionCommentImage = async (selectedFileDetails, item) => {
    const { data, error } = await submitImageComment(userId, item.id, '', 'IMAGE', selectedFileDetails);
    if (error) {
      alert.error(error);
      return log(error);
    } else {
        const chatId = data.id;
        const chatType = data.messageType;
        const chatDetails = parseChat(data, chatData?.bannedUsers);
        dispatch(ChatActions.updateChatMessage(chatId, chatDetails, chatType));
        dispatch(ChatActions.updateChatComment(userId, '', chatType, chatId));
        setChatImage('');
        setMessage('');
        scrollToBottom();
      return false;
    }
  }

  /**
   * handleClick
   * @param {*} event
   * @description Handle Click of Upload Image Button
   */
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  /**
   * onFileChange
   * @param {*} event
   * @description On Question Image Upload Event
   */
  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
        /* Check for Image Validation */
        if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
            alert.error('Uploaded file is invalid');
            return false;
        }

        /* Max 2 MB */
        const maxSize = '2048';
        const fSize = event.target.files[0].size/1024;
        if(fSize > maxSize) {
            alert.error('File size should not exceed 2 MB');
            return false;
        }

      let reader = new FileReader();
      reader.onload = (e) => {
        setChatImage(e.target.result);
      };
      uploadQuestionImage(event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  /**
   * uploadQuestionImage
   * @param {*} selectedFileDetails
   * @description Upload Question Image
   */
  const uploadQuestionImage = async (selectedFileDetails) => {
    const { data, error } = await submitImageQuestion(userId, '', 'IMAGE', selectedFileDetails);
    if (error) {
      alert.error(error);
      return log(error);
    } else {
        const chatId = data.id;
        const chatType = data.messageType;
        const index = -1;
        const chatDetails = data;
        dispatch(ChatActions.addChatMessage(index, chatDetails, chatType, chatId));
        setChatImage('');
        setMessage('');
        scrollToBottom();
        return false;
    }
  }

  /**
   * onMessageChange
   * @param {object} event
   * @description on Main Message Change Event
   */
  const onMessageChange = (event) => {
    setMessage(event.target.value);
  }

  /**
   * handleKeyDown
   * @param {object} event
   * @description On Main Message Handle Key Down
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        if (message !== '') {
            submitQuestion();
        }
      }
  }

  /**
   * submitQuestion
   * @description Main Question Submit
   */
  const submitQuestion = () => {
    const chatMessage = ltrim(message);
    dispatch(ChatActions.submitChatMessage(userId, chatMessage, 'text'));
    setMessage('');
    scrollToBottom();
  }

  /**
   * scrollToBottom
   * @description Scroll to Bottom Section in the Chat Messages Part
   */
  const scrollToBottom = () => {
    if (chatListRef) {
        chatListRef.current.addEventListener('DOMNodeInserted', event => {
          const { currentTarget: target } = event;
          target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
        });
      }
  };

  /**
   * handleMessageLikeStatus
   * @param {*} item
   * @param {*} chatLikeStatus
   * @description Handle Chat Question Like Unlike Status
   */
  const handleMessageLikeStatus = (item, chatLikeStatus) => {
      if (chatLikeStatus) {
        dispatch(ChatActions.UnlikeQuestion(userId, item.type, item.name, item.id));
      } else {
        dispatch(ChatActions.LikeQuestion(userId, item.type, item.name, item.id));
      }
  }

  /**
   * onCommentChange
   * @param {*} event
   * @param {*} item
   * @description On Comment Change Action
   */
  const onCommentChange = (event, item) => {
    dispatch(ChatActions.updateChatComment(userId, event.target.value, 'text', item.id));
  }

  /**
   * handleCommentKeyDown
   * @param {*} event
   * @param {*} item
   * @description Handle Comment Key Down
   */
  const handleCommentKeyDown = (event, item) => {
    if (event.key === 'Enter') {
        if (item.inputComment !== '') {
            dispatch(ChatActions.commentChatMessageText(userId, item.id, event.target.value, 'text'));
        }
    }
  }

  /**
   * chatItem
   * @param {*} item
   * @param {*} index
   * @description Show Chat Item
   */
  const chatItem = (item, index) => {
    const messageDateTime = moment(item.createdDate).format('DD MMM hh:mm:ss a');
    let className = 'messageItem';
    let isMine = false;
    if (+userId == +item.senderUserId) {
      className = 'messageItem myItem';
      isMine = true;
    }

    if (!item.hidden) {
      return (<div className={className} key={`chat-${index}`}>
          <div className="messageItemHeader">
              <div className="mIUserName">
                  <span>{item?.senderUser?.firstName + " " + item?.senderUser?.lastName}</span>
              </div>
              <div className="mILikes">
                  {!isMine && false && !+accountData?.accountSettings?.is_banned && <span className={`mIUserBanIcon`} onClick={() => showReportUserModal(item)}><FontAwesomeIcon icon={faUserSlash} size="lg" className="userBanIcon" /></span>}
                  {!isMine && false && !+accountData?.accountSettings?.is_banned && <span className={`mIFlagIcon`} onClick={() => showReportModal(item)}><FontAwesomeIcon icon={faFlag} size="lg" className="flagIcon" /></span>}
                  <span className="mICount">{messageDateTime}</span>
                  <span className="mICount">{item.totalLikedBy}</span>
                  <span className={`mILikeIcon ${item.liked ? 'Liked': ''}`} onClick={() => handleMessageLikeStatus(item, item.liked)}><FontAwesomeIcon icon={faThumbsUp} size="lg" className="likeIcon" /></span>
              </div>
          </div>
          <div className="messageItemContent">
              {item.type === "text" && <p>{item.name}</p>}
              {item.type === "IMAGE" && <img src={item.name} alt={item?.senderUser?.firstName} title={item?.senderUser?.firstName} />}
          </div>
          <div className="messageResponses">
              {item.comments.length > 0 &&<ul>
                  {item.comments.map((item, index) => chatCommentItem(item, index))}
              </ul>}
          </div>
          {!+accountData?.accountSettings?.is_banned && <div className="messageReplyInput">
              <input type="text" className="chatInputElement" placeholder="Quill your point and press ENTER" value={item.inputComment} onChange={(event) => onCommentChange(event, item)} onKeyDown={(event) => handleCommentKeyDown(event, item)} />
              <span className="SelectImage" onClick={(event) => handleCommentClick(event, item)}><img src={quillIcon} className="quillItIcon" alt="QuillIt" title="QuillIt" /></span>
              <input type="file" accept="image/*" ref={commentFileRefs.ref(item.id)} style={{ display:'none' }} onChange={(event) => onCommentFileChange(event, item)} />
          </div>}
      </div>);
    } else {
      return (null);
    }
  }

    /**
     * chatCommentItem
     * @param {*} item
     * @param {*} index
     * @description Show Chat Comment Item Details
     */
    const chatCommentItem = (item, index) => {
      let isMine = false;
      if (+userId == +item?.senderUser?.uid) {
        isMine = true;
      }

      if (!item.hidden) {
        return (<li className="commentItem" key={`comment-${item.id}-${index}`}>
          <div className="mICommentContent">
            <span>{showTruncatedUserName(item?.senderUser?.firstName + " " + item?.senderUser?.lastName, 15)} :</span>
            {item.type === "TEXT" && item.name}
            {item.type === "IMAGE" && <img src={item.name} alt={item?.senderUser?.firstName} title={item?.senderUser?.firstName} />}
          </div>
          <div className="mIReportContent">
            {!isMine && false && !+accountData?.accountSettings?.is_banned && <span className={`mIUserBanIcon`} onClick={() => showReportCommentUserModal(item)}><FontAwesomeIcon icon={faUserSlash} size="lg" className="userBanIcon" /></span>}
            {!isMine && false && !+accountData?.accountSettings?.is_banned && <span className={`mIFlagIcon`} onClick={() => showReportCommentModal(item)}><FontAwesomeIcon icon={faFlag} size="lg" className="flagIcon" /></span>}
          </div>
        </li>);
      } else {
        return (null);
      }
    }

    const showTruncatedUserName = (string, n) => {
      if (string === '') {
        return '';
      }
      return string.slice(0,n);
    }

    const showReportModal = (item) => {
      setReportItem(item);
      dispatch(NavigationActions.toggleReportMessageModal(true));
    }
  
    const hideReportModal = () => {
      setReportItem({});
      dispatch(NavigationActions.toggleReportMessageModal(false));
    }

    const showReportUserModal = (item) => {
      setReportItem(item);
      dispatch(NavigationActions.toggleReportUserModal(true));
    }
  
    const hideReportUserModal = () => {
      setReportItem({});
      dispatch(NavigationActions.toggleReportUserModal(false));
    }

    const showReportCommentModal = (item) => {
      setReportItem(item);
      dispatch(NavigationActions.toggleReportCommentModal(true));
    }
  
    const hideReportCommentModal = () => {
      setReportItem({});
      dispatch(NavigationActions.toggleReportCommentModal(false));
    }

    const showReportCommentUserModal = (item) => {
      setReportItem(item);
      dispatch(NavigationActions.toggleReportCommentUserModal(true));
    }
  
    const hideReportCommentUserModal = () => {
      setReportItem({});
      dispatch(NavigationActions.toggleReportCommentUserModal(false));
    }

  return (
    <Fragment>
      <div className="pageScreenContainer logoBg noPadding">
        <div className="chatPage">
            <div className="pageTitle">
              <h1>{Strings.quillItTitle}</h1>
            </div>
            <div className="chatContainer">
                <div className="chatMessages" id="chatList" ref={chatListRef}>
                    {props.webSocketStatus === 'CONNECTED' && chatData.items.length > 0 &&
                        chatData.items.map((item, index) => chatItem(item, index))}
                    {props.webSocketStatus !== 'CONNECTED' && <span>{props.webSocketTitle}</span>}
                    {/*<AlwaysScrollToBottom />*/}
                </div>
                {!+accountData?.accountSettings?.is_banned && <div className="messageSubmit">
                    <input type="text" className="chatInputElement" placeholder="Quill your question and press ENTER" value={message} onChange={(event) => onMessageChange(event)} onKeyDown={(event) => handleKeyDown(event)} />
                    <span className="SelectImage" onClick={(event) => handleClick(event)}><img src={quillIcon} className="quillItIcon" alt={Strings.quillIt} title={Strings.quillIt} /></span>
                    <input type="file" accept="image/*" ref={hiddenFileInput} style={{ display:'none' }} onChange={(event) => onFileChange(event)} />
                </div>}
            </div>
        </div>
      </div>
      {navigationData.showReportMessage ? <ReportMessage item={reportItem} onGoBack={() => hideReportModal()} /> : null}
      {navigationData.showReportUser ? <ReportUser item={reportItem} onGoBack={() => hideReportUserModal()} /> : null}
      {navigationData.showReportComment ? <ReportComment item={reportItem} onGoBack={() => hideReportCommentModal()} /> : null}
      {navigationData.showReportCommentUser ? <ReportCommentUser item={reportItem} onGoBack={() => hideReportCommentUserModal()} /> : null}
    </Fragment>
  );
}
