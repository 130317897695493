import * as actions from "redux/action-types";
import {
    submitQuestion as submitQuestionFromAPI,
    replyQuestion as replyQuestionFromAPI,
    fetchPaginatedQuestions as fetchPaginatedQuestionsFromAPI,
    submitTextComment as submitTextCommentFromAPI,
    LikeQuestion as LikeQuestionFromAPI,
    UnlikeQuestion as UnlikeQuestionFromAPI,
    updateUserDetails as updateUserDetailsFromAPI
} from "services/ChatService";
import {
    isUserLoggedIn,
    getUserAccountSettings as getUserAccountSettingsFromAPI,
  } from "services/AuthService";
import { Strings } from "resources";
import { CancelToken } from "axios";
import { parseChatMessages, parseChat, parseChatComment } from "utils/chat";
import { log } from "utils";
import { getUserId } from "services/AuthService";

/**
 * setChatLoadingState
 * @param {boolean} isLoading
 * @description Set Loading State
 */
export function setChatLoadingState(isLoading) {
    return {
        type: actions.LOADING_CHAT_DATA,
        isLoading,
    };
}

/**
 * addChatData
 * @param {array} items
 * @param {boolean} hasMore
 * @param {number} page
 * @description Add Chat Messages into State
 */
export function addChatData(items, hasMore, page) {
    return {
        type: actions.ADD_CHAT_DATA,
        items,
        hasMore,
        page,
    };
}

/**
 * setChatCancelToken
 * @param {string} token
 * @description Handle Cancel Token
 */
export function setChatCancelToken(token) {
    return {
        type: actions.SET_CHAT_CANCEL_TOKEN,
        token,
    };
}

/**
 * hasNoMoreChat
 * @description check If more chat messages are there or not
 */
export function hasNoMoreChat() {
    return {
        type: actions.SET_CHAT_HAS_NO_MORE,
    };
}

/**
 * getChatMessages
 * @param {boolean} reset
 * @description Get Chat Messages
 */
export function getChatMessages(reset) {
    return async (dispatch, getState) => {
        if (reset) {
            let state = await getState();
            let request = state.chatData.cancelRequest;
            request.cancel(Strings.operation_canceled_by_user);
            const newRequestSource = CancelToken.source();
            await dispatch(setChatCancelToken(newRequestSource));
            await dispatch(addChatData([], true, 0));
        }

        await dispatch(setChatLoadingState(true));

        let { chatData } = await getState();
        let { page, limit, items, bannedUsers } = chatData;
        const userId = getUserId();
        const { data, error } = await fetchPaginatedQuestionsFromAPI(userId, page, limit);
        if (!error && Array.isArray(data) && data.length) {
            dispatch(addChatData(mergeData(items, parseChatMessages(data, bannedUsers)), true, page + 1));
            if (data.length < limit) {
                dispatch(hasNoMoreChat());
            }
        } else {
            dispatch(hasNoMoreChat());
        }
        dispatch(setChatLoadingState(false));
    };
}

/**
 * mergeData
 * @param {*} oldData object
 * @param {*} newData object
 */
function mergeData(oldData, newData) {
    newData.forEach((newDataItem, newDataIndex) => {
        if (newDataItem.id) {
            let index = oldData.findIndex(data => (data.id && data.id === newDataItem.id));
            if (index === -1) {
                oldData.unshift(newDataItem);
            }
        }
    });
    return oldData;
}

export function setWebsocketStatus(status) {
  let action= actions.SET_CHAT_WEBSOCKET_STATUS;
  return {
    type: action,
    status,
  };
}

export function replyChatMessage(userId, messageText, replyingToMessageId) {
    return async (dispatch, getState) => {
        const { data, error } = await replyQuestionFromAPI(userId, messageText);
        if (!error) {
            log(data);
        }
    };
}

/**
 * submitChatMessage
 * @param {*} userId
 * @param {*} messageText
 * @param {*} messageType
 * @description Submit Chat Message into the DB
 */
export function submitChatMessage(userId, messageText, messageType) {
    return async (dispatch, getState) => {
        const { data, error } = await submitQuestionFromAPI(userId, messageText, messageType);
        if (!error) {
            const chatId = data.id;
            const chatType = data.messageType;
            const index = -1;
            const chatDetails = data;
            dispatch(addChatMessage(index, chatDetails, chatType, chatId));
        }
    };
}

/**
 * addChatMessage
 * @param {*} index
 * @param {*} chatDetails
 * @param {*} chatType
 * @param {*} chatId
 * @description Add Chat Data into State
 */
export function addChatMessage(index, chatDetails, chatType, chatId) {
    return {
        type: actions.ADD_CHAT_MESSAGE,
        message: chatDetails,
        messageType: chatType,
        messageId: chatId,
        index: index,
    };
}

/**
 * commentChatMessageText
 * @param {*} userId
 * @param {*} messageId
 * @param {*} messageText
 * @param {*} messageType
 * @description Comment as a Text upon Chat Question Message
 */
export function commentChatMessageText(userId, messageId, messageText, messageType) {
    return async (dispatch, getState) => {
        const { data, error } = await submitTextCommentFromAPI(userId, messageId, messageText, messageType);
        if (!error) {
            let { chatData } = await getState();
            let { bannedUsers } = chatData;
            const chatId = data.id;
            const chatType = data.messageType;
            const chatDetails = parseChat(data, bannedUsers);
            dispatch(updateChatMessage(chatId, chatDetails, chatType));
            dispatch(updateChatComment(userId, '', chatType, chatId));
        }
    };
}


/**
 * updateChatComment
 * @param {*} userId
 * @param {*} chatMessage
 * @param {*} chatType
 * @param {*} chatId
 * @description Update Chat with Comment Which User is Writing
 */
export function updateChatComment(userId, chatMessage, chatType, chatId) {
    return {
        type: actions.UPDATE_CHAT_COMMENT,
        message: chatMessage,
        messageType: chatType,
        messageId: chatId
    };
}

export function addChatCommentData(index, messageId, commentId, commentDetails) {
    return async (dispatch, getState) => {
            let { chatData } = await getState();
            let { items, bannedUsers } = chatData;
            let chatIndex = -1;
            if (items.length > 0) {
                chatIndex = items.findIndex((item) => item['id'] === messageId);
            }
            if (chatIndex < 0) return;
            const chatItemComments = items[chatIndex].comments;
            const commentItem = parseChatComment(commentDetails, messageId, bannedUsers);
            chatItemComments.unshift(commentItem);
            const updatedChatItem = {
                ...items[chatIndex],
                comments: chatItemComments
            }
            dispatch(updateChatMessage(messageId, updatedChatItem, updatedChatItem.type));
    };
}

/**
 * updateChatMessage
 * @param {*} chatId
 * @param {*} chatDetails
 * @param {*} chatType
 * @description Update chat message with comments
 */
 export function updateChatMessage(chatId, chatDetails, chatType) {
    return {
        type: actions.UPDATE_CHAT_MESSAGE,
        message: chatDetails,
        messageType: chatType,
        messageId: chatId
    };
}

export function LikeQuestion(userId, messageText, messageType, messageId) {
    return async (dispatch, getState) => {
        const { error } = await LikeQuestionFromAPI(userId, messageText, messageType, messageId);
        if (!error) {
            let { chatData } = await getState();
            let { items } = chatData;
            let chatIndex = -1;
            if (items.length > 0) {
                chatIndex = items.findIndex((item) => item['id'] === messageId);
            }
            if (chatIndex < 0) return;
            let likeCount = +items[chatIndex].totalLikedBy+1;
            if (likeCount < 0) {
                likeCount = 0;
            }
            const newQuestionItemLikedBy = items[chatIndex].likedBy;
            dispatch(updateMessageLikeCount(chatIndex, messageId, items[chatIndex].type, newQuestionItemLikedBy, likeCount, true));
        }
    };
}

export function UnlikeQuestion(userId, messageText, messageType, messageId) {
    return async (dispatch, getState) => {
        const { error } = await UnlikeQuestionFromAPI(userId, messageText, messageType, messageId);
        if (!error) {
            let { chatData } = await getState();
            let { items } = chatData;
            let chatIndex = -1;
            if (items.length > 0) {
                chatIndex = items.findIndex((item) => item['id'] === messageId);
            }
            if (chatIndex < 0) return;
            let likeCount = +items[chatIndex].totalLikedBy-1;
            if (likeCount < 0) {
                likeCount = 0;
            }
            const newQuestionItemLikedBy = items[chatIndex].likedBy;
            dispatch(updateMessageLikeCount(chatIndex, messageId, items[chatIndex].type, newQuestionItemLikedBy, likeCount, false));
        }
    };
}

export function updateMessageLikeCount(chatIndex, chatId, chatType, likedBy, totalLikedBy, liked) {
    return {
        type: actions.UPDATE_CHAT_LIKE_COUNT,
        messageIndex: chatIndex,
        messageType: chatType,
        messageId: chatId,
        likedBy: likedBy,
        liked: liked,
        totalLikedBy: totalLikedBy
    };
}

export function updateMessageLike(chatIndex, chatId, likeCount, chatAction) {
    return async (dispatch, getState) => {
        let { chatData } = await getState();
        let { items } = chatData;
        if (chatAction === 'liked') {
            const newQuestionItemLikedBy = items[chatIndex].likedBy;
            dispatch(updateMessageLikeCount(chatIndex, chatId, items[chatIndex].type, newQuestionItemLikedBy, likeCount, items[chatIndex].liked));
        }
    };
}

export function updateMessageUnlike(chatIndex, chatId, likeCount, chatAction) {
    return async (dispatch, getState) => {
        let { chatData } = await getState();
        let { items } = chatData;
        if (chatAction === 'unliked') {
            const newQuestionItemLikedBy = items[chatIndex].likedBy;
            dispatch(updateMessageLikeCount(chatIndex, chatId, items[chatIndex].type, newQuestionItemLikedBy, likeCount, items[chatIndex].liked));
        }
    };
}

export function updateChatUserDetails(userId) {
    return async (dispatch, getState) => {
        const { data, error } = await updateUserDetailsFromAPI(userId);
        if (!error) {
            log(data);
        }
    };
}

export function hideMessageFromChat(chatIndex, chatId) {
    return {
        type: actions.HIDE_CHAT_MESSAGE,
        messageIndex: chatIndex,
        messageId: chatId
    };
}

export function hideCommentFromChat(chatIndex, chatId, commentId) {
    return {
        type: actions.HIDE_CHAT_COMMENT,
        messageIndex: chatIndex,
        messageId: chatId,
        commentId: commentId
    };
}

export function setBannedUsers(bannedUsers) {
    return {
        type: actions.SET_BANNED_USERS,
        bannedUsers: bannedUsers
    };
}

export function getBannedUsers(userId) {
    return async (dispatch, getState) => {
      const loggedInUser = isUserLoggedIn();
      const { user } = loggedInUser;
      const payload = {
        "user_id" : userId,
        "token" : user?.token
      };
      const { data } = await getUserAccountSettingsFromAPI(payload);
      if (data?.status){
        const bannedUsers = data?.data?.blocked_senders !== null && data?.data?.blocked_senders !== '' ? data?.data?.blocked_senders?.split(',') : [];
        const totalBannedUsers = bannedUsers?.length || 0;
        if (totalBannedUsers > 0) {
            dispatch(setBannedUsers(bannedUsers));
        }
      } 
    };
  }
