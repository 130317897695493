import React, { useEffect } from "react";
import { setPageTitle } from "utils";
import {
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AccountActions from "redux/actions/AccountActions";
import { getUserId, isUserLoggedIn } from "services/AuthService";
import UserAccessForgery from "modals/UserAccessForgery";
import "./ExamLayout.scss";

export default function ExamLayout({
  component: Component,
  pageTitle,
  reducerType,
  ...rest
}) {
  const userId = getUserId();
  const dispatch = useDispatch();
  const navigationData = useSelector((state) => state.navigationData);
  const location = useLocation();

  useEffect(() => {
    dispatch(AccountActions.getAccountInfo());
    // On Load
    return () => {
      // On UnLoad
    };
  }, [dispatch, userId]);

  useEffect(() => {
    if (pageTitle) setPageTitle(pageTitle);
  }, [pageTitle]);
  const { status } = isUserLoggedIn();

  return (
    <Route
      {...rest}
      render={(props) =>
        status ? (
        <div className="examLayout">
          <div className="examLayoutContainer">
              <div className="examPageContainer">
                <div className="examPage">
                  <Component {...props} />
                </div>
              </div>
          </div>
          {/*navigationData.showUserAccessForgery ? <UserAccessForgery /> : null*/}
        </div>
        ) : (
          <Redirect to={`/sign-in/?redirect=${location.pathname}`} />
        )
      }
    />
  );
}
